export const getImageRatio = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const url = URL.createObjectURL(file);

        image.onload = () => {
            resolve(image.width / image.height);
            URL.revokeObjectURL(url);
        };

        image.onerror = (error) => {
            reject(error);
            URL.revokeObjectURL(url);
        };

        image.src = url;
    });
};