import { action, computed, observable, flow, makeAutoObservable } from "mobx";
import {
    StorePreview,
    MobileDeal,
    MobileHomeContent,
} from "@full-circle-types";
import {
    AgvtState,
    CoreNavigation,
    CoreCouponManager,
} from "../../components/advance-gmap-visualization-tool";
import {
    GlowConfiguration,
    DemoMode,
    AllConfigurations,
} from "./glow-configurations";
import * as DemoContent from "./demo-content";

export class AgvtDemoStore implements AgvtState {
    @observable.shallow
    dealBuckets = DemoContent.DealBuckets;

    @observable.shallow
    deals: MobileDeal[] | null = null;

    loyalties = undefined;
    dealToActivate: MobileDeal | null = null;

    @observable
    store: StorePreview = {
        id: 2709355,
        simId: 7900382,
        city: "ST-JEAN-SUR-RICHELIEU",
        street: "165 Boul Omer Marcil",
        postalCode: "J2W 0A3",
        state: "QC",
        country: "CA",
    };

    @observable.shallow
    homeContent: MobileHomeContent = DemoContent.HomePage;

    @observable
    demoMode: DemoMode | null = null;

    constructor(
        public couponManager = new CoreCouponManager(),
        public navigation = new CoreNavigation()
    ) {
        makeAutoObservable(this);
    }

    @action
    setStore(store: StorePreview): void {
        this.store = store;
    }

    fetchHomeContent = flow(function* (this: AgvtDemoStore) {
        // do nothing
    });

    fetchDealBuckets = flow(function* (this: AgvtDemoStore) {
        // do nothing
    });

    fetchDeals = flow(function* (
        this: AgvtDemoStore,
        options: {
            dealBucketId: number;
        }
    ) {
        yield;
        const bucket = this.dealBuckets?.find(
            (db) => db.id === options.dealBucketId
        );
        this.deals = bucket!.deals;
    });

    fetchDeal = flow(function* (
        this: AgvtDemoStore,
        options: {
            dealId: number;
        }
    ) {
        yield;
        const deal = this.dealBuckets
            ?.flatMap((db) => db.deals)
            .find((d) => d.id === options.dealId);
        this.navigation.currentPage.data.deal = deal;
    });

    @action
    pushInStoreDealDemo(): void {
        this.navigation.push({
            section: "deal",
            data: {
                deal: this.dealBuckets
                    ?.flatMap((db) => db.deals)
                    .find((d) => d.type === "in_store"),
                bucketName: "demo",
            },
        });
    }

    @action
    pushCouponDealDemo(): void {
        this.navigation.push({
            section: "deal",
            data: {
                deal: this.dealBuckets
                    ?.flatMap((db) => db.deals)
                    .find((d) => d.type === "coupon"),
                bucketName: "demo",
            },
        });
    }

    @action
    pushContestDealDemo(): void {
        this.navigation.push({
            section: "deal",
            data: {
                deal: this.dealBuckets
                    ?.flatMap((db) => db.deals)
                    .find((d) => d.type === "contest"),
                bucketName: "demo",
            },
        });
    }

    @action
    setDemoMode(mode: DemoMode | null): void {
        this.demoMode = mode;
    }

    @computed
    get glowConfiguration(): GlowConfiguration {
        return this.demoMode ? AllConfigurations[this.demoMode] : {};
    }

    @computed
    get isCouponListVisible(): boolean {
        return (
            this.couponManager.couponList.length > 0 &&
            (this.navigation.currentPage.section === "deal-bucket" ||
                this.navigation.currentPage.section === "deals")
        );
    }
}
