/**
 * This is an helper class to help handle concurrent requests. As of now, the
 * only cases are list request with query parameters. Almost every time, the network
 * timing will be right, but since the yupValidation is also async it is easy to trigger
 * requests in order 1 -> 2 -> 3 and receive 1 -> 3 -> 2.
 *
 * So this helper class will only make sure only the last request can be shown
 */
export class WithNetworkConcurrency {
    private latestTag = Symbol();

    public getTag(): symbol {
        this.latestTag = Symbol();
        return this.latestTag;
    }

    public isLatestTag(tag: symbol): boolean {
        return this.latestTag === tag;
    }
}
