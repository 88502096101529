import * as yup from "yup";

// Language definitions in the both type and JS context
export type Lang = "en" | "fr";
export const Lang = ["en", "fr"] as const;
export const LangRegEx = /en|fr/;

const {
    REACT_APP_REQUIRED_LANGS = "en",
    REACT_APP_OPTIONAL_LANGS = "",
} = process.env;

export const RequiredLangs = REACT_APP_REQUIRED_LANGS.split(",").filter(
    Boolean
) as Lang[];
export const OptionalLangs = REACT_APP_OPTIONAL_LANGS.split(",").filter(
    Boolean
) as Lang[];

export const AllLangs = [...RequiredLangs, ...OptionalLangs];
export const isAppUniLang = AllLangs.length === 1;
export const isAppMultiLang = !isAppUniLang;

export const Market = process.env.REACT_APP_MARKET as string;

// Helper method to extract a localized item
export const getItem = <T extends { language: Lang }>(
    items: T[],
    lang: Lang
): T | undefined => {
    return items.find((i) => i.language === lang);
};

// Get the prop of a localized item
export const getItemProp = <T extends { language: Lang }>(
    items: T[],
    lang: Lang,
    key: keyof T
) => {
    const item = getItem(items, lang);
    return item?.[key];
};

//  Yup test method to validate if an items array contain an item for each language
export function validateItems(items: any): boolean {
    try {
        for (const lang of Lang) {
            const item = items.find((i: any) => i.language === lang);
            if (!item) {
                return false;
            }
        }
        return true;
    } catch (e) {
        return false;
    }
}

// Create yup items for the other objects
// eslint-disable-next-line @typescript-eslint/ban-types
export const createYupItems = <T extends object>(
    fields: yup.ObjectSchemaDefinition<T>,
    name: string
) => {
    return yup
        .array(
            yup
                .object<T>({
                    ...fields,
                })
                .shape({
                    language: yup.string<Lang>().matches(LangRegEx),
                })
        )
        .test("items", `Invalid ${name} items`, validateItems)
        .required()
        .min(2)
        .max(2);
};

// regEx to validate an email
export function validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// Utility method to modify an existing type
export type Modify<T, R> = Omit<T, keyof R> & R;
