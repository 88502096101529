import React from "react";
import { SupportedLanguage, Translations } from "../types";
import {
    useFormik,
    validateYupSchema,
    yupToFormErrors,
    FormikErrors,
} from "formik";
import set from "lodash/set";
import { ObjectSchema } from "yup";
import * as yup from "yup";
import { RequiredLangs, OptionalLangs, AllLangs } from ".";

export function useTranslationFormik<
    Form extends { translations?: Translations<any> }
>(
    initialValues: Form,
    getTranslationSchema: (form: Form) => ObjectSchema,
    getCoreSchema?: (form: Form) => ObjectSchema
) {
    const [selectedLangs, _setSelectedLangs] = React.useState<
        SupportedLanguage[]
    >(RequiredLangs);

    const formik = useFormik<Form>({
        initialValues,
        validateOnMount: true,
        validate: async (values) => {
            let errors: FormikErrors<Form> | undefined;

            // Basic check for the presence of all required languages
            for (const lang of RequiredLangs) {
                if (!selectedLangs.includes(lang)) {
                    return { e: `Missing ${lang}` };
                }
            }

            const translationSchema = getTranslationSchema(values);
            for (const lang of RequiredLangs) {
                try {
                    await validateYupSchema(
                        values.translations?.[lang],
                        translationSchema
                    );
                } catch (e) {
                    errors = errors ?? {};
                    set(errors, `translations.${lang}`, yupToFormErrors(e));
                }
            }

            for (const lang of OptionalLangs) {
                try {
                    if (selectedLangs.includes(lang)) {
                        await validateYupSchema(
                            values.translations?.[lang] ?? {},
                            translationSchema
                        );
                    }
                } catch (e) {
                    errors = errors ?? {};
                    set(errors, `translations.${lang}`, yupToFormErrors(e));
                }
            }
            try {
                if (getCoreSchema) {
                    await validateYupSchema(values, getCoreSchema(values));
                }
            } catch (e) {
                errors = errors ?? {};
                errors = { ...errors, ...yupToFormErrors(e) };
            }
            return errors;
        },
        onSubmit: () => {
            // do nothing
        },
    });

    React.useEffect(() => {
        formik.validateForm();
    }, [selectedLangs]);

    const setSelectedLangs = React.useCallback(
        (langs: SupportedLanguage[]) => {
            const filteredLangs = langs.filter((l) => AllLangs.includes(l));
            _setSelectedLangs(filteredLangs.sort((a, b) => (a > b ? 1 : -1)));
        },
        [_setSelectedLangs]
    );

    const getFinalValue = () => {
        const coreSchema = getCoreSchema
            ? getCoreSchema(formik.values)
            : yup.object();

        let translationSchema = yup
            .object({
                en: getTranslationSchema(formik.values).required(),
            })
            .required();

        if (selectedLangs.includes("fr")) {
            translationSchema = translationSchema.shape({
                fr: getTranslationSchema(formik.values),
            });
        }

        const fullSchema = coreSchema.shape({
            translations: translationSchema,
        });

        return fullSchema.validateSync(formik.values, {
            stripUnknown: true,
        });
    };

    return {
        formik,
        selectedLangs,
        setSelectedLangs,
        getFinalValue,
    };
}
