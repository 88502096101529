import React, { ReactElement } from "react";
import style from "./style.module.scss";
import { Market } from "../../utils";
import { HelpfulSpan } from "components/helpful-text";

interface Props {
    isBackButton?: boolean;
    children?: React.ReactNode;
    onBackClick?: () => void;
    section?: string;
}

export const CkLogo = (props: Props): ReactElement => {
    return (
        <span className={style.ck_logo}>
            {props.isBackButton && (
                <svg
                    className={style.back_button}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    onClick={() =>
                        props.onBackClick
                            ? props.onBackClick()
                            : window.history.back()
                    }
                >
                    <path
                        d="M10 13L5 8L10 3"
                        stroke="#98A2B3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
            <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/round-circle-k.jpg"}
                alt="logo"
            />
            FULL CIRCLE {Market && `- ${Market}`}
            {props.children && (
                <svg
                    className={style.extra_content_caret}
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                >
                    <path
                        d="M5.67813 3.82186L3.17813 1.32186C3.14198 1.28795 3.09697 1.26496 3.0483 1.25556C2.99964 1.24616 2.9493 1.25073 2.90313 1.26874C2.85763 1.28786 2.8188 1.32003 2.79156 1.36118C2.76431 1.40233 2.74985 1.45063 2.75 1.49999V6.49999C2.74985 6.54935 2.76431 6.59764 2.79156 6.63879C2.8188 6.67995 2.85763 6.71211 2.90313 6.73124C2.93396 6.74348 2.96682 6.74984 3 6.74999C3.06639 6.74971 3.13014 6.72398 3.17813 6.67811L5.67813 4.17811C5.72507 4.13071 5.75141 4.0667 5.75141 3.99999C5.75141 3.93328 5.72507 3.86926 5.67813 3.82186Z"
                        fill="#1D2939"
                    />
                </svg>
            )}
            <HelpfulSpan section={props.section}>
                {props.children ? props.children : null}
            </HelpfulSpan>
        </span>
    );
};
