import axios, { AxiosRequestConfig, AxiosError } from "axios";
import Qs from "qs";
import produce from "immer";
import * as yup from "yup";
import { action, makeObservable } from "mobx";
import { NotificationManager } from "../components";
import { PromotionStore } from "./promotion";
import { CategoryStore } from "./category";
import { FeaturedStore } from "./featured";
import { StoresStore } from "./stores";
import { StoreGroupsStore } from "./store-group";
import { GalleryStore } from "./gallery";
import { DealBucketStore } from "./deal-bucket";
import { LoyaltyStore } from "./loyalty";
import { ServiceStore } from "./service";
import { AuthStore } from "./auth";
import { ConfigStore } from "./config";
import { oktaAuth } from "../App";
import { TierBenefitStore } from "./tier-benefit";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const isAxiosError = (error: any): error is AxiosError => {
    return error && error.isAxiosError;
};

export type Result<T> = { data: T; err: null } | { data: null; err: true };
export type MakeNetworkCall<T = unknown, R = void> = Generator<
    Promise<Result<T>>,
    R,
    Result<T>
>;

export class RootStore {
    public promotionStore: PromotionStore;
    public categoryStore: CategoryStore;
    public featuredStore: FeaturedStore;
    public storesStore: StoresStore;
    public storeGroupsStore: StoreGroupsStore;
    public galleryStore: GalleryStore;
    public dealBucketStore: DealBucketStore;
    public loyaltyStore: LoyaltyStore;
    public serviceStore: ServiceStore;
    public tierBenefitStore: TierBenefitStore;
    public configStore: ConfigStore;
    public authStore = new AuthStore();

    private cancelSource = axios.CancelToken.source();

    constructor() {
        makeObservable(this);
        this.promotionStore = new PromotionStore(this);
        this.categoryStore = new CategoryStore(this);
        this.featuredStore = new FeaturedStore(this);
        this.storesStore = new StoresStore(this);
        this.storeGroupsStore = new StoreGroupsStore(this);
        this.galleryStore = new GalleryStore(this);
        this.dealBucketStore = new DealBucketStore(this);
        this.loyaltyStore = new LoyaltyStore(this);
        this.serviceStore = new ServiceStore(this);
        this.tierBenefitStore = new TierBenefitStore(this);
        this.configStore = new ConfigStore(this);
    }

    /**
     * Helper method to wrap all the chores associated with networks requests.
     *  • JWT management
     *  • Error handling
     *  • Base url
     *  • Payload validation
     *
     * @param this
     * @param opts
     * @param schema
     */
    @action
    async makeNetworkCall<NetworkResponse>(
        this: RootStore,
        opts: AxiosRequestConfig,
        schema?: yup.Schema<NetworkResponse>,
        validationOptions?: yup.ValidateOptions
    ): Promise<Result<NetworkResponse>> {
        try {
            const token = oktaAuth.getAccessToken();
            const augmentedOpts = produce(opts, (draft) => {
                if (draft.headers) {
                    draft.headers["jwt"] = token;
                    draft.headers["accept-language"] = "en";
                } else {
                    draft.headers = {
                        jwt: token,
                        "accept-language": "en",
                    };
                }
                draft.baseURL = `${REACT_APP_API_URL}/api-cms`;
                draft.cancelToken = this.cancelSource.token;
                draft.paramsSerializer = function (params) {
                    return Qs.stringify(params, { arrayFormat: "repeat" });
                };
            });
            const response = await axios.request<NetworkResponse>(
                augmentedOpts
            );

            if (schema) {
                const validatedData = await schema.validate(
                    response.data,
                    validationOptions
                );
                return { data: validatedData, err: null };
            }
            return { data: response.data, err: null };
        } catch (e) {
            console.error(e);
            if (isAxiosError(e)) {
                const status = e.response?.status;
                if (status === 401) {
                    NotificationManager.showError(e.response?.data?.message);
                    this.cancelSource.cancel();
                    oktaAuth.signOut();
                } else {
                    NotificationManager.showError(e.response?.data?.message);
                }
            } else if (!axios.isCancel(e)) {
                NotificationManager.showError("Unexpected error");
            }
            return { data: null, err: true };
        }
    }

    /**
     * Helper method to wrap all the chores associated with networks requests.
     *  • JWT management
     *  • Error handling
     *  • Base url
     *  • Payload validation
     *
     * @param this
     * @param opts
     * @param schema
     */
    @action
    async makeMobileNetworkCall<NetworkResponse>(
        this: RootStore,
        opts: AxiosRequestConfig
    ): Promise<
        { data: NetworkResponse; err: null } | { data: null; err: true }
    > {
        try {
            const augmentedOpts = produce(opts, (draft) => {
                if (draft.headers) {
                    draft.headers["accept-language"] = "en";
                } else {
                    draft.headers = {
                        "accept-language": "en",
                    };
                }
                draft.baseURL = `${REACT_APP_API_URL}/api-mobile`;
                draft.cancelToken = this.cancelSource.token;
            });
            const response = await axios.request<NetworkResponse>(
                augmentedOpts
            );
            return { data: response.data, err: null };
        } catch (e) {
            console.error(e);
            if (isAxiosError(e)) {
                NotificationManager.showError(e.response?.data?.message);
            } else if (!axios.isCancel(e)) {
                NotificationManager.showError("Unexpected error");
            }
            return { data: null, err: true };
        }
    }
}
