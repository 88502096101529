import {
    action,
    computed,
    observable,
    makeAutoObservable,
    makeObservable,
} from "mobx";
import { Navigation, StackItem } from "./agvt-state";

/**
 * Basic navigation implementation
 */
export class CoreNavigation implements Navigation {
    @observable.shallow
    stack: StackItem[] = [
        {
            section: "home",
        },
    ];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    push(path: StackItem): void {
        this.stack.push(path);
    }

    @action
    pop(): void {
        this.stack.pop();
    }

    @action
    replace(path: StackItem): void {
        this.stack.pop();
        this.stack.push(path);
    }

    @computed
    get currentPage(): StackItem {
        return this.stack[this.stack.length - 1];
    }
}

/**
 * Navigation implementation that always results in a no-op
 */
export class NoopNavigation implements Navigation {
    public stack: StackItem[];

    constructor(public stackItem: StackItem) {
        this.stack = [stackItem];
        makeObservable(this, {
            stack: observable,
        });
    }

    push(): void {
        console.log("NOOP");
    }
    pop(): void {
        console.log("NOOP");
    }

    replace(): void {
        console.log("NOOP");
    }

    get currentPage(): StackItem {
        return this.stack[0];
    }
}

/**
 * Navigation implementation that only allows to navigate
 * inside the inner-circle tab
 */
export class InnerCircleNavigation implements Navigation {
    public stack: StackItem[];

    constructor(public stackItem: StackItem) {
        this.stack = [stackItem];
        makeObservable(this, {
            stack: observable,
        });
    }

    push(path: StackItem): void {
        if (path.section === "loyalty") {
            this.stack.push(path);
        }
    }

    pop(): void {
        this.stack.pop();
    }

    replace(path: StackItem): void {
        if (path.section === "inner-circle") {
            this.stack.pop();
            this.stack.push(path);
        }
    }

    get currentPage(): StackItem {
        return this.stack[this.stack.length - 1];
    }
}

/**
 * Basic navigation implementation
 */
export class NoInnerCircleNavigation implements Navigation {
    @observable.shallow
    stack: StackItem[] = [
        {
            section: "home",
        },
    ];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    push(path: StackItem): void {
        path.section !== "inner-circle" && this.stack.push(path);
    }

    @action
    pop(): void {
        this.stack.pop();
    }

    @action
    replace(path: StackItem): void {
        if (path.section !== "inner-circle") {
            this.stack.pop();
            this.stack.push(path);
        }
    }

    @computed
    get currentPage(): StackItem {
        return this.stack[this.stack.length - 1];
    }
}
