import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Select } from "antd";
import { useFormik } from "formik";
import get from "lodash/get";
import style from "./style.module.scss";
import { useAuthStore, usePromotionStore } from "../../contexts/mobx";
import { Permission } from "../../utils/permission";
import promoStyle from "../promotion-page/style.module.scss";
import {
    ExternalUrlFeaturedForm,
    FeaturedForm,
    ModalFeaturedForm,
} from "../../models";
import { isAppMultiLang } from "../../utils";
import { DealPreview, Deal, AgeRestriction } from "@full-circle-types";
import { SupportedLanguage } from "types";
import {
    Select as EasySelect,
    ToggleSwitch,
    Input,
    DatePicker,
} from "../../easyui-components";
import {
    GalleryPicker,
    HelpfulSpan,
    ImageHolder,
    LangSelector,
    DebounceSelectMultiple,
    DebounceSelectSingle,
} from "../../components";
import moment from "moment-timezone";

const __doNotCall = () => useFormik<FeaturedForm>({} as any);
type Formik = ReturnType<typeof __doNotCall>;

interface Props {
    formik: Formik;
    deals: DealPreview[];
    langs: SupportedLanguage[];
    isFetching: boolean;
    setModalUrl: (url: string) => void;
    setModalVisible: (arg: boolean) => void;
    setSelectedLangs: (lang: SupportedLanguage[]) => void;
    params: "create" | "edit";
}
const INPUT_WIDTH = 200;

export const SideForm = observer((props: Props) => {
    const { role } = useAuthStore();
    const {
        formik,
        langs,
        setModalUrl,
        setModalVisible,
        setSelectedLangs,
        params,
    } = props;

    const { translations } = formik.values;
    const promotionStore = usePromotionStore();

    const canEnable = Permission.canEnable({ role });

    // The type inference is not working well for nested error schema. So we are
    // going to cheat a little bit using lodash
    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return hasError && (isTouched || params !== "create");
    };

    const timeZone = useMemo(() => {
        const now = new Date();
        const zone = moment.tz.guess().includes("America") ? "EST" : "UTC";

        const offsetMinutes = now.getTimezoneOffset();
        const offsetHours =
            zone === "EST" ? offsetMinutes / 60 - 4 : offsetMinutes / 60;

        const offsetDirection = offsetHours > 0 ? `${zone}-` : `${zone}+`;
        const absoluteOffsetHours = Math.abs(offsetHours);

        return `${offsetDirection}${absoluteOffsetHours}`;
    }, []);

    const onAgeRestrictionChange = React.useCallback(
        (e: AgeRestriction) => {
            formik.setFieldValue("ageRestriction", e);

            if (e !== "none" && formik.values.ageRestrictionType === null) {
                formik.setFieldValue("ageRestrictionType", "other");
            } else if (e === "none") {
                formik.setFieldValue("ageRestrictionType", null);
            }
        },
        [formik]
    );

    return (
        <>
            {/* Is active */}
            <div className={promoStyle.basic_input}>
                <HelpfulSpan section="featured-enabled" className="left_label">
                    Is enabled
                </HelpfulSpan>
                <ToggleSwitch
                    checked={formik.values.isActive}
                    name="isActive"
                    onChange={formik.handleChange}
                    disabled={!canEnable}
                />
            </div>

            {/* Is inner circle */}
            <div className={promoStyle.basic_input}>
                <HelpfulSpan
                    section="featured-inner-circle"
                    className="left_label"
                >
                    Is Inner Circle
                </HelpfulSpan>
                <ToggleSwitch
                    checked={formik.values.isInnerCircle}
                    name="isInnerCircle"
                    onChange={formik.handleChange}
                    disabled={!canEnable}
                />
            </div>

            {/* Type */}
            <div className={promoStyle.basic_input}>
                <HelpfulSpan section="featured-type">
                    Redirect to...
                </HelpfulSpan>
                <EasySelect
                    width={200}
                    onChange={(v) => {
                        formik.setFieldValue("isFullScreen", false);
                        return formik.setFieldValue("type", v);
                    }}
                    value={formik.values.type}
                >
                    <Select.Option value="deal">Deal</Select.Option>
                    <Select.Option value="external_url">
                        External url
                    </Select.Option>
                    <Select.Option value="inner_circle_offers">
                        Inner-Circle Offers
                    </Select.Option>
                    <Select.Option value="inner_circle_clubs">
                        Inner-Circle Clubs
                    </Select.Option>
                    <Select.Option value="inner_circle_rewards">
                        Inner-Circle Rewards
                    </Select.Option>
                    <Select.Option value="modal">Modal</Select.Option>
                </EasySelect>
            </div>

            {/* Position */}
            <div className={style.basic_input}>
                <HelpfulSpan section="featured-position">Position</HelpfulSpan>
                <Input
                    width={INPUT_WIDTH}
                    name="position"
                    onChange={(v) =>
                        formik.setFieldValue("position", v.target.value)
                    }
                    type="number"
                    value={formik.values.position}
                />
            </div>

            <div className={style.separator}></div>

            {/* Dates */}
            <div className={style.basic_input}>
                <HelpfulSpan section="featured-expiration">
                    Start Date ({timeZone})
                </HelpfulSpan>

                <DatePicker
                    width={INPUT_WIDTH}
                    value={formik.values.startDate}
                    hasError={checkIfHasError("startDate")}
                    label="Start"
                    onChange={(date) => formik.setFieldValue("startDate", date)}
                    disableIfBefore="now"
                    disabled={false}
                    ableToSetTime
                />
            </div>

            <div className={style.basic_input}>
                <HelpfulSpan section="featured-expiration">
                    End Date ({timeZone})
                </HelpfulSpan>

                <DatePicker
                    width={INPUT_WIDTH}
                    value={formik.values.endDate}
                    hasError={checkIfHasError("endDate")}
                    label="End"
                    onChange={(date) => formik.setFieldValue("endDate", date)}
                    disableIfBefore={formik.values.startDate}
                    disabled={false}
                    ableToSetTime
                />
            </div>

            {/* Store group */}
            <div className={promoStyle.basic_input}>
                <HelpfulSpan section="store-groups">Store Group</HelpfulSpan>
                <DebounceSelectMultiple
                    width={200}
                    placeholder="Store Group"
                    onChange={(v: number[]) =>
                        formik.setFieldValue("storeGroupIds", v)
                    }
                    value={formik.values.storeGroupIds}
                    tooltip={"Clear the Store input to select a Store"}
                />
            </div>

            <div className={style.separator}></div>

            {/*
                IDENTITY VERIFICATION
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="identity-verification">
                    Requires Identity Verification
                </HelpfulSpan>

                <ToggleSwitch
                    checked={formik.values.requiresIdentityVerification}
                    name="requiresIdentityVerification"
                    onChange={formik.handleChange}
                />
            </div>
            {/*
                AGE RESTRICTION
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="featured-age-restriction">
                    Age Restriction
                </HelpfulSpan>

                <EasySelect
                    width={INPUT_WIDTH}
                    value={formik.values.ageRestriction ?? undefined}
                    onChange={onAgeRestrictionChange}
                >
                    <Select.Option value="none">None</Select.Option>
                    <Select.Option value="18+">18+</Select.Option>
                    <Select.Option value="21+">21+</Select.Option>
                </EasySelect>
            </div>

            {/*
                AGE RESTRICTION TYPE
            */}
            {formik.values.ageRestriction !== "none" && (
                <div className={style.basic_input}>
                    <HelpfulSpan
                        section="featured-age-restriction-type"
                        className={style.required}
                    >
                        Age Restriction Type
                    </HelpfulSpan>

                    <EasySelect
                        width={INPUT_WIDTH}
                        value={formik.values.ageRestrictionType ?? undefined}
                        onChange={(e) =>
                            formik.setFieldValue("ageRestrictionType", e)
                        }
                    >
                        <Select.Option value="tobacco">Tobacco</Select.Option>
                        <Select.Option value="alcohol">Alcohol</Select.Option>
                        <Select.Option value="lottery">Lottery</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                    </EasySelect>
                </div>
            )}

            {formik.values.type === "external_url" && (
                <>
                    {/* Authentication Type */}
                    <div className={promoStyle.basic_input}>
                        <HelpfulSpan section="featured-authentication-type">
                            Authentication Type
                        </HelpfulSpan>
                        <EasySelect
                            width={200}
                            onChange={(authType) => {
                                if (authType === "no_authentication") {
                                    formik.setFieldValue(
                                        "authenticationName",
                                        null
                                    );
                                }
                                formik.setFieldValue(
                                    "authenticationType",
                                    authType
                                );
                            }}
                            value={formik.values.authenticationType}
                        >
                            <Select.Option value="no_authentication">
                                No Authentication
                            </Select.Option>
                            <Select.Option value="ck_token">
                                CK Token
                            </Select.Option>
                            <Select.Option value="user_id">
                                User ID
                            </Select.Option>
                            <Select.Option value="loyalty_id">
                                Loyalty ID
                            </Select.Option>
                            <Select.Option value="okta_id">
                                Okta ID
                            </Select.Option>
                        </EasySelect>
                    </div>

                    <div className={promoStyle.basic_input}>
                        <HelpfulSpan
                            section="featured-authentication-name"
                            className={
                                formik.values.authenticationType !==
                                "no_authentication"
                                    ? style.required
                                    : ""
                            }
                        >
                            Authentication Name
                        </HelpfulSpan>
                        <Input
                            disabled={
                                formik.values.authenticationType ===
                                "no_authentication"
                            }
                            width={200}
                            name={`authenticationName`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.authenticationName ?? ""}
                        />
                    </div>

                    <div className={style.separator}></div>
                </>
            )}

            {/* Visible promotions */}
            {formik.values.type === "deal" && (
                <div className={promoStyle.basic_input}>
                    <HelpfulSpan
                        section="featured-deal"
                        className={style.required}
                    >
                        Deal
                    </HelpfulSpan>

                    <DebounceSelectSingle<Deal>
                        width={200}
                        placeholder="Deal"
                        onChange={(v: number) =>
                            formik.setFieldValue("promotionId", v)
                        }
                        value={formik.values.promotionId ?? undefined}
                        getValue={(deal: Deal) => deal.id}
                        getPreviews={(q: string) =>
                            promotionStore.fetchForSelect({
                                q: q === "undefined" ? "" : q,
                            })
                        }
                        getDisplay={(deal: Deal) => (
                            <div className={style.custom_option}>
                                <div className={style.titles_holder}>
                                    <span>{deal.translations.en.title}</span>
                                </div>
                                <ImageHolder
                                    src={deal.translations.en.thumbnail}
                                    size={32}
                                    className={style.option_image}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setModalVisible(true);
                                        setModalUrl(
                                            deal.translations.en.thumbnail ?? ""
                                        );
                                    }}
                                />
                            </div>
                        )}
                        findElement={(deals: Deal[], id: number) =>
                            deals.find((deal) => deal.id === id)
                        }
                    />
                </div>
            )}

            {isAppMultiLang && (
                <LangSelector
                    langs={langs}
                    onChange={(langs) => setSelectedLangs(langs)}
                />
            )}

            {/* External url */}
            {formik.values.type === "external_url" && (
                <>
                    <div className={promoStyle.basic_input}>
                        <HelpfulSpan
                            section="featured-is-full-screen"
                            className={style.required}
                        >
                            Is Full Screen
                        </HelpfulSpan>
                        <ToggleSwitch
                            checked={formik.values.isFullScreen}
                            name="isFullScreen"
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={style.item_padding}>
                        {langs.map((lang) => (
                            <div key={lang} className={promoStyle.basic_input}>
                                <HelpfulSpan
                                    section="featured-external-url"
                                    className={style.required}
                                >
                                    External Url
                                </HelpfulSpan>
                                <Input
                                    width={INPUT_WIDTH}
                                    name={`translations.${lang}.externalUrl`}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={
                                        (formik.values as ExternalUrlFeaturedForm)
                                            .translations[lang]?.externalUrl
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}

            {/* Modal */}
            {formik.values.type === "modal" && (
                <div className={style.item_padding}>
                    {langs.map((lang) => (
                        <div key={lang} className={promoStyle.basic_input}>
                            <HelpfulSpan
                                section="featured-modal"
                                className={style.required}
                            >
                                Value
                            </HelpfulSpan>
                            <Input
                                width={INPUT_WIDTH}
                                name={`translations.${lang}.value`}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={
                                    (formik.values as ModalFeaturedForm)
                                        .translations[lang]?.value
                                }
                            />
                        </div>
                    ))}
                </div>
            )}

            {/* Background image */}
            {langs.map((lang) => (
                <>
                    <div className={style.basic_input}>
                        <HelpfulSpan
                            section="featured-cta"
                            className={style.required}
                        >
                            CTA
                        </HelpfulSpan>

                        <Input
                            width={INPUT_WIDTH}
                            name={`translations.${lang}.cta`}
                            onChange={formik.handleChange}
                            value={
                                (formik.values as ExternalUrlFeaturedForm)
                                    .translations[lang]?.cta
                            }
                        />
                    </div>
                    <div key={lang} className={style.rectangle_image}>
                        <HelpfulSpan
                            section="featured-background-image"
                            className={style.required}
                        >
                            Background Image
                        </HelpfulSpan>

                        <GalleryPicker
                            size={437 - 32}
                            dataType="featured"
                            url={translations[lang]?.backgroundImage}
                            onChange={(url) =>
                                formik.setFieldValue(
                                    `translations.${lang}.backgroundImage`,
                                    url
                                )
                            }
                            onDelete={() =>
                                formik.setFieldValue(
                                    `translations.${lang}.backgroundImage`,
                                    null
                                )
                            }
                        />
                    </div>
                </>
            ))}
        </>
    );
});
