import React, { useRef } from "react";
import { Tooltip } from "antd";
import style from "./style.module.scss";

interface ToggleSwitchProps {
    name?: string;
    checked: boolean;
    disabled?: boolean;
    tooltipMessage?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleSwitch = (props: ToggleSwitchProps) => {
    const hiddenInputRef = useRef<HTMLInputElement | null>(null);
    const [onHover, setOnHover] = React.useState(false);

    const classes = [style.toggle_container];

    if (props.checked) {
        classes.push(style.checked);
    }

    if (props.disabled) {
        classes.push(style.disabled);
    }

    return (
        <div
            className={classes.join(" ")}
            onClick={() => !props.disabled && hiddenInputRef.current?.click()}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
        >
            {props.tooltipMessage && (
                <Tooltip title={props.tooltipMessage} visible={onHover} />
            )}
            <div className={style.ball}></div>
            <input
                ref={hiddenInputRef}
                type="checkbox"
                name={props.name}
                onChange={props.onChange}
                checked={props.checked}
                className={style.hidden_input}
            />
        </div>
    );
};
