import React from "react";
import "./style.scss";
import { ImageHolder } from "../image-holder";

export type GlowingColor = "blue" | "red" | "purple" | "green" | "orange";

export function GlowingHoc<P>(
    Component: React.ForwardRefExoticComponent<P & React.RefAttributes<unknown>>
) {
    return function GlowingComponent(
        props: { glowColor?: GlowingColor } & P
    ): JSX.Element {
        const ref = React.useRef<HTMLElement>();

        React.useEffect(() => {
            if (ref.current) {
                ref.current.style.animation = props.glowColor
                    ? `${props.glowColor}-pulse 1.5s linear infinite`
                    : "";
            }
        }, [props.glowColor]);

        return <Component ref={ref} {...props} />;
    };
}

export function NativeGlowingHoc(Component: string) {
    return function GlowingComponent(
        props: { glowColor?: GlowingColor } & any
    ): JSX.Element {
        const ref = React.useRef<HTMLElement>();

        React.useEffect(() => {
            if (ref.current) {
                ref.current.style.animation = props.glowColor
                    ? `${props.glowColor}-pulse 1.5s linear infinite`
                    : "";
            }
        }, [props.glowColor]);

        const { glowColor, ...rest } = props;

        return React.createElement(
            Component,
            { ...rest, ref },
            ...(props.children
                ? Array.isArray(props.children)
                    ? props.children
                    : [props.children]
                : [])
        );
    };
}

export const GlowingImageHolder = GlowingHoc(ImageHolder);
export const GlowingDiv = NativeGlowingHoc("div");
export const GlowingSpan = NativeGlowingHoc("span");
