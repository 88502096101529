import React from "react";
import ReactDOM from "react-dom";

const TOP_HEADER_ID = "__top-header-content";

export const TopHeaderPortal = (props: {
    children: React.ReactNode;
}): React.ReactPortal | null => {
    const element = document.getElementById(TOP_HEADER_ID);
    const [, setFakeState] = React.useState(0);

    /**
     * The problem here is that the TopHeaderPortal will be rendered in the same commit as the rest of the app
     * on page load.
     *
     * So initially, at first the <code>element</code> will be null and the initial content won't be rendered.
     * As a hacky work-around, we can use React.useEffect to validate that the navbar has been rendered <b>after</b> the
     * commit.
     *
     * If it has, then create a fake rerender
     */
    React.useEffect(() => {
        const isNavbarRendered = !!document.getElementById(TOP_HEADER_ID);
        if (element === null && isNavbarRendered) {
            setFakeState((s) => s + 1);
        }
    }, [element]);

    return element && ReactDOM.createPortal(props.children, element);
};
