import React from "react";

const PromotionsIcon = () => {
    return (
        <span className="anticon">
            <svg
                width="0.9em"
                height="1.7em"
                style={{ marginBottom: -3 }}
                viewBox="0 0 17 28"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
            >
                <title>Icon_Promotions_1X</title>
                <g
                    id="Icon_Promotions"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M5.11653775,0 L5.31269249,0.45070243 C5.90649554,1.81507667 6.92573652,2.46289062 8.5,2.46289062 C10.0742635,2.46289062 11.0935045,1.81507667 11.6873075,0.45070243 L11.8834622,0 L17,0 L17,27.5 L11.8250156,27.5 L11.6596882,26.9754529 C11.1729847,25.431251 10.1727164,24.7114258 8.5,24.7114258 C6.82728364,24.7114258 5.82701528,25.431251 5.34031183,26.9754529 L5.17498441,27.5 L0,27.5 L0,0 L5.11653775,0 Z M4.15594022,1.5 L1.5,1.5 L1.5,26 L4.10071657,26 C4.86531005,24.1677549 6.37247153,23.2114258 8.5,23.2114258 C10.6275285,23.2114258 12.13469,24.1677549 12.8992834,26 L15.5,26 L15.5,18 L14,18 L14,16.5 L15.5,16.5 L15.5,1.5 L12.8440598,1.5 C11.9684511,3.12416878 10.4869573,3.96289062 8.5,3.96289062 C6.57325348,3.96289062 5.12180057,3.17423023 4.23720732,1.64548646 L4.15594022,1.5 Z M12,16.5 L12,18 L10,18 L10,16.5 L12,16.5 Z M8,16.5 L8,18 L6,18 L6,16.5 L8,16.5 Z M4,16.5 L4,18 L2,18 L2,16.5 L4,16.5 Z"
                        id="Combined-Shape"
                        fill="currentColor"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        </span>
    );
};

export default PromotionsIcon;
