import React from "react";
import style from "./style.module.scss";
import moment from "moment";

const TEN_MINUTES_IN_MS = 10 * 60 * 1000;

export const ActivationZone = (props: {
    activationCode: string;
    activationTime: number;
}): JSX.Element => {
    const [remainingTime, setRemainingTime] = React.useState(
        Math.max(props.activationTime - Date.now() + TEN_MINUTES_IN_MS, 0)
    );

    React.useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(
                Math.max(
                    props.activationTime - Date.now() + TEN_MINUTES_IN_MS,
                    0
                )
            );
        }, 1000);

        return () => clearInterval(timer);
    }, [props.activationTime]);

    const duration = moment.duration(remainingTime);

    return (
        <div className={style.activation_zone}>
            <p className={style.title}>Ask the cashier to scan this code</p>
            <div className={style.bar_code_holder}>
                <p className={style.bar_code}>{props.activationCode}</p>
            </div>
            <p className={style.normal_text}>{props.activationCode}</p>
            <p className={style.countdown}>
                {duration.minutes()}m :{" "}
                <span className={style.seconds_holder}>
                    {duration.seconds()}s
                </span>
            </p>
            <p className={style.normal_text}>Until coupon expires</p>
        </div>
    );
};
