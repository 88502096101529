import * as yup from "yup";
import { AssociationType } from "@full-circle-types";

export type Params = {
    action: "create" | "edit";
    id?: string;
};

export const INITIAL_VALUES = {
    associationType: "sim_name" as AssociationType,
    isEnabled: false,
    storeGroupIds: [],
    simName: "",
    inAppService: "",
    backgroundImage: null,
    supportedBUs: [],
};

export const coreSchema = yup.object({
    associationType: yup.mixed<AssociationType>().required(),
    isEnabled: yup.boolean().required(),
    simName: yup.string().nullable().max(255),
    storeGroupIds: yup.array().of(yup.number()),
    backgroundImage: yup.string().nullable(),
    supportedBus: yup.array().of(yup.string()),
});

export const convertBUCodes = (bu: string): string | undefined => {
    switch (bu) {
        case "US":
            return "United States";
        case "CA":
            return "Canada";
        case "SE":
            return "Sweden";
        case "NO":
            return "Norway";
        case "LT":
            return "Lithuania";
        case "LV":
            return "Latvia";
        case "EE":
            return "Estonia";
        case "DK":
            return "Denmark";
        case "PL":
            return "Poland";
    }
};
