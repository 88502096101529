import { MobileDeal } from "@full-circle-types";
import { action, observable, makeAutoObservable } from "mobx";
import { CouponManager, BottomDrawerContent } from "./agvt-state";

export class CoreCouponManager implements CouponManager {
    @observable.shallow
    couponList: MobileDeal[] = [];

    @observable
    activatedCoupons = new Map<number, number>();

    @observable
    bottomDrawerContent: BottomDrawerContent | null = null;

    @observable
    isBottomDrawerVisible = false;

    dealToActivate: MobileDeal | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    addCouponToList(coupon: MobileDeal): void {
        if (!this.couponList.find((c) => c.id === coupon.id)) {
            this.couponList.push(coupon);
        }
    }

    @action
    removeCouponFromList(coupon: MobileDeal): void {
        const index = this.couponList.findIndex((c) => c.id === coupon.id);
        if (index !== -1) {
            this.couponList.splice(index, 1);
        }
    }

    @action
    activateCoupon(): void {
        if (this.dealToActivate) {
            this.activatedCoupons.set(this.dealToActivate.id, Date.now());
        }
    }

    @action
    showBottomDrawer(content: BottomDrawerContent, deal?: MobileDeal): void {
        this.isBottomDrawerVisible = true;
        this.bottomDrawerContent = content;

        if (content === "coupon-activation") {
            this.dealToActivate = deal!;
        }
    }

    @action
    hideBottomDrawer(): void {
        this.isBottomDrawerVisible = false;
    }
}
