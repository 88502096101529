import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import * as yup from "yup";

import { linkDecorator } from "../../components/rich-text-editor/helper";
import {
    LoyaltyPayload,
    LoyaltyCmsDto,
    TierBenefitType,
} from "@full-circle-types";
import { LoyaltyType } from "../../utils/const";

/**
 * Helper method to create an editor state
 */
export const createEditorState = (text: string): EditorState => {
    const { contentBlocks, entityMap } = htmlToDraft(text);
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );
    return EditorState.createWithContent(contentState, linkDecorator);
};

/**
 * Helper method to convert a loyalty to a form
 *
 * @param loyalty
 * @returns
 */
export const convertToForm = (loyalty: LoyaltyCmsDto): LoyaltyPayload => {
    const form = {
        ...loyalty,
        categoryId: loyalty.category.id,
        storeGroupIds: loyalty.storeGroups.map((sg) => sg.id),
    } as LoyaltyPayload;

    return form;
};

export const lllinverseConverter = (promo: any): unknown => {
    const hashConfig = {
        trigger: "#",
        separator: " ",
    };

    return {
        ...promo,
        items: promo.items.map((x: any) => {
            const rawContentState = convertToRaw(
                x.description.getCurrentContent()
            );
            const markup = draftToHtml(rawContentState, hashConfig);
            return {
                ...x,
                description: markup,
            };
        }),
    };
};

export const getLangStyle = (
    lang: string,
    selectedLangs: string[]
): { display?: undefined } | { display: string } => {
    if (selectedLangs.includes(lang)) {
        return {};
    } else {
        return { display: "none" };
    }
};

export const INITIAL_VALUES: LoyaltyPayload = {
    type: "club",
    emptyPunchIcon: "",
    filledPunchIcon: "",
    brierleyClubId: "",
    categoryId: undefined,
    storeGroupIds: [],
    isEnabled: false,
    translations: {
        en: {
            title: "",
            subTitle: "",
            description: "",
            image: "",
            thumbnail: "",
            tosLink: "",
        },
    },
};

export const translationSchema = yup.object({
    title: yup.string().required(),
    subTitle: yup.string().required(),
    description: yup.string().required(),
    image: yup.string().required(),
    thumbnail: yup.string().nullable(),
    tosLink: yup.string().required(),
    titleHighlight: yup.string().nullable().max(20),
});

const coreSchema = yup.object({
    type: yup.string<LoyaltyType>().required(),
    categoryId: yup.number().required(),
    position: yup.number().nullable(),
    storeGroupIds: yup.array().of(yup.number()),
    isEnabled: yup.boolean().required(),
});

const clubSchema = coreSchema.shape({
    emptyPunchIcon: yup.string().required(),
    filledPunchIcon: yup.string().required(),
    brierleyClubId: yup.string().required(),
});

const rewardSchema = coreSchema.shape({
    filledPunchIcon: yup.string().required(),
    brierleyRewardId: yup.string().required(),
});

const offerSchema = coreSchema.shape({
    brierleyOfferId: yup.string().required(),
    isNonTargeted: yup.boolean().required(),
    isAgeVerified: yup.boolean().required(),
    tier: yup.string<TierBenefitType>().nullable(),
    startDate: yup.string().nullable(),
    endDate: yup.string().nullable(),
    isVisibleOnHomePage: yup.boolean().nullable(),
});

export const getCoreSchema = (form: LoyaltyPayload): yup.ObjectSchema => {
    if (form.type === "club") {
        return clubSchema;
    } else if (form.type === "reward") {
        return rewardSchema;
    } else {
        return offerSchema;
    }
};

export const getTranslationSchema = (): yup.ObjectSchema<{
    title: string;
    subTitle: string;
    description: string;
    image: string;
    thumbnail: string | null;
    tosLink: string;
    titleHighlight: string | null;
}> => {
    return translationSchema;
};
