import React from "react";
import { observer } from "mobx-react";
import style from "./style.module.scss";
import { DealBucketPreview } from "../../deal-bucket-preview";
import { StandardCard } from "../../standard-card";
import { useAgvtStore } from "..";
import { GlowingDiv } from "../../glowing-hoc";
import { MobileDealBucket } from "@full-circle-types";

export const DealBucketPage = observer(() => {
    const store = useAgvtStore();
    const bucket = store.navigation.currentPage.data as MobileDealBucket;

    React.useEffect(() => {
        store.fetchDealBuckets();
        store.fetchDeals({ dealBucketId: bucket.id });
    }, [bucket, store]);

    const { glowConfiguration } = store;

    return (
        <div className={style.page}>
            <div
                className={style.sticky_header}
                onClick={() => store.navigation.pop()}
            >
                ← {bucket.name}
            </div>
            <div className={style.main}>
                <span className={style.explore_subtitle}>Explore Deals</span>
                <div className={style.list_holder}>
                    <div className={style.deal_list}>
                        {store.dealBuckets &&
                            store.dealBuckets.map((d) => (
                                <DealBucketPreview
                                    key={d.id}
                                    image={
                                        d.id === bucket.id
                                            ? d.focusedIcon
                                            : d.notFocusedIcon
                                    }
                                    name={d.name}
                                    glowConfiguration={
                                        glowConfiguration.dealBucket
                                    }
                                    isSelected={d.id === bucket.id}
                                    onClick={() =>
                                        store.navigation.push({
                                            section: "deal-bucket",
                                            parentSection: "deals",
                                            data: d,
                                        })
                                    }
                                />
                            ))}
                    </div>
                    <GlowingDiv
                        glowColor={glowConfiguration.dealBuckets}
                        className={style.list_glower}
                    ></GlowingDiv>
                </div>
                <div className={style.vertical_deal_list}>
                    {store.deals &&
                        store.deals.map((d) => (
                            <StandardCard
                                glowingConfiguration={
                                    glowConfiguration.dealCard
                                }
                                onClick={() =>
                                    store.navigation.push({
                                        section: "deal",
                                        parentSection: "deals",
                                        data: {
                                            deal: d,
                                            bucketName: "store specials",
                                        },
                                    })
                                }
                                cursorType="pointer"
                                key={d.id}
                                content={StandardCard.transform(d)}
                                fluid={true}
                                isCouponSelected={
                                    !!store.couponManager.couponList.find(
                                        (c) => c.id === d.id
                                    )
                                }
                                onAddCoupon={() =>
                                    store.couponManager.addCouponToList(d)
                                }
                                onRemoveCoupon={() =>
                                    store.couponManager.removeCouponFromList(d)
                                }
                            />
                        ))}
                </div>
            </div>
        </div>
    );
});
