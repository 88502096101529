import { notification } from "antd";
import "./style.scss";

export const NotificationManager = {
    showError: (description = "Unexpected error", duration = 4.5): void => {
        notification.open({
            className: "notification-error",
            message: description,
            placement: "bottomRight",
            bottom: 0,
            duration,
        });
    },
    showSuccess: (description: string): void => {
        notification.open({
            className: "notification-success",
            message: description,
            placement: "bottomRight",
            bottom: 0,
        });
    },
    showWarning: (description: string): void => {
        notification.open({
            className: "notification-warning",
            message: description,
            placement: "bottomRight",
            bottom: 0,
        });
    },
};
