import moment from "moment";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import * as yup from "yup";

import { linkDecorator } from "../../components/rich-text-editor/helper";
import { DealForm } from "../../models";
import { Deal, GroupLimitations } from "@full-circle-types";
import { PromotionTypes, PromotionTypesRegEx } from "../../utils/const";

/**
 * Helper method to create an editor state
 */
export const createEditorState = (text: string): EditorState => {
    const { contentBlocks, entityMap } = htmlToDraft(text);
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );
    return EditorState.createWithContent(contentState, linkDecorator);
};

/**
 * Helper method to convert a deal to a form
 *
 * @param deal
 * @returns
 */
export const convertToForm = (deal: Deal): DealForm => {
    const form = {
        ...deal,
        bucketId: deal.bucketId ?? undefined,
        storeGroupIds: deal.storeGroups.map((sg) => sg.id) ?? null,
        categoryId: deal.category.id,
    };

    return form;
};

export const inverseConverter = (promo: any): unknown => {
    const hashConfig = {
        trigger: "#",
        separator: " ",
    };

    return {
        ...promo,
        items: promo.items.map((x: any) => {
            const rawContentState = convertToRaw(
                x.description.getCurrentContent()
            );
            const markup = draftToHtml(rawContentState, hashConfig);
            return {
                ...x,
                description: markup,
            };
        }),
    };
};

export const getLangStyle = (
    lang: string,
    selectedLangs: string[]
): { display?: undefined } | { display: string } => {
    if (selectedLangs.includes(lang)) {
        return {};
    } else {
        return { display: "none" };
    }
};

export const INITIAL_VALUES: DealForm = {
    startDate: moment().startOf("day").toISOString(),
    endDate: moment().endOf("day").toISOString(),
    isActive: false,
    isVisibleOnHomePage: false,
    type: "in_store",
    categoryId: undefined,
    bucketId: undefined,
    storeGroupIds: [],
    requiresIdentityVerification: false,
    ageRestriction: "none",
    ageRestrictionType: "other",
    groupLimitation: null,
    translations: {
        en: {
            title: "",
            subTitle: null,
            description: null,
            thumbnail: null,
            smallThumbnail: null,
            tos: null,
        },
    },
};

export const translationSchema = yup.object({
    title: yup.string().required(),
    subTitle: yup.string().required(),
    description: yup.string().required(),
    thumbnail: yup.string().required(),
    smallThumbnail: yup.string().nullable(),
    tos: yup.string().nullable(),
});

export const contestTranslationSchema = translationSchema.shape({
    externalUrl: yup.string().min(1).required(),
    cta: yup.string().min(1).required(),
});

const coreSchema = yup.object({
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    isActive: yup.boolean().required(),
    requiresIdentityVerification: yup.boolean().required(),
    isVisibleOnHomePage: yup.boolean().required(),
    groupLimitation: yup.string<GroupLimitations>().nullable(),
    storeGroupIds: yup.array().of(yup.number()),
    ageRestriction: yup.string(),
    ageRestrictionType: yup.string().nullable(),
    type: yup.string<PromotionTypes>().matches(PromotionTypesRegEx).required(),
    bucketId: yup.number().nullable(),
    categoryId: yup.number().required(),
    position: yup.number().notRequired().nullable(),
});

const couponCoreSchema = coreSchema.shape({
    activationCode: yup.string().required(),
    quantity: yup.number().required(),
    maxActivationsCount: yup.number().required(),
});

export const getCoreSchema = (form: DealForm) => {
    if (form.type === "coupon") {
        return couponCoreSchema;
    } else {
        return coreSchema;
    }
};

export const getTranslationSchema = (
    form: DealForm
): yup.ObjectSchema<{
    title: string;
    subTitle: string;
    description: string;
    thumbnail: string;
    smallThumbnail: string | null;
    tos: string | null;
}> => {
    if (form.type === "contest") {
        return contestTranslationSchema;
    } else {
        return translationSchema;
    }
};
