import React from "react";
import { Spin, Modal, Select } from "antd";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import * as yup from "yup";
import { setAutoFreeze } from "immer";
import {
    useCategoryStore,
    usePromotionStore,
    useStoreGroupsStore,
    useDealBucketStore,
    useGalleryStore,
    useAuthStore,
} from "../../contexts/mobx";
import style from "./style.module.scss";
import { useNavigation } from "../../utils/use-navigation";
import { useTranslationFormik } from "../../utils/use-translation-formik";
import { DealForm } from "../../models";
import { GalleryLabel, DealType } from "@full-circle-types";
import { Permission } from "../../../../shared-utils/";
import { Button, Select as EasySelect } from "../../easyui-components";
import { SupportedLanguage } from "../../types";
import {
    convertToForm,
    getTranslationSchema,
    getCoreSchema,
    INITIAL_VALUES,
} from "./utils";
import { AGVT, TopHeaderPortal, ShadowSwitch, CkLogo } from "../../components";
import { AgvtDealStore } from "./agvt-store";
import { SideForm } from "./side-form";
import { NavigationSection } from "components/advance-gmap-visualization-tool";

setAutoFreeze(false);

// Validation for the expected params
const YupParams = yup.object({
    action: yup.string().oneOf(["create", "edit", "duplicate"]),
    id: yup
        .string()
        .test("shouldBeDefined", "Id should be defined", function (v) {
            const action = this.resolve(yup.ref("action"));
            return !(v === undefined && action !== "create");
        })
        .notRequired(),
});

type Params = yup.InferType<typeof YupParams>;

export const PromotionPage = observer(function PromotionPage() {
    const categoryStore = useCategoryStore();
    const promotionStore = usePromotionStore();
    const storeGroupsStore = useStoreGroupsStore();
    const dealBucketStore = useDealBucketStore();
    const galleryStore = useGalleryStore();
    const authStore = useAuthStore();
    const params = useParams<Params>();
    const navigation = useNavigation();
    const { current: mobilePreviewStore } = React.useRef(new AgvtDealStore());
    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);
    const [isFetching, setIsFetching] = React.useState<boolean>(
        params.action !== "create"
    );
    const [initialDealType, setInitialDealType] = React.useState<DealType>(
        "in_store"
    );
    const role = authStore.role;

    if (!YupParams.isValidSync(params)) {
        navigation.replaceToPromotionCreate();
    }

    const { setSelectedLangs, formik, getFinalValue } = useTranslationFormik<
        DealForm
    >(INITIAL_VALUES, getTranslationSchema, getCoreSchema);

    React.useEffect(() => {
        mobilePreviewStore.syncWithForm(formik.values);
    }, [formik.values, mobilePreviewStore]);

    React.useEffect(() => {
        mobilePreviewStore.syncWithCategories(categoryStore.categoryPreviews);
    }, [categoryStore.categoryPreviews, mobilePreviewStore]);

    React.useEffect(() => {
        mobilePreviewStore.syncWithDealBuckets(dealBucketStore.previews);
    }, [dealBucketStore.previews, mobilePreviewStore]);

    React.useEffect(() => {
        categoryStore.fetchCategoryPreviews();
    }, [categoryStore]);

    React.useEffect(() => {
        dealBucketStore.fetchPreviews();
    }, [dealBucketStore]);

    React.useEffect(() => {
        galleryStore.fetchGalleryPreview("tos" as GalleryLabel);
    }, [galleryStore]);

    React.useEffect(() => {
        if (params.action === "edit") {
            setIsFetching(true);
            promotionStore
                .fetchSinglePromotion(Number(params.id))
                .then((deal) => {
                    const langs = Object.keys(deal.translations);
                    const dealForm = convertToForm(deal);
                    setSelectedLangs(langs as SupportedLanguage[]);
                    formik.setValues(dealForm);
                    setInitialDealType(dealForm.type);
                })
                .catch(() => navigation.replaceToPromotionCreate())
                .finally(() => setIsFetching(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotionStore]);

    const createPromotion = async () => {
        setIsUpserting(true);
        const finalValue = getFinalValue();
        const success = await promotionStore.createPromotion(
            finalValue as DealForm
        );
        if (success) {
            navigation.replaceToPromotions();
            setIsUpserting(false);
        }
    };

    const updatePromotion = async () => {
        setIsUpserting(true);
        const finalValue = getFinalValue();

        const success = await promotionStore.modifyPromotion(
            Number(params.id),
            finalValue as DealForm
        );
        if (success) {
            setIsUpserting(false);
        }
    };

    const deletePromotion = async () => {
        Modal.confirm({
            title: "Are you sure you want to delete this deal?",
            onOk: async () => {
                await promotionStore.delete(Number(promotionId));
                navigation.replaceToPromotions();
            },
        });
    };

    const promotionId = params.id;

    const canEdit = Permission.canEdit({
        role,
        enabled: formik.values.isActive,
    });

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="deal">
                        DEAL {params.id}
                    </CkLogo>

                    <div className={style.shadow_switch_holder}>
                        <ShadowSwitch<NavigationSection>
                            value={
                                mobilePreviewStore.navigation.currentPage
                                    .section
                            }
                            items={[
                                { value: "home", display: "Home" },
                                { value: "deal", display: "Details" },
                                { value: "deal-bucket", display: "Bucket" },
                            ]}
                            onChange={(value: NavigationSection) =>
                                mobilePreviewStore.handleExternalNavigation(
                                    value
                                )
                            }
                        />
                    </div>
                    <div className="nav_button_holder">
                        {isUpserting && (
                            <>
                                <Spin />{" "}
                            </>
                        )}
                        {params.action !== "create" ? (
                            <>
                                <Button
                                    disabled={isFetching || !canEdit}
                                    type="secondary"
                                    onClick={deletePromotion}
                                >
                                    Delete
                                </Button>
                                <Button
                                    disabled={
                                        isFetching ||
                                        !canEdit ||
                                        !formik.isValid
                                    }
                                    type="primary"
                                    onClick={updatePromotion}
                                >
                                    SAVE
                                </Button>
                            </>
                        ) : (
                            <Button
                                disabled={isFetching || !formik.isValid}
                                type="primary"
                                onClick={createPromotion}
                            >
                                CREATE
                            </Button>
                        )}
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.deal_page}>
                <div className={style.preview_holder}>
                    <div className={style.display_mode_holder}>
                        <EasySelect
                            withWhiteBackground
                            width={150}
                            value={mobilePreviewStore.displayMode}
                            onChange={(value) =>
                                mobilePreviewStore.setDisplayMode(value)
                            }
                        >
                            <Select.Option value="deal-only">
                                Deal Only
                            </Select.Option>
                            <Select.Option value="fake-content">
                                Fake Content
                            </Select.Option>
                        </EasySelect>
                    </div>
                    <AGVT store={mobilePreviewStore} />
                </div>
                <div className={style.side_form}>
                    {isFetching ? (
                        <div className={style.spinner_center}>
                            <Spin />
                        </div>
                    ) : (
                        <SideForm
                            formik={formik}
                            categories={categoryStore.categoryPreviews}
                            dealBuckets={dealBucketStore.previews}
                            storeGroupPreviews={
                                storeGroupsStore.storeGroupPreviews
                            }
                            tos={galleryStore.previews}
                            id={Number(params.id)}
                            initialDealType={initialDealType}
                        />
                    )}
                </div>
            </div>
        </>
    );
});
