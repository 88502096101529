import React from "react";
import { ColumnProps } from "antd/es/table";
import { Table } from "antd";
import { observer } from "mobx-react";

import style from "./style.module.scss";
import { Button, Input, Highlighter } from "../../easyui-components";
import { Pagination, CkLogo, TopHeaderPortal } from "../../components";
import { useCategoryStore } from "../../contexts/mobx";
import { useQueryParams } from "../../utils/use-query-params";
import { useNavigation } from "../../utils/use-navigation";
import { CategoryDto, CategorySearchParams } from "@full-circle-types";

export const CategoriesPage = observer(() => {
    const categoryStore = useCategoryStore();
    const navigation = useNavigation();

    const [queryParams, updateQueryParams] = useQueryParams<
        CategorySearchParams
    >();

    React.useEffect(() => {
        categoryStore.fetchCategories(queryParams);
    }, [categoryStore, queryParams]);

    const renderTitle = (_: unknown, record: CategoryDto) => {
        return (
            <Highlighter search={queryParams.q ?? ""}>
                {record.translations.en.title}
            </Highlighter>
        );
    };

    const columns: ColumnProps<CategoryDto>[] = [
        {
            title: "Title",
            key: "title",
            width: "20%",
            render: renderTitle,
        },
    ];

    const goToEditPage = (c: CategoryDto) => () => {
        navigation.goToCategoryEdit(c.id);
    };

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton>CATEGORIES</CkLogo>
                    <div className="nav_button_holder">
                        <Button
                            type="primary"
                            onClick={() => navigation.goToCategoryCreate()}
                        >
                            CREATE NEW
                        </Button>
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.categories_page}>
                <Input
                    value={queryParams.q}
                    onChange={(e) =>
                        updateQueryParams({ q: e.target.value, page: 0 })
                    }
                    allowClear={true}
                    placeholder="Search"
                />
                <Pagination
                    className={style.pagination}
                    params={queryParams}
                    meta={categoryStore.categories}
                    onChange={(page, perPage) =>
                        updateQueryParams({
                            page,
                            perPage,
                        })
                    }
                    onPageSizeChange={(perPage) =>
                        updateQueryParams({ perPage })
                    }
                />
                <Table<CategoryDto>
                    className="clickable_table"
                    rowKey="id"
                    dataSource={categoryStore.categories?.results}
                    columns={columns}
                    loading={categoryStore.isFetchingCount > 0}
                    pagination={false}
                    onRow={(r) => ({
                        onClick: goToEditPage(r),
                    })}
                />
            </div>
        </>
    );
});
