import React from "react";
import { Select } from "antd";

function Wrapper<T extends { id: number }>(props: {
    loading: boolean;
    value?: number;
    onChange: (id: number) => void;
    options: T[];
    defaultKey: keyof T;
}) {
    const { loading, value, onChange, options, defaultKey } = props;
    return (
        <Select
            loading={loading}
            style={{ width: 200 }}
            defaultValue={undefined}
            allowClear={true}
            onChange={onChange}
            value={value}
        >
            {options.map((preview) => (
                <Select.Option value={preview.id} key={preview.id}>
                    {preview[defaultKey]}
                </Select.Option>
            ))}
        </Select>
    );
}

export const WrapperSelect = React.memo(Wrapper) as typeof Wrapper;
