import React from "react";
import { observer } from "mobx-react";
import { useAgvtStore } from "..";
import style from "../deal-page/style.module.scss";
import { GlowingSpan, GlowingColor, GlowingDiv } from "../../glowing-hoc";
import { ClubPreviewProgress } from "../../club-preview";
import { InnerCircleHeader } from "../slick-header";

export interface GlowingConfiguration {
    title?: GlowingColor;
    description?: GlowingColor;
    category?: GlowingColor;
    subTitle?: GlowingColor;
    expiration?: GlowingColor;
    image?: GlowingColor;
    thumbnail?: GlowingColor;
    tos?: GlowingColor;
    progress?: GlowingColor;
    offer?: GlowingColor;
    club?: GlowingColor;
    reward?: GlowingColor;
    emptyIcon?: GlowingColor;
    filledIcon?: GlowingColor;
    tab?: GlowingColor;
    titleHighlight?: GlowingColor;
}

export const LoyaltyPage = observer(() => {
    const store = useAgvtStore();

    const { loyaltyType, fakeLoyalty } = store.navigation.currentPage.data;
    const glowConfiguration = store.glowConfiguration.loyalty;

    return (
        <div>
            <InnerCircleHeader sticky bordered>
                <span
                    className={style.back_button}
                    onClick={() => store.navigation.pop()}
                >
                    ← {loyaltyType}
                </span>
            </InnerCircleHeader>

            <div className={style.image_holder}>
                <img
                    className={style.deal_img}
                    src={fakeLoyalty.image ?? undefined}
                    alt="deal visual"
                />
                <GlowingDiv
                    glowColor={glowConfiguration?.image}
                    className={style.list_glower}
                ></GlowingDiv>
            </div>
            <div className={style.page_content}>
                <GlowingSpan
                    className={style.category}
                    glowColor={glowConfiguration?.category}
                >
                    {fakeLoyalty.category}
                </GlowingSpan>
                <GlowingSpan
                    className={style.title}
                    glowColor={glowConfiguration?.title}
                >
                    {fakeLoyalty.title}
                </GlowingSpan>
                {fakeLoyalty.subTitle && (
                    <GlowingSpan
                        className={style.subtitle}
                        glowColor={glowConfiguration?.subTitle}
                    >
                        {fakeLoyalty.subTitle}
                    </GlowingSpan>
                )}
                {loyaltyType !== "offer" && (
                    <ClubPreviewProgress
                        progress={
                            loyaltyType === "club" ? fakeLoyalty.progress : 5
                        }
                        filledIcon={fakeLoyalty.filledIcon}
                        emptyIcon={fakeLoyalty.emptyIcon}
                    />
                )}
                <GlowingSpan
                    className={style.subtitle}
                    glowColor={glowConfiguration?.expiration}
                >
                    Expire in 10 days
                </GlowingSpan>

                <GlowingSpan
                    className={style.tos}
                    glowColor={glowConfiguration?.tos}
                >
                    View terms & conditions
                </GlowingSpan>

                <GlowingSpan
                    className={style.description}
                    dangerouslySetInnerHTML={{
                        __html: fakeLoyalty.description ?? "",
                    }}
                    glowColor={glowConfiguration?.description}
                ></GlowingSpan>
            </div>
        </div>
    );
});
