import { observable, makeObservable, flow } from "mobx";

import { MakeNetworkCall, RootStore } from "./root";
import {
    PaginatedStoreGroups,
    StoreGroup,
    StoreGroupParams,
    StoreGroupPreview,
    StoreGroupPreviewParams,
} from "@full-circle-types";
import { NotificationManager } from "../components";
import { WithNetworkConcurrency } from "./with-network-concurrency";

export class StoreGroupsStore extends WithNetworkConcurrency {
    @observable paginatedList: PaginatedStoreGroups | null = null;
    @observable storeGroupPreviews: StoreGroupPreview[] = [];
    @observable isFetchingStoreGroups = false;
    @observable isDeleting = false;
    @observable isUpdating = false;
    @observable isCreating = false;
    @observable isFetchingCount = 0;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    fetchStoreGroups = flow(function* (
        this: StoreGroupsStore,
        params?: StoreGroupParams
    ) {
        const tag = this.getTag();
        this.isFetchingCount++;
        const response = yield this.rootStore.makeNetworkCall<StoreGroup[]>({
            method: "get",
            url: "/store-groups",
            params,
        });
        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.paginatedList = response.data;
        }
    });

    fetchStoreGroupsPreview = flow(function* (
        this: StoreGroupsStore,
        params?: StoreGroupPreviewParams
    ): MakeNetworkCall<StoreGroupPreview[], StoreGroupPreview[] | undefined> {
        const tag = this.getTag();

        this.isFetchingCount++;

        const response = yield this.rootStore.makeNetworkCall<
            StoreGroupPreview[]
        >({
            method: "get",
            url: "/store-groups/preview",
            params,
        });

        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.storeGroupPreviews = response.data;
            return response.data;
        }
    });

    updateStoreGroup = flow(function* (
        this: StoreGroupsStore,
        id: number,
        name: string,
        description: string,
        storeIds: string[]
    ) {
        if (this.isFetchingStoreGroups) {
            return;
        }

        this.isUpdating = true;
        yield this.rootStore.makeNetworkCall({
            method: "put",
            url: `/store-groups/${id}`,
            data: {
                name,
                description,
                storeIds,
            },
        });
        this.isUpdating = false;
    });

    createStoreGroup = flow(function* (
        this: StoreGroupsStore,
        name: string,
        description: string,
        storeIds: string[]
    ): MakeNetworkCall<void, boolean> {
        if (this.isFetchingStoreGroups) {
            return false;
        }

        this.isCreating = true;
        const result = yield this.rootStore.makeNetworkCall({
            method: "post",
            url: `/store-groups`,
            data: {
                name,
                description,
                storeIds,
            },
        });
        this.isCreating = false;

        return !result.err;
    });

    deleteStoreGroup = flow(function* (this: StoreGroupsStore, id: number) {
        if (this.isDeleting) {
            return;
        }

        this.isDeleting = true;
        const result = yield this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/store-groups/${id}`,
        });
        this.isDeleting = false;

        if (!result.err) {
            NotificationManager.showSuccess("Store Group deleted!");
        }
    });

    get isLoading(): boolean {
        return this.isFetchingStoreGroups || this.isUpdating || this.isCreating;
    }
}
