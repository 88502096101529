import React from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "../../contexts/mobx";
import { Select, Option } from "../../easyui-components";
import { useNavigation } from "../../utils/use-navigation";
import { CkLogo, TopHeaderPortal } from "../../components";
import style from "./style.module.scss";

export const AuthPage = observer(() => {
    const authStore = useAuthStore();
    const navigation = useNavigation();
    const { claims, realRole } = authStore;

    if (realRole === "user") {
        navigation.goToHome();
    }

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton>Authentication</CkLogo>
                </div>
            </TopHeaderPortal>
            <div className={style.container}>
                <p>
                    <b>Name</b>: {claims?.name}
                </p>
                <p>
                    <b>Email</b>: {claims?.email}
                </p>
                <p>
                    <b>Role</b>: {authStore.role}
                </p>
                <div>
                    <span>View as</span>
                    <br />
                    {realRole !== "user" && (
                        <Select
                            width={200}
                            allowClear
                            onChange={(value) =>
                                authStore.setViewAs(value ?? null)
                            }
                            value={authStore.viewsAs ?? undefined}
                        >
                            {realRole === "developer" && (
                                <Option value="publisher">Publisher</Option>
                            )}
                            <Option value="user">User</Option>
                        </Select>
                    )}
                </div>
            </div>
        </>
    );
});
