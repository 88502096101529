import React from "react";
import { useHistory } from "react-router-dom";
import { PER_PAGE } from "./const";

export const useNavigation = () => {
    const history = useHistory();

    const navigation = React.useMemo(
        () => ({
            // Home
            goToHome() {
                history.push("/");
            },
            // Featured
            replaceToFeaturedList() {
                history.replace(`/featured?page=0&perPage=${PER_PAGE}`);
            },
            goToFeaturedList() {
                history.push(`/featured?page=0&perPage=${PER_PAGE}`);
            },
            replaceToFeaturedCreation() {
                history.replace("/featured/create");
            },
            goToFeaturedCreation() {
                history.replace("/featured/create");
            },
            goToFeaturedEdit(id: number) {
                history.push(`/featured/edit/${id}`);
            },
            // Promotions
            goToPromotions() {
                history.push(`/promotions?page=0&perPage=${PER_PAGE}`);
            },
            replaceToPromotions() {
                history.replace(`/promotions?page=0&perPage=${PER_PAGE}`);
            },
            goToHomePreview() {
                history.push("/home-preview");
            },
            goToPromotionEdit(id: number) {
                history.push(`/promotion/edit/${id}`);
            },
            goToPromotionCreate() {
                history.push(`/promotion/create`);
            },
            goToPromotionDuplicate(id: number) {
                history.push(`/promotion/duplicate/${id}`);
            },
            replaceToPromotionCreate() {
                history.push(`/promotion/create`);
            },
            // Promotion grants
            goToGrants(id: number) {
                history.push(`/promotion/${id}/grants`);
            },
            // Stores
            goToStores(extraParams = "") {
                history.push(
                    `/stores?page=0&perPage=${PER_PAGE}${extraParams}`
                );
            },
            goToStoresDiff() {
                history.push("/stores-diff");
            },
            replaceToStores() {
                history.replace("/stores");
            },
            goToStoreGroups() {
                history.push(`/store-groups?page=0&perPage=${PER_PAGE}`);
            },
            goToStoreGroupExcel() {
                history.push("/store-group-excel");
            },
            // Categories
            goToCategories() {
                history.push(`/categories?page=0&perPage=${PER_PAGE}`);
            },
            replaceToCategories() {
                history.replace(`/categories?page=0&perPage=${PER_PAGE}`);
            },
            goToCategoryEdit(id: number) {
                history.push(`/category/edit/${id}`);
            },
            goToCategoryCreate() {
                history.push(`/category/create`);
            },
            replaceToCategoryCreate() {
                history.replace(`/category/create`);
            },
            // Offers
            goToOffers() {
                history.push(`/offers?page=0&perPage=${PER_PAGE}`);
            },
            replaceToOffers() {
                history.replace(`/offers?page=0&perPage=${PER_PAGE}`);
            },
            goToOfferEdit(id: number) {
                history.push(`/offer/edit/${id}`);
            },
            goToOfferCreate() {
                history.push(`/offer/create`);
            },
            replaceToOfferCreate() {
                history.replace(`/offer/create`);
            },
            // Gallery
            goToGalleryNone() {
                history.push(`/gallery/none?page=0&perPage=${PER_PAGE}`);
            },
            goToGalleryTOS() {
                history.push(`/gallery/tos?page=0&perPage=${PER_PAGE}`);
            },
            goToGalleryFeatured() {
                history.push(`/gallery/featured?page=0&perPage=${PER_PAGE}`);
            },
            goToGalleryService() {
                history.push(`/gallery/service?page=0&perPage=${PER_PAGE}`);
            },
            goToGalleryDeals() {
                history.push(`/gallery/deals?page=0&perPage=${PER_PAGE}`);
            },
            goToGalleryLoyalty() {
                history.push(`/gallery/loyalty?page=0&perPage=${PER_PAGE}`);
            },
            goToGalleryIcon() {
                history.push(`/gallery/icon?page=0&perPage=${PER_PAGE}`);
            },
            //Loyalties
            goToLoyalties() {
                history.push(`/loyalty?page=0&perPage=${PER_PAGE}`);
            },
            goToLoyaltyCreation() {
                history.push("/loyalty/create");
            },
            goToLoyaltyEdit(id: number) {
                history.push(`/loyalty/edit/${id}`);
            },
            replaceToLoyaltyCreate() {
                history.replace("/loyalty/create");
            },
            replaceToLoyalties() {
                history.replace(`/loyalty?page=0&perPage=${PER_PAGE}`);
            },
            // Tier Benefits
            goToTierBenefits() {
                history.push("/tier-benefit");
            },
            // Deal Buckets
            goToDealBuckets() {
                history.push(`/deal-buckets?page=0&perPage=${PER_PAGE}`);
            },
            replaceToDealBuckets() {
                history.replace(`/deal-buckets?page=0&perPage=${PER_PAGE}`);
            },
            goToDealBucketEdit(id: number) {
                history.push(`/deal-buckets/edit/${id}`);
            },
            goToDealBucketCreate() {
                history.push(`/deal-buckets/create`);
            },
            // Services
            goToServices() {
                history.push(`/services?page=0&perPage=${PER_PAGE}`);
            },
            goToServiceEdit(id: number) {
                history.push(`/service/edit/${id}`);
            },
            replaceToServices() {
                history.replace(`/services?page=0&perPage=${PER_PAGE}`);
            },
            // Auth
            goToAuth() {
                history.push(`/auth`);
            },
            // Configs
            goToConfigs() {
                history.push(`/configs`);
            },
        }),
        [history]
    );
    return navigation;
};
