import React from "react";
import moment from "moment";
import { Tag } from "antd";

import style from "./style.module.scss";
import { Deal, LoyaltyCmsDto } from "@full-circle-types";
import {
    getPromotionsTypeDisplay,
    getLoyaltyTypeDisplay,
} from "../../utils/content-type";

export const renderTitle = (onEdit: (record: Deal | LoyaltyCmsDto) => void) => (
    _: unknown,
    record: Deal | LoyaltyCmsDto
): JSX.Element => {
    return <div className={style.title}>{record.translations.en.title}</div>;
};

export const renderThumbnail = (_: unknown, record: Deal): JSX.Element => {
    return (
        <img
            key="en"
            src={record.translations.en.thumbnail ?? ""}
            alt=""
            className={"thumbnail"}
        />
    );
};
export const renderImage = (_: unknown, record: LoyaltyCmsDto): JSX.Element => {
    return (
        <img
            key="en"
            src={record.translations.en.image ?? ""}
            alt=""
            className={"thumbnail"}
        />
    );
};

export const renderDates = (_: unknown, record: Deal): JSX.Element => {
    return (
        <div className={style.dates_holder}>
            <div className="text_center inline_block">
                <div className="light_label">
                    Start
                    <div className="vr"></div>
                </div>
                <div>{moment(record.startDate).format("ll")}</div>
                <div>{moment(record.startDate).format("LT")}</div>
            </div>
            {record.endDate != null && (
                <div className="text_center inline_block">
                    <div className="light_label">
                        End
                        <div className="vr"></div>
                    </div>
                    <div>{moment(record.endDate).format("ll")}</div>
                    <div className="lil_label">
                        {moment(record.endDate).format("LT")}
                    </div>
                </div>
            )}
        </div>
    );
};

export const renderDealIsActive = (_: unknown, record: Deal): JSX.Element => {
    const promotionDateStatus = record.status;
    return (
        <div>
            {record.isActive ? (
                <Tag color="#87d068">Enabled</Tag>
            ) : (
                <Tag color="#ee1313">Disabled</Tag>
            )}
            {promotionDateStatus !== null && (
                <Tag className={style.date_status} color="#f0f0f0">
                    {promotionDateStatus}
                </Tag>
            )}
        </div>
    );
};
export const renderLoyaltyId = (
    _: unknown,
    record: LoyaltyCmsDto
): JSX.Element => {
    return <div>{record.id}</div>;
};

export const renderDealType = (
    _: unknown,
    record: Deal
): JSX.Element | undefined => {
    return getPromotionsTypeDisplay(record.type);
};
export const renderLoyaltyType = (
    _: unknown,
    record: LoyaltyCmsDto
): JSX.Element | undefined => {
    return getLoyaltyTypeDisplay(record.type);
};
