import React, { CSSProperties } from "react";
import style from "./style.module.scss";

interface ButtonProps {
    type: "primary" | "secondary" | 'tertiary';
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
    style?: CSSProperties;
}

export const Button = (props: ButtonProps) => {
    const classes = [style.button];

    if (props.disabled) {
        classes.push(style.disabled);
    }

    if (props.type === "secondary") {
        classes.push(style.secondary);
    }

    if (props.type === "tertiary") {
        classes.push(style.tertiary);
    }

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <button
            className={classes.join(" ")}
            onClick={(e) => props.onClick(e)}
            disabled={props.disabled}
            style={props.style}
        >
            {props.children}
        </button>
    );
};
