import React from "react";
import { observer } from "mobx-react";
import style from "./style.module.scss";
import { useAgvtStore } from "..";
import { SlickHeader } from "../slick-header";
import { StandardCard } from "../../standard-card";
import { ClubPreview } from "../../club-preview";
import { GlowingDiv } from "../../glowing-hoc";

import { ReactComponent as CircleIcon } from "../../../assets/circle-icon.svg";
import { ReactComponent as PercentIcon } from "../../../assets/percent-icon.svg";
import { ReactComponent as IceCreamIcon } from "../../../assets/ice-cream-icon.svg";
import { ReactComponent as GiftIcon } from "../../../assets/gift-icon.svg";

import { FakeRewards, FakeClubs, FakeOffers, Club } from "./fake-loyaties";

type Tab = "offer" | "club" | "reward";

export const InnerCirclePage = observer(() => {
    const store = useAgvtStore();

    const tab = store.navigation.currentPage.data?.tab ?? "offer";

    const setTab = (tab: Tab) =>
        store.navigation.replace({
            section: "inner-circle",
            data: { tab },
        });

    const getTabClass = (t: Tab) =>
        `${style.tab_picker} ${tab === t ? style.selected : ""}`;

    return (
        <div>
            <SlickHeader name={store.store?.street ?? ""} />
            <div className={style.tabs}>
                <div
                    className={getTabClass("offer")}
                    onClick={() => setTab("offer")}
                >
                    <span className={style.hacky_holder}>
                        <CircleIcon className={style.circle_icon} />
                        <PercentIcon className={style.percent_icon} />
                    </span>
                    <span>Offers</span>
                </div>
                <div
                    className={getTabClass("club")}
                    onClick={() => setTab("club")}
                >
                    <IceCreamIcon className={style.ice_cream_icon} />
                    <span>Clubs</span>
                </div>
                <div
                    className={getTabClass("reward")}
                    onClick={() => setTab("reward")}
                >
                    <GiftIcon className={style.gift_icon} />
                    <span>Rewards</span>
                </div>
                <GlowingDiv
                    glowColor={store.glowConfiguration?.loyalty?.tab}
                    className={style.list_glower}
                ></GlowingDiv>
            </div>
            <div className={style.list_content}>
                {tab === "offer" && <OfferList />}
                {tab === "club" && <ClubList />}
                {tab === "reward" && <RewardList />}
            </div>
        </div>
    );
});

const ClubList = observer(() => {
    const store = useAgvtStore();
    const glow = store.glowConfiguration;
    const hasLoyaltiesAndIsClub =
        store.loyalties && store.loyalties[0].type === "club";
    const onClick = (club: Club) =>
        store.navigation.push({
            section: "loyalty",
            parentSection: "inner-circle",
            data: {
                fakeLoyalty: club,
                loyaltyType: "club",
            },
        });

    return (
        <>
            <span className={style.section_title}>Redeemable</span>
            {hasLoyaltiesAndIsClub
                ? null
                : !store.loyalties &&
                  FakeClubs.filter((c) => c.progress === 5).map((c) => (
                      <ClubPreview
                          key={c.id}
                          title={c.title}
                          progress={c.progress}
                          emptyIcon={c.emptyPunchIcon}
                          filledIcon={c.filledPunchIcon}
                          glow={glow.loyalty}
                          onClick={() => onClick(c)}
                      />
                  ))}

            <span className={style.section_title}>In Progress</span>
            {hasLoyaltiesAndIsClub ? (
                <ClubPreview
                    key={store.loyalties && store.loyalties[0].id}
                    title={store.loyalties && store.loyalties[0].title}
                    progress={3}
                    emptyIcon={
                        store.loyalties![0].type === "club"
                            ? store.loyalties![0].emptyPunchIcon
                            : ""
                    }
                    filledIcon={
                        store.loyalties![0].type === "club"
                            ? store.loyalties![0].filledPunchIcon
                            : ""
                    }
                    glow={glow.loyalty}
                    onClick={() => onClick(store.loyalties![0] as Club)}
                />
            ) : (
                !store.loyalties &&
                FakeClubs.filter(
                    (c) => c.progress < 5 && c.progress > 0
                ).map((c) => (
                    <ClubPreview
                        key={c.id}
                        title={c.title}
                        progress={c.progress}
                        emptyIcon={c.emptyPunchIcon}
                        filledIcon={c.filledPunchIcon}
                        glow={glow.loyalty}
                        onClick={() => onClick(c)}
                    />
                ))
            )}

            <span className={style.section_title}>Not Yet Started</span>
            {hasLoyaltiesAndIsClub
                ? null
                : !store.loyalties &&
                  FakeClubs.filter((c) => c.progress === 0).map((c) => (
                      <ClubPreview
                          key={c.id}
                          title={c.title}
                          progress={c.progress}
                          emptyIcon={c.emptyPunchIcon}
                          filledIcon={c.filledPunchIcon}
                          glow={glow.loyalty}
                          onClick={() => onClick(c)}
                      />
                  ))}
        </>
    );
});

const OfferList = observer(() => {
    const store = useAgvtStore();
    const glow = store.glowConfiguration;

    return (
        <>
            {store.loyalties && store.loyalties[0].type === "offer" ? (
                <StandardCard
                    fluid
                    glowingConfiguration={glow.loyalty}
                    onClick={() =>
                        store.navigation.push({
                            section: "loyalty",
                            parentSection: "inner-circle",
                            data: {
                                fakeLoyalty: store.loyalties![0],
                                loyaltyType: "offer",
                            },
                        })
                    }
                    key={store.loyalties[0].id}
                    content={StandardCard.transform(store.loyalties[0])}
                    cursorType="pointer"
                />
            ) : (
                !store.loyalties &&
                FakeOffers.map((d) => (
                    <StandardCard
                        fluid
                        glowingConfiguration={glow.loyalty}
                        onClick={() =>
                            store.navigation.push({
                                section: "loyalty",
                                parentSection: "inner-circle",
                                data: {
                                    fakeLoyalty: d,
                                    loyaltyType: "offer",
                                },
                            })
                        }
                        key={d.id}
                        content={StandardCard.transform(d)}
                        cursorType="pointer"
                    />
                ))
            )}
        </>
    );
});

const RewardList = observer(() => {
    const store = useAgvtStore();
    const glow = store.glowConfiguration;

    return (
        <>
            <span className={style.section_title}>Redeemable</span>
            {store.loyalties && store.loyalties[0].type === "reward" ? (
                <StandardCard
                    fluid
                    glowingConfiguration={glow.loyalty}
                    onClick={() =>
                        store.navigation.push({
                            section: "loyalty",
                            parentSection: "inner-circle",
                            data: {
                                fakeLoyalty: store.loyalties![0],
                                loyaltyType: "reward",
                            },
                        })
                    }
                    key={store.loyalties[0].id}
                    content={StandardCard.transform(store.loyalties[0])}
                    cursorType="pointer"
                />
            ) : (
                !store.loyalties &&
                FakeRewards.map((d) => (
                    <StandardCard
                        fluid
                        glowingConfiguration={glow.loyalty}
                        onClick={() =>
                            store.navigation.push({
                                section: "loyalty",
                                parentSection: "inner-circle",
                                data: {
                                    fakeLoyalty: d,
                                    loyaltyType: "reward",
                                },
                            })
                        }
                        key={d.id}
                        content={StandardCard.transform(d)}
                        cursorType="pointer"
                    />
                ))
            )}
        </>
    );
});
