import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Spin, Modal } from "antd";
import * as yup from "yup";
import { Permission } from "shared-utils";

import style from "./style.module.scss";
import { DealBucketForm } from "../../models";
import { useNavigation } from "../../utils/use-navigation";
import { SupportedLanguage } from "../../types";
import { useTranslationFormik } from "../../utils/use-translation-formik";
import { isAppMultiLang } from "../../utils";
import { Input, Button } from "../../easyui-components";
import {
    useDealBucketStore,
    usePromotionStore,
    useAuthStore,
} from "../../contexts/mobx";
import {
    LangSelector,
    GalleryPicker,
    AssociatedDeals,
    CkLogo,
    TopHeaderPortal,
    HelpfulSpan,
} from "../../components";

const translationSchema = yup.object({
    name: yup.string().min(1).required(),
});

const coreSchema = yup.object({
    position: yup.number().required(),
    focusedIcon: yup.string().required(),
    notFocusedIcon: yup.string().required(),
});

type Params = {
    action: "create" | "edit";
    id?: string;
};

const INITIAL_VALUES: DealBucketForm = {
    position: 0,
    focusedIcon: "",
    notFocusedIcon: "",
    translations: { en: { name: undefined } },
};

export const DealBucketPage = observer(() => {
    const params = useParams<Params>();
    const store = useDealBucketStore();
    const promotionStore = usePromotionStore();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const [isUpserting, setIsUpserting] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(
        params.action !== "create"
    );

    const role = authStore.role;

    const {
        selectedLangs,
        setSelectedLangs,
        formik,
        getFinalValue,
    } = useTranslationFormik<DealBucketForm>(
        INITIAL_VALUES,
        () => translationSchema,
        () => coreSchema
    );

    React.useEffect(() => {
        if (params.id) {
            const id = Number(params.id);
            setIsFetching(true);
            store
                .fetchSingleBucket(id)
                .then((bucket) => {
                    const langs = Object.keys(bucket.translations);
                    setSelectedLangs(langs as SupportedLanguage[]);
                    formik.setValues(bucket);
                })
                .finally(() => {
                    setIsFetching(false);
                });
            promotionStore.fetchForBucket(id);
        }
    }, [params.id, promotionStore, setSelectedLangs, store]);

    const createDealBucket = async () => {
        try {
            setIsUpserting(true);
            await store.createDealBucket(getFinalValue() as DealBucketForm);
            navigation.replaceToDealBuckets();
        } finally {
            setIsUpserting(false);
        }
    };

    const updateDealBucket = async () => {
        try {
            setIsUpserting(true);
            await store.updateDealBucket(
                Number(params.id),
                getFinalValue() as DealBucketForm
            );
        } finally {
            setIsUpserting(false);
        }
    };

    const deleteDealBucket = async () => {
        try {
            Modal.confirm({
                title: "Are you sure you want to delete this deal bucket?",
                onOk: async () => {
                    setIsDeleting(true);
                    await store.deleteDealBucket(Number(params.id));
                    navigation.replaceToDealBuckets();
                },
            });
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <TopHeaderPortal>
                <div className={"nav_header"}>
                    <CkLogo isBackButton section="deal-bucket">
                        DEAL BUCKET {params.id ?? ""}
                    </CkLogo>
                    <div className={"nav_button_holder"}>
                        {isUpserting && (
                            <>
                                <Spin />{" "}
                            </>
                        )}
                        {params.action !== "create" ? (
                            <>
                                <Button
                                    disabled={
                                        promotionStore.promotionsForBucket
                                            .length > 0
                                    }
                                    type="secondary"
                                    onClick={deleteDealBucket}
                                >
                                    Delete
                                </Button>
                                <Button
                                    disabled={
                                        !formik.isValid ||
                                        !Permission.canEdit({
                                            role,
                                            enabled: false,
                                        })
                                    }
                                    type="primary"
                                    onClick={updateDealBucket}
                                >
                                    SAVE
                                </Button>
                            </>
                        ) : (
                            <Button
                                disabled={!formik.isValid}
                                type="primary"
                                onClick={createDealBucket}
                            >
                                CREATE
                            </Button>
                        )}
                    </div>
                </div>
            </TopHeaderPortal>

            <div className={style.bucket_page}>
                {params.id && isFetching && <Spin />}
                {(!params.id || !isFetching) && (
                    <div>
                        <div className={style.input_holder}>
                            <span className={style.required}>Position</span>
                            <Input
                                name="position"
                                onChange={(v) =>
                                    formik.setFieldValue(
                                        "position",
                                        v.target.value
                                    )
                                }
                                type="number"
                                value={formik.values.position}
                            />
                        </div>
                        <div className={style.icon_container}>
                            <div>
                                <HelpfulSpan
                                    section="deal-bucket-icon"
                                    className={style.required}
                                >
                                    Focused Icon
                                </HelpfulSpan>
                                <GalleryPicker
                                    dataType="icon"
                                    url={formik.values.focusedIcon}
                                    onChange={(url) =>
                                        formik.setFieldValue(`focusedIcon`, url)
                                    }
                                />
                            </div>
                            <div>
                                <HelpfulSpan
                                    section="deal-bucket-icon"
                                    className={style.required}
                                >
                                    Not Focused Icon
                                </HelpfulSpan>
                                <GalleryPicker
                                    dataType="icon"
                                    url={formik.values.notFocusedIcon}
                                    onChange={(url) =>
                                        formik.setFieldValue(
                                            `notFocusedIcon`,
                                            url
                                        )
                                    }
                                />
                            </div>
                        </div>
                        {isAppMultiLang && (
                            <>
                                <span style={{ display: "block" }}>
                                    Language
                                </span>
                                <LangSelector
                                    langs={selectedLangs}
                                    onChange={(langs) => {
                                        setSelectedLangs(langs);
                                    }}
                                />
                            </>
                        )}

                        <div className={style.translatable_contents}>
                            {selectedLangs.map((lang) => (
                                <div
                                    key={lang}
                                    className={style.translatable_content}
                                >
                                    <div className={style.input_holder}>
                                        <HelpfulSpan
                                            section="deal-bucket-name"
                                            className={style.required}
                                        >
                                            Name
                                        </HelpfulSpan>
                                        <Input
                                            name={`translations.${lang}.name`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                                formik.values.translations?.[
                                                    lang
                                                ]?.name ?? undefined
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {params.action !== "create" && (
                            <AssociatedDeals
                                loading={promotionStore.isFetchingForBucket}
                                data={promotionStore.promotionsForBucket}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
});
