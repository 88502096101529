import { FeaturedType } from "@full-circle-types";
import React from "react";
import styled from "styled-components";
import { CursorType } from "../../models";
import {
    GlowingDiv,
    GlowingImageHolder,
    GlowingColor,
    GlowingSpan,
} from "../glowing-hoc";

export interface GlowingConfiguration {
    deal?: GlowingColor;
    externalUrl?: GlowingColor;
    cta?: GlowingColor;
    innerCircle?: GlowingColor;
}

interface Content {
    image?: string | null;
    cta?: string | null;
    type?: FeaturedType;
}

interface Props {
    cursorType?: CursorType;
    content: Content;
    onClick?: () => void;
    glowingConfiguration?: GlowingConfiguration;
}

const Button = styled.button`
    background-color: white;
    border: 2px solid black;
    width: calc(100% - 48px);
    margin-bottom: 24px;
    font-size: 12px;
    font-weight: bold;
    padding: 0;
    height: 40px;
    position: absolute;
    bottom: 0;
    font-size: 17px;
    line-height: 18px;
    left: 24px;
`;

const GlowingContainer = styled(GlowingDiv)`
    position: relative;
    min-height: 1px;
`;

export const FeaturedCard = (props: Props): JSX.Element => {
    const { content, cursorType } = props;
    const glow = props.glowingConfiguration;

    const dealGlow = glow?.deal;
    const externalGlow = glow?.externalUrl;
    const innerCircleGlow = glow?.innerCircle;

    return (
        <GlowingContainer
            glowColor={
                content.type === "deal"
                    ? dealGlow
                    : content.type === "external_url"
                    ? externalGlow
                    : innerCircleGlow
            }
        >
            <Button style={{ cursor: cursorType }}>
                <GlowingSpan glowColor={glow?.cta} onClick={props.onClick}>
                    {content.cta?.toUpperCase()}
                </GlowingSpan>
            </Button>
            <GlowingImageHolder
                size={185}
                rectangle
                src={content.image}
                showOverlay={true}
            />
        </GlowingContainer>
    );
};
