import React from "react";
import moment from "moment";
import { observer } from "mobx-react";
import { Table, Tag, Progress, Select } from "antd";
import { ColumnProps } from "antd/es/table";

import style from "./style.module.scss";
import { useQueryParams } from "../../utils/use-query-params";
import {
    PromotionTypes,
    ActiveQueryParam,
    GroupLimitations,
} from "../../utils/const";
import { useNavigation } from "../../utils/use-navigation";
import {
    Deal,
    DealSearchParams,
    StorePreview,
    StoreGroupPreview,
} from "@full-circle-types";
import {
    Select as EasySelect,
    Input,
    Button,
    Highlighter,
} from "../../easyui-components";
import {
    usePromotionStore,
    useCategoryStore,
    useStoreGroupsStore,
    useStoresStore,
} from "../../contexts/mobx";
import {
    Pagination,
    DebounceSelectSingle,
    TopHeaderPortal,
    CkLogo,
} from "../../components";
import {
    getPromotionsTypeDisplay,
    getPromotionsTypeName,
} from "../../utils/content-type";

export const PromotionsPage = observer(() => {
    const storeStore = useStoresStore();
    const promotionStore = usePromotionStore();
    const categoryStore = useCategoryStore();
    const storeGroupStore = useStoreGroupsStore();
    const navigation = useNavigation();

    const [queryParams, updateQueryParams] = useQueryParams<DealSearchParams>();

    React.useEffect(() => {
        promotionStore.fetchPromotions(queryParams);
    }, [queryParams, promotionStore]);

    React.useEffect(() => {
        categoryStore.fetchCategoryPreviews();
    }, [categoryStore]);

    React.useEffect(() => {
        storeGroupStore.fetchStoreGroups();
    }, [storeGroupStore]);

    const onEdit = (record: Deal) => {
        navigation.goToPromotionEdit(record.id);
    };

    const renderTitle = (onEdit: (record: Deal) => void) => (
        _: unknown,
        record: Deal
    ) => {
        return (
            <Highlighter search={queryParams.q ?? ""}>
                {record.translations.en.title}
            </Highlighter>
        );
    };

    const renderThumbnail = (_: unknown, record: Deal) => {
        return (
            <img
                key="en"
                src={record.translations.en.thumbnail ?? ""}
                alt=""
                className={style.thumbnail}
                loading="lazy"
            />
        );
    };

    const renderDates = (_: unknown, record: Deal) => {
        return (
            <div className="dates_holder">
                <div className="text_center inline_block">
                    <div className="light_label">
                        Start
                        <div className="vr"></div>
                    </div>
                    <div>{moment(record.startDate).format("ll")}</div>
                    <div className="lil_label">
                        {moment(record.startDate).format("LT")}
                    </div>
                </div>
                {record.endDate != null && (
                    <div className="text_center inline_block">
                        <div className="light_label">
                            End
                            <div className="vr"></div>
                        </div>
                        <div>{moment(record.endDate).format("ll")}</div>
                        <div className="lil_label">
                            {moment(record.endDate).format("LT")}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderIsActive = (_: unknown, record: Deal) => {
        let activationsPercent = 0;
        if (record.type === "coupon") {
            activationsPercent = +(
                (record.totalActivationsCount! / record.quantity) *
                100
            ).toFixed(2);
        }

        return (
            <div>
                {record.isActive ? (
                    <Tag color="#28a745">Enabled</Tag>
                ) : (
                    <Tag color="#ad2116">Disabled</Tag>
                )}
                {record.status !== null && (
                    <Tag className={style.date_status} color="#f0f0f0">
                        {record.status}
                    </Tag>
                )}
                {record.type === "coupon" && (
                    <div className={style.activations_count}>
                        {record.totalActivationsCount} of {record.quantity}{" "}
                        activations
                        <Progress percent={activationsPercent} />
                    </div>
                )}
            </div>
        );
    };

    const renderType = (_: unknown, record: Deal) => {
        return getPromotionsTypeDisplay(record.type);
    };

    const renderCategory = (_: unknown, record: Deal) => {
        return <span className="simple-tag">{record.category.title}</span>;
    };

    const columns: ColumnProps<Deal>[] = [
        {
            title: "Status",
            key: "is_active",
            render: renderIsActive,
            width: "20%",
        },
        {
            title: "Image",
            key: "image",
            render: renderThumbnail,
            width: "15%",
        },
        {
            title: "Title",
            key: "title",
            render: renderTitle(onEdit),
            width: "25%",
        },
        {
            title: "Type",
            key: "type",
            render: renderType,
            width: "8%",
        },
        {
            title: "Category",
            key: "category",
            render: renderCategory,
            width: "12%",
        },
        {
            title: "Dates",
            key: "dates",
            render: renderDates,
            width: "20%",
        },
    ];

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="deal">
                        DEALS
                    </CkLogo>
                    <div className="nav_button_holder">
                        <Button
                            type="primary"
                            onClick={() => navigation.goToPromotionCreate()}
                        >
                            CREATE NEW
                        </Button>
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.promotions_page}>
                <div className={style.selects_row}>
                    <Input
                        value={queryParams.q}
                        allowClear={true}
                        onChange={(e) =>
                            updateQueryParams({ q: e.target.value, page: 0 })
                        }
                        placeholder="Search"
                    />

                    <EasySelect
                        placeholder="Type"
                        value={queryParams.type}
                        onChange={(v) =>
                            updateQueryParams({ type: v, page: 0 })
                        }
                        allowClear
                    >
                        <Select.Option value={PromotionTypes[0]}>
                            {getPromotionsTypeName(PromotionTypes[0])}
                        </Select.Option>
                        <Select.Option value={PromotionTypes[1]}>
                            {getPromotionsTypeName(PromotionTypes[1])}
                        </Select.Option>
                        <Select.Option value={PromotionTypes[2]}>
                            {getPromotionsTypeName(PromotionTypes[2])}
                        </Select.Option>
                    </EasySelect>
                    <EasySelect<ActiveQueryParam>
                        placeholder="Enabled"
                        value={queryParams.filter}
                        onChange={(v) =>
                            updateQueryParams({ filter: v, page: 0 })
                        }
                        allowClear
                    >
                        <Select.Option value="active">Enabled</Select.Option>
                        <Select.Option value="inactive">Disabled</Select.Option>
                    </EasySelect>
                    <EasySelect
                        placeholder="Category"
                        onChange={(id) =>
                            updateQueryParams({ categoryId: id, page: 0 })
                        }
                        value={queryParams.categoryId}
                        allowClear
                        loading={categoryStore.isFetchingPreviews}
                    >
                        {categoryStore.categoryPreviews?.map((category) => (
                            <Select.Option
                                value={category.id}
                                key={category.id}
                            >
                                {category.title}
                            </Select.Option>
                        ))}
                    </EasySelect>

                    <EasySelect
                        placeholder="Status"
                        value={queryParams.status}
                        onChange={(v) =>
                            updateQueryParams({ status: v, page: 0 })
                        }
                        allowClear
                    >
                        <Select.Option value="active">Active</Select.Option>
                        <Select.Option value="upcoming">Upcoming</Select.Option>
                        <Select.Option value="expired">Expired</Select.Option>
                    </EasySelect>
                    <DebounceSelectSingle<StoreGroupPreview>
                        disabled={!!queryParams.storeId}
                        placeholder="Store Group"
                        onChange={(id: number) =>
                            updateQueryParams({
                                storeGroupId: id,
                                page: 0,
                            })
                        }
                        value={queryParams.storeGroupId}
                        tooltip={"Clear the Store input to select a Store"}
                        getValue={(storeGroup: StoreGroupPreview) =>
                            storeGroup.id
                        }
                        getPreviews={(q: string) =>
                            storeGroupStore.fetchStoreGroupsPreview({
                                q,
                                limit: 20,
                            })
                        }
                        getDisplay={(storeGroup: StoreGroupPreview) => (
                            <>{storeGroup.name}</>
                        )}
                        findElement={(
                            storeGroups: StoreGroupPreview[],
                            id: number
                        ) => storeGroups.find((sg) => sg.id === id)}
                    />
                    <DebounceSelectSingle<StorePreview>
                        disabled={!!queryParams.storeGroupId}
                        placeholder="Store"
                        value={queryParams.storeId}
                        getPreviews={(q: string) =>
                            storeStore.fetchStoresPreview({ q, limit: 20 })
                        }
                        onChange={(id: number, store?: StorePreview) =>
                            updateQueryParams({ storeId: id, page: 0 })
                        }
                        getDisplay={(store: StorePreview) => (
                            <>
                                <span className={style.sim_highlight}>
                                    {store.simId}
                                </span>
                                {` - ${store.street} ${store.city} ${store.postalCode}`}
                            </>
                        )}
                        findElement={(stores: StorePreview[], id: number) =>
                            stores.find((store) => store.simId === id)
                        }
                        getValue={(store: StorePreview) => store.simId}
                        tooltip="Clear the Store Group input to select a Store"
                    />
                    <EasySelect
                        placeholder="Group Limitation"
                        allowClear
                        value={queryParams.groupLimitation}
                        onChange={(groupLimitation) =>
                            updateQueryParams({
                                page: 0,
                                groupLimitation,
                            })
                        }
                    >
                        {Object.keys(GroupLimitations).map((limitation) => {
                            return (
                                <Select.Option value={limitation}>
                                    {limitation}
                                </Select.Option>
                            );
                        })}
                    </EasySelect>
                </div>
                <div className={style.pagination_wrapper}>
                    <Pagination
                        params={queryParams}
                        meta={promotionStore.promotions}
                        onChange={(pageNumber, pageSize) =>
                            updateQueryParams({
                                page: pageNumber,
                                perPage: pageSize,
                            })
                        }
                        onPageSizeChange={(size) =>
                            updateQueryParams({ perPage: size })
                        }
                    />
                </div>
                <Table<Deal>
                    className={`${style.table} clickable_table`}
                    scroll={{ x: 900 }}
                    rowKey="id"
                    dataSource={promotionStore.promotions?.results}
                    columns={columns}
                    loading={promotionStore.isFetchingCount > 0}
                    pagination={false}
                    onRow={(r) => ({
                        onClick: () => navigation.goToPromotionEdit(r.id),
                    })}
                />
            </div>
        </>
    );
});
