import React from "react";
import { Select } from "antd";
import { SupportedLanguage } from "../../types";
import { Lang, AllLangs } from "../../utils";
import style from "../lang-selector/style.module.scss";
const { Option } = Select;

interface Props {
    onChange: (value: SupportedLanguage[]) => void;
    langs: SupportedLanguage[];
}

const LangOptions: { value: Lang; display: string; flag: string }[] = [
    {
        value: "en",
        display: "English",
        flag: "🇺🇸",
    },
    { value: "fr", display: "French", flag: "🇫🇷" },
];

const FilteredLangOptions = LangOptions.filter((option) =>
    AllLangs.includes(option.value)
);

export const LangSelector = (props: Props): JSX.Element => {
    const handleChange = (value: SupportedLanguage[]) => {
        props.onChange(value);
    };

    return (
        <span className={style.lang_selector}>
            <Select
                mode="multiple"
                style={{ width: "200px" }}
                placeholder="select language(s)"
                value={props.langs}
                defaultValue={["en"]}
                onChange={handleChange}
                optionLabelProp="label"
                dropdownClassName={style.options}
            >
                {FilteredLangOptions.map((option) => (
                    <Option
                        key={option.value}
                        value={option.value}
                        label={option.display}
                    >
                        <span
                            role="img"
                            aria-label={option.value}
                            style={{ marginRight: 6 }}
                        >
                            {option.flag}
                        </span>
                        {option.display}
                    </Option>
                ))}
            </Select>
        </span>
    );
};
