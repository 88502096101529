import React from "react";
import { observer } from "mobx-react";
import style from "./style.module.scss";
import { HomePage } from "./home-page";
import { DealBucketsPage } from "./deal-buckets-page";
import { DealBucketPage } from "./deal-bucket-page";
import { DealPage } from "./deal-page";
import { LoyaltyPage } from "./loyalty-page";
import { InnerCirclePage } from "./inner-circle-page";
import { ViewListButton } from "./view-list-button";
import { BottomDrawer, Overlay } from "./bottom-drawer";
import {
    HomeIcon,
    StoresIcon,
    RewardsIcon,
    DealsIcon,
    AccountIcon,
} from "./navigation-icon";
import { AgvtState } from "components/advance-gmap-visualization-tool/agvt-state";
import stubFalse from "lodash/stubFalse";

export * from "./agvt-state";
export * from "./coupon-manager";
export * from "./navigation";

const agvtContext = React.createContext<AgvtState | null>(null);

export const useAgvtStore = (): AgvtState => {
    const store = React.useContext(agvtContext);
    if (!store) {
        throw new Error("useAgvtStore must be used within a AgvtProvider.");
    }
    return store;
};

export const AgvtContextWrapper = (props: {
    store: AgvtState;
    children: React.ReactNode;
}): JSX.Element => {
    return (
        <agvtContext.Provider value={props.store}>
            {props.children}
        </agvtContext.Provider>
    );
};

export const AGVT = (props: { store: AgvtState }): JSX.Element => {
    return (
        <AgvtContextWrapper store={props.store}>
            <AgvtUi />
        </AgvtContextWrapper>
    );
};

export const AgvtUi = observer(() => {
    const store = useAgvtStore();
    const section = store.navigation.currentPage.section;
    const parentSection =
        store.navigation.currentPage.parentSection ||
        store.navigation.currentPage.section;

    const contentRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        contentRef.current?.scrollTo({ top: 0 });
    }, [store.navigation.currentPage]);

    return (
        <div className={style.content_holder}>
            <div className={style.content} ref={contentRef}>
                {section === "home" && <HomePage />}
                {section === "deals" && <DealBucketsPage />}
                {section === "deal" && <DealPage />}
                {section === "deal-bucket" && <DealBucketPage />}
                {section === "inner-circle" && <InnerCirclePage />}
                {section === "loyalty" && <LoyaltyPage />}
            </div>
            <ViewListButton />
            <div className={style.navigation}>
                <HomeIcon
                    associatedSection="home"
                    currentSection={parentSection}
                    onClick={(s) => store.navigation.replace({ section: s })}
                />
                <StoresIcon
                    associatedSection="stores"
                    currentSection={parentSection}
                />
                <RewardsIcon
                    associatedSection="inner-circle"
                    currentSection={parentSection}
                    onClick={
                        window.location.pathname.includes("documentation")
                            ? (s) =>
                                  store.navigation.replace({
                                      section: s,
                                  })
                            : stubFalse
                    }
                />
                <DealsIcon
                    associatedSection="deals"
                    currentSection={parentSection}
                    onClick={(s) => store.navigation.replace({ section: s })}
                />
                <AccountIcon
                    associatedSection="account"
                    currentSection={parentSection}
                />
            </div>
            <Overlay />
            <BottomDrawer />
        </div>
    );
});
