import React, { ChangeEvent } from "react";
import { observable, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import * as XLSX from "xlsx";
import { Spin } from "antd";
import style from "./style.module.scss";
import { Select, Option, Button, Input } from "../../easyui-components";
import { NotificationManager, CkLogo, TopHeaderPortal } from "../../components";
import { useStoresStore, useStoreGroupsStore } from "../../contexts/mobx";
import { StoresStore } from "../../stores/stores";
import { useNavigation } from "../../utils/use-navigation";

type Row = Record<string, string | number>;

export const StoreGroupExcelPage = observer(() => {
    const navigation = useNavigation();
    const storeStore = useStoresStore();
    const storeGroupStore = useStoreGroupsStore();
    const excel = React.useRef(new ExcelHandler(storeStore));
    const {
        workBook,
        sheet,
        rows,
        gridTemplateColumns,
        storeIdCol,
        sheetNames,
        headers,
        isValidatingIds,
    } = excel.current;

    const hiddenInpuRef = React.useRef<HTMLInputElement | null>(null);

    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");

    const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            // User cancelled the operation
            return;
        }
        const workbook = XLSX.read(await file.arrayBuffer());
        excel.current.setWorkBook(workbook);
    };

    const createStoreGroup = async () => {
        const ids = rows.map((r) => String(r[storeIdCol!]));
        const success = await storeGroupStore.createStoreGroup(
            name,
            description,
            ids
        );

        if (success) {
            NotificationManager.showSuccess("Store Group Created");
            navigation.goToStoreGroups();
        }
    };

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="store-groups">
                        STORE GROUP CREATION
                    </CkLogo>
                </div>
            </TopHeaderPortal>
            <div className={style.top_content}>
                <div className={style.select_box}>
                    <span>Name</span>
                    <Input
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>

                <div className={style.select_box}>
                    <span>Description</span>
                    <Input
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                    />
                </div>
                <div className={style.buttons_holder}>
                    <Button
                        type="secondary"
                        onClick={() => hiddenInpuRef.current?.click()}
                    >
                        Select File
                    </Button>
                    <Button
                        type="primary"
                        disabled={
                            !name || !description || !rows.length || !storeIdCol
                        }
                        onClick={createStoreGroup}
                    >
                        Create
                    </Button>

                    {storeGroupStore.isCreating && <Spin />}
                </div>
                <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    type="file"
                    className={style.hidden_input}
                    onChange={handleFile}
                    ref={hiddenInpuRef}
                />
                <div className={style.select_boxes}>
                    {workBook && (
                        <div className={style.select_box}>
                            <span>Select Sheet</span>
                            <Select<string>
                                disabled={isValidatingIds}
                                width={250}
                                onChange={(sn) => excel.current.selectSheet(sn)}
                            >
                                {sheetNames?.map((sn) => (
                                    <Option key={sn} value={sn}>
                                        {sn}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    {sheet && (
                        <div className={style.select_box}>
                            <span>Select column used for Store number</span>
                            <Select<string>
                                disabled={isValidatingIds}
                                loading={isValidatingIds}
                                width={250}
                                onChange={(h) =>
                                    excel.current.selectStoreIdCol(h)
                                }
                            >
                                {headers?.map((h) => (
                                    <Option key={h} value={h}>
                                        {h}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    )}
                </div>
            </div>

            {rows[0] && (
                <>
                    <div
                        className={style.grid_header}
                        style={{ gridTemplateColumns }}
                    >
                        {Object.entries(rows[0]).map(([key, value]) => (
                            <div
                                key={key}
                                className={
                                    key === storeIdCol ? style.store_id : ""
                                }
                            >
                                {key}
                            </div>
                        ))}
                    </div>
                </>
            )}
            <Table excel={excel.current} />
        </>
    );
});

const Table = observer((props: { excel: ExcelHandler }) => {
    const { rows, gridTemplateColumns } = props.excel;

    if (!rows?.[0]) {
        return null;
    }

    return (
        <>
            <div className={style.grid} style={{ gridTemplateColumns }}>
                {rows.map((row, i) => (
                    <React.Fragment key={i}>
                        {Object.entries(row).map(([key, value]) => (
                            <div
                                key={`${key}-${i}`}
                                className={`${style.cell} ${
                                    i % 2 === 0 ? style.even : style.odd
                                }`}
                            >
                                {value}
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </>
    );
});

class ExcelHandler {
    workBook: XLSX.WorkBook | null = null;
    sheet: XLSX.Sheet | null | undefined = null;
    storeIdCol: string | null = null;

    constructor(private storeStore: StoresStore) {
        makeAutoObservable(this, {
            workBook: observable.ref,
            sheet: observable.ref,
        });
    }

    setWorkBook(workBook: XLSX.WorkBook) {
        this.workBook = workBook;
    }

    selectSheet(sheetName: string) {
        this.sheet = this.workBook?.Sheets[sheetName];
        this.storeIdCol = null;
    }

    selectStoreIdCol(col: string) {
        this.storeIdCol = col;
        const ids = this.rows.map((r) => Number(r[col]));
        this.storeStore.validateIds(ids);
    }

    get isValidatingIds() {
        return this.storeStore.isValidatingIds;
    }

    get invalidIds() {
        return this.storeStore.invalidIds;
    }

    get rows(): Row[] {
        if (!this.sheet) {
            return [];
        }
        return XLSX.utils.sheet_to_json<Row>(this.sheet);
    }

    get sheetNames() {
        return this.workBook?.SheetNames;
    }

    get headers() {
        if (this.rows.length === 0) {
            return;
        }
        return Object.keys(this.rows[0]);
    }

    get gridTemplateColumns() {
        const widthMap = new Map<string, number>();

        for (const col of this.rows) {
            for (const [key, value] of Object.entries(col)) {
                widthMap.set(
                    key,
                    Math.max(
                        widthMap.get(key) ?? 0,
                        String(value).length,
                        key.length
                    )
                );
            }
        }

        const colTemplate: string[] = [];
        for (const width of widthMap.values()) {
            colTemplate.push(`${width * 10 + 20}px`);
        }
        return colTemplate.join(" ");
    }
}
