import React from "react";
import styled from "styled-components";
import { DocumentationPage } from ".";
import { Input, Button } from "../../easyui-components";

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100vh;
`;

/**
 * This is of course not a real security mechanism. This is a temporary solution
 * while everybody get access to the CMS
 *
 * @param props
 * @returns
 */
function LockScreen(props: { onUnlock: () => void }) {
    const [password, setPassword] = React.useState("");

    return (
        <Div>
            <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                type="primary"
                onClick={() => {
                    if (password === "2202CircleK") {
                        props.onUnlock();
                    }
                }}
            >
                Unlock
            </Button>
        </Div>
    );
}

export function ProtectedDocumentationPage(): JSX.Element {
    const currentSection = window.location.href;
    const [isUnlock, setIsUnlock] = React.useState(
        !!sessionStorage.getItem("unlock")
    );

    React.useEffect(() => {
        if (currentSection.includes("#")) {
            window.location.href = currentSection;
        }
    }, [isUnlock, currentSection]);

    return isUnlock ? (
        <DocumentationPage />
    ) : (
        <LockScreen
            onUnlock={() => {
                sessionStorage.setItem("unlock", "true");
                setIsUnlock(true);
            }}
        />
    );
}
