import React from "react";
import styled from "styled-components";
import { IssuesCloseOutlined, WarningOutlined } from "@ant-design/icons";

interface Props {
    children: React.ReactNode;
    warning?: boolean;
}

const Div = styled.div`
    border: 1px solid ${(props) => props.theme.colors.smoothGrey};
    position: relative;
    padding: 4px 8px;
    display: inline-block;
    border-radius: 5px;
`;

const Icon = styled(IssuesCloseOutlined)`
    position: absolute;
    top: -10px;
    left: 2px;
    background-color: white;
    padding: 0 2px;

    svg {
        fill: ${(props) => props.theme.colors.ckOrange};
    }
`;

const WarningIcon = styled(WarningOutlined)`
    position: absolute;
    top: -10px;
    left: 2px;
    background-color: white;
    padding: 0 2px;

    svg {
        fill: ${(props) => props.theme.colors.ckRed};
    }
`;

export function Specs(props: Props): JSX.Element {
    return (
        <Div>
            {props.warning ? <WarningIcon /> : <Icon />}
            {props.children}
        </Div>
    );
}

export function MaxLengthSpec(props: { max: number | string }): JSX.Element {
    return (
        <Specs>
            <span>
                <i>Max Length</i>: {props.max}
            </span>
        </Specs>
    );
}
