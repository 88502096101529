import React from "react";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";

import style from "./style.module.scss";
import { CursorType } from "../../models";
import { GlowingSpan, GlowingDiv, GlowingColor } from "../glowing-hoc";
import PayByPlateIcon from "icons/payByPlateIcon";

interface Props {
    cursorType?: CursorType;
    onClick?: () => void;
    glowColor?: GlowingColor;
}

export const expirationText = (amountOfDays: number): string => {
    return amountOfDays === 0
        ? `Expires today`
        : amountOfDays === 1
        ? "Expires tomorrow"
        : amountOfDays < 0
        ? "Expired"
        : `Expires in ${amountOfDays + 1} days`;
};

export const PayByPlateCard = (props: Props): JSX.Element => {
    const { cursorType } = props;

    const classes = [style.card];

    return (
        <GlowingDiv
            glowColor={props.glowColor}
            className={classes.join(" ")}
            style={{ cursor: cursorType }}
            onClick={() => props.onClick?.()}
        >
            <div className={style.left}>
                <GlowingSpan className={style.title}>pay by plate</GlowingSpan>
                <p className={style.description}>
                    Register your plate today and sign up later for fueling
                </p>
                <div className={style.fake_link_container}>
                    <span className={style.fake_link}>Here's How It Works</span>
                    <InfoCircleOutlined style={{ fontSize: "80%" }} />
                </div>
            </div>
            <div className={style.right}>
                <PayByPlateIcon />
                <div className={style.fake_link_container}>
                    <span className={style.fake_link}>Get Started!</span>
                    <RightOutlined style={{ fontSize: "80%" }} />
                </div>
            </div>
        </GlowingDiv>
    );
};
