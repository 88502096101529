import React from "react";
import { observer } from "mobx-react";
import { Select, Modal } from "antd";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import style from "./style.module.scss";
import { useAuthStore, usePromotionStore } from "../../contexts/mobx";
import { PromotionTypes, GroupLimitations } from "../../utils/const";
import { getPromotionsTypeName } from "../../utils/content-type";
import { RichText, DebounceSelectMultiple } from "../../components";
import { DealForm, ContestDealForm } from "../../models";
import { Permission } from "../../utils/permission";
import { Controller, useForm } from "react-hook-form";
import {
    DatePicker,
    Select as EasySelect,
    ToggleSwitch,
    Input,
    Button,
} from "../../easyui-components";
import {
    ErrorAwareLabel,
    GalleryPicker,
    IconWrapper,
    HelpfulSpan,
} from "../../components";
import {
    CategoryPreviewDto,
    DealBucketPreview,
    GalleryPreview,
    StoreGroupPreview,
    DealType,
    AgeRestriction,
} from "@full-circle-types";
import { INITIAL_VALUES } from "./utils";

const __doNotCall = () => useFormik<DealForm>({} as any);
type Formik = ReturnType<typeof __doNotCall>;

interface Props {
    formik: Formik;
    categories: CategoryPreviewDto[];
    dealBuckets: DealBucketPreview[];
    tos: GalleryPreview[] | null;
    storeGroupPreviews: StoreGroupPreview[];
    id: number;
    initialDealType: DealType;
}

const INPUT_WIDTH = 192;

export const SideForm = observer((props: Props) => {
    const { formik, categories, dealBuckets, tos, id } = props;
    const { role } = useAuthStore();
    const promotionStore = usePromotionStore();
    const params = useParams<{ action: string }>();
    const canEnable = Permission.canEnable({ role });
    const [previewContent, setPreviewContent] = React.useState<string | null>(
        null
    );
    const [isReissueVisible, setIsReissueVisible] = React.useState<boolean>(
        false
    );

    // The type inference is not working well for nested error schema. So we are
    // going to cheat a little bit using lodash
    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return hasError && (isTouched || params.action !== "create");
    };

    const onDescriptionChange = React.useCallback(
        (text: string, name: string) => {
            formik.setFieldValue(name, text);
        },
        []
    );

    const onAgeRestrictionChange = React.useCallback(
        (e: AgeRestriction) => {
            formik.setFieldValue("ageRestriction", e);

            if (e !== "none" && formik.values.ageRestrictionType === null) {
                formik.setFieldValue(
                    "ageRestrictionType",
                    INITIAL_VALUES.ageRestrictionType
                );
            } else if (e === "none") {
                formik.setFieldValue("ageRestrictionType", null);
            }
        },
        [formik]
    );

    const { getValues, control, reset } = useForm({
        defaultValues: {
            oktaId: "",
        },
    });

    const canReissue =
        params.action === "edit" && props.initialDealType === "coupon";

    return (
        <>
            {/*
                ENABLED
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="enabled">Enabled</HelpfulSpan>

                <ToggleSwitch
                    checked={formik.values.isActive}
                    name="isActive"
                    onChange={formik.handleChange}
                    disabled={!canEnable}
                    tooltipMessage={
                        canEnable
                            ? undefined
                            : "Only a Publisher can change the enabled value of a Deal"
                    }
                />
            </div>
            {/*
                VISIBLE ON HOME PAGE
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="store-specials">
                    Visible on home page
                </HelpfulSpan>

                <ToggleSwitch
                    name="isVisibleOnHomePage"
                    checked={formik.values.isVisibleOnHomePage}
                    onChange={formik.handleChange}
                />
            </div>
            <div className={style.separator}></div>
            {/*
                GROUP LIMITATION
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="deal-group-limitation">
                    Group Limitation
                </HelpfulSpan>

                <EasySelect
                    allowClear
                    width={INPUT_WIDTH}
                    value={formik.values.groupLimitation}
                    onChange={(group) =>
                        formik.setFieldValue("groupLimitation", group ?? null)
                    }
                >
                    {Object.keys(GroupLimitations).map((limitation) => {
                        return (
                            <Select.Option value={limitation}>
                                {limitation}
                            </Select.Option>
                        );
                    })}
                </EasySelect>
            </div>
            {/*
                TYPE
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="deal-type">Type</HelpfulSpan>
                <EasySelect
                    width={INPUT_WIDTH}
                    value={formik.values.type}
                    onChange={(type) => formik.setFieldValue("type", type)}
                >
                    <Select.Option value={PromotionTypes[0]}>
                        {getPromotionsTypeName(PromotionTypes[0])}
                    </Select.Option>
                    <Select.Option value={PromotionTypes[1]}>
                        {getPromotionsTypeName(PromotionTypes[1])}
                    </Select.Option>
                    <Select.Option value={PromotionTypes[2]}>
                        {getPromotionsTypeName(PromotionTypes[2])}
                    </Select.Option>
                </EasySelect>
            </div>

            {/*
                DEAL BUCKET
            */}
            <div className={style.basic_input}>
                <ErrorAwareLabel text="Deal Bucket" section="deal-buckets" />
                <EasySelect
                    width={INPUT_WIDTH}
                    onChange={(id) => formik.setFieldValue("bucketId", id)}
                    value={formik.values.bucketId}
                >
                    {dealBuckets.map((preview) => (
                        <Select.Option value={preview.id} key={preview.id}>
                            {preview.name}
                        </Select.Option>
                    ))}
                </EasySelect>
            </div>

            {/*
                CATEGORY
            */}
            <div className={style.basic_input}>
                <span className={style.required}>Category</span>
                <EasySelect
                    width={INPUT_WIDTH}
                    onChange={(value) =>
                        formik.setFieldValue("categoryId", value)
                    }
                    value={formik.values.categoryId}
                >
                    {categories.map((category) => (
                        <Select.Option value={category.id} key={category.id}>
                            {category.title}
                        </Select.Option>
                    ))}
                </EasySelect>
            </div>

            {/*
                STORE GROUP
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="store-groups">Store Group</HelpfulSpan>
                <DebounceSelectMultiple
                    width={INPUT_WIDTH}
                    placeholder="Store Group"
                    onChange={(v: number[]) =>
                        formik.setFieldValue("storeGroupIds", v)
                    }
                    value={formik.values.storeGroupIds}
                    tooltip={"Clear the Store input to select a Store"}
                />
            </div>
            <div className={style.separator}></div>

            {/*
                IDENTITY VERIFICATION
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="identity-verification">
                    Requires Identity Verification
                </HelpfulSpan>

                <ToggleSwitch
                    checked={formik.values.requiresIdentityVerification}
                    name="requiresIdentityVerification"
                    onChange={formik.handleChange}
                />
            </div>
            {/*
                AGE RESTRICTION
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="deal-age-restriction">
                    Age Restriction
                </HelpfulSpan>

                <EasySelect
                    width={INPUT_WIDTH}
                    value={formik.values.ageRestriction ?? undefined}
                    onChange={onAgeRestrictionChange}
                >
                    <Select.Option value="none">None</Select.Option>
                    <Select.Option value="18+">18+</Select.Option>
                    <Select.Option value="21+">21+</Select.Option>
                </EasySelect>
            </div>

            {/*
                AGE RESTRICTION TYPE
            */}
            {formik.values.ageRestriction !== "none" && (
                <div className={style.basic_input}>
                    <HelpfulSpan
                        section="deal-age-restriction-type"
                        className={style.required}
                    >
                        Age Restriction Type
                    </HelpfulSpan>

                    <EasySelect
                        width={INPUT_WIDTH}
                        value={formik.values.ageRestrictionType ?? undefined}
                        onChange={(e) =>
                            formik.setFieldValue("ageRestrictionType", e)
                        }
                    >
                        <Select.Option value="tobacco">Tobacco</Select.Option>
                        <Select.Option value="alcohol">Alcohol</Select.Option>
                        <Select.Option value="lottery">Lottery</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                    </EasySelect>
                </div>
            )}

            {/*
                ORDER
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="deal-age-restriction">
                    Position
                </HelpfulSpan>
                <Input
                    width={INPUT_WIDTH}
                    name="position"
                    onChange={(e) => {
                        if (e.target.value !== "") {
                            formik.handleChange(e);
                        } else {
                            formik.setFieldValue("position", null);
                        }
                    }}
                    onBlur={formik.handleBlur}
                    type="number"
                    value={formik.values.position ?? undefined}
                />
            </div>

            <div className={style.separator}></div>

            {/*
                START DATE
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="deal-expiration">Start Date</HelpfulSpan>

                <DatePicker
                    width={INPUT_WIDTH}
                    value={formik.values.startDate}
                    hasError={checkIfHasError(`startDate`)}
                    label="Start"
                    onChange={(date) => formik.setFieldValue("startDate", date)}
                    disableIfBefore="now"
                    disabled={false}
                    ableToSetTime
                />
            </div>

            {/*
                END DATE
            */}
            <div className={style.basic_input}>
                <HelpfulSpan section="deal-expiration">End Date</HelpfulSpan>

                <DatePicker
                    width={INPUT_WIDTH}
                    value={formik.values.endDate}
                    hasError={checkIfHasError(`endDate`)}
                    label="End"
                    onChange={(date) => formik.setFieldValue("endDate", date)}
                    disableIfBefore={formik.values.startDate}
                    disabled={false}
                    ableToSetTime
                />
            </div>

            <div className={style.separator}></div>

            {/*
                IMAGE / THUMBNAIL
            */}
            <div className={style.image_and_thumbnail}>
                <div className={style.image}>
                    <HelpfulSpan
                        section="deal-image"
                        className={style.required}
                    >
                        Image
                    </HelpfulSpan>
                    <GalleryPicker
                        size={192}
                        dataType="deals"
                        url={formik.values.translations.en?.thumbnail}
                        onChange={(url) =>
                            formik.setFieldValue(
                                `translations.en.thumbnail`,
                                url
                            )
                        }
                    />
                </div>
                <div className={style.thumbnail}>
                    <HelpfulSpan section="deal-thumbnail">
                        Thumbnail
                    </HelpfulSpan>
                    <GalleryPicker
                        size={192}
                        dataType="deals"
                        allowDeletion={true}
                        url={formik.values.translations.en?.smallThumbnail}
                        onChange={(url) =>
                            formik.setFieldValue(
                                `translations.en.smallThumbnail`,
                                url
                            )
                        }
                    />
                </div>
            </div>

            <div className={style.separator}></div>

            {/*
                DESCRIPTION
            */}
            <div className={style.description}>
                <HelpfulSpan
                    section="deal-thumbnail"
                    className={style.required}
                >
                    Description
                </HelpfulSpan>
                <RichText
                    name={`translations.en.description`}
                    text={formik.values.translations.en.description ?? ""}
                    onChange={onDescriptionChange}
                />
            </div>

            <div className={style.separator}></div>

            {/*
                TITLE
            */}
            <div className={style.basic_input}>
                <span className={style.required}>Title</span>
                <Input
                    width={INPUT_WIDTH}
                    name={`translations.en.title`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.translations.en.title ?? ""}
                />
            </div>

            {/*
                SUB TITLE
            */}
            <div className={style.basic_input}>
                <span className={style.required}>Sub title</span>

                <Input
                    width={INPUT_WIDTH}
                    name={`translations.en.subTitle`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.translations.en.subTitle ?? ""}
                />
            </div>
            {/*
                TOS
            */}
            <div className={style.tos}>
                <div className={style.select_with_preview}>
                    <ErrorAwareLabel
                        text="TOS"
                        hasError={checkIfHasError(`translations.en.tos`)}
                        section="deal-tos"
                    />
                    {formik.values.translations.en?.tos && (
                        <IconWrapper>
                            <EyeOutlined
                                onClick={() =>
                                    setPreviewContent(
                                        formik.values.translations.en.tos!
                                    )
                                }
                            />
                        </IconWrapper>
                    )}
                </div>
                <EasySelect
                    width={INPUT_WIDTH}
                    onChange={(e) =>
                        formik.setFieldValue(`translations.en.tos`, e)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.translations.en?.tos ?? ""}
                    allowClear
                >
                    {tos?.map((to: GalleryPreview) => {
                        return (
                            <Select.Option value={to.url} key={to.id}>
                                {to.name}
                            </Select.Option>
                        );
                    })}
                </EasySelect>
            </div>

            {formik.values.type !== "in_store" && (
                <div className={style.separator}></div>
            )}

            {/*
                EXTERNAL URL ( CONTEST )
            */}
            {formik.values.type === "contest" && (
                <div className={style.basic_input}>
                    <span className={style.required}>External Url</span>
                    <Input
                        width={INPUT_WIDTH}
                        name={`translations.en.externalUrl`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                            (formik.values as ContestDealForm).translations.en
                                ?.externalUrl ?? ""
                        }
                    />
                </div>
            )}
            {/*
                CTA ( CONTEST )
            */}
            {formik.values.type === "contest" && (
                <div className={style.basic_input}>
                    <span className={style.required}>CTA</span>
                    <Input
                        width={INPUT_WIDTH}
                        name={`translations.en.cta`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                            (formik.values as ContestDealForm).translations.en
                                ?.cta ?? undefined
                        }
                    />
                </div>
            )}
            {/* COUPON related fields
             */}
            {formik.values.type === "coupon" && (
                <>
                    <div className={style.basic_input}>
                        <HelpfulSpan
                            className={style.required}
                            section="deal-coupon-activation-code"
                        >
                            Activation Code
                        </HelpfulSpan>
                        <Input
                            width={INPUT_WIDTH}
                            value={formik.values.activationCode ?? ""}
                            name="activationCode"
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className={style.basic_input}>
                        <HelpfulSpan
                            className={style.required}
                            section="deal-coupon-quantity"
                        >
                            Quantity
                        </HelpfulSpan>
                        <Input
                            width={INPUT_WIDTH}
                            type="number"
                            value={formik.values.quantity}
                            name="quantity"
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className={style.basic_input}>
                        <HelpfulSpan
                            className={style.required}
                            section="deal-coupon-max-activations-count"
                        >
                            Max Activations Count
                        </HelpfulSpan>
                        <Input
                            width={INPUT_WIDTH}
                            name="maxActivationsCount"
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    formik.handleChange(e);
                                } else {
                                    formik.setFieldValue(
                                        "maxActivationsCount",
                                        null
                                    );
                                }
                            }}
                            onBlur={formik.handleBlur}
                            type="number"
                            value={formik.values.maxActivationsCount}
                        />
                    </div>
                    {canReissue && (
                        <div>
                            <Button
                                className={style.reissue_button}
                                type="secondary"
                                onClick={() => setIsReissueVisible(true)}
                            >
                                Reissue Coupon
                            </Button>
                        </div>
                    )}
                </>
            )}

            <Modal
                title={"Preview"}
                visible={Boolean(previewContent)}
                onCancel={() => setPreviewContent(null)}
                footer={null}
            >
                <iframe
                    frameBorder="0"
                    src={previewContent!}
                    title="deal preview"
                />
            </Modal>

            <Modal
                title={`Reissue Coupon #${id}`}
                visible={isReissueVisible}
                onCancel={() => setIsReissueVisible(false)}
                onOk={() => {
                    promotionStore.reissueCoupon(getValues().oktaId, id);
                    setIsReissueVisible(false);
                }}
            >
                <Controller
                    name="oktaId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <div className={style.basic_input}>
                            <HelpfulSpan
                                className={style.required}
                                section="deal-coupon-reissue"
                            >
                                Okta Id
                            </HelpfulSpan>
                            <Input
                                width={350}
                                placeholder="Okta Id"
                                value={field.value}
                                onChange={field.onChange}
                            />
                        </div>
                    )}
                />
            </Modal>
        </>
    );
});
