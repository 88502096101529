import { observable, action, makeObservable, flow } from "mobx";

import { RootStore } from "./root";
import { NotificationManager } from "../components";
import { Featured, FeaturedDeleteParams } from "@full-circle-types";
import { PaginatedList } from "../types";
import { ActiveQueryParam } from "../utils/const";

export type PaginatedFeatured = PaginatedList<Featured>;

export type SearchParams = {
    perPage?: number;
    page?: number;
    filter?: ActiveQueryParam;
    storeGroupId?: number;
    storeId?: number;
};

export class FeaturedStore {
    @observable paginatedList: PaginatedFeatured | null = null;
    @observable isFetchingList = false;
    @observable visibleList: Featured[] = [];
    @observable isFetchingVisibleList = false;
    @observable isUpdatingVisibleList = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    getPaginatedList = flow(function* (
        this: FeaturedStore,
        params: SearchParams
    ) {
        if (this.isFetchingList) {
            return;
        }

        this.isFetchingList = true;

        const response = yield this.rootStore.makeNetworkCall<
            PaginatedFeatured
        >({
            method: "get",
            url: "/featured",
            params,
        });

        this.isFetchingList = false;

        if (!response.err) {
            this.paginatedList = response.data;
        }
    });

    delete = flow(function* (
        this: FeaturedStore,
        params: FeaturedDeleteParams
    ) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/featured/${params.id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Featured deleted");
        }

        return response;
    });

    @action
    setVisibleList(list: Featured[]): void {
        this.visibleList = list;
    }
}
