import { observable, makeObservable, flow } from "mobx";
import { RootStore, MakeNetworkCall } from "./root";
import { NotificationManager } from "../components";
import { WithNetworkConcurrency } from "./with-network-concurrency";
import {
    ServiceList,
    AllServiceParams,
    ServiceCmsDto,
    ServicePayload,
} from "@full-circle-types";
export class ServiceStore extends WithNetworkConcurrency {
    @observable paginatedList: ServiceList | null = null;
    @observable isFetchingServicesCount = 0;
    @observable isUpdatingServices = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    fetchStores = flow(function* (
        this: ServiceStore,
        params?: AllServiceParams
    ) {
        const tag = this.getTag();
        this.isFetchingServicesCount++;
        const response = yield this.rootStore.makeNetworkCall<ServiceList>({
            method: "get",
            url: "/services",
            params,
        });
        this.isFetchingServicesCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.paginatedList = response.data;
        }
    });

    fetchSingleService = flow(function* (
        this: ServiceStore,
        id: number
    ): MakeNetworkCall<ServiceCmsDto, ServiceCmsDto> {
        const response = yield this.rootStore.makeNetworkCall({
            method: "get",
            url: `/services/${id}`,
        });

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    });

    updateService = flow(function* (
        this: ServiceStore,
        id: number,
        data: ServicePayload
    ) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "put",
            url: `/services/${id}`,
            data,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Service updated!");
        }
    });

    delete = flow(function* (this: ServiceStore, id: number) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/services/${id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Service deleted!");
        }
    });
}
