import { GlowingColor } from "../../components/glowing-hoc";
import { GlowingConfiguration as DealCardConfiguration } from "../../components/standard-card";
import { GlowingConfiguration as DealPageConfiguration } from "../../components/advance-gmap-visualization-tool/deal-page";
import { GlowingConfiguration as LoyaltyPageConfiguration } from "../../components/advance-gmap-visualization-tool/loyalty-page";
export interface GlowConfiguration {
    storeSpecials?: GlowingColor;
    dealBuckets?: GlowingColor;
    dealBucket?: {
        name?: GlowingColor;
        image?: GlowingColor;
    };
    dealCard?: DealCardConfiguration;
    dealPage?: DealPageConfiguration;
    featured?: {
        deal?: GlowingColor;
        externalUrl: GlowingColor;
    };
    featuredCard?: { cta?: GlowingColor };
    loyalty?: LoyaltyPageConfiguration;
    services?: {
        inApp?: GlowingColor;
        externalUrl?: GlowingColor;
    };
}

export const AllConfigurations = {
    "deal-title": {
        dealCard: {
            title: "blue",
        },
        dealPage: {
            title: "blue",
        },
    },
    "deal-sub-title": {
        dealCard: {
            subTitle: "blue",
        },
        dealPage: {
            subTitle: "blue",
        },
    },
    "deal-category": {
        dealCard: {
            category: "blue",
        },
        dealPage: {
            category: "blue",
        },
    },
    "deal-description": {
        dealPage: {
            description: "blue",
        },
    },
    "deal-image": {
        dealCard: {
            thumbnail: "blue",
        },
        dealPage: {
            image: "blue",
        },
    },
    "deal-thumbnail": {
        dealCard: {
            thumbnail: "green",
        },
    },
    "deal-expiration": {
        dealCard: {
            expiration: "blue",
        },
        dealPage: {
            expiration: "blue",
        },
    },
    "deal-tos": {
        dealPage: {
            tos: "blue",
        },
    },
    "store-specials": {
        storeSpecials: "red",
    },
    "deal-buckets": {
        dealBuckets: "red",
    },
    "deal-bucket-name": {
        dealBucket: {
            name: "red",
        },
    },
    "deal-bucket-icon": {
        dealBucket: { image: "red" },
    },
    "deal-type": {
        dealCard: {
            body: {
                coupon: "red",
                contest: "green",
                in_store: "blue",
            },
        },
        dealPage: {
            couponButtons: "red",
            contestButton: "green",
        },
    },
    "deal-age-restriction": {
        dealCard: {
            body: {
                ageRestricted: "orange",
            },
        },
    },
    "featured-type": {
        featured: {
            deal: "red",
            externalUrl: "blue",
            innerCircle: "green",
        },
    },
    "featured-cta": { featuredCard: { cta: "orange" } },
    "loyalty-category": {
        loyalty: {
            category: "red",
        },
    },
    "loyalty-type": {
        loyalty: {
            tab: "blue",
        },
    },
    "loyalty-filled-icon": {
        loyalty: {
            filledIcon: "red",
        },
    },
    "loyalty-empty-icon": {
        loyalty: {
            emptyIcon: "blue",
        },
    },
    "loyalty-title": {
        loyalty: {
            title: "blue",
        },
    },
    "loyalty-title-highlight": {
        loyalty: {
            titleHighlight: "orange",
        },
    },
    "loyalty-sub-title": {
        loyalty: {
            subTitle: "red",
        },
    },
    "loyalty-image": {
        loyalty: {
            thumbnail: "blue",
            image: "blue",
        },
    },
    "loyalty-thumbnail": {
        loyalty: {
            thumbnail: "green",
        },
    },
    "service-type": {
        services: {
            inApp: "red",
            externalUrl: "blue",
        },
    },
} as const;

export type DemoMode = keyof typeof AllConfigurations;
