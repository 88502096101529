import React from "react";
import { Modal, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import { SupportedLanguage } from "types";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";

import style from "./style.module.scss";
import { GalleryPicker, IconWrapper, HelpfulSpan } from "../../components";
import { useAuthStore, useTierBenefitStore } from "contexts/mobx";
import { Permission } from "shared-utils";
import {
    TierBenefitPayload,
    TierBenefitType,
    TierBenefitCmsDto,
} from "@full-circle-types";
import {
    ToggleSwitch,
    Input,
    Select as EasySelect,
} from "../../easyui-components";

// ███████ ██████  ██ ████████        ██         ██████ ██████  ███████  █████  ████████ ███████     ███    ███  ██████  ██████   █████  ██
// ██      ██   ██ ██    ██           ██        ██      ██   ██ ██      ██   ██    ██    ██          ████  ████ ██    ██ ██   ██ ██   ██ ██
// █████   ██   ██ ██    ██        ████████     ██      ██████  █████   ███████    ██    █████       ██ ████ ██ ██    ██ ██   ██ ███████ ██
// ██      ██   ██ ██    ██        ██  ██       ██      ██   ██ ██      ██   ██    ██    ██          ██  ██  ██ ██    ██ ██   ██ ██   ██ ██
// ███████ ██████  ██    ██        ██████        ██████ ██   ██ ███████ ██   ██    ██    ███████     ██      ██  ██████  ██████  ██   ██ ███████
type EditAndCreateProps = {
    isModalVisible: false | "edit" | "create";
    setIsModalVisible: React.Dispatch<
        React.SetStateAction<false | "edit" | "create">
    >;
    canEnable: boolean;
    currentTB?: TierBenefitCmsDto;
};

export const EditAndCreateModal = (props: EditAndCreateProps): JSX.Element => {
    const authStore = useAuthStore();
    const role = authStore.role;
    const tierBenefitStore = useTierBenefitStore();
    const { isModalVisible, setIsModalVisible, canEnable, currentTB } = props;
    const { getValues, control, reset } = useForm<TierBenefitPayload>({
        defaultValues: {
            isEnabled: false,
            type: "member" as TierBenefitType,
            description: undefined,
            icon: undefined,
            lang: "en" as SupportedLanguage,
            position: 0,
            isFuelReward: false,
            unit: null,
        },
    });

    React.useEffect(() => {
        reset({
            isEnabled: currentTB?.isEnabled ?? false,
            type: currentTB?.type ?? "member",
            description: currentTB?.description ?? "",
            icon: currentTB?.icon ?? "",
            lang: "en",
            position: 0,
            isFuelReward: false,
            unit: null,
        });
    }, [currentTB, reset]);

    const onEdit = async () => {
        await tierBenefitStore.update(Number(currentTB!.id), getValues());
        tierBenefitStore.getAll({});
        setIsModalVisible(false);
    };
    const submitCreation = async () => {
        await tierBenefitStore.create(getValues());
        tierBenefitStore.getAll({});
        setIsModalVisible(false);
    };

    return (
        <Modal
            title={
                isModalVisible === "create"
                    ? "Create Tier Benefit"
                    : "Edit Tier Benefit"
            }
            visible={!!isModalVisible}
            onOk={() => {
                isModalVisible === "create" ? submitCreation() : onEdit();
            }}
            onCancel={() => {
                reset();
                setIsModalVisible(false);
            }}
            okButtonProps={{
                disabled: !Permission.canEdit({
                    role,
                    enabled: currentTB?.isEnabled ?? false,
                }),
            }}
        >
            <form className={style.create_form}>
                <div className={style.left_inputs}>
                    <Controller
                        name="isEnabled"
                        control={control}
                        render={({ field }) => (
                            <div>
                                <HelpfulSpan section="enabled">
                                    Enabled
                                </HelpfulSpan>
                                <ToggleSwitch
                                    checked={field.value}
                                    name="isEnabled"
                                    onChange={field.onChange}
                                    disabled={!canEnable}
                                    tooltipMessage={
                                        canEnable
                                            ? undefined
                                            : "Only a Publisher can change the enabled value of a Deal"
                                    }
                                />
                            </div>
                        )}
                    />

                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <div className={style.input_container}>
                                <HelpfulSpan>Type</HelpfulSpan>
                                <EasySelect<TierBenefitType>
                                    placeholder="Type"
                                    width={200}
                                    value={field.value}
                                    allowClear
                                    onChange={field.onChange}
                                >
                                    <Select.Option
                                        value={"member"}
                                        key={"member"}
                                    >
                                        Member
                                    </Select.Option>
                                    <Select.Option
                                        value={"premium"}
                                        key={"premium"}
                                    >
                                        Premium
                                    </Select.Option>
                                </EasySelect>
                            </div>
                        )}
                    />

                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <div className={style.input_container}>
                                <HelpfulSpan>Description</HelpfulSpan>
                                <Input
                                    placeholder="Description"
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            </div>
                        )}
                    />
                </div>

                <Controller
                    name="icon"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <div className={style.input_container}>
                            <HelpfulSpan>Icon</HelpfulSpan>
                            <GalleryPicker
                                dataType="icon"
                                allowDeletion={false}
                                url={field.value}
                                onChange={field.onChange}
                            />
                        </div>
                    )}
                />
            </form>
        </Modal>
    );
};

// ████████ ██ ███████ ██████      ██████  ███████ ███    ██ ███████ ███████ ██ ████████     ██████   ██████  ██     ██
//    ██    ██ ██      ██   ██     ██   ██ ██      ████   ██ ██      ██      ██    ██        ██   ██ ██    ██ ██     ██
//    ██    ██ █████   ██████      ██████  █████   ██ ██  ██ █████   █████   ██    ██        ██████  ██    ██ ██  █  ██
//    ██    ██ ██      ██   ██     ██   ██ ██      ██  ██ ██ ██      ██      ██    ██        ██   ██ ██    ██ ██ ███ ██
//    ██    ██ ███████ ██   ██     ██████  ███████ ██   ████ ███████ ██      ██    ██        ██   ██  ██████   ███ ███
type TierBenefitRowProps = {
    tierBenefit: TierBenefitPayload;
    onDelete: () => void;
    onEdit: () => void;
    cursorType: string;
};

export const TierBenefitRow = (props: TierBenefitRowProps): JSX.Element => {
    return (
        <div
            className={style.benefit_row}
            style={
                !props.tierBenefit.isEnabled
                    ? { backgroundColor: "rgba(218,41,28,0.2)" }
                    : {}
            }
        >
            <div className={style.benefit_left}>
                <div className="handle" style={{ cursor: props.cursorType }}>
                    <IconWrapper>
                        <MoreOutlined style={{ fontSize: "24px" }} />
                    </IconWrapper>
                </div>
                <img
                    src={props.tierBenefit.icon}
                    className={style.benefit_icon}
                    alt="tier benefit icon"
                />
                {props.tierBenefit.description}
            </div>
            <div className={style.benefit_command}>
                <IconWrapper>
                    <DeleteOutlined
                        onClick={() => props.onDelete()}
                        style={{ fontSize: "24px", marginLeft: "10px" }}
                    />
                </IconWrapper>
                <IconWrapper>
                    <EditOutlined
                        onClick={() => props.onEdit()}
                        style={{ fontSize: "24px", marginLeft: "10px" }}
                    />
                </IconWrapper>
            </div>
        </div>
    );
};
//
