import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { ColumnProps } from "antd/es/table";
import { Table, message, Modal, Typography, Space, Tooltip } from "antd";
import {
    CopyOutlined,
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    FileTextOutlined,
    PictureOutlined,
    FolderOutlined,
} from "@ant-design/icons";

import style from "./style.module.scss";
import { useGalleryStore } from "../../contexts/mobx";
import { useQueryParams } from "../../utils/use-query-params";
import { Pagination, IconWrapper } from "../../components";
import { RichTextEditor } from "../../components";
import { Gallery, GalleryLabel, GallerySearchParams } from "@full-circle-types";
import { NotificationManager, CkLogo, TopHeaderPortal } from "../../components";
import { getImageRatio } from "utils/image-ratio";
import {
    Button,
    Input,
    Select,
    Option,
    Highlighter,
} from "../../easyui-components";
import {
    linkDecorator,
    RichProvider,
} from "../../components/rich-text-editor/helper";

const { Text } = Typography;

type Params = {
    label: GalleryLabel;
};

export const GalleryPage = observer(() => {
    const store = useGalleryStore();
    const params = useParams<Params>();
    const hiddenImageInputRef = React.useRef<HTMLInputElement | null>(null);
    const hiddenZipInputRef = React.useRef<HTMLInputElement | null>(null);
    const [newContentName, setNewContentName] = React.useState("");
    const [isImageModalVisible, setIsImageModalVisible] = React.useState(false);
    const [previewContent, setPreviewContent] = React.useState<Gallery | null>(
        null
    );
    const [isTextModalVisible, setIsTextModalVisible] = React.useState(false);
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createEmpty(linkDecorator)
    );
    const [richTextName, setRichTextName] = React.useState("");
    const [queryParams, setParams] = useQueryParams<GallerySearchParams>();
    const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState<number>(-1);

    React.useEffect(() => {
        store.fetchGallery(queryParams, params.label);
    }, [store, queryParams, params]);

    React.useEffect(() => {
        store.getTags(params.label);
    }, [params.label, store]);

    const showPreview = (gallery: Gallery) => {
        if (gallery.type === "site") {
            window.open(gallery.cdnUrl, "_blank", "noreferrer");
        } else {
            setPreviewContent(gallery);
        }
    };

    const { getValues, control, setValue, reset } = useForm();

    const columns: ColumnProps<Gallery>[] = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_, record) => (
                <Highlighter search={queryParams.query ?? ""}>
                    {record.name}
                </Highlighter>
            ),
            width: "30%",
        },
        {
            title: "Tag",
            render: (_, record) => {
                return (
                    <div>
                        {record.tags?.map((tag, index) => {
                            return (
                                <span key={index} className="simple-tag">
                                    {tag}
                                </span>
                            );
                        })}
                    </div>
                );
            },
            width: "30%",
        },
        {
            title: "Type",
            render: (_, record) => {
                if (record.type === "html") {
                    return (
                        <IconWrapper>
                            <Tooltip title="Rich Text">
                                <FileTextOutlined className={style.type_svg} />
                            </Tooltip>
                        </IconWrapper>
                    );
                } else if (record.type === "site") {
                    return (
                        <IconWrapper>
                            <Tooltip title="Website">
                                <FolderOutlined className={style.type_svg} />
                            </Tooltip>
                        </IconWrapper>
                    );
                } else {
                    return (
                        <IconWrapper>
                            <Tooltip title="Image">
                                <PictureOutlined className={style.type_svg} />
                            </Tooltip>
                        </IconWrapper>
                    );
                }
            },
            width: "8%",
        },
        {
            title: "Copy",
            key: "copy",
            render: (_, record) => (
                <IconWrapper>
                    <CopyOutlined
                        onClick={() => {
                            navigator.clipboard.writeText(record.cdnUrl);
                            message.success("Copied to clipboard!");
                        }}
                    />
                </IconWrapper>
            ),
            width: "8%",
        },
        {
            title: "View",
            key: "view",
            render: (_, record) => (
                <IconWrapper>
                    <EyeOutlined onClick={() => showPreview(record)} />
                </IconWrapper>
            ),
            width: "8%",
        },
        {
            title: "Delete",
            key: "delete",
            render: (_, record) => (
                <IconWrapper>
                    <DeleteOutlined
                        onClick={() =>
                            Modal.confirm({
                                title:
                                    "Are you sure you want to delete this content?",
                                onOk: async () => {
                                    await store.deleteContent(record.id);
                                    store.fetchGallery(
                                        queryParams,
                                        params.label
                                    );
                                },
                            })
                        }
                    />
                </IconWrapper>
            ),
            width: "8%",
        },
        {
            title: "Edit",
            key: "edit",
            render: (_, record) => (
                <IconWrapper>
                    <EditOutlined
                        onClick={() => {
                            setValue("name", record.name);
                            setValue("tags", record.tags);
                            setSelectedId(record.id);
                            setIsEditModalVisible(true);
                        }}
                    />
                </IconWrapper>
            ),
            width: "8%",
        },
    ];

    const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const isRectangleType =
            params.label === "featured" || params.label === "service";

        if (file) {
            const ratio = await getImageRatio(file);

            if (file.size > 200000) {
                // User selects an Image with a size > 200kb
                NotificationManager.showError(
                    "Image size should not exceed 200kb"
                );
                return;
            }

            if (isRectangleType && !(ratio >= 1.68 && ratio <= 1.69)) {
                NotificationManager.showError(
                    "The image must respect a 1.686 ratio ( 312px x 185px , 624px x 370px, etc )",
                    15
                );
                return;
            }

            await store.uploadFile("image", file, file.name, params.label);
            store.fetchGallery(queryParams, params.label);
        }

        setNewContentName("");
    };

    const uploadZipFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0];
        if (file) {
            await store.uploadFile("site", file, file.name, params.label);
            store.fetchGallery(queryParams, params.label);
        }
    };

    const getHtml = async () => {
        const hashConfig = {
            trigger: "#",
            separator: " ",
        };
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState, hashConfig);

        setIsTextModalVisible(false);
        await store.uploadHtml(markup, richTextName, params.label);
        store.fetchGallery(queryParams, params.label);
        setEditorState(EditorState.createEmpty(linkDecorator));
        setRichTextName("");
    };

    const updateGalleryName = async () => {
        await store.update(
            selectedId,
            getValues().name,
            getValues().tags || []
        );
        store.fetchGallery(queryParams, params.label);
    };

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="gallery">
                        GALLERY
                    </CkLogo>
                    <div className="nav_button_holder">
                        <Button
                            type="primary"
                            onClick={() => hiddenImageInputRef.current?.click()}
                            style={
                                params.label === "tos"
                                    ? { display: "none" }
                                    : {}
                            }
                        >
                            Add Image
                        </Button>
                        <input
                            ref={hiddenImageInputRef}
                            className={style.hidden_input}
                            onChange={(event) => uploadImage(event)}
                            type="file"
                            accept={
                                params.label === "icon"
                                    ? ".svg"
                                    : "image/png, image/gif, image/jpeg"
                            }
                        />
                        <Button
                            type="primary"
                            onClick={() => setIsTextModalVisible(true)}
                            style={
                                params.label === "tos" ||
                                params.label === "none"
                                    ? {}
                                    : { display: "none" }
                            }
                        >
                            Add Rich Text
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => hiddenZipInputRef.current?.click()}
                            style={
                                params.label === "tos" ||
                                params.label === "none"
                                    ? { marginRight: 0 }
                                    : { display: "none", marginRight: 0 }
                            }
                        >
                            Add Website zip
                        </Button>
                        <input
                            ref={hiddenZipInputRef}
                            className={style.hidden_input}
                            onChange={(event) => uploadZipFile(event)}
                            type="file"
                            accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
                        />
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.static_content_page}>
                <div className={style.commands}>
                    <Select
                        mode="multiple"
                        placeholder="Select your tags"
                        width={200}
                        value={queryParams.tags || []}
                        onChange={(value: string[]) =>
                            setParams({ tags: value })
                        }
                        allowClear
                    >
                        {store.tags.map((tag, index) => {
                            return (
                                <Option value={tag} key={index}>
                                    {tag}
                                </Option>
                            );
                        })}
                    </Select>
                    <Input
                        value={queryParams.query}
                        onChange={(e) => setParams({ query: e.target.value })}
                        allowClear={true}
                        placeholder="Search"
                    />
                </div>

                <Pagination
                    params={queryParams}
                    meta={store.list}
                    onChange={(pageNumber, pageSize) =>
                        setParams({ page: pageNumber, perPage: pageSize })
                    }
                    onPageSizeChange={(size) => setParams({ perPage: size })}
                />
                <Table<Gallery>
                    rowKey="id"
                    columns={columns}
                    scroll={{ x: 900 }}
                    dataSource={store.list?.results}
                    loading={
                        store.isFetchingCount > 0 &&
                        (store.isFetching ||
                            store.isUploading ||
                            store.isDeleting ||
                            store.isUpdating)
                    }
                    pagination={false}
                />

                <Modal
                    title="Upload Text"
                    visible={isTextModalVisible}
                    okButtonProps={{
                        disabled: !richTextName,
                    }}
                    onOk={() => getHtml()}
                    onCancel={() => setIsTextModalVisible(false)}
                >
                    <div className={style.rich_text_modal}>
                        <span>Name</span>
                        <Input
                            value={richTextName}
                            onChange={(event) =>
                                setRichTextName(event.target.value)
                            }
                            fullWidth={true}
                        />
                    </div>
                    <RichProvider>
                        <RichTextEditor
                            onEditorStateChange={(x) => setEditorState(x)}
                            editorState={editorState}
                        />
                    </RichProvider>
                </Modal>

                <Modal
                    title="Upload Image"
                    visible={isImageModalVisible}
                    okButtonProps={{
                        disabled: !newContentName,
                    }}
                    onOk={() => {
                        setIsImageModalVisible(false);
                        hiddenImageInputRef.current?.click();
                    }}
                    onCancel={() => setIsImageModalVisible(false)}
                >
                    <p>
                        Before uploading the image, please select a name for it
                    </p>
                    <Input
                        value={newContentName}
                        onChange={(event) =>
                            setNewContentName(event.target.value)
                        }
                    />
                </Modal>

                <Modal
                    title={previewContent?.name || "Preview"}
                    visible={Boolean(previewContent)}
                    onCancel={() => setPreviewContent(null)}
                    footer={null}
                >
                    {previewContent?.type === "image" && (
                        <div>
                            <img
                                className={style.preview_image}
                                src={previewContent?.cdnUrl}
                                alt="gallery item preview"
                            />
                        </div>
                    )}
                    {previewContent?.type === "html" && (
                        <iframe
                            frameBorder="0"
                            src={previewContent?.cdnUrl}
                            title="html preview"
                        />
                    )}
                </Modal>

                <Modal
                    title={"Edit Gallery"}
                    visible={isEditModalVisible}
                    onOk={() => {
                        updateGalleryName();
                        setIsEditModalVisible(false);
                    }}
                    onCancel={() => {
                        setIsEditModalVisible(false);
                    }}
                    afterClose={() => reset({ name: "", tags: [] })}
                >
                    <Space direction="vertical">
                        <Text strong>Name</Text>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Input
                                    placeholder="Name"
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />

                        <Text strong>Tags</Text>
                        <Controller
                            name="tags"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    mode="tags"
                                    placeholder="Select your tags"
                                    width={200}
                                    onChange={field.onChange}
                                    value={field.value || []}
                                    allowClear
                                >
                                    {store.tags.map((tag, index) => {
                                        return (
                                            <Option value={tag} key={index}>
                                                {tag}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </Space>
                </Modal>
            </div>
        </>
    );
});
