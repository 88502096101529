import { observable, makeObservable, flow } from "mobx";
import { RootStore, MakeNetworkCall } from "./root";
import { NotificationManager } from "../components";
import { WithNetworkConcurrency } from "./with-network-concurrency";
import {
    CategoryList,
    CategoryPreviewDto,
    CategoryCmsDto,
    CategorySearchParams,
} from "@full-circle-types";
export class CategoryStore extends WithNetworkConcurrency {
    @observable categories: CategoryList | null = null;
    @observable isFetchingCount = 0;
    @observable isFetchingPreviews = false;
    @observable categoryPreviews: CategoryPreviewDto[] = [];

    activeCategoriesMap: Map<number, CategoryCmsDto> = new Map();
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    fetchCategoryPreviews = flow(function* (this: CategoryStore) {
        if (this.isFetchingPreviews || this.categoryPreviews.length > 0) {
            return;
        }

        this.isFetchingPreviews = true;
        const response = yield this.rootStore.makeNetworkCall<
            CategoryPreviewDto[]
        >({
            method: "get",
            url: "/promotion-categories/preview",
        });
        this.isFetchingPreviews = false;

        if (!response.err) {
            this.categoryPreviews = response.data;
        }
    });

    fetchCategories = flow(function* (
        this: CategoryStore,
        searchParams: CategorySearchParams
    ) {
        const tag = this.getTag();
        this.isFetchingCount++;
        const response = yield this.rootStore.makeNetworkCall<CategoryList>({
            method: "get",
            url: "/promotion-categories",
            params: searchParams,
        });
        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.categories = response.data;
        }
    });

    fetchCategory = flow(function* (
        this: CategoryStore,
        categoryId: number
    ): MakeNetworkCall<CategoryCmsDto, CategoryCmsDto> {
        const response = yield this.rootStore.makeNetworkCall({
            method: "get",
            url: `/promotion-categories/${categoryId}`,
        });

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    });

    updateCategory = flow(function* (
        this: CategoryStore,
        category: any,
        categoryId: number
    ) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "put",
            url: `/promotion-categories/${categoryId}`,
            data: category,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Category updated");
            return response.data;
        } else {
            throw response.err;
        }
    });

    createCategory = flow(function* (this: CategoryStore, category: any) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "post",
            url: `/promotion-categories`,
            data: category,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Category created");
            return response.data;
        } else {
            throw response.err;
        }
    });

    deleteCategory = flow(function* (this: CategoryStore, categoryId: number) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/promotion-categories/${categoryId}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Category deleted");
        }
        return response;
    });
}
