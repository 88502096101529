import { action, computed, observable, flow, makeAutoObservable } from "mobx";
import { StorePreview, MobileFeatured, MobileDeal } from "@full-circle-types";
import { DealFeaturedForm, FeaturedForm } from "models";
import { HomePage } from "../promotion-page/dummy-content";
import { RootStore } from "../../stores/root";
import {
    AgvtState,
    CoreCouponManager,
    NavigationSection,
} from "../../components/advance-gmap-visualization-tool";
import {
    Navigation,
    StackItem,
} from "../../components/advance-gmap-visualization-tool/agvt-state";

export type DisplayMode = "featured-only" | "fake-content";

const formToFeatured = (form: FeaturedForm): MobileFeatured => {
    return {
        id: 0,
        title: form.translations.en.textTitle!,
        image: form.translations.en.backgroundImage,
        externalUrl: null,
        dealId: "promotionId" in form ? form.promotionId : 0,
        position: 0,
        type: form.type,
        cta: form.translations.en.cta,
        isInnerCircle: true,
        isFullScreen: false,
        requiresIdentityVerification: false,
        ageRestriction: "none",
        ageRestrictionType: null,
        authenticationType: "no_authentication",
        authenticationName: null,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        value: null,
    };
};

export class FeaturedNavigation implements Navigation {
    @observable
    public stack: StackItem[];

    constructor() {
        this.stack = [
            {
                section: "home" as NavigationSection,
            },
        ];
        makeAutoObservable(this);
    }

    @action
    push(path: StackItem): void {
        if (path.section === "deal" || path.section === "inner-circle") {
            this.stack.push(path);
        }
    }

    @action
    pop(): void {
        this.stack.pop();
    }

    replace(path: StackItem): void {
        if (path.section === "inner-circle" || path.section === "home") {
            this.stack.pop();
            this.stack.push(path);
        }
    }

    get currentPage(): StackItem {
        return this.stack[this.stack.length - 1];
    }
}

export class AgvtFeaturedStore implements AgvtState {
    @observable
    store: StorePreview = {
        id: 2709355,
        simId: 7900382,
        city: "ST-JEAN-SUR-RICHELIEU",
        street: "165 Boul Omer Marcil",
        postalCode: "J2W 0A3",
        state: "QC",
        country: "CA",
    };

    glowConfiguration = {};
    deals = null;
    dealBuckets = null;
    loyalties = undefined;

    fetchHomeContent = flow(function* () {
        // do nothing
    });
    fetchDealBuckets = flow(function* () {
        // do nothing
    });
    fetchDeals = flow(function* () {
        // do nothing
    });
    fetchDeal = flow(function* (
        this: AgvtFeaturedStore,
        options: {
            dealId: number;
        }
    ) {
        const response = yield this.rootStore.makeMobileNetworkCall<{
            deal: MobileDeal;
        }>({
            method: "get",
            url: `/deals/${options.dealId}`,
        });

        if (!response.err) {
            this.navigation.currentPage.data.deal = response.data.deal;
        }
    });

    isCouponListVisible = false;

    @observable
    trackedFeatured: MobileFeatured | null = null;
    @observable
    displayMode: DisplayMode = "featured-only";

    constructor(
        private rootStore: RootStore,
        public couponManager = new CoreCouponManager(),
        public navigation = new FeaturedNavigation()
    ) {
        makeAutoObservable(this);
    }

    @action
    setStore(): void {
        // do nothing
    }

    @action
    syncWithForm(form: FeaturedForm): void {
        const isOnDealPage = this.navigation.currentPage.section === "deal";
        const newDealId = (form as DealFeaturedForm).promotionId;
        const isDifferentDealId = newDealId !== this.trackedFeatured?.dealId;

        this.trackedFeatured = formToFeatured(form);

        if (isOnDealPage && isDifferentDealId) {
            if (newDealId) {
                this.fetchDeal({ dealId: newDealId });
            } else {
                this.navigation.currentPage.data.deal = undefined;
            }
        }
    }

    @action
    setDisplayMode(mode: DisplayMode): void {
        this.displayMode = mode;
    }

    @computed
    get displayedFeatured(): MobileFeatured[] {
        if (this.useFakeContent) {
            return this.trackedFeatured
                ? [this.trackedFeatured, ...HomePage.featured]
                : [...HomePage.featured];
        } else {
            return this.trackedFeatured ? [this.trackedFeatured] : [];
        }
    }

    @computed
    get useFakeContent(): boolean {
        return this.displayMode === "fake-content";
    }

    @computed
    get homeContent() {
        return {
            services: this.useFakeContent ? HomePage.services : [],
            featured: this.displayedFeatured,
            deals: this.useFakeContent ? HomePage.deals : [],
            dealBuckets: this.useFakeContent ? HomePage.dealBuckets : [],
        };
    }
}
