import React from "react";
import { RootStore } from "../stores/root";
import { PromotionStore } from "../stores/promotion";
import { CategoryStore } from "../stores/category";
import { FeaturedStore } from "../stores/featured";
import { StoresStore } from "../stores/stores";
import { StoreGroupsStore } from "../stores/store-group";
import { GalleryStore } from "../stores/gallery";
import { DealBucketStore } from "../stores/deal-bucket";
import { LoyaltyStore } from "../stores/loyalty";
import { ServiceStore } from "../stores/service";
import { ConfigStore } from "../stores/config";
import { AuthStore } from "../stores/auth";
import { TierBenefitStore } from "../stores/tier-benefit";

const rootStore = new RootStore();
const storeContext = React.createContext<RootStore | null>(null);

interface StoreProviderProps {
    children: React.ReactNode;
}

export const StoreProvider = ({
    children,
}: StoreProviderProps): JSX.Element => {
    return (
        <storeContext.Provider value={rootStore}>
            {children}
        </storeContext.Provider>
    );
};

export const useStore = (): RootStore => {
    const store = React.useContext(storeContext);
    if (!store) {
        throw new Error("useStore must be used within a StoreProvider.");
    }
    return store;
};

export const usePromotionStore = (): PromotionStore => {
    const store = useStore();
    return store.promotionStore;
};

export const useCategoryStore = (): CategoryStore => {
    const store = useStore();
    return store.categoryStore;
};

export const useFeaturedStore = (): FeaturedStore => {
    const store = useStore();
    return store.featuredStore;
};

export const useStoresStore = (): StoresStore => {
    const store = useStore();
    return store.storesStore;
};

export const useStoreGroupsStore = (): StoreGroupsStore => {
    const store = useStore();
    return store.storeGroupsStore;
};

export const useGalleryStore = (): GalleryStore => {
    const store = useStore();
    return store.galleryStore;
};

export const useDealBucketStore = (): DealBucketStore => {
    const store = useStore();
    return store.dealBucketStore;
};

export const useLoyaltyStore = (): LoyaltyStore => {
    const store = useStore();
    return store.loyaltyStore;
};

export const useServiceStore = (): ServiceStore => {
    const store = useStore();
    return store.serviceStore;
};

export const useConfigStore = (): ConfigStore => {
    const store = useStore();
    return store.configStore;
};

export const useAuthStore = (): AuthStore => {
    const store = useStore();
    return store.authStore;
};

export const useTierBenefitStore = (): TierBenefitStore => {
    const store = useStore();
    return store.tierBenefitStore;
};
