import { observable, action, computed, makeObservable } from "mobx";
import { fromResource, IResource } from "mobx-utils";
import { AuthState, UserClaims } from "@okta/okta-auth-js";
import { oktaAuth } from "../App";
import { Permission } from "shared-utils";

export type Role = "user" | "publisher" | "developer";

export class AuthStore {
    @observable viewsAs: Role | null = null;
    authState: IResource<AuthState | undefined | null> | null = null;

    constructor() {
        makeObservable(this);
        let authSubscription: (authState: AuthState) => void;
        this.authState = fromResource<AuthState | null>(
            (sink) => {
                sink(oktaAuth.authStateManager.getAuthState());
                authSubscription = (authState) => {
                    sink(authState);
                };
                oktaAuth.authStateManager.subscribe(authSubscription);
            },
            () => {
                oktaAuth.authStateManager.unsubscribe(authSubscription);
            }
        );
    }

    @action
    setViewAs(fakeRole: Role | null) {
        this.viewsAs = fakeRole;
    }

    @computed
    get claims() {
        return this.authState?.current()?.idToken?.claims ?? null;
    }

    @computed
    get realRole(): Role {
        const authState = this.authState?.current();

        const claims = authState?.accessToken?.claims as UserClaims<{
            groups: string[];
        }>;
        const groups = claims.groups;

        return Permission.getRole(groups);
    }

    @computed
    get role(): Role {
        if (this.viewsAs) {
            return this.viewsAs;
        } else {
            return this.realRole;
        }
    }
}
