import React from "react";
import { observer } from "mobx-react";

import style from "./style.module.scss";
import { useAgvtStore } from "..";
import { HomePageHeader } from "../home-page-header";
import { GlowingDiv } from "../../glowing-hoc";
import { PayByPlateCard, StandardCard, FeaturedCard } from "../../index";
import { DealBucketPreview } from "../../deal-bucket-preview";
import { StoreHeader } from "../slick-header";
import { MobileFeatured } from "@full-circle-types";

export const HomePage = observer(() => {
    const store = useAgvtStore();

    React.useEffect(() => {
        store.fetchHomeContent();
    }, [store]);

    const { glowConfiguration } = store;

    const handleFeaturedClick = (featured: MobileFeatured) => {
        switch (featured.type) {
            case "external_url":
                alert("The in-app browser in not available in the demo");
                break;
            case "deal":
                store.navigation.push({
                    section: "deal",
                    parentSection: "home",
                    data: {
                        dealId: featured.dealId,
                        bucketName: "featured",
                    },
                });
                break;
            case "inner_circle_clubs":
                store.navigation.push({
                    section: "inner-circle",
                    data: {
                        tab: "club",
                    },
                });
                break;
            case "inner_circle_offers":
                store.navigation.push({
                    section: "inner-circle",
                    data: {
                        tab: "offer",
                    },
                });
                break;
            case "inner_circle_rewards":
                store.navigation.push({
                    section: "inner-circle",
                    data: {
                        tab: "reward",
                    },
                });
                break;
        }
    };

    return (
        <>
            <HomePageHeader />
            <div className={style.home_content}>
                <StoreHeader name={store.store?.street ?? ""} />

                {store.homeContent?.services?.map((service) => {
                    return (
                        <PayByPlateCard
                            glowColor={glowConfiguration.services?.inApp}
                        />
                    );
                })}
                <div className={style.list_holder}>
                    <div
                        className={`${style.deal_list} ${style.featured_list}`}
                    >
                        {store.homeContent &&
                            store.homeContent.featured.map((f) => (
                                <FeaturedCard
                                    key={f.id}
                                    content={f}
                                    onClick={() => handleFeaturedClick(f)}
                                    glowingConfiguration={
                                        glowConfiguration.featured ||
                                        glowConfiguration.featuredCard
                                    }
                                />
                            ))}
                    </div>

                    <GlowingDiv className={style.list_glower}></GlowingDiv>
                </div>

                <div className={style.list_holder}>
                    <div className={style.list_header}>
                        <span>FEATURED DEALS</span>
                        <span
                            onClick={() =>
                                store.navigation.push({
                                    section: "deals",
                                })
                            }
                        >
                            View All →
                        </span>
                    </div>
                    <div className={style.deal_list}>
                        {store?.homeContent?.deals &&
                            store.homeContent.deals.map((d) => (
                                <StandardCard
                                    glowingConfiguration={
                                        glowConfiguration.dealCard
                                    }
                                    onClick={() =>
                                        store.navigation.push({
                                            section: "deal",
                                            parentSection: "home",
                                            data: {
                                                deal: d,
                                                bucketName: "home",
                                            },
                                        })
                                    }
                                    key={d.id}
                                    content={StandardCard.transform(d)}
                                    cursorType="pointer"
                                    isCouponSelected={
                                        !!store.couponManager.couponList.find(
                                            (c) => c.id === d.id
                                        )
                                    }
                                    onAddCoupon={() =>
                                        store.couponManager.addCouponToList(d)
                                    }
                                    onRemoveCoupon={() =>
                                        store.couponManager.removeCouponFromList(
                                            d
                                        )
                                    }
                                />
                            ))}
                    </div>

                    <GlowingDiv
                        glowColor={glowConfiguration.storeSpecials}
                        className={style.list_glower}
                    ></GlowingDiv>
                </div>

                <div className={style.list_holder}>
                    <div className={style.list_header}>
                        <span>DEALS</span>
                        <span
                            onClick={() =>
                                store.navigation.push({
                                    section: "deals",
                                })
                            }
                        >
                            View All →
                        </span>
                    </div>
                    <div className={style.deal_list}>
                        {store.homeContent &&
                            store.homeContent.dealBuckets.map((d) => (
                                <DealBucketPreview
                                    key={d.id}
                                    image={d.notFocusedIcon}
                                    name={d.name}
                                    glowConfiguration={
                                        glowConfiguration.dealBucket
                                    }
                                    onClick={() =>
                                        store.navigation.push({
                                            section: "deal-bucket",
                                            parentSection: "deals",
                                            data: d,
                                        })
                                    }
                                />
                            ))}
                    </div>
                    <GlowingDiv
                        glowColor={glowConfiguration.dealBuckets}
                        className={style.list_glower}
                    ></GlowingDiv>
                </div>
            </div>
        </>
    );
});
