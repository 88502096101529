import React from "react";
import { Table } from "antd";
import { ColumnProps } from "antd/es/table";

import style from "./style.module.scss";
import { useNavigation } from "../../utils/use-navigation";
import { Deal, LoyaltyCmsDto } from "@full-circle-types";
import {
    renderDates,
    renderDealIsActive,
    renderLoyaltyId,
    renderThumbnail,
    renderImage,
    renderDealType,
    renderLoyaltyType,
    renderTitle,
} from "./utils";

type AssDealsProps = {
    data: Deal[];
    loading: boolean;
};

export const AssociatedDeals = (props: AssDealsProps): JSX.Element => {
    const navigation = useNavigation();

    const onEdit = (record: Deal | LoyaltyCmsDto) => {
        navigation.goToPromotionEdit(record.id);
    };

    const dealColumns: ColumnProps<Deal>[] = [
        {
            title: "Is Enabled",
            key: "is_active",
            render: renderDealIsActive,
            width: "15%",
        },
        {
            title: "Image",
            key: "image",
            render: renderThumbnail,
            width: "15%",
        },
        {
            title: "Title",
            key: "title",
            render: renderTitle(onEdit),
            width: "30%",
        },
        {
            title: "Type",
            key: "type",
            render: renderDealType,
            width: "10%",
        },
        {
            title: "Dates",
            key: "dates",
            render: renderDates,
            width: "30%",
        },
    ];

    return (
        <>
            <br />
            <h2 className="h4-header">Associated Active Deals</h2>
            <Table<Deal>
                className={style.table}
                scroll={{ x: 900 }}
                rowKey="id"
                dataSource={props.data}
                columns={dealColumns}
                loading={props.loading}
                pagination={false}
                onRow={(r) => ({
                    onClick: () => navigation.goToPromotionEdit(r.id),
                })}
            />
        </>
    );
};

type AssLoyaltiesProps = {
    data: LoyaltyCmsDto[];
    loading: boolean;
};

export const AssociatedLoyalties = (props: AssLoyaltiesProps): JSX.Element => {
    const navigation = useNavigation();

    const onEdit = (record: Deal | LoyaltyCmsDto) => {
        navigation.goToPromotionEdit(record.id);
    };

    const loyaltyColumns: ColumnProps<LoyaltyCmsDto>[] = [
        {
            title: "Id",
            key: "id",
            render: renderLoyaltyId,
            width: "15%",
        },
        {
            title: "Image",
            key: "image",
            render: renderImage,
            width: "15%",
        },
        {
            title: "Title",
            key: "title",
            render: renderTitle(onEdit),
            width: "30%",
        },
        {
            title: "Type",
            key: "type",
            render: renderLoyaltyType,
            width: "10%",
        },
    ];

    return (
        <>
            <br />
            <h2 className="h4-header">Associated Active Loyalties</h2>
            <Table<LoyaltyCmsDto>
                className={style.table}
                scroll={{ x: 900 }}
                rowKey="id"
                dataSource={props.data}
                columns={loyaltyColumns}
                loading={props.loading}
                pagination={false}
                onRow={(r) => ({
                    onClick: () => navigation.goToLoyaltyEdit(r.id),
                })}
            />
        </>
    );
};
