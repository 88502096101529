import { observable, action, makeObservable, flow } from "mobx";
import _ from "lodash";
import { RootStore } from "./root";
import { NotificationManager } from "../components";
import {
    GetTierBenefitsQuery,
    TierBenefitCmsDto,
    TierBenefitPayload,
    TierBenefitGetAllResponse,
    TierBenefitType,
    TierBenefitGetFuelResponse,
} from "@full-circle-types";
import { FuelDiscountDetails } from "components/fuel-discount";

export class TierBenefitStore {
    @observable isUpsertingTierBenefit = false;
    @observable isFetchingList = false;
    @observable didMemberOrderChange = false;
    @observable didPremiumOrderChange = false;
    @observable memberBenefits: TierBenefitCmsDto[] = [];
    @observable initialMemberOrder: string[] = [];
    @observable premiumBenefits: TierBenefitCmsDto[] = [];
    @observable initialPremiumOrder: string[] = [];
    @observable latestFuelBenefit: {
        member: FuelDiscountDetails;
        premium: FuelDiscountDetails;
    } = {
        member: {
            description: "",
            unit: "gal",
        },
        premium: {
            description: "",
            unit: "gal",
        },
    };

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    getAll = flow(function* (
        this: TierBenefitStore,
        params: GetTierBenefitsQuery
    ) {
        if (this.isFetchingList) {
            return;
        }

        this.isFetchingList = true;
        const response = yield this.rootStore.makeNetworkCall<
            TierBenefitGetAllResponse
        >({
            method: "get",
            url: "/tier-benefits",
            params,
        });
        this.isFetchingList = false;

        if (!response.err) {
            this.memberBenefits = response.data.member.en;
            this.premiumBenefits = response.data.premium.en;

            this.initialMemberOrder = response.data.member.en.map(
                (benefit: TierBenefitCmsDto) => benefit.id
            );
            this.initialPremiumOrder = response.data.premium.en.map(
                (benefit: TierBenefitCmsDto) => benefit.id
            );
        }
    });

    getFuel = flow(function* (this: TierBenefitStore) {
        const response = yield this.rootStore.makeNetworkCall<
            TierBenefitGetFuelResponse
        >({
            method: "get",
            url: "/tier-benefits/fuel",
        });

        if (!response.err) {
            this.latestFuelBenefit = response.data;
        }

        return !response.err;
    });

    create = flow(function* (
        this: TierBenefitStore,
        tierBenefit: TierBenefitPayload
    ) {
        this.isUpsertingTierBenefit = true;

        const response = yield this.rootStore.makeNetworkCall({
            method: "post",
            data: tierBenefit,
            url: `/tier-benefits`,
        });

        this.isUpsertingTierBenefit = false;

        if (!response.err) {
            NotificationManager.showSuccess("Tier Benefit created");
        }

        return !response.err;
    });

    update = flow(function* (
        this: TierBenefitStore,
        id: number,
        tierBenefit: TierBenefitPayload
    ) {
        this.isUpsertingTierBenefit = true;

        const response = yield this.rootStore.makeNetworkCall({
            method: "put",
            data: tierBenefit,
            url: `/tier-benefits/${id}`,
        });

        this.isUpsertingTierBenefit = false;

        if (!response.err) {
            NotificationManager.showSuccess("Tier Benefit modified");
        }
    });

    delete = flow(function* (this: TierBenefitStore, id: number) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/tier-benefits/${id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Tier Benefit deleted");
        }

        return response;
    });

    order = flow(function* (
        this: TierBenefitStore,
        ids: number[],
        type: TierBenefitType
    ) {
        this.isUpsertingTierBenefit = true;
        const response = yield this.rootStore.makeNetworkCall({
            method: "put",
            data: ids,
            url: "/tier-benefits/order",
        });

        this.isUpsertingTierBenefit = false;
        if (!response.err) {
            NotificationManager.showSuccess("Order updated");
            this.didMemberOrderChange = false;
            this.didPremiumOrderChange = false;
            type === "member"
                ? (this.initialMemberOrder = ids.map((id) => id.toString()))
                : (this.initialPremiumOrder = ids.map((id) => id.toString()));
        }
    });

    @action
    setTierOrder(list: TierBenefitCmsDto[], type: TierBenefitType) {
        type === "member"
            ? (this.memberBenefits = list)
            : (this.premiumBenefits = list);
    }

    @action
    isMemberOrderSameSame(list: TierBenefitCmsDto[]) {
        return (
            list.length === this.initialMemberOrder.length &&
            list.every(
                (value: TierBenefitCmsDto, index: number) =>
                    value.id === this.initialMemberOrder[index]
            )
        );
    }
    @action
    isPremiumOrderSameSame(list: TierBenefitCmsDto[]) {
        return (
            list.length === this.initialPremiumOrder.length &&
            list.every(
                (value: TierBenefitCmsDto, index: number) =>
                    value.id === this.initialPremiumOrder[index]
            )
        );
    }
}
