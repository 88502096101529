import React from "react";
import style from "./style.module.scss";

interface Props {
    src?: string | null;
    loading?: "lazy" | "eager";
    alt?: string;
    size?: number;
    className?: string;
    showOverlay?: boolean;
    grayscale?: boolean;
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
    children?: React.ReactNode;
    rectangle?: boolean;
}

export const ImageHolder = React.forwardRef<HTMLDivElement, Props>(
    (props, ref) => {
        const size = props.size ?? 140;
        const onClick = props.onClick ?? (() => void 0);
        return (
            <div
                ref={ref}
                className={`${style.image_holder} ${props.className ?? ""}`}
                style={{
                    width: size * (props.rectangle ? 1.8 : 1),
                    height: size,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <img
                    className={`${style.image} ${
                        props.grayscale ? style.expired : ""
                    }`}
                    src={props.src ?? undefined}
                    loading={props.loading}
                    alt={props.alt ?? ""}
                    onClick={onClick}
                />
                {props.showOverlay && props.children}
            </div>
        );
    }
);
