import React from "react";
import { observer } from "mobx-react";
import Editor, { DiffEditor } from "@monaco-editor/react";
import { ToggleSwitch, Button } from "../../easyui-components";
import style from "./style.module.scss";
import { useAuthStore, useConfigStore } from "../../contexts/mobx";
import { useNavigation } from "../../utils/use-navigation";
import { CkLogo, TopHeaderPortal } from "../../components";

const checkIfJsonValid = (json: string) => {
    try {
        JSON.parse(json);
        return true;
    } catch {
        return false;
    }
};

const checkIfJsonDifferent = (json1: string, json2: string) => {
    return (
        JSON.stringify(JSON.parse(json1)) !== JSON.stringify(JSON.parse(json2))
    );
};

export const ConfigsPage = observer(() => {
    const navigation = useNavigation();
    const authStore = useAuthStore();
    const role = authStore.role;

    if (role !== "developer") {
        navigation.goToHome();
    }

    const [value, setValue] = React.useState("");
    const [viewDiff, setViewDiff] = React.useState(false);

    const configStore = useConfigStore();
    const isValid = checkIfJsonValid(value);
    const isDifferent = isValid
        ? checkIfJsonDifferent(value, configStore.config)
        : null;

    React.useEffect(() => {
        configStore.fetch();
    }, [configStore]);

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton>Configs</CkLogo>
                </div>
            </TopHeaderPortal>
            <div className={style.container}>
                <div className={style.top_content}>
                    <div>
                        <span>View Diff</span>
                        <ToggleSwitch
                            checked={viewDiff}
                            onChange={(v) => setViewDiff(v.target.checked)}
                        />
                    </div>
                    <Button
                        type="primary"
                        disabled={!isValid || !isDifferent}
                        onClick={() => configStore.create({ config: value })}
                    >
                        Update
                    </Button>
                </div>
                <div className={style.editor_holder}>
                    {!viewDiff && (
                        <Editor
                            defaultLanguage="json"
                            height="30vh"
                            value={configStore.config ?? value}
                            options={{
                                minimap: { enabled: false },
                                scrollBeyondLastLine: false,
                                overviewRulerBorder: false,
                            }}
                            onChange={(editorState) => {
                                if (editorState) {
                                    setValue(editorState);
                                }
                            }}
                        />
                    )}
                    {viewDiff && (
                        <DiffEditor
                            originalLanguage="json"
                            modifiedLanguage="json"
                            height="30vh"
                            original={configStore.config ?? value}
                            modified={value}
                            options={{
                                minimap: { enabled: false },
                                scrollBeyondLastLine: false,
                                overviewRulerBorder: false,
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
});
