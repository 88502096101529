import React from "react";
import style from "./style.module.scss";
import {
    Editor,
    EditorState,
    RichUtils,
    SelectionState,
    DraftEditorCommand,
} from "draft-js";
import { Button, Modal, Select } from "antd";
import {
    BoldOutlined,
    UnderlineOutlined,
    ItalicOutlined,
    LinkOutlined,
} from "@ant-design/icons";
import { useListener } from "./helper";
import { Input, Select as EasySelect } from "../../easyui-components";

interface Props {
    editorState: EditorState;
    onEditorStateChange: (state: EditorState) => void;
}

const FONT_SIZE_PREFIX = "FONTSIZE";
const FONT_SIZES = [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 38, 46, 54, 62, 72];

interface FontStyle {
    fontSize: string;
    lineHeight: string;
}

const getInlineStyleMap = () => {
    const map: { [key: string]: FontStyle } = {};
    for (const fontSize of FONT_SIZES) {
        map[`${FONT_SIZE_PREFIX}${fontSize}`] = {
            fontSize: `${fontSize}px`,
            lineHeight: `${fontSize + 4}px`,
        };
    }
    return map;
};

const FONT_INLINE_STYLES_MAP = getInlineStyleMap();

type CallbackArgs = {
    startKey: string;
    endKey: string;
    startOffset: number;
    endOffset: number;
};
const useOnSelectionChange = (
    editorState: EditorState,
    fn: (args: CallbackArgs) => void
) => {
    React.useEffect(() => {
        const startKey = editorState.getSelection().getStartKey();
        const endKey = editorState.getSelection().getEndKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const endOffset = editorState.getSelection().getEndOffset();

        if (startKey !== endKey || startOffset !== endOffset) {
            fn({ startKey, endKey, startOffset, endOffset });
        }
    }, [editorState, fn]);
};

export const RichTextEditor = ({
    editorState,
    onEditorStateChange,
}: Props): JSX.Element => {
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [url, setUrl] = React.useState("");
    const [protocol, setProtocol] = React.useState("https://");
    const [isLinkDisable, setLinkDisable] = React.useState(false);

    useOnSelectionChange(
        editorState,
        ({ startKey, endKey, startOffset, endOffset }) => {
            // Disallow multi blocks selection
            if (startKey !== endKey) {
                setLinkDisable(true);
                return;
            }
            const block = editorState
                .getCurrentContent()
                .getBlockForKey(startKey);
            for (let i = startOffset; i <= endOffset; i++) {
                const entity = block.getEntityAt(i);
                if (entity) {
                    setLinkDisable(true);
                    return;
                }
            }

            setLinkDisable(false);
        }
    );

    const handleKeyCommand = (
        command: DraftEditorCommand,
        editorState: EditorState
    ) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorStateChange(newState);
            return "handled";
        }
        return "not-handled";
    };

    useListener((key) => {
        const blocks = editorState.getCurrentContent().getBlocksAsArray();
        for (const block of blocks) {
            block.findEntityRanges(
                (char) => {
                    return char.getEntity() === key;
                },
                (start, end) => {
                    const newSelection = SelectionState.createEmpty(
                        block.getKey()
                    );
                    const updatedSelection = newSelection.merge({
                        anchorOffset: start,
                        focusOffset: end,
                    });

                    onEditorStateChange(
                        RichUtils.toggleLink(
                            editorState,
                            updatedSelection,
                            null
                        )
                    );
                }
            );
        }
    });

    const handleInlineStyleClick = (style: string) =>
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, style));

    const getBlockTypeStyle = (block: { getType: () => string }) => {
        switch (block.getType()) {
            case "code-block":
                return "code-block";
            case "blockquote":
                return "blockquote";
            case "align-left":
                return "align-left";
            case "align-center":
                return "align-center";
            case "align-right":
                return "align-right";
            default:
                return "";
        }
    };

    const addLink = () => {
        setModalVisible(false);
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "LINK",
            "MUTABLE",
            { url: `${protocol}${url}` }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });

        onEditorStateChange(
            RichUtils.toggleLink(
                newEditorState,
                newEditorState.getSelection(),
                entityKey
            )
        );
    };

    const showLinkModal = () => {
        setProtocol("https://");
        setUrl("");
        setModalVisible(true);
    };

    return (
        <div className={style.rich_text_editor}>
            <Modal
                title="Set url"
                visible={isModalVisible}
                onOk={addLink}
                onCancel={() => setModalVisible(false)}
            >
                <EasySelect value={protocol} onChange={(v) => setProtocol(v)}>
                    <Select.Option value="http://">http://</Select.Option>
                    <Select.Option value="https://">https://</Select.Option>
                </EasySelect>
                <Input value={url} onChange={(e) => setUrl(e.target.value)} />
            </Modal>
            <div className={style.toolbar}>
                <div onClick={() => handleInlineStyleClick("BOLD")}>
                    <Button icon={<BoldOutlined />}></Button>
                </div>

                <div onClick={() => handleInlineStyleClick("ITALIC")}>
                    <Button icon={<ItalicOutlined />}></Button>
                </div>

                <div onClick={() => handleInlineStyleClick("UNDERLINE")}>
                    <Button icon={<UnderlineOutlined />}></Button>
                </div>
                <div>
                    <Button
                        onClick={() => showLinkModal()}
                        disabled={isLinkDisable}
                        icon={<LinkOutlined />}
                    ></Button>
                </div>
            </div>
            <Editor
                editorState={editorState}
                onChange={onEditorStateChange}
                handleKeyCommand={handleKeyCommand}
                customStyleMap={FONT_INLINE_STYLES_MAP}
                blockStyleFn={getBlockTypeStyle}
            />
        </div>
    );
};
