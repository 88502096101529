import React from "react";
import style from "./style.module.scss";
import { ReactComponent as BadgeIcon } from "../../../assets/badge-icon.svg";
import { ReactComponent as CodeBarIcon } from "../../../assets/code-bar-icon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/location-icon.svg";
import CkLogoRed from "../../../assets/ck-logo-red.png";

export const SlickHeader = (props: { name: string }): JSX.Element => {
    return (
        <div>
            <InnerCircleHeader>
                <img src={CkLogoRed} alt="circle k logo" />
                <span className={style.inner_circle_header}>INNER CIRCLE</span>
            </InnerCircleHeader>
            <StoreHeader name={props.name} />
        </div>
    );
};

export const StoreHeader = (props: { name: string }): JSX.Element => {
    return (
        <div className={style.orange_row}>
            <LocationIcon />
            <span className={style.store_name}>{props.name}</span>
            <span className={style.change_cta}>Change</span>
        </div>
    );
};

export const InnerCircleHeader = (props: {
    children: React.ReactNode;
    sticky?: boolean;
    bordered?: boolean;
}): JSX.Element => {
    const classes = [style.white_row];

    if (props.sticky) {
        classes.push(style.sticky);
    }

    if (props.bordered) {
        classes.push(style.bordered);
    }

    return (
        <div className={classes.join(" ")}>
            <div className={style.left_content}>{props.children}</div>
            <BadgeIcon />
            <CodeBarIcon />
        </div>
    );
};
