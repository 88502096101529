import React from "react";
import style from "./style.module.scss";

interface Props<T extends string> {
    items: { value: T; display: string }[];
    value: T;
    onChange: (value: T) => void;
}

export function ShadowSwitch<T extends string>(props: Props<T>): JSX.Element {
    return (
        <span className={style.shadow_switch}>
            {props.items.map((item) => (
                <span
                    className={
                        item.value === props.value ? style.selected : undefined
                    }
                    onClick={() => props.onChange(item.value)}
                >
                    {item.display}
                </span>
            ))}
        </span>
    );
}
