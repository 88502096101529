import moment from "moment";
import { FeaturedType } from "@full-circle-types";

export type PromotionTypes = "in_store" | "coupon" | "contest";
export const PromotionTypes = ["in_store", "coupon", "contest"] as const;
export const PromotionTypesRegEx = /in_store|coupon|contest/;

export const PER_PAGE = 10 as const;

export const START_DATE = moment()
    .subtract(3, "months")
    .toISOString() as string;
export const END_DATE = moment().toISOString() as string;

export const FeaturedTypes = ["deal", "external_url", "modal"] as const;

export type FeedbackTypes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const FeedbackTypes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

export const FeaturedTypesRegEx = /deal|external_url|modal/;

export const FeatureTypeColors: { [key in FeaturedType]: string } = {
    deal: "magenta",
    external_url: "red",
    inner_circle_offers: "blue",
    inner_circle_rewards: "green",
    inner_circle_clubs: "goldenrod",
    modal: "yellow",
};

export const FeedbackTypeColors: { [key in FeedbackTypes]: string } = {
    0: "red",
    1: "red",
    2: "red",
    3: "red",
    4: "red",
    5: "red",
    6: "red",
    7: "orange",
    8: "orange",
    9: "green",
    10: "green",
};

export type ActiveQueryParam = "active" | "inactive";
export const ActiveQueryParam = ["active", "inactive"] as const;

export type LoyaltyType = "club" | "reward" | "offer";
export const LoyaltyType = ["club", "reward", "offer"] as const;

export const GroupLimitations = {
    "o.users.employee": "Employees",
    "o.app.sip-and-save": "Sip & Save Users",
} as const;
