import { Role } from "../stores/auth";

export const Permission = {
    canEdit(options: { role: Role; enabled: boolean }) {
        if (options.role === "user") {
            return !options.enabled;
        } else {
            return true;
        }
    },

    canEnable(options: { role: Role }) {
        return options.role !== "user";
    },
};
