import { observable, action, toJS, makeObservable, flow } from "mobx";

import { RootStore, MakeNetworkCall } from "./root";
import { VisibleParams } from "../types";
import { NotificationManager } from "../components";
import { WithNetworkConcurrency } from "./with-network-concurrency";
import { DealForm } from "../models";
import {
    Deal,
    DealList,
    DealSearchParams,
    VisibleDealsParams,
} from "@full-circle-types";

export class PromotionStore extends WithNetworkConcurrency {
    @observable promotions: DealList | null = null;
    @observable storeSpecialDeals: Deal[] = [];
    @observable isFetchingCount = 0;
    @observable isFetchingVisiblePromotions = false;
    @observable isUpsertingPromotion = false;
    @observable isReissuingCoupon = false;
    @observable isUpdatingStoreSpecialsOrder = false;
    @observable private cachedPromotion: Deal | null = null;
    @observable isFetchingForSelect = false;
    @observable promotionsForSelect: Deal[] = [];
    @observable isDeletingAndRefreshing = false;
    @observable isFetchingForCategory = false;
    @observable isFetchingForBucket = false;
    @observable promotionsForCategory: Deal[] = [];
    @observable promotionsForBucket: Deal[] = [];

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    private setPromotions(this: PromotionStore, promotions: DealList) {
        this.promotions = promotions;
    }

    @action
    public setStoreSpecials(this: PromotionStore, promotions: Deal[]): void {
        this.storeSpecialDeals = [...promotions];
    }

    @action
    setCachedPromotion(this: PromotionStore, promotion: Deal): void {
        this.cachedPromotion = promotion;
    }

    fetchPromotions = flow(function* (
        this: PromotionStore,
        searchParams: DealSearchParams
    ) {
        const tag = this.getTag();
        this.isFetchingCount++;
        const response = yield this.rootStore.makeNetworkCall<DealList>({
            method: "get",
            url: "/promotions",
            params: searchParams,
        });
        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.setPromotions(response.data);
        }
    });

    fetchForSelect = flow(function* (
        this: PromotionStore,
        params?: VisibleDealsParams
    ) {
        this.isFetchingForSelect = true;

        const response = yield this.rootStore.makeNetworkCall<Deal[]>({
            method: "get",
            url: "/promotions/visible",
            params,
        });
        this.isFetchingForSelect = false;

        if (!response.err) {
            this.promotionsForSelect = response.data;
            return response.data;
        }
    });

    fetchForCategory = flow(function* (
        this: PromotionStore,
        categoryId: number
    ) {
        this.isFetchingForCategory = true;
        const response = yield this.rootStore.makeNetworkCall<DealList>({
            method: "get",
            url: "/promotions",
            params: { all: true, categoryId, filter: "active" },
        });
        this.isFetchingForCategory = false;

        if (!response.err) {
            this.promotionsForCategory = response.data.results;
        }
    });

    fetchForBucket = flow(function* (this: PromotionStore, bucketId: number) {
        this.isFetchingForBucket = true;
        const response = yield this.rootStore.makeNetworkCall<DealList>({
            method: "get",
            url: "/promotions",
            params: { all: true, bucketId, filter: "active" },
        });
        this.isFetchingForBucket = false;

        if (!response.err) {
            this.promotionsForBucket = response.data.results;
        }
    });

    fetchVisiblePromotions = flow(function* (
        this: PromotionStore,
        params?: VisibleParams
    ) {
        if (this.isFetchingVisiblePromotions) {
            return;
        }

        this.isFetchingVisiblePromotions = true;
        const response = yield this.rootStore.makeNetworkCall<Deal[]>({
            method: "get",
            url: "/promotions/visible",
            params,
        });
        this.isFetchingVisiblePromotions = false;

        if (!response.err) {
            this.setStoreSpecials(response.data);
        }
    });

    fetchSinglePromotion = flow(function* (
        this: PromotionStore,
        promotionId: number
    ): MakeNetworkCall<Deal, Deal> {
        if (promotionId === this.cachedPromotion?.id) {
            return toJS(this.cachedPromotion);
        }

        const response = yield this.rootStore.makeNetworkCall({
            method: "get",
            url: `/promotions/${promotionId}`,
        });

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    });

    createPromotion = flow(function* (
        this: PromotionStore,
        promotion: DealForm
    ) {
        this.isUpsertingPromotion = true;
        const response = yield this.rootStore.makeNetworkCall({
            method: "post",
            data: promotion,
            url: `/promotions`,
        });
        this.isUpsertingPromotion = false;

        if (!response.err) {
            NotificationManager.showSuccess("Promotion created");
        }

        return !response.err;
    });

    modifyPromotion = flow(function* (
        this: PromotionStore,
        promotionId: number,
        promotion: DealForm
    ) {
        this.isUpsertingPromotion = true;
        const response = yield this.rootStore.makeNetworkCall({
            method: "put",
            data: promotion,
            url: `/promotions/${promotionId}`,
        });
        this.isUpsertingPromotion = false;

        if (!response.err) {
            NotificationManager.showSuccess("Deal modified");
        }

        return !response.err;
    });

    updateStoreSpecialsOrder = flow(function* (this: PromotionStore) {
        if (this.isUpdatingStoreSpecialsOrder) {
            return;
        }

        const data = {
            promotionIds: this.storeSpecialDeals.map((p) => p.id),
        };

        this.isUpdatingStoreSpecialsOrder = true;
        const response = yield this.rootStore.makeNetworkCall({
            method: "patch",
            url: `/promotions/sort`,
            data,
        });
        this.isUpdatingStoreSpecialsOrder = false;

        if (!response.err) {
            NotificationManager.showSuccess("Coupons order updated");
        }
    });

    delete = flow(function* (this: PromotionStore, promotionId: number) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/promotions/${promotionId}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Promotion deleted");
        }

        return response;
    });

    deleteAndRefreshList = flow(function* (
        this: PromotionStore,
        promotionId: number,
        params: DealSearchParams
    ) {
        if (this.isDeletingAndRefreshing) {
            return;
        }

        this.isDeletingAndRefreshing = true;
        const response = yield this.delete(promotionId);
        if (!response.err) {
            yield this.fetchPromotions(params);
        }
        this.isDeletingAndRefreshing = false;
    });

    reissueCoupon = flow(function* (
        this: PromotionStore,
        oktaId: string,
        promotionId: number
    ) {
        this.isReissuingCoupon = true;
        const response = yield this.rootStore.makeNetworkCall({
            method: "post",
            url: `/promotions/reissue/${promotionId}`,
            data: { oktaId },
        });
        this.isReissuingCoupon = false;

        if (!response.err) {
            NotificationManager.showSuccess("Coupon Reissued");
        }

        return !response.err;
    });
}
