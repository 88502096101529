import React from "react";
import { Pagination as AntdPagination } from "antd";
import { PER_PAGE } from "../../utils/const";

interface Props {
    className?: string;
    params: {
        page?: number;
        perPage?: number;
    };
    meta: {
        perPage?: number | undefined;
        total?: number | undefined;
    } | null;
    onChange: (pageNumber: number, pageSize: number) => void;
    onPageSizeChange: (pageSize: number) => void;
}

export const Pagination = (props: Props): JSX.Element => {
    const { className, params, meta, onChange, onPageSizeChange } = props;
    return (
        <AntdPagination
            className={className}
            current={params.page ? params.page + 1 : 1}
            pageSize={params.perPage ?? meta?.perPage ?? PER_PAGE}
            total={meta?.total}
            onChange={(pageNumber, pageSize) => {
                if (pageNumber - 1 !== params.page) {
                    onChange(pageNumber - 1, pageSize ?? meta?.perPage ?? 10);
                }
            }}
            defaultPageSize={PER_PAGE}
            onShowSizeChange={(_, size) => onPageSizeChange(size)}
        />
    );
};
