import React from "react";
import { observer } from "mobx-react";
import style from "./style.module.scss";
import { useAgvtStore } from "..";
import { StandardCard } from "../../standard-card";
import { Button } from "../../../easyui-components";

export const BottomDrawer = observer(() => {
    const store = useAgvtStore();
    const classes = [style.bottom_drawer];

    if (store.couponManager.isBottomDrawerVisible) {
        classes.push(style.visible);
    }

    if (store.couponManager.bottomDrawerContent === "coupon-activation") {
        classes.push(style.coupon_activation);
    } else if (store.couponManager.bottomDrawerContent === "coupons-list") {
        classes.push(style.coupon_list);
    }

    return (
        <div className={classes.join(" ")}>
            {store.couponManager.bottomDrawerContent ===
                "coupon-activation" && (
                <>
                    <p
                        className={style.close_text}
                        onClick={() => store.couponManager.hideBottomDrawer()}
                    >
                        Close
                    </p>
                    <p className={style.title}>
                        Would you like to use this coupon?
                    </p>
                    <p className={style.sub_title}>
                        You only have 10 minutes to use it.
                    </p>
                    <Button
                        type="primary"
                        onClick={() => {
                            store.couponManager.activateCoupon();
                            store.couponManager.hideBottomDrawer();
                        }}
                    >
                        CONFIRM
                    </Button>
                    <Button
                        onClick={() => store.couponManager.hideBottomDrawer()}
                        type="secondary"
                    >
                        NO, MAYBE LATER
                    </Button>
                </>
            )}
            {store.couponManager.bottomDrawerContent === "coupons-list" && (
                <>
                    <p
                        className={style.close_text}
                        onClick={() => store.couponManager.hideBottomDrawer()}
                    >
                        Close
                    </p>
                    <p className={style.title}>My Coupons</p>
                    <div className={style.coupon_list}>
                        {store.couponManager.couponList.map((d) => (
                            <StandardCard
                                glowingConfiguration={
                                    store.glowConfiguration.dealCard
                                }
                                onClick={() => {
                                    store.navigation.push({
                                        section: "deal",
                                        parentSection: "deals",
                                        data: {
                                            deal: d,
                                            bucketName: "Explore Deals",
                                        },
                                    });
                                    store.couponManager.hideBottomDrawer();
                                }}
                                cursorType="pointer"
                                key={d.id}
                                content={StandardCard.transform(d)}
                                fluid={true}
                                isCouponSelected={
                                    !!store.couponManager.couponList.find(
                                        (c) => c.id === d.id
                                    )
                                }
                                onAddCoupon={() =>
                                    store.couponManager.addCouponToList(d)
                                }
                                onRemoveCoupon={() =>
                                    store.couponManager.removeCouponFromList(d)
                                }
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
});

export const Overlay = observer(() => {
    const store = useAgvtStore();
    const classes = [style.overlay];

    if (store.couponManager.isBottomDrawerVisible) {
        classes.push(style.visible);
    }

    return (
        <div
            className={classes.join(" ")}
            onClick={() => store.couponManager.hideBottomDrawer()}
        ></div>
    );
});
