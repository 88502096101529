import { action, computed, observable, flow, makeAutoObservable } from "mobx";
import {
    StorePreview,
    MobileDeal,
    CategoryPreviewDto,
    DealBucketPreview,
} from "@full-circle-types";
import {
    AgvtState,
    NoInnerCircleNavigation,
    CoreCouponManager,
    NavigationSection,
} from "../../components/advance-gmap-visualization-tool";
import { DealForm } from "models";
import { HomePage } from "./dummy-content";

type AugmentedDeal = MobileDeal & {
    isVisibleOnHomePage: boolean;
    categoryId?: number;
    bucketId?: number;
    position: number;
};

const formToMobileDeal = (form: DealForm) => {
    const isContest = form.type === "contest";
    const isCoupon = form.type === "coupon";
    return {
        id: -1,
        uid: -1,
        position: form.position,
        isVisibleOnHomePage: form.isVisibleOnHomePage,
        groupLimitation: form.groupLimitation,
        categoryId: form.categoryId,
        bucketId: form.bucketId,
        title: form.translations.en.title,
        subTitle: form.translations.en.subTitle ?? "",
        image: form.translations.en.thumbnail,
        thumbnail: form.translations.en.smallThumbnail ?? "",
        startDate: form.startDate,
        endDate: form.endDate,
        category: "category",
        ageRestriction: form.ageRestriction,
        ageRestrictionType: form.ageRestrictionType,
        requiresIdentityVerification: false,
        description: form.translations.en.description ?? "",
        termsAndConditions: form.translations.en.description ?? "",
        associatedOffer: null,
        type: form.type,
        externalUrl: isContest && form.translations.en.externalUrl,
        cta: isContest && form.translations.en.cta,
        activationCode: isCoupon && form.activationCode,
    } as AugmentedDeal;
};

/**
 * This is a hacky helper to fake the positioning of a deal in real time. Since
 * MobileDeal don't have a <code>position</code> property, we are going to use
 * their <code>id</code> instead.
 *
 * @param deal1
 * @param deal2
 * @returns
 */
const dealSorter = (
    deal1: MobileDeal | AugmentedDeal,
    deal2: MobileDeal | AugmentedDeal
) => {
    const field1 = "position" in deal1 ? deal1.position : deal1.id;
    const field2 = "position" in deal2 ? deal2.position : deal2.id;
    return field1 < field2 ? -1 : 1;
};

export type DisplayMode = "deal-only" | "fake-content";

export class AgvtDealStore implements AgvtState {
    @observable
    store: StorePreview = {
        id: 2709355,
        simId: 7900382,
        city: "ST-JEAN-SUR-RICHELIEU",
        street: "165 Boul Omer Marcil",
        postalCode: "J2W 0A3",
        state: "QC",
        country: "CA",
    };

    glowConfiguration = {};
    loyalties = undefined;

    @observable
    trackedDeal: AugmentedDeal | null = null;

    @observable.shallow
    availableCategories: CategoryPreviewDto[] = [];

    @observable.shallow
    availableDealBuckets: DealBucketPreview[] = [];

    @observable
    displayMode: DisplayMode = "deal-only";

    constructor(
        public couponManager = new CoreCouponManager(),
        public navigation = new NoInnerCircleNavigation()
    ) {
        makeAutoObservable(this);
    }

    @action
    setStore(store: StorePreview): void {
        this.store = store;
    }

    @action
    syncWithForm(form: DealForm): void {
        this.trackedDeal = formToMobileDeal(form);

        if (this.navigation.currentPage.section === "deal") {
            this.navigation.currentPage.data.deal = this.trackedDeal;
        }
    }

    @action
    syncWithCategories(categories: CategoryPreviewDto[]): void {
        this.availableCategories = categories;
    }

    @action
    syncWithDealBuckets(dealBuckets: DealBucketPreview[]): void {
        this.availableDealBuckets = dealBuckets;
    }

    @action
    setDisplayMode(mode: DisplayMode): void {
        this.displayMode = mode;
    }

    @action
    handleExternalNavigation(section: NavigationSection): void {
        if (section === "home") {
            this.navigation.push({
                section,
            });
        } else if (section === "deal") {
            this.navigation.push({
                section,
                data: {
                    deal: this.displayedDeal,
                    bucketName: "Details",
                },
            });
        } else if (section === "deal-bucket" && !!this.displayedBucket) {
            this.navigation.push({
                section,
                data: this.displayedBucket,
            });
        }
    }

    fetchHomeContent = flow(function* () {
        // do nothing
    });
    fetchDealBuckets = flow(function* () {
        // do nothing
    });
    fetchDeals = flow(function* () {
        // do nothing
    });
    fetchDeal = flow(function* () {
        // do nothing
    });

    @computed
    get deals(): AugmentedDeal[] {
        return this.displayedDeal ? [this.displayedDeal] : [];
    }

    @computed
    get dealBuckets(): {
        deals: AugmentedDeal[];
        id: number;
        name: string;
        focusedIcon: string;
        notFocusedIcon: string;
    }[] {
        return this.displayedBucket && this.displayedDeal
            ? [{ ...this.displayedBucket, deals: [this.displayedDeal] }]
            : [];
    }

    @computed
    get displayedDeal(): AugmentedDeal | null {
        const category = this.availableCategories.find(
            (c) => c.id === this.trackedDeal?.categoryId
        );

        if (this.trackedDeal) {
            this.trackedDeal.category = category?.title ?? "category";
        }

        return this.trackedDeal;
    }

    @computed
    get displayedBucket():
        | 0
        | {
              id: number;
              name: string;
              focusedIcon: string;
              notFocusedIcon: string;
          }
        | undefined {
        return (
            this.displayedDeal?.bucketId &&
            this.availableDealBuckets.find(
                (db) => db.id === this.displayedDeal?.bucketId
            )
        );
    }

    @computed
    get homeContent() {
        const useFakeContent = this.displayMode === "fake-content";
        const fakeDeals = useFakeContent ? HomePage.deals ?? [] : [];
        const fakeBuckets = useFakeContent ? HomePage.dealBuckets : [];

        const deals = this.displayedDeal?.isVisibleOnHomePage
            ? [this.displayedDeal, ...fakeDeals]
            : fakeDeals;

        return {
            services: useFakeContent ? HomePage.services : [],
            featured: useFakeContent ? HomePage.featured : [],
            deals: deals.sort(dealSorter),
            dealBuckets: this.displayedBucket
                ? [this.displayedBucket, ...fakeBuckets]
                : fakeBuckets,
        };
    }

    @computed
    get isCouponListVisible(): boolean {
        return (
            this.couponManager.couponList.length > 0 &&
            (this.navigation.currentPage.section === "deal-bucket" ||
                this.navigation.currentPage.section === "deals")
        );
    }
}
