import React, { ChangeEvent } from "react";
import qs from "qs";
import { Spin, Modal } from "antd";
import {
    PlusOutlined,
    EyeOutlined,
    EditOutlined,
    CheckOutlined,
    DeleteOutlined,
} from "@ant-design/icons";

import style from "./style.module.scss";
import { useStore, useGalleryStore } from "../../contexts/mobx";
import { NotificationManager } from "..";
import { Gallery, GalleryLabel } from "@full-circle-types";
import { getImageRatio } from "../../utils/image-ratio";

interface Props {
    url: string | null | undefined;
    className?: string;
    allowDeletion?: boolean;
    onChange: (url: string | null) => void;
    onDelete?: () => void;
    dataType?: "deals" | "featured" | "none" | "icon" | "loyalty" | "service";
    size?: number;
}

export const GalleryPicker = (props: Props): JSX.Element => {
    const rootStore = useStore();
    const galleryStore = useGalleryStore();
    const [isUploading, setIsUploading] = React.useState(false);
    const [isPreviewVisible, setPreviewVisible] = React.useState(false);
    const [isGalleryVisible, setGalleryVisible] = React.useState(false);
    const [preview, setPreview] = React.useState<string | null>(null);
    const [gallery, setGallery] = React.useState<Gallery[]>([]);
    const hiddenInputRef = React.useRef<HTMLInputElement | null>(null);

    const isRectangleType =
        props.dataType === "featured" || props.dataType === "service";

    const specialStyle = {
        icon: { width: props.dataType !== "icon" ? "100%" : "" },
        featured: {
            height: isRectangleType ? "59%" : "100%",
        },
    };

    const onImageSelected = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            // User cancelled the operation
            return;
        }

        const ratio = await getImageRatio(file);

        if (file.size > 200000) {
            // User selects an Image with a size > 200kb
            NotificationManager.showError("Image size should not exceed 200kb");
            return;
        }

        if (isRectangleType && !(ratio >= 1.68 && ratio <= 1.69)) {
            NotificationManager.showError(
                "The image must respect a 1.686 ratio ( 312px x 185px , 624px x 370px, etc )",
                15
            );
            return;
        }

        setIsUploading(true);
        const newGallery = await galleryStore.uploadImage(
            file,
            file.name,
            props.dataType as GalleryLabel,
            "en",
            ratio.toString()
        );
        setIsUploading(false);

        if (newGallery) {
            props.onChange(newGallery.cdnUrl);
            setGalleryVisible(false);
        }
    };

    const openGallery = async () => {
        setGalleryVisible(true);
        const response = await rootStore.makeNetworkCall<Gallery[]>({
            url: "/gallery/gallery",
            method: "GET",
            params: {
                type: "image",
                label: props.dataType || "deals",
                languages: ["en", "fr"],
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
        if (!response.err) {
            setGallery(response.data);
        }
    };

    const cells = Array(Math.max(gallery.length + 1, 10)).fill(0);

    return (
        <div
            className={`${style.container} ${props.className ?? ""}`}
            style={{
                width: props.size ?? 200,
                height: isRectangleType
                    ? (props.size ?? 200) * 0.59
                    : props.size ?? 200,
            }}
        >
            {props.url ? (
                <div className={style.image_holder}>
                    <div className={style.overlay}>
                        <EyeOutlined
                            className={style.eye_svg}
                            onClick={() => setPreviewVisible(true)}
                        />
                        <EditOutlined
                            className={style.edit_svg}
                            onClick={() => openGallery()}
                        />
                        {props.allowDeletion && (
                            <DeleteOutlined
                                className={style.delete_svg}
                                onClick={() => props.onChange(null)}
                            />
                        )}
                    </div>
                    <img
                        src={props.url ?? ""}
                        alt="thumbnail"
                        style={specialStyle.icon}
                    />
                </div>
            ) : (
                <div
                    className={style.empty_interface}
                    style={specialStyle.featured}
                    onClick={() => openGallery()}
                >
                    {isUploading ? <Spin /> : <PlusOutlined />}
                </div>
            )}
            <input
                type="file"
                name="image"
                accept={`image/jpeg, image/png, ${
                    props.dataType === "icon" && " .svg"
                }`}
                className={style.hidden_input}
                ref={hiddenInputRef}
                onChange={onImageSelected}
            />
            <Modal
                visible={isPreviewVisible}
                title="Image"
                footer={null}
                zIndex={1000 + 1}
                onCancel={() => setPreviewVisible(false)}
                afterClose={() => setPreview(null)}
            >
                <img
                    alt="thumbnail"
                    style={{ width: "100%" }}
                    src={preview ?? props.url ?? ""}
                />
            </Modal>
            <Modal
                visible={isGalleryVisible}
                title="Gallery"
                footer={null}
                width="894px"
                bodyStyle={{ height: 740 }}
                onCancel={() => setGalleryVisible(false)}
            >
                <div className={style.grid_wrapper}>
                    {cells.map((_, index) =>
                        index === 0 ? (
                            <div
                                key={index}
                                className={style.empty_interface}
                                onClick={() => hiddenInputRef.current?.click()}
                            >
                                {isUploading ? <Spin /> : <PlusOutlined />}
                                <span
                                    className={style.hacky_white_border}
                                ></span>
                            </div>
                        ) : (
                            <div key={index} className={style.grid_cell}>
                                {gallery[index - 1] && (
                                    <>
                                        <div className={style.overlay}>
                                            <EyeOutlined
                                                className={style.eye_svg}
                                                onClick={() => {
                                                    setPreview(
                                                        gallery[index - 1]
                                                            .cdnUrl
                                                    );
                                                    setPreviewVisible(true);
                                                }}
                                            />
                                            <CheckOutlined
                                                className={style.select_svg}
                                                onClick={() => {
                                                    props.onChange(
                                                        gallery[index - 1]
                                                            .cdnUrl
                                                    );
                                                    setGalleryVisible(false);
                                                }}
                                            />
                                        </div>
                                        <img
                                            src={
                                                gallery[index - 1].smallCdnUrl!
                                            }
                                            alt="thumbnail"
                                            loading="lazy"
                                        />
                                    </>
                                )}
                            </div>
                        )
                    )}
                </div>
            </Modal>
        </div>
    );
};
