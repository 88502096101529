import { action, observable, flow, makeAutoObservable } from "mobx";
import { StorePreview } from "@full-circle-types";
import {
    AgvtState,
    CoreCouponManager,
    NavigationSection,
    NoopNavigation,
} from "../../components/advance-gmap-visualization-tool";

export class AgvtServiceStore implements AgvtState {
    @observable
    store: StorePreview = {
        id: 2709355,
        simId: 7900382,
        city: "ST-JEAN-SUR-RICHELIEU",
        street: "165 Boul Omer Marcil",
        postalCode: "J2W 0A3",
        state: "QC",
        country: "CA",
    };

    glowConfiguration = {};
    deals = null;
    dealBuckets = null;
    loyalties = undefined;

    fetchHomeContent = flow(function* () {
        // do nothing
    });
    fetchDealBuckets = flow(function* () {
        // do nothing
    });
    fetchDeals = flow(function* () {
        // do nothing
    });
    fetchDeal = flow(function* () {
        // do nothing
    });

    isCouponListVisible = false;

    homeContent = {
        services: [{ id: 1, inAppService: "pay-by-plate" }],
        featured: [],
        deals: [],
        dealBuckets: [],
    };

    constructor(
        public couponManager = new CoreCouponManager(),
        public navigation = new NoopNavigation({
            section: "home" as NavigationSection,
        })
    ) {
        makeAutoObservable(this);
    }

    @action
    setStore(): void {
        // do nothing
    }
}
