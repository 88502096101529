import React from "react";
import { observer } from "mobx-react";
import { AgvtHomePreviewStore } from "./agvt-store";
import { useStore, useStoresStore } from "../../contexts/mobx";
import { StorePreview } from "@full-circle-types";
import {
    DebounceSelectSingle,
    AGVT,
    CkLogo,
    TopHeaderPortal,
} from "../../components";

import style from "./style.module.scss";

export const HomePreview = observer(() => {
    const rootStore = useStore();
    const storeStore = useStoresStore();
    const { current: agvtStore } = React.useRef(
        new AgvtHomePreviewStore(rootStore)
    );
    const [storeId, setStoreId] = React.useState<number>();

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton>HOME PREVIEW</CkLogo>
                    <div className="nav_button_container">
                        <DebounceSelectSingle<StorePreview>
                            width={750}
                            placeholder="Store"
                            value={storeId}
                            getValue={(store: StorePreview) => store.simId}
                            getPreviews={(q: string) =>
                                storeStore.fetchStoresPreview({ q, limit: 20 })
                            }
                            onChange={(_: number, store?: StorePreview) => {
                                if (store) {
                                    setStoreId(store.simId);
                                    agvtStore.setStore(store);
                                }
                            }}
                            getDisplay={(store: StorePreview) => (
                                <>
                                    <span className={style.sim_highlight}>
                                        {store.simId}
                                    </span>
                                    {` - ${store.street} ${store.city} ${store.postalCode}`}
                                </>
                            )}
                            findElement={(stores: StorePreview[], id: number) =>
                                stores.find((store) => store.simId === id)
                            }
                        />
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.page}>
                <AGVT store={agvtStore}></AGVT>
            </div>
        </>
    );
});
