import React from "react";
import moment from "moment";

import style from "./style.module.scss";
import { CursorType } from "../../models";
import { ReactComponent as Lock } from "../../assets/age-verify-lock-18.svg";
import {
    GlowingSpan,
    GlowingDiv,
    GlowingImageHolder,
    GlowingColor,
} from "../glowing-hoc";
import {
    DealStatus,
    DealType,
    LoyaltyType,
    DealPayload,
    MobileDeal,
    MobileReward,
    MobileOffer,
    CategoryPreviewDto,
    LoyaltyPayload,
} from "@full-circle-types";

export interface GlowingConfiguration {
    body?: {
        in_store?: GlowingColor;
        contest?: GlowingColor;
        coupon?: GlowingColor;
        club?: GlowingColor;
        reward?: GlowingColor;
        offer?: GlowingColor;
        ageRestricted?: GlowingColor;
    };
    title?: GlowingColor;
    titleHighlight?: GlowingColor;
    category?: GlowingColor;
    subTitle?: GlowingColor;
    expiration?: GlowingColor;
    thumbnail?: GlowingColor;
}

interface Content {
    startDate?: string;
    endDate?: string;
    category: string;
    type?: DealType | LoyaltyType;
    title?: string | null;
    subTitle?: string | null;
    thumbnail?: string | null;
    titleHighlight?: string | null;
    image?: string | null;
    status: DealStatus;
    isAgeRestricted?: boolean;
}
interface Props {
    cursorType?: CursorType;
    content: Content;
    fluid?: boolean;
    onClick?: () => void;
    glowingConfiguration?: GlowingConfiguration;
    isCouponSelected?: boolean;
    onAddCoupon?: () => void;
    onRemoveCoupon?: () => void;
}

export const expirationText = (amountOfDays: number): string => {
    return amountOfDays === 0
        ? `Expires today`
        : amountOfDays === 1
        ? "Expires tomorrow"
        : amountOfDays < 0
        ? "Expired"
        : `Expires in ${amountOfDays} days`;
};

export const StandardCard = (props: Props): JSX.Element => {
    const { content, cursorType } = props;
    const { status, isAgeRestricted } = content;
    const expired = status === "expired";
    const amountOfDays = moment(content.endDate)
        .startOf("day")
        .diff(moment().startOf("day"), "days");
    const glow = props.glowingConfiguration;

    const classes = [style.card];
    const couponButtonClasses = [style.coupon_button];

    if (props.fluid) {
        classes.push(style.fluid);
    }

    if (props.content.type === "coupon" && !isAgeRestricted) {
        classes.push(style.coupon);
    }

    if (isAgeRestricted) {
        classes.push(style.age_restricted);
    }

    if (props.isCouponSelected) {
        couponButtonClasses.push(style.coupon_selected);
    }

    const title = isAgeRestricted
        ? "Verify your age and unlock this deal"
        : content.title;
    const subTitle = isAgeRestricted
        ? "Tap and verify your age to see this deal"
        : content.subTitle;

    const bodyGlow = isAgeRestricted
        ? glow?.body?.ageRestricted
        : glow?.body?.[content.type ?? "coupon"];

    return (
        <GlowingDiv
            glowColor={bodyGlow}
            className={classes.join(" ")}
            style={{ cursor: cursorType }}
            onClick={() => !isAgeRestricted && props.onClick?.()}
        >
            {isAgeRestricted ? (
                <Lock className={style.lock_svg} width={46.5} height={58.5} />
            ) : (
                <GlowingImageHolder
                    size={74}
                    src={content.thumbnail || content.image}
                    showOverlay={true}
                    grayscale={expired}
                    glowColor={glow?.thumbnail}
                />
            )}
            <div className={style.card_body}>
                {!isAgeRestricted && (
                    <GlowingSpan
                        className={style.category}
                        glowColor={glow?.category}
                    >
                        {content.category}
                    </GlowingSpan>
                )}

                <span className={style.title}>
                    {content.titleHighlight && (
                        <GlowingSpan
                            className={style.title_highlight}
                            glowColor={glow?.titleHighlight}
                        >
                            {content.titleHighlight}{" "}
                        </GlowingSpan>
                    )}
                    <GlowingSpan glowColor={glow?.title}>{title}</GlowingSpan>
                </span>

                <GlowingSpan
                    className={style.description}
                    glowColor={glow?.subTitle}
                >
                    {subTitle}
                </GlowingSpan>

                {!isAgeRestricted && (
                    <GlowingSpan
                        className={`${style.card_expiration} ${
                            expired ? style.expired : ""
                        }`}
                        glowColor={glow?.expiration}
                    >
                        {expirationText(amountOfDays)}
                    </GlowingSpan>
                )}

                {props.content.type === "coupon" && !isAgeRestricted && (
                    <GlowingDiv
                        className={couponButtonClasses.join(" ")}
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            if (props.isCouponSelected) {
                                props.onRemoveCoupon?.();
                            } else {
                                props.onAddCoupon?.();
                            }
                        }}
                    >
                        <svg width="12" height="12" viewBox="0 0 24 24">
                            <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                        </svg>
                    </GlowingDiv>
                )}
            </div>
        </GlowingDiv>
    );
};

type Transform = {
    (content: MobileDeal): Content;
    (content: MobileOffer | MobileReward): Content;
    (content: LoyaltyPayload, categories: CategoryPreviewDto[]): Content;
    (content: DealPayload, categories: CategoryPreviewDto[]): Content;
};

const transform: Transform = (
    content:
        | MobileDeal
        | MobileReward
        | MobileOffer
        | LoyaltyPayload
        | DealPayload,
    categories?: CategoryPreviewDto[]
) => {
    // cms
    if ("translations" in content) {
        const category = categories?.find((c) => c.id === content.categoryId);
        // deal
        if ("ageRestriction" in content) {
            return {
                startDate: content.startDate,
                endDate: content.endDate,
                status: "active",
                type: content.type,
                category: category?.title ?? "",
                isAgeRestricted: content.ageRestriction !== "none",
                title: content.translations.en.title,
                subTitle: content.translations.en.subTitle,
                image: content.translations.en.thumbnail,
                thumbnail: content.translations.en.smallThumbnail,
            };
        }
        // loyalty
        else {
            return {
                status: "active",
                type: content.type,
                category: category?.title ?? "",
                title: content.translations.en.title,
                subTitle: content.translations.en.subTitle,
                image: content.translations.en.image,
                thumbnail: content.translations.en.thumbnail,
                titleHighlight: content.translations.en.titleHighlight,
            };
        }
    }
    // mobile
    else {
        // deal
        if ("ageRestriction" in content) {
            return {
                startDate: content.startDate,
                endDate: content.endDate,
                status: "active",
                type: content.type,
                category: content.category,
                isAgeRestricted: false,
                title: content.title,
                subTitle: content.subTitle,
                thumbnail: content.thumbnail,
                image: content.image,
            };
        }
        // loyalty
        else {
            return {
                status: "active",
                type: content.type,
                category: content.category,
                title: content.title,
                subTitle: content.subTitle,
                thumbnail: content.image,
                titleHighlight: content.titleHighlight,
            };
        }
    }
};

StandardCard.transform = transform;
