import { Store } from "@full-circle-types";

export const normalize = (title?: string) => {
    return title
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLocaleLowerCase()
        .replace(/-/g, "")
        .replace(/,/g, "")
        .replace(/\s/g, "");
};

export const storeFilter = (filter?: string) => (store: Store) => {
    const normalizeFilter = normalize(filter) ?? "";
    return (
        !filter ||
        store.id.toString().includes(filter) ||
        normalize(store.city ?? "")?.includes(normalizeFilter) ||
        normalize(store.street ?? "")?.includes(normalizeFilter) ||
        normalize(store.postalCode ?? "")?.includes(normalizeFilter)
    );
};
