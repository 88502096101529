import React from "react";
import style from "./style.module.scss";

export const Highlighter = React.memo(function Highlighter(props: {
    children: string;
    search: string;
}) {
    const { children: text, search } = props;
    const escapeSearch = String(search).replace(
        /[-[\]{}()*+?,\\^$|#\s]/g,
        "\\$&"
    );
    const regEx = new RegExp(escapeSearch, "gi");
    const iterator = text.matchAll(regEx);
    const jsxParts: JSX.Element[] = [];

    let pos = 0;
    let end = 0;
    let currentMatch = iterator.next();

    while (pos < text.length) {
        const start = pos;
        if (start === currentMatch.value?.index) {
            end = start + escapeSearch.length;
            jsxParts.push(
                <i key={jsxParts.length} className={style.highlight}>
                    {text.substring(start, end)}
                </i>
            );
            currentMatch = iterator.next();
        } else {
            end = currentMatch.value?.index || text.length;
            jsxParts.push(
                <span key={jsxParts.length}>{text.substring(start, end)}</span>
            );
        }
        pos = end;
    }

    return <>{jsxParts}</>;
});
