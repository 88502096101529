import React from "react";
import { Select, Modal } from "antd";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { EyeOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import style from "./style.module.scss";
import { LoyaltyType } from "../../utils/const";
import { SupportedLanguage } from "../../types";
import { useAuthStore } from "../../contexts/mobx";
import { Permission } from "../../utils/permission";
import { getLoyaltyTypeName } from "../../utils/content-type";
import get from "lodash/get";
import {
    Select as EasySelect,
    Input,
    ToggleSwitch,
    DatePicker,
} from "../../easyui-components";
import {
    CategoryPreviewDto,
    GalleryPreview,
    LoyaltyPayload,
    OfferTierType,
} from "@full-circle-types";
import {
    ErrorAwareLabel,
    GalleryPicker,
    IconWrapper,
    HelpfulSpan,
    RichText,
    DebounceSelectMultiple,
} from "../../components";

// This is a little work around to extract the generic return type from formik
const __doNotCall = () => useFormik<LoyaltyPayload>({} as any);
type Formik = ReturnType<typeof __doNotCall>;

interface Props {
    formik: Formik;
    categories: CategoryPreviewDto[];
    tos: GalleryPreview[] | null;
    selectedLangs: SupportedLanguage[];
    isAppMultiLang: boolean;
}
const INPUT_WIDTH = 200;

export const SideForm = observer((props: Props) => {
    const { formik, categories, tos, selectedLangs } = props;
    const params = useParams<{ action: string }>();
    const [previewContent, setPreviewContent] = React.useState<string | null>(
        null
    );
    const { role } = useAuthStore();
    const canEnable = Permission.canEnable({ role });

    // The type inference is not working well for nested error schema. So we are
    // going to cheat a little bit using lodash
    const checkIfHasError = (path: string) => {
        const hasError = Boolean(get(formik.errors, path));
        const isTouched = Boolean(get(formik.touched, path)); // Typescript is actually correct for the touched object, but I will use lodash.
        return hasError && (isTouched || params.action !== "create");
    };

    const onDescriptionChange = React.useCallback(
        (text: string, name: string) => {
            formik.setFieldValue(name, text);
        },
        []
    );

    return (
        <>
            {/* CATEGORY */}
            <div className={style.basic_input}>
                <HelpfulSpan
                    section="loyalty-category"
                    className={style.required}
                >
                    Category
                </HelpfulSpan>
                <EasySelect
                    width={INPUT_WIDTH}
                    onChange={(value) =>
                        formik.setFieldValue("categoryId", value)
                    }
                    value={formik.values.categoryId}
                >
                    {categories.map((category) => (
                        <Select.Option value={category.id} key={category.id}>
                            {category.title}
                        </Select.Option>
                    ))}
                </EasySelect>
            </div>

            {/* LOYALTY TYPE */}
            <div className={style.basic_input}>
                <HelpfulSpan section="loyalty-type">Type Selection</HelpfulSpan>
                <EasySelect
                    width={INPUT_WIDTH}
                    value={formik.values.type}
                    onChange={(type) => formik.setFieldValue("type", type)}
                >
                    <Select.Option value={LoyaltyType[0]}>
                        {getLoyaltyTypeName(LoyaltyType[0])}
                    </Select.Option>
                    <Select.Option value={LoyaltyType[1]}>
                        {getLoyaltyTypeName(LoyaltyType[1])}
                    </Select.Option>
                    <Select.Option value={LoyaltyType[2]}>
                        {getLoyaltyTypeName(LoyaltyType[2])}
                    </Select.Option>
                </EasySelect>
            </div>

            {/* POSITION */}
            <div className={style.basic_input}>
                <ErrorAwareLabel
                    text="Position"
                    hasError={checkIfHasError(`position`)}
                />

                <Input
                    width={INPUT_WIDTH}
                    name="position"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="number"
                    value={formik.values.position ?? undefined}
                />
            </div>

            {/* ENABLED */}
            <div className={style.basic_input}>
                <HelpfulSpan section="enabled">Enabled</HelpfulSpan>

                <ToggleSwitch
                    checked={formik.values.isEnabled}
                    name="isEnabled"
                    onChange={formik.handleChange}
                    disabled={!canEnable}
                    tooltipMessage={
                        canEnable
                            ? undefined
                            : "Only a Publisher can change the enabled value of a Deal"
                    }
                />
            </div>

            <div className={style.separator}></div>

            {/* BRIERLEY CLUB ID & ASSOCIATED REWARD ID */}
            {formik.values.type === "club" && (
                <>
                    <div className={style.basic_input}>
                        <HelpfulSpan
                            section="loyalty-brierley-club-id"
                            className={style.required}
                        >
                            Brierley Club Id
                        </HelpfulSpan>
                        <Input
                            width={INPUT_WIDTH}
                            name={`brierleyClubId`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.brierleyClubId ?? ""}
                        />
                    </div>
                </>
            )}

            {/* BRIERLEY REWARD ID & CLUB ASSOCIATION */}
            {formik.values.type === "reward" && (
                <>
                    <div className={style.basic_input}>
                        <HelpfulSpan
                            section="loyalty-brierley-reward-id"
                            className={style.required}
                        >
                            Brierley Reward Id
                        </HelpfulSpan>
                        <Input
                            width={INPUT_WIDTH}
                            name={`brierleyRewardId`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.brierleyRewardId ?? ""}
                        />
                    </div>
                </>
            )}

            {/* BRIERLEY OFFER ID */}
            {formik.values.type === "offer" && (
                <>
                <div className={style.basic_input}>
                        <HelpfulSpan section="loyalty-age-verified-offer">
                            Age Verified
                        </HelpfulSpan>
                        <ToggleSwitch
                            checked={formik.values.isAgeVerified!}
                            name="isAgeVerified"
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "isAgeVerified",
                                    e.target.checked
                                );
                            }}
                        />
                    </div>
                    <div className={style.basic_input}>
                        <HelpfulSpan section="loyalty-non-targeted-offer">
                            Non Targeted
                        </HelpfulSpan>
                        <ToggleSwitch
                            checked={formik.values.isNonTargeted!}
                            name="isNonTargeted"
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "isNonTargeted",
                                    e.target.checked
                                );
                                if (!e.target.checked) {
                                    formik.setFieldValue("tier", null);
                                    formik.setFieldValue("storeGroupIds", []);
                                    formik.setFieldValue(
                                        "startDate",
                                        new Date().toISOString()
                                    );
                                    formik.setFieldValue(
                                        "endDate",
                                        new Date().toISOString()
                                    );
                                    formik.setFieldValue(
                                        "isVisibleOnHomePage",
                                        false
                                    );
                                }
                            }}
                        />
                    </div>
                    <div className={style.basic_input}>
                        <HelpfulSpan section="store-specials">
                            Visible on home page
                        </HelpfulSpan>

                        <ToggleSwitch
                            name="isVisibleOnHomePage"
                            disabled={!formik.values.isNonTargeted}
                            checked={formik.values.isVisibleOnHomePage || false}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={style.basic_input}>
                        <HelpfulSpan>Tier</HelpfulSpan>
                        <EasySelect<OfferTierType>
                            disabled={!formik.values.isNonTargeted}
                            placeholder="Tier"
                            width={200}
                            value={formik.values.tier}
                            allowClear
                            onChange={(e) => formik.setFieldValue("tier", e)}
                            tooltip="Only non targeted offer require a specific tier."
                        >
                            <Select.Option value={"member"} key={"member"}>
                                Member
                            </Select.Option>
                            <Select.Option value={"premium"} key={"premium"}>
                                Premium
                            </Select.Option>
                            <Select.Option value={"both"} key={"both"}>
                                Both
                            </Select.Option>
                        </EasySelect>
                    </div>
                    <div className={style.basic_input}>
                        <HelpfulSpan
                            section="loyalty-brierley-offer-id"
                            className={style.required}
                        >
                            Brierley Offer Id
                        </HelpfulSpan>
                        <Input
                            width={INPUT_WIDTH}
                            name={`brierleyOfferId`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.brierleyOfferId ?? ""}
                        />
                    </div>
                    <div className={style.basic_input}>
                        <HelpfulSpan section="store-groups">
                            Store Group
                        </HelpfulSpan>
                        <DebounceSelectMultiple
                            tooltip="Store Group association is only available for non targeted offers."
                            disabled={!formik.values.isNonTargeted}
                            width={INPUT_WIDTH}
                            placeholder="Store Group"
                            onChange={(v: number[]) =>
                                formik.setFieldValue("storeGroupIds", v)
                            }
                            value={formik.values.storeGroupIds}
                        />
                    </div>
                    <div className={style.basic_input}>
                        <HelpfulSpan section="deal-expiration">
                            Start Date
                        </HelpfulSpan>

                        <DatePicker
                            width={200}
                            value={
                                formik.values.startDate ||
                                new Date().toISOString()
                            }
                            hasError={checkIfHasError(`startDate`)}
                            label="Start"
                            onChange={(date) =>
                                formik.setFieldValue("startDate", date)
                            }
                            disableIfBefore="now"
                            disabled={!formik.values.isNonTargeted}
                            ableToSetTime
                        />
                    </div>
                    <div className={style.basic_input}>
                        <HelpfulSpan section="deal-expiration">
                            End Date
                        </HelpfulSpan>

                        <DatePicker
                            width={200}
                            value={
                                formik.values.endDate ||
                                new Date().toISOString()
                            }
                            hasError={checkIfHasError(`endDate`)}
                            label="End"
                            onChange={(date) =>
                                formik.setFieldValue("endDate", date)
                            }
                            disableIfBefore={formik.values.startDate}
                            disabled={!formik.values.isNonTargeted}
                            ableToSetTime
                        />
                    </div>
                </>
            )}

            <div className={style.separator}></div>

            {/* ICONS */}
            <div className={style.image_and_thumbnail}>
                {formik.values.type !== "offer" && (
                    <>
                        <div className={style.image}>
                            <HelpfulSpan
                                section="loyalty-filled-icon"
                                className={style.required}
                            >
                                Filled Icon
                            </HelpfulSpan>
                            <GalleryPicker
                                dataType="icon"
                                url={formik.values.filledPunchIcon}
                                onChange={(url) =>
                                    formik.setFieldValue(`filledPunchIcon`, url)
                                }
                            />
                        </div>
                        {formik.values.type === "club" && (
                            <div className={style.thumbnail}>
                                <HelpfulSpan
                                    section="loyalty-empty-icon"
                                    className={style.required}
                                >
                                    Empty Icon
                                </HelpfulSpan>
                                <GalleryPicker
                                    dataType="icon"
                                    url={formik.values.emptyPunchIcon}
                                    onChange={(url) =>
                                        formik.setFieldValue(
                                            `emptyPunchIcon`,
                                            url
                                        )
                                    }
                                />
                            </div>
                        )}
                    </>
                )}
            </div>

            {selectedLangs.map((lang) => (
                <>
                    <div className={style.image_and_thumbnail}>
                        {/* IMAGE */}
                        <div className={style.image}>
                            <HelpfulSpan
                                section="loyalty-image"
                                className={style.required}
                            >
                                Image
                            </HelpfulSpan>
                            <GalleryPicker
                                dataType="loyalty"
                                url={formik.values.translations[lang]?.image}
                                onChange={(url) =>
                                    formik.setFieldValue(
                                        `translations.${lang}.image`,
                                        url
                                    )
                                }
                            />
                        </div>

                        {/* THUMBNAIL */}
                        {formik.values.type !== "club" && (
                            <div className={style.thumbnail}>
                                <HelpfulSpan section="loyalty-thumbnail">
                                    Thumbnail
                                </HelpfulSpan>
                                <GalleryPicker
                                    dataType="loyalty"
                                    allowDeletion={true}
                                    url={
                                        formik.values.translations[lang]
                                            ?.thumbnail
                                    }
                                    onChange={(url) =>
                                        formik.setFieldValue(
                                            `translations.${lang}.thumbnail`,
                                            url
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>

                    {/* TITLE */}
                    <div className={style.basic_input}>
                        <HelpfulSpan
                            section="loyalty-title"
                            className={style.required}
                        >
                            Title
                        </HelpfulSpan>
                        <Input
                            name={`translations.${lang}.title`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                                formik.values.translations[lang]?.title ?? ""
                            }
                        />
                    </div>

                    {/* TITLE HIGHLIGHT */}
                    {formik.values.type === "reward" && (
                        <div className={style.basic_input}>
                            <HelpfulSpan section="loyalty-title-highlight">
                                Title Highlight
                            </HelpfulSpan>
                            <Input
                                name={`translations.${lang}.titleHighlight`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                maxlength={10}
                                value={
                                    formik.values.translations[lang]
                                        ?.titleHighlight ?? ""
                                }
                            />
                        </div>
                    )}

                    {/* SUBTITLE */}
                    <div className={style.basic_input}>
                        <HelpfulSpan
                            section="loyalty-title"
                            className={style.required}
                        >
                            Sub Title
                        </HelpfulSpan>
                        <Input
                            name={`translations.${lang}.subTitle`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                                formik.values.translations[lang]?.subTitle ?? ""
                            }
                        />
                    </div>

                    {/* TOS */}
                    <div className={style.basic_input}>
                        <div className={style.select_with_preview}>
                            <HelpfulSpan
                                section="deal-tos"
                                className={style.required}
                            >
                                TOS
                            </HelpfulSpan>
                            {formik.values.translations[lang]?.tosLink && (
                                <IconWrapper>
                                    <EyeOutlined
                                        onClick={() =>
                                            setPreviewContent(
                                                formik.values.translations.en
                                                    .tosLink!
                                            )
                                        }
                                    />
                                </IconWrapper>
                            )}
                        </div>
                        <EasySelect
                            width={250}
                            onChange={(e) =>
                                formik.setFieldValue(
                                    `translations.${lang}.tosLink`,
                                    e
                                )
                            }
                            onBlur={formik.handleBlur}
                            value={
                                formik.values.translations[lang]?.tosLink ?? ""
                            }
                            allowClear
                        >
                            {tos?.map((to: GalleryPreview) => {
                                return (
                                    <Select.Option value={to.url} key={to.id}>
                                        {to.name}
                                    </Select.Option>
                                );
                            })}
                        </EasySelect>
                    </div>
                    <div className={style.description}>
                        <span className={style.required}>Description</span>
                        <RichText
                            name={`translations.${lang}.description`}
                            text={
                                formik.values.translations[lang]?.description ??
                                ""
                            }
                            onChange={onDescriptionChange}
                        />
                    </div>
                </>
            ))}
            <Modal
                title={"Preview"}
                visible={Boolean(previewContent)}
                onCancel={() => setPreviewContent(null)}
                footer={null}
            >
                <iframe
                    frameBorder="0"
                    src={previewContent!}
                    title="TOS preview"
                />
            </Modal>
        </>
    );
});
