import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Typography } from "antd";
import style from "./style.module.scss";
import { Button } from "../../easyui-components";
import { useOktaAuth } from "@okta/okta-react";
import landingImage from "../../assets/landing.png";
import ckFullLogo from "../../assets/ck-full-logo.png";

const { Title } = Typography;

const Container = styled.div`
    display: grid;
    grid-template-columns: 450px 1fr;
    height: 100vh;
    overflow: hidden;
`;

const LandingImg = styled.img`
    object-fit: cover;
    width: 100%;
    min-height: 100%;
`;

const Logo = styled.img`
    width: 80%;
`;

const LeftSide = styled.div`
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const ButtonHolder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
        color: ${(p) => p.theme.colors.ckOrange};
    }
`;

const FullWidthButton = styled(Button)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoginPage = observer(() => {
    const { oktaAuth } = useOktaAuth();

    const signIn = () => {
        oktaAuth.signInWithRedirect({ originalUri: "/" });
    };

    return (
        <Container>
            <LeftSide>
                <Logo src={ckFullLogo} alt="logo" />
                <Title level={2} className="h1-header">
                    Sign In With Okta
                </Title>

                <ButtonHolder>
                    <FullWidthButton
                        type="primary"
                        onClick={signIn}
                        className={style.submit_button}
                    >
                        Sign In
                    </FullWidthButton>
                    <span>
                        For access, please contact{" "}
                        <a href="mailto:dpeckard@circlek.com">Dan Peckardt</a>
                    </span>
                </ButtonHolder>
            </LeftSide>
            <LandingImg src={landingImage} alt="landing" />
        </Container>
    );
});
