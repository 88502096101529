import React from "react";
import style from "./style.module.scss";
import { HelpfulSpan } from "../helpful-text";

interface Props {
    text: string;
    hasError?: boolean;
    section?: string;
}

export const ErrorAwareLabel = (props: Props): JSX.Element => {
    return (
        <HelpfulSpan
            className={props.hasError ? style.has_error : undefined}
            section={props.section}
        >
            {props.text}
        </HelpfulSpan>
    );
};
