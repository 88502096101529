import React from "react";
import style from "./style.module.scss";
import { NavigationSection } from "../";

interface BaseProps {
    associatedSection: NavigationSection;
    currentSection: NavigationSection;
    onClick?: (section: NavigationSection) => void;
}

interface Props extends BaseProps {
    children: React.ReactElement<HTMLOrSVGElement>;
}

export const NavigationIcon = (props: Props): JSX.Element => {
    const isSelected = props.associatedSection === props.currentSection;
    const classes = [style.navigation_icon];

    if (isSelected) {
        classes.push(style.is_selected);
    }

    if (props.onClick) {
        classes.push(style.clickable);
    }

    return (
        <span
            className={classes.join(" ")}
            onClick={() => props.onClick?.(props.associatedSection)}
        >
            <span className={style.red_line}></span>
            {props.children}
            <span className={style.section_name}>
                {props.associatedSection.replace("-", " ")}
            </span>
        </span>
    );
};

export const HomeIcon = (props: BaseProps): JSX.Element => (
    <NavigationIcon {...props}>
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m4.422 7.897 6.267-5.094v1.796a1 1 0 0 1-.382.787L6.77 8.166a1 1 0 0 0-.382.786v7.542H4.422V7.897z"
                fill="#E6E6E6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.422 15.994h4v1h-4v-1z"
                fill="#ED8B00"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.656 12.994h1v4h-1v-4z"
                fill="#FFD100"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.656 15.994h4v1h-4v-1zM15.656 11.994h1v-5h-1v5z"
                fill="#FFD100"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.19 11.752h-.998v3h.999v-3z"
                fill="#E6E6E6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.19 17.752h-.998v-3h.999v3z"
                fill="#CCC"
            />
            <circle cx="11.191" cy="14.5" r=".5" fill="#777" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.18 18a.5.5 0 0 0 .5-.5V8.776l1.212.978a.213.213 0 0 0 .3-.032l.444-.547a.222.222 0 0 0-.035-.303L17.68 7.326V4.25a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25V6.52L11.386 2.25a1.12 1.12 0 0 0-1.412 0L1.759 8.872a.21.21 0 0 0-.032.297l.444.547a.213.213 0 0 0 .3.031l1.209-.974V17.5a.5.5 0 0 0 .5.5h13zm-9-1h-3.5V7.966l5.866-4.73a.207.207 0 0 1 .262 0l5.872 4.733V17h-3.5v-5.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V17zm1 0h3v-5h-3v5z"
                fill="#000"
            />
        </svg>
    </NavigationIcon>
);

export const AccountIcon = (props: BaseProps): JSX.Element => (
    <NavigationIcon {...props}>
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.48 3.5c1.399 0 2.69.459 3.73 1.235a6.224 6.224 0 0 1 2.52 5.015c0 1.488-.48 3.072-1.352 4.142M10.457 3.5a6.222 6.222 0 0 0-3.73 1.235A6.287 6.287 0 0 0 5.56 5.866 6.223 6.223 0 0 0 4.207 9.75c0 1.488.48 3.072 1.353 4.142"
                stroke="#F5F5F5"
                strokeLinecap="round"
            />
            <path
                d="M10.48 13.48s.511-.001 1.22-.074c.709-.072.853-.431 1.553 0 .7.432.985 1.448.985 2.26"
                stroke="#F5F5F5"
            />
            <path
                d="M10.488 13.48a12.65 12.65 0 0 1-1.22-.074c-.709-.072-.853-.431-1.552 0-.7.432-.986 1.448-.986 2.26"
                stroke="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.469 17.5a7.749 7.749 0 0 0 7.75-7.75A7.749 7.749 0 0 0 10.469 2a7.749 7.749 0 0 0-7.75 7.75 7.749 7.749 0 0 0 7.75 7.75zm4.956-3.184a2.99 2.99 0 0 0-2.956-2.566c-.281 0-.498.069-.742.145-.313.099-.669.211-1.258.211a3.72 3.72 0 0 1-1.256-.21c-.244-.077-.462-.146-.744-.146a2.99 2.99 0 0 0-2.956 2.566A6.714 6.714 0 0 1 3.719 9.75 6.758 6.758 0 0 1 10.469 3a6.758 6.758 0 0 1 6.75 6.75 6.714 6.714 0 0 1-1.794 4.566zM13.219 8.25a2.75 2.75 0 1 1-5.5 0 2.75 2.75 0 0 1 5.5 0zM10.469 10a1.751 1.751 0 0 1 0-3.5 1.751 1.751 0 0 1 0 3.5zm-4 5.175a6.715 6.715 0 0 0 4 1.325 6.715 6.715 0 0 0 4-1.325v-.425c0-1.103-.897-2-2-2-.125 0-.27.045-.455.103a4.724 4.724 0 0 1-1.545.253c-.735 0-1.21-.148-1.547-.253-.186-.058-.33-.103-.453-.103-1.103 0-2 .897-2 2v.425z"
                fill="#777"
            />
        </svg>
    </NavigationIcon>
);

export const StoresIcon = (props: BaseProps): JSX.Element => (
    <NavigationIcon {...props}>
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.703 9h2.5v8.5a.5.5 0 0 1-.5.5h-1.5a.5.5 0 0 1-.5-.5V9z"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.434 11.063h4v1h-4v-1zM11.434 11.063h-2v1h2v-1zM2.003 7l-.585 1.01h10.018V7H2.003z"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.434 7v1.01h8L18.966 7h-7.532zM3.434 15.994h4v1h-4v-1zM9.434 15.994h2v1h-2v-1z"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.44 16.953c0 .578-.446 1.047-1 1.047h-8c-.552 0-1-.469-1-1.047V9h-1a.999.999 0 0 1-.865-1.497l2.857-5C3.61 2.193 3.94 2 4.3 2h12.281c.36 0 .691.194.87.503l2.855 5A.999.999 0 0 1 19.442 9h-1v8.75a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25V9h-5v7.953zM1.445 8h17.997l-2.86-5H4.301L1.443 8zm9.997 4h-8V9h8v3zm-8 4.953c0 .038.019.053.019.053L11.435 17c-.002-.001 0-.005 0-.01a.134.134 0 0 0 .006-.037V13h-8v3.953z"
                fill="#777"
            />
        </svg>
    </NavigationIcon>
);

export const RewardsIcon = (props: BaseProps): JSX.Element => (
    <NavigationIcon {...props}>
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m10.211 5.016-.804 1.413-1.516.337L9.05 8.03l-.388 1.41 1.548-.726 1.416.727-.26-1.41 1.018-1.265-1.464-.337-.71-1.413z"
                fill="#F5F5F5"
            />
            <path
                d="M2.703 2.994c.081 1.938 1.111 2.938 3.03 3"
                stroke="#F5F5F5"
            />
            <path
                d="M5.918 8.293c.287 1.39 1.123 2.316 2 3M5.92 2.96s.009 1.488.018 2.052c.01.597.024 1.061.041 1.39M11.877 2.76h2.528c.017 2.111 0 3.904 0 4.968 0 1.695-.722 2.46-1.5 3.248-.517.524-1.885.989-2.702.984"
                stroke="#F5F5F5"
                strokeWidth=".5"
            />
            <path
                d="M17.733 2.994c-.08 1.938-1.11 2.938-3.03 3"
                stroke="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.657 1.91a.5.5 0 0 0-.492-.41H5.236l-.09.008a.5.5 0 0 0-.41.492v.594H2.2l-.09.008a.5.5 0 0 0-.41.492c0 1.734 1.367 3.447 3.037 3.739v.858a.507.507 0 0 0-.002.053c0 2.541 1.761 4.673 4.138 5.262v1.176l-.007.133c-.076.83-.843 1.65-1.655 1.68a.5.5 0 0 0-.51.311l-.547 1.36a.495.495 0 0 0 .046.477.498.498 0 0 0 .425.22h7.15a.493.493 0 0 0 .426-.22.496.496 0 0 0 .045-.477l-.547-1.36-.006-.011a.509.509 0 0 0-.027-.052l-.008-.017a.5.5 0 0 0-.468-.231c-.854-.031-1.662-.94-1.662-1.813v-1.176c2.344-.581 4.09-2.664 4.137-5.16V6.832C17.336 6.54 18.7 4.828 18.7 3.094a.5.5 0 0 0-.5-.5h-2.534V2l-.008-.09zm-5.125 12.43c.081 1.247 1.111 2.438 2.354 2.629l.16.394H7.352l.16-.394c1.296-.2 2.359-1.483 2.359-2.787v-1.025a5.643 5.643 0 0 0 .655 0v1.025l.005.157zM4.736 5.81V3.593H2.752l.03.133c.252.97 1.046 1.84 1.954 2.082zm12.88-2.083c-.251.97-1.043 1.839-1.951 2.082V3.594h1.98l-.029.133zm-2.951 4.017V2.5H5.735v5.315l-.001.038c.059 2.391 2.034 4.314 4.464 4.314 2.465 0 4.462-1.978 4.466-4.416v-.007zM9.18 5.172l-1.26.193-.083.02a.5.5 0 0 0-.198.825l.924.94-.22 1.332-.007.081a.5.5 0 0 0 .741.44l1.124-.618 1.123.617.074.034a.5.5 0 0 0 .66-.554l-.22-1.333.925-.94.054-.064a.5.5 0 0 0-.336-.78l-1.263-.193-.564-1.197a.5.5 0 0 0-.904-.002l-.57 1.199zm1.02.184.228.484.051.085a.5.5 0 0 0 .326.196l.558.084-.417.425-.06.073a.5.5 0 0 0-.077.36l.094.566-.46-.252-.093-.04a.5.5 0 0 0-.389.04l-.464.253.095-.568.006-.094a.5.5 0 0 0-.143-.338l-.418-.425.556-.084a.5.5 0 0 0 .376-.28l.231-.485z"
                fill="#777"
            />
        </svg>
    </NavigationIcon>
);

export const DealsIcon = (props: BaseProps): JSX.Element => (
    <NavigationIcon {...props}>
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.505 17.387s6.303-6.131 6.768-6.728c.464-.597 0-1.131 0-1.131l-6.36-6.473s-.761 1.41 0 2.033c.572.47 3.226 3.17 4.505 4.48a.997.997 0 0 1-.015 1.408l-6.13 6.023s.05.157.388.389c.338.23.844 0 .844 0z"
                fill="#F5F5F5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.506 17.387s6.303-6.131 6.768-6.728c.465-.597 0-1.131 0-1.131l-6.36-6.473s-.76 1.498 0 2.121c.565.463 3.102 2.894 4.37 4.113a.997.997 0 0 1 .017 1.42l-6.2 6.29s.223.157.561.389c.338.23.844 0 .844 0z"
                fill="#F5F5F5"
            />
            <path d="m3.063 9.99 5.21 5.212" stroke="#F5F5F5" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m10.143 17.56 6.379-6.378a1.5 1.5 0 0 0 0-2.121L9.9 2.439A1.496 1.496 0 0 0 8.84 2H2.46a1.5 1.5 0 0 0-1.5 1.5v6.379c0 .414.169.789.44 1.06l6.622 6.622a1.5 1.5 0 0 0 2.12 0zm4 0 6.379-6.378a1.5 1.5 0 0 0 0-2.121L13.9 2.439A1.496 1.496 0 0 0 12.84 2h-1.611l1 1h.612c.138 0 .262.056.352.146l6.621 6.622a.5.5 0 0 1 0 .707l-6.378 6.379a.5.5 0 0 1-.707 0l-.056-.056-.707.707.056.056a1.5 1.5 0 0 0 2.12 0zM2.107 10.233l6.622 6.622a.5.5 0 0 0 .707 0l6.378-6.38a.5.5 0 0 0 0-.706l-6.62-6.622A.495.495 0 0 0 8.84 3H2.46a.5.5 0 0 0-.5.5v6.379c0 .133.053.259.147.353zM5.461 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-.625-1.5a.626.626 0 1 0 1.251-.001.626.626 0 0 0-1.251.001z"
                fill="#777"
            />
        </svg>
    </NavigationIcon>
);
