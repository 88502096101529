import { observable, makeObservable, flow } from "mobx";

import { RootStore, MakeNetworkCall } from "./root";
import { SupportedLanguage } from "../types";
import { NotificationManager } from "../components";
import {
    Gallery,
    GalleryLabel,
    GalleryPreview,
    PaginatedGallery,
    GallerySearchParams,
} from "@full-circle-types";
import { WithNetworkConcurrency } from "./with-network-concurrency";

export class GalleryStore extends WithNetworkConcurrency {
    @observable isFetching = false;
    @observable isFetchingCount = 0;
    @observable isUploading = false;
    @observable isDeleting = false;
    @observable isUpdating = false;
    @observable previews: GalleryPreview[] | null = null;
    @observable list: PaginatedGallery | null = null;
    @observable tags: string[] = [];
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    fetchGallery = flow(function* (
        this: GalleryStore,
        params: GallerySearchParams,
        label: GalleryLabel
    ) {
        params.label = label;

        const tag = this.getTag();
        this.isFetchingCount++;
        const response = yield this.rootStore.makeNetworkCall<PaginatedGallery>(
            {
                method: "GET",
                url: "gallery",
                params,
            }
        );
        this.isFetchingCount--;

        if (response.data && this.isLatestTag(tag)) {
            this.list = response.data;
        }
    });

    fetchGalleryPreview = flow(function* (
        this: GalleryStore,
        label: GalleryLabel
    ) {
        this.isFetching = true;
        const response = yield this.rootStore.makeNetworkCall<GalleryPreview[]>(
            {
                method: "GET",
                url: "/gallery/preview",
                params: { label },
            }
        );
        this.isFetching = false;

        if (response.data) {
            this.previews = response.data;
        }
    });

    uploadImage = flow(function* (
        this: GalleryStore,
        file: File,
        name: string,
        label?: GalleryLabel,
        language?: SupportedLanguage,
        ratio?: string
    ): MakeNetworkCall<Gallery, Gallery | undefined> {
        this.isUploading = true;
        const formData = new FormData();
        formData.set("file", file);
        formData.set("name", name);

        if (label) {
            formData.set("label", label);
        }

        if (language) {
            formData.set("language", language);
        }

        if (ratio) {
            formData.set("ratio", ratio);
        }

        const response = yield this.rootStore.makeNetworkCall({
            url: "/gallery/image",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        this.isUploading = false;

        if (!response.err) {
            return response.data;
        }
    });

    uploadFile = flow(function* (
        this: GalleryStore,
        type: "image" | "site",
        file: File,
        name: string,
        label?: GalleryLabel,
        language?: SupportedLanguage
    ): MakeNetworkCall<Gallery, Gallery | undefined> {
        this.isUploading = true;
        const formData = new FormData();
        formData.set("file", file);
        formData.set("name", name);

        if (label) {
            formData.set("label", label);
        }

        if (language) {
            formData.set("language", language);
        }

        const response = yield this.rootStore.makeNetworkCall({
            url: `/gallery/${type}`,
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        this.isUploading = false;

        if (!response.err) {
            return response.data;
        }
    });

    uploadHtml = flow(function* (
        this: GalleryStore,
        content: string,
        name: string,
        label: GalleryLabel
    ) {
        this.isUploading = true;

        yield this.rootStore.makeNetworkCall({
            url: "/gallery/html",
            method: "POST",
            data: {
                name,
                content,
                label,
            },
        });
        this.isUploading = false;
    });

    deleteContent = flow(function* (this: GalleryStore, contentId: number) {
        this.isDeleting = true;

        const response = yield this.rootStore.makeNetworkCall({
            url: `/gallery/${contentId}`,
            method: "delete",
        });
        this.isDeleting = false;

        if (!response.err) {
            NotificationManager.showSuccess("Item Deleted");
        }
    });

    update = flow(function* (
        this: GalleryStore,
        contentId: number,
        name: string,
        tags: string[]
    ) {
        if (this.isUpdating) {
            return;
        }

        this.isUpdating = true;

        const response = yield this.rootStore.makeNetworkCall({
            method: "PATCH",
            url: `/gallery/name/${contentId}`,
            data: { name, tags },
        });

        this.isUpdating = false;

        if (!response.err) {
            NotificationManager.showSuccess("Gallery Updated");
        }
    });

    getTags = flow(function* (this: GalleryStore, label: GalleryLabel) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "GET",
            url: `/gallery/tags/${label}`,
        });

        this.tags = response.data;
    });
}
