import React from "react";
import { observer } from "mobx-react";
import { useAgvtStore } from "..";

import style from "./style.module.scss";

export const ViewListButton = observer(() => {
    const store = useAgvtStore();

    const { isCouponListVisible } = store;

    const classes = [style.coupon_button];

    if (isCouponListVisible) {
        classes.push(style.visible);
    }

    return (
        <>
            <div
                className={classes.join(" ")}
                onClick={() =>
                    store.couponManager.showBottomDrawer("coupons-list")
                }
            >
                VIEW MY COUPONS ( {store.couponManager.couponList.length || 1} )
            </div>
        </>
    );
});
