import React from "react";
import { Select as AntdSelect, Tooltip } from "antd";
import { SelectValue, SelectProps } from "antd/es/select";
import style from "./style.module.scss";
import { RefCallBack } from "react-hook-form";

interface Props<VT> extends SelectProps<VT> {
    width?: number;
    ref?: RefCallBack;
    tooltip?: string;
    /**
     * Using a specific field like that is far from ideal, but because the underlying css is ugly
     * I used this approach even if it doesn't respect the Open/Close principle at all...
     */
    withWhiteBackground?: boolean;
}

export const Option = AntdSelect.Option;

export function Select<VT extends SelectValue = SelectValue>(props: Props<VT>) {
    const classes = [style.custom_select];

    if (props.withWhiteBackground) {
        classes.push(style.white_bg);
    }

    return (
        <span className={classes.join(" ")}>
            <Tooltip placement="bottom" title={props.disabled && props.tooltip}>
                <AntdSelect
                    allowClear={props.allowClear}
                    onClear={props.onClear}
                    placeholder={props.placeholder}
                    style={{ width: props.width }}
                    value={props.value}
                    onChange={props.onChange}
                    suffixIcon={
                        <svg width="11" height="11" viewBox="0 0 24 24">
                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </svg>
                    }
                    clearIcon={
                        <svg width="10" height="10" viewBox="0 0 24 24">
                            <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                        </svg>
                    }
                    defaultValue={props.defaultValue}
                    filterOption={false}
                    onSearch={props.onSearch}
                    notFoundContent={props.notFoundContent}
                    showSearch={props.showSearch}
                    disabled={props.disabled}
                    dropdownClassName={style.options}
                    loading={props.loading}
                    ref={props.ref}
                    mode={props.mode}
                >
                    {props.children}
                </AntdSelect>
            </Tooltip>
        </span>
    );
}
