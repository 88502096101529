import { MobileReward, MobileOffer, MobileClub } from "@full-circle-types";

export type Club = MobileClub & { progress: 0 | 1 | 2 | 3 | 4 | 5 };

export const FakeRewards: MobileReward[] = [
    {
        id: 691,
        title: "Cranberry Anti-Oxidant Mix",
        titleHighlight: "Free",
        type: "reward",
        brierleyRewardId: "123",
        subTitle: "",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/7ad0219b74b803.png",
        thumbnail: "",
        category: "Nuts",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        tosLink: "",
    },
    {
        id: 345,
        title: "Pink Frosted with Sprinkles",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        subTitle: "",
        thumbnail:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/4ad4c38b796612.png",
        image:
            "https://static-content.full-circle.thirdbridge.ca/uat/images/e97b80622e58e6.jpeg",
        category: "Donuts",
        description:
            "<p>With this coupon, you get <strong>$5 OFF on pack of 6 or 12 cans VIZZY Hard Seltzer (355 ML – Various Flavours).</strong></p>\n<p></p>\n<p>Benefit now in participating Couche-Tard stores!</p>\n<p></p>\n<p>Valid while supplies last, coupons or by April 26, 2021. The coupon is only valid once during the entire promotion, for one use per person. No reduction granted if the value does not comply with the minimum price established by law. Prices are set exclusively by the retailer, who must respect the minimum price established by law.</p>\n<p>Offer valid at participating Couche-Tard in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse.</p>\n<p>* Taxes and deposit not included.</p>\n<p>* Must be 21 years or older.</p>\n",
        type: "reward",
        brierleyRewardId: "123",
        tosLink: "",
    },
    {
        id: 680,
        title: "Circle K Original Potato Chips",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        subTitle:
            "Try today - For a limited time only. This reward is very special",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/563ef5464828ef.png",
        thumbnail: "",
        type: "reward",
        brierleyRewardId: "123",
        category: "Chips",
        description:
            "<p>Buy 2 BioSteel and receive 1 <strong>FREE</strong>! (various flavours - 500ml)&nbsp;</p>\n<p></p>\n<p>Valid while coupons last or by December 31, 2021. The coupon is only valid once during the entire promotion, for one use per person. No reduction granted if the value does not comply with the minimum price established by law. Prices are set exclusively by the retailer, who must respect the minimum price established by law. Offer valid at participating Couche-Tard in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse. Taxes are not included.</p>\n",
        tosLink: "",
    },
    {
        id: 691,
        title: "Cranberry Anti-Oxidant Mix",
        titleHighlight: "Free",
        type: "reward",
        brierleyRewardId: "123",
        subTitle: "",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/7ad0219b74b803.png",
        thumbnail: "",
        category: "Nuts",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        tosLink: "",
    },
];

export const FakeOffers: MobileOffer[] = [
    {
        id: 345,
        title: "Pink Frosted with Sprinkles",
        subTitle: "",
        brierleyOfferId: "123",
        thumbnail:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/4ad4c38b796612.png",
        image:
            "https://static-content.full-circle.thirdbridge.ca/uat/images/e97b80622e58e6.jpeg",
        category: "Donuts",
        description:
            "<p>With this coupon, you get <strong>$5 OFF on pack of 6 or 12 cans VIZZY Hard Seltzer (355 ML – Various Flavours).</strong></p>\n<p></p>\n<p>Benefit now in participating Couche-Tard stores!</p>\n<p></p>\n<p>Valid while supplies last, coupons or by April 26, 2021. The coupon is only valid once during the entire promotion, for one use per person. No reduction granted if the value does not comply with the minimum price established by law. Prices are set exclusively by the retailer, who must respect the minimum price established by law.</p>\n<p>Offer valid at participating Couche-Tard in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse.</p>\n<p>* Taxes and deposit not included.</p>\n<p>* Must be 21 years or older.</p>\n",
        type: "offer",
        tosLink: "",
        isNonTargeted: true,
        isAgeVerified: false,
    },
    {
        id: 680,
        title: "Circle K Original Potato Chips",
        brierleyOfferId: "123",
        subTitle:
            "Try today - For a limited time only. This reward is very special",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/563ef5464828ef.png",
        thumbnail: "",
        category: "Chips",
        description:
            "<p>Buy 2 BioSteel and receive 1 <strong>FREE</strong>! (various flavours - 500ml)&nbsp;</p>\n<p></p>\n<p>Valid while coupons last or by December 31, 2021. The coupon is only valid once during the entire promotion, for one use per person. No reduction granted if the value does not comply with the minimum price established by law. Prices are set exclusively by the retailer, who must respect the minimum price established by law. Offer valid at participating Couche-Tard in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse. Taxes are not included.</p>\n",
        type: "offer",
        tosLink: "",
        isNonTargeted: true,
        isAgeVerified: false,
    },
    {
        id: 691,
        title: "Cranberry Anti-Oxidant Mix",
        subTitle: "",
        brierleyOfferId: "123",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/7ad0219b74b803.png",
        thumbnail: "",
        category: "Nuts",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        type: "offer",
        tosLink: "",
        isNonTargeted: true,
        isAgeVerified: false,
    },

    {
        id: 692,
        title: "Cranberry Anti-Oxidant Mix",
        subTitle: "",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/7ad0219b74b803.png",
        thumbnail: "",
        brierleyOfferId: "123",
        category: "Nuts",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        type: "offer",
        tosLink: "",
        isNonTargeted: true,
        isAgeVerified: false,
    },
];

export const FakeClubs: Club[] = [
    {
        id: 1691,
        title: "Donut Club - Buy 5 Get the 6th Free",
        progress: 2,
        brierleyClubId: "123",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        emptyPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/9afb20f0245d4d.svg+xml",
        subTitle: "1 of every 3 plays win!",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/4ad4c38b796612.png",
        thumbnail: "",
        category: "Contest",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        type: "club",
        tosLink: "",
    },
    {
        id: 1692,
        title: "Hot Dog Club - Buy 5 Get the 6th Free",
        progress: 5,
        brierleyClubId: "123",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/870c05ea139743.svg+xml",
        emptyPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/5cfa33c6ce597b.svg+xml",
        subTitle: "1 of every 3 plays win!",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/7a8facce5f0f67.png",
        thumbnail: "",
        category: "Contest",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        type: "club",
        tosLink: "",
    },
    {
        id: 1693,
        title: "Donut Club - Buy 5 Get the 6th Free",
        progress: 3,
        brierleyClubId: "123",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        emptyPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/9afb20f0245d4d.svg+xml",
        subTitle: "1 of every 3 plays win!",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/4ad4c38b796612.png",
        thumbnail: "",
        category: "Contest",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        type: "club",
        tosLink: "",
    },
    {
        id: 1694,
        title: "Hot Dog Club - Buy 5 Get the 6th Free",
        progress: 4,
        brierleyClubId: "123",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/870c05ea139743.svg+xml",
        emptyPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/5cfa33c6ce597b.svg+xml",
        subTitle: "1 of every 3 plays win!",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/7a8facce5f0f67.png",
        thumbnail: "",
        category: "Contest",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        type: "club",
        tosLink: "",
    },
    {
        id: 1695,
        title: "Donut Club - Buy 5 Get the 6th Free",
        progress: 0,
        brierleyClubId: "123",
        filledPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        emptyPunchIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/9afb20f0245d4d.svg+xml",
        subTitle: "1 of every 3 plays win!",
        image:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/4ad4c38b796612.png",
        thumbnail: "",
        category: "Contest",
        description:
            "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
        type: "club",
        tosLink: "",
    },
];
