import React from "react";
import * as yup from "yup";
import { Spin, Modal } from "antd";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { setAutoFreeze } from "immer";

import style from "./style.module.scss";
import { useNavigation } from "../../utils/use-navigation";
import { useTranslationFormik } from "../../utils/use-translation-formik";
import { GalleryLabel, LoyaltyPayload } from "@full-circle-types";
import { Button } from "../../easyui-components";
import { SupportedLanguage } from "../../types";
import { isAppMultiLang } from "../../utils";
import { Permission } from "../../../../shared-utils/";
import { SideForm } from "./side-form";
import { AgvtLoyaltyStore } from "./agvt-store";
import { AGVT, CkLogo, TopHeaderPortal } from "../../components";
import {
    convertToForm,
    getTranslationSchema,
    getCoreSchema,
    INITIAL_VALUES,
} from "../loyalty-page/utils";
import {
    useCategoryStore,
    useGalleryStore,
    useLoyaltyStore,
    useAuthStore,
} from "../../contexts/mobx";

setAutoFreeze(false);

// Validation for the expected params
const YupParams = yup.object({
    action: yup.string().oneOf(["create", "edit", "duplicate"]),
    id: yup
        .string()
        .test("shouldBeDefined", "Id should be defined", function (v) {
            const action = this.resolve(yup.ref("action"));
            return !(v === undefined && action !== "create");
        })
        .notRequired(),
});

type Params = yup.InferType<typeof YupParams>;

export const LoyaltyPage = observer(function LoyaltyPage() {
    const loyaltyStore = useLoyaltyStore();
    const categoryStore = useCategoryStore();
    const galleryStore = useGalleryStore();
    const params = useParams<Params>();
    const navigation = useNavigation();
    const authStore = useAuthStore();

    const role = authStore.role;
    const { current: mobilePreviewStore } = React.useRef(
        new AgvtLoyaltyStore()
    );

    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);
    const [isFetching, setIsFetching] = React.useState<boolean>(
        params.action !== "create"
    );

    if (!YupParams.isValidSync(params)) {
        navigation.replaceToLoyaltyCreate();
    }

    const {
        selectedLangs,
        setSelectedLangs,
        formik,
        getFinalValue,
    } = useTranslationFormik<LoyaltyPayload>(
        INITIAL_VALUES,
        getTranslationSchema,
        getCoreSchema
    );

    React.useEffect(() => {
        categoryStore.fetchCategoryPreviews();
    }, [categoryStore]);

    React.useEffect(() => {
        galleryStore.fetchGalleryPreview("tos" as GalleryLabel);
    }, [galleryStore]);

    React.useEffect(() => {
        if (params.action === "edit") {
            setIsFetching(true);
            loyaltyStore
                .getSingleLoyalty(Number(params.id))
                .then((loyalty) => {
                    const langs = Object.keys(loyalty.translations);
                    const loyaltyPayload = convertToForm(loyalty);
                    setSelectedLangs(langs as SupportedLanguage[]);
                    formik.setValues(loyaltyPayload);
                })
                .catch(() => navigation.replaceToLoyaltyCreate())
                .finally(() => setIsFetching(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loyaltyStore]);

    React.useEffect(() => {
        mobilePreviewStore.syncWithForm(formik.values);
        mobilePreviewStore.navigation.replace({
            section: "inner-circle",
            data: {
                tab: formik.values.type,
            },
        });
    }, [formik.values, mobilePreviewStore]);

    React.useEffect(() => {
        mobilePreviewStore.syncWithCategories(categoryStore.categoryPreviews);
    }, [categoryStore.categoryPreviews, mobilePreviewStore]);

    const createLoyalty = async () => {
        setIsUpserting(true);
        const finalValue = getFinalValue();
        const success = await loyaltyStore.createLoyalty(
            finalValue as LoyaltyPayload
        );
        if (success) {
            navigation.replaceToLoyalties();
        }
        setIsUpserting(false);
    };

    const updateLoyalty = async () => {
        setIsUpserting(true);
        const finalValue = getFinalValue();
        await loyaltyStore.updateLoyalty(
            Number(params.id),
            finalValue as LoyaltyPayload
        );
        setIsUpserting(false);
    };

    const deleteLoyalty = async () => {
        Modal.confirm({
            title: "Are you sure you want to delete this Loyalty?",
            onOk: async () => {
                await loyaltyStore.delete(Number(params.id));
                navigation.replaceToLoyalties();
            },
        });
    };

    const canEdit = Permission.canEdit({
        role,
        enabled: false,
    });

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="loyalty">
                        LOYALTY {params.id}
                    </CkLogo>
                    <div className="nav_button_holder">
                        {isUpserting && <Spin />}
                        {params.action !== "create" ? (
                            <>
                                <Button
                                    disabled={
                                        isFetching || !canEdit || isUpserting
                                    }
                                    type="secondary"
                                    onClick={deleteLoyalty}
                                >
                                    Delete
                                </Button>
                                <Button
                                    disabled={
                                        isFetching ||
                                        !canEdit ||
                                        !formik.isValid ||
                                        isUpserting
                                    }
                                    type="primary"
                                    onClick={updateLoyalty}
                                >
                                    SAVE
                                </Button>
                            </>
                        ) : (
                            <Button
                                disabled={isFetching || !formik.isValid}
                                type="primary"
                                onClick={createLoyalty}
                            >
                                CREATE
                            </Button>
                        )}
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.container}>
                <div className={style.preview_holder}>
                    <AGVT store={mobilePreviewStore} />
                </div>

                <div className={style.side_form}>
                    {isFetching ? (
                        <div className={style.spinner_center}>
                            <Spin />
                        </div>
                    ) : (
                        <SideForm
                            formik={formik}
                            categories={categoryStore.categoryPreviews}
                            tos={galleryStore.previews}
                            isAppMultiLang={isAppMultiLang}
                            selectedLangs={selectedLangs}
                        />
                    )}
                </div>
            </div>
        </>
    );
});
