import React from "react";
import { observer } from "mobx-react";
import { useOktaAuth } from "@okta/okta-react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { SelectInfo } from "rc-menu/lib/interface";
import {
    PictureOutlined,
    LogoutOutlined,
    EnvironmentOutlined,
    HomeOutlined,
    PushpinOutlined,
    StarOutlined,
    TagsOutlined,
    RocketOutlined,
    LockOutlined,
} from "@ant-design/icons";

import style from "./style.module.scss";
import PromotionsIcon from "../../icons/promotionsIcon";
import { useNavigation } from "../../utils/use-navigation";
import { useAuthStore } from "../../contexts/mobx";
import {
    CategoriesPage,
    CategoryPage,
    PromotionsPage,
    PromotionPage,
    HomePreview,
    FeaturedPage,
    FeaturedSinglePage,
    StoresPage,
    StoreGroupsPage,
    GalleryPage,
    DealBucketsPage,
    DealBucketPage,
    LoyaltiesPage,
    LoyaltyPage,
    ServicesPage,
    ServicePage,
    AuthPage,
    StoreGroupExcelPage,
    TierBenefitsPage,
    ConfigsPage,
} from "../index";

const { REACT_APP_LATEST_SHORT_SHA } = process.env;

const items = [
    { label: "Home Preview", key: "home-preview", icon: <HomeOutlined /> },
    { label: "Featured", key: "featured", icon: <PushpinOutlined /> },
    { label: "Categories", key: "categories", icon: <TagsOutlined /> },
    { label: "Services", key: "services", icon: <RocketOutlined /> },
    {
        label: "Inner Circle",
        key: "inner-circle",
        icon: <StarOutlined />,
        children: [
            { label: "Loyalties", key: "loyalty" },
            { label: "Tier Benefits", key: "tier-benefit" },
        ],
    },
    {
        label: "Deals & cie",
        key: "deals-sub",
        icon: <PromotionsIcon />,
        children: [
            { label: "Deals", key: "promotions" },
            { label: "Buckets", key: "buckets" },
        ],
    },
    {
        label: "Stores",
        key: "store-sub",
        icon: <EnvironmentOutlined />,
        children: [
            { label: "Stores", key: "stores" },
            { label: "Store Groups", key: "store-groups" },
        ],
    },
    {
        label: "Gallery",
        key: "gallery",
        icon: <PictureOutlined />,
        children: [
            { label: "Other", key: "gallery-none" },
            { label: "Terms of Service", key: "gallery-tos" },
            { label: "Featured", key: "gallery-featured" },
            { label: "Services", key: "gallery-service" },
            { label: "Deals", key: "gallery-deals" },
            { label: "Loyalties", key: "gallery-loyalty" },
            { label: "Icons", key: "gallery-icon" },
        ],
    },
    { label: "Auth", key: "auth", icon: <LockOutlined /> },
    { label: "Log out", key: "log-out", icon: <LogoutOutlined /> },
];

export const HomePage = observer(() => {
    const location = useLocation();
    const navigation = useNavigation();
    const { oktaAuth } = useOktaAuth();
    const authStore = useAuthStore();
    const role = authStore.role;

    role === "developer" &&
        !items.find((item) => item.label === "Configs") &&
        items.splice(-1, 0, {
            label: "Configs",
            key: "configs",
            icon: <LockOutlined />,
        });

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location, role]);

    const onItemSelected = (param: SelectInfo) => {
        switch (param.key) {
            case "log-out":
                //authStore.setJwt(null);
                oktaAuth.signOut();
                break;
            case "promotions":
                navigation.goToPromotions();
                break;
            case "buckets":
                navigation.goToDealBuckets();
                break;
            case "categories":
                navigation.goToCategories();
                break;
            case "offers":
                navigation.goToOffers();
                break;
            case "home-preview":
                navigation.goToHomePreview();
                break;
            case "featured":
                navigation.goToFeaturedList();
                break;
            case "loyalty":
                navigation.goToLoyalties();
                break;
            case "tier-benefit":
                navigation.goToTierBenefits();
                break;
            case "stores":
                navigation.goToStores();
                break;
            case "store-groups":
                navigation.goToStoreGroups();
                break;
            case "gallery-none":
                navigation.goToGalleryNone();
                break;
            case "gallery-tos":
                navigation.goToGalleryTOS();
                break;
            case "gallery-featured":
                navigation.goToGalleryFeatured();
                break;
            case "gallery-service":
                navigation.goToGalleryService();
                break;
            case "gallery-deals":
                navigation.goToGalleryDeals();
                break;
            case "gallery-icon":
                navigation.goToGalleryIcon();
                break;
            case "gallery-loyalty":
                navigation.goToGalleryLoyalty();
                break;
            case "services":
                navigation.goToServices();
                break;
            case "auth":
                navigation.goToAuth();
                break;
            case "store-group-excel":
                navigation.goToStoreGroupExcel();
                break;
            case "configs":
                navigation.goToConfigs();
                break;
            default:
                navigation.goToHome();
        }
    };

    return (
        <div className={style.home_container}>
            <div id="__top-header-content" className={style.top_header}></div>

            <div className={style.sider}>
                <Menu
                    onSelect={onItemSelected}
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    items={items}
                />
                <div>{REACT_APP_LATEST_SHORT_SHA}</div>
            </div>
            <div className={style.content}>
                <Switch>
                    <Route path="/categories">
                        <CategoriesPage />
                    </Route>
                    <Route path="/category/:action/:id?">
                        <CategoryPage />
                    </Route>
                    <Route path="/gallery/:label">
                        <GalleryPage />
                    </Route>
                    <Route path="/promotions">
                        <PromotionsPage />
                    </Route>
                    <Route path="/home-preview">
                        <HomePreview />
                    </Route>
                    <Route path="/promotion/:action/:id?">
                        <PromotionPage />
                    </Route>
                    <Route path="/featured/:action/:id?">
                        <FeaturedSinglePage />
                    </Route>
                    <Route path="/featured">
                        <FeaturedPage />
                    </Route>
                    <Route path="/stores">
                        <StoresPage />
                    </Route>
                    <Route path="/store-groups">
                        <StoreGroupsPage />
                    </Route>
                    <Route path="/deal-buckets/:action/:id?">
                        <DealBucketPage />
                    </Route>
                    <Route path="/deal-buckets">
                        <DealBucketsPage />
                    </Route>
                    <Route path="/loyalty/:action/:id?">
                        <LoyaltyPage />
                    </Route>
                    <Route path="/loyalty">
                        <LoyaltiesPage />
                    </Route>
                    <Route path="/tier-benefit">
                        <TierBenefitsPage />
                    </Route>
                    <Route path="/service/:action/:id?">
                        <ServicePage />
                    </Route>
                    <Route path="/services">
                        <ServicesPage />
                    </Route>
                    <Route path="/auth">
                        <AuthPage />
                    </Route>
                    <Route path="/store-group-excel">
                        <StoreGroupExcelPage />
                    </Route>
                    <Route path="/configs">
                        <React.Suspense fallback={<div>Loading..</div>}>
                            <ConfigsPage />
                        </React.Suspense>
                    </Route>
                    <Route path="/">
                        <HomePreview />
                    </Route>
                </Switch>
            </div>
        </div>
    );
});
