import React from "react";
import { RichTextEditor } from "../rich-text-editor";
import { RichProvider } from "../rich-text-editor/helper";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { linkDecorator } from "../../components/rich-text-editor/helper";

interface Props {
    text: string;
    onChange: (text: string, name: string) => void;
    name: string;
}

const createEditorState = (text: string) => {
    const { contentBlocks, entityMap } = htmlToDraft(text);
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );
    return EditorState.createWithContent(contentState, linkDecorator);
};

export const createMarkup = (state: EditorState): string => {
    const hashConfig = {
        trigger: "#",
        separator: " ",
    };

    const rawContentState = convertToRaw(state.getCurrentContent());
    return draftToHtml(rawContentState, hashConfig);
};

export const RichText = React.memo((props: Props) => {
    const [editorState, setEditorState] = React.useState(
        createEditorState(props.text)
    );

    const currentMarkup = React.useRef<string>();

    // This is a little hack to only update the editor state when the change was
    // not created by the component it self
    React.useEffect(() => {
        if (props.text !== currentMarkup.current) {
            setEditorState(createEditorState(props.text));
        }
    }, [props.text]);

    return (
        <RichProvider>
            <RichTextEditor
                onEditorStateChange={(x) => {
                    setEditorState(x);
                    const markup = createMarkup(x);
                    currentMarkup.current = markup;
                    props.onChange(markup, props.name);
                }}
                editorState={editorState}
            />
        </RichProvider>
    );
});
