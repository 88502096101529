import React from "react";
import { GlowingColor, GlowingImageHolder, GlowingSpan } from "../glowing-hoc";
import style from "./style.module.scss";

interface Props {
    image: string;
    name: string;
    onClick?: () => void;
    glowConfiguration?: {
        image?: GlowingColor;
        name?: GlowingColor;
    };
    isSelected?: boolean;
}

export const DealBucketPreview = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {
        const classes = [style.container];

        if (props.isSelected) {
            classes.push(style.selected);
        }

        return (
            <div
                ref={ref}
                className={classes.join(" ")}
                onClick={() => props.onClick?.()}
            >
                <div className={style.image_container}>
                    <GlowingImageHolder
                        size={60}
                        src={props.image}
                        glowColor={props.glowConfiguration?.image}
                    />
                </div>
                <GlowingSpan
                    className={style.name}
                    glowColor={props.glowConfiguration?.name}
                >
                    {props.name.toUpperCase()}
                </GlowingSpan>
            </div>
        );
    }
);
