import React from "react";
import { observer } from "mobx-react";
import style from "./style.module.scss";
import { AGVT, GalleryPicker } from "../../components";
import { DemoMode } from "./glow-configurations";
import { SideNav } from "./side-nav";
import { AgvtDemoStore } from "./agvt-store";
import { Specs, MaxLengthSpec } from "./specs";

export const DocumentationPage = (): JSX.Element => {
    const { current: store } = React.useRef(new AgvtDemoStore());
    const [demoGalleryUrl, setDemoGalleryUrl] = React.useState<string | null>(
        null
    );

    return (
        <div className={style.page}>
            <div className={style.navigation}>
                <SideNav />
            </div>
            <div className={style.actual_content}>
                <div className={style.documentation_text}>
                    {/*
                        ██████  ███████  █████  ██      ███████ 
                        ██   ██ ██      ██   ██ ██      ██      
                        ██   ██ █████   ███████ ██      ███████ 
                        ██   ██ ██      ██   ██ ██           ██ 
                        ██████  ███████ ██   ██ ███████ ███████ 
                    */}
                    <h1 id="deal">Deals</h1>
                    <IH2 store={store} demoMode="deal-title">
                        Title
                    </IH2>
                    <p>The main title of the promotion</p>
                    <MaxLengthSpec max={255} />
                    <IH2 store={store} demoMode="deal-sub-title">
                        Sub Title
                    </IH2>
                    <p>A more detailed explanation about the promotion</p>
                    <MaxLengthSpec max={255} />
                    <IH2 store={store} demoMode="deal-image">
                        Image
                    </IH2>
                    <p>
                        The main image associated with the Deal. This image will
                        always be visible on the detailed Deal page.
                    </p>
                    <p>
                        This image will also be used on the Deal card{" "}
                        <b>if no Thumbnail is defined.</b>
                    </p>
                    <p>
                        The content of the Image Picker can be controlled on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/deals?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            Deal Gallery page.
                        </span>
                    </p>
                    <Specs>
                        • The image must be a square
                        <br />• The image size must be lower than 200kb
                    </Specs>
                    <IH2 store={store} demoMode="deal-thumbnail">
                        Thumbnail
                    </IH2>
                    <p>
                        The image displayed on the Deal cards.{" "}
                        <b>This is field is not required.</b> If not present,
                        the Image field will be used instead
                    </p>
                    <p>
                        The content of the Image Picker can be controlled on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/deals?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            Deal Gallery page.
                        </span>
                    </p>
                    <Specs>
                        • The image must be a square
                        <br />• The image size must be lower than 200kb
                    </Specs>

                    <IH2 store={store} demoMode="deal-category">
                        Category
                    </IH2>
                    <p>
                        The category is a logical an indicator field to help the
                        user know which type of deal he is looking at.
                    </p>
                    <IH2 store={store} demoMode="deal-expiration">
                        End Date / Start Date
                    </IH2>
                    <p>
                        The dates are set in your current timezone. They are
                        stored in UTC on the database
                    </p>
                    <p>
                        Those field give more control over the visibility of a
                        Deal. It will only be visible if the we are currently in
                        the period between the Start Date and End Date.
                    </p>
                    <p>
                        Also the End Date value is used to control the
                        expiration message visible on both Deal card and the
                        detailed Deal page.
                    </p>
                    <IH2 store={store} demoMode="deal-description">
                        Description
                    </IH2>
                    <p>
                        A longer description of the Deal. This information is
                        only visible on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                store.setDemoMode("deal-description");
                                store.pushInStoreDealDemo();
                            }}
                        >
                            detailed Deal page.
                        </span>
                    </p>
                    <MaxLengthSpec max="unlimited" />
                    <IH2 store={store} demoMode="deal-tos">
                        Terms of Service
                    </IH2>
                    <p>
                        This field will add clickable link in the detailed Deal
                        page. When clicking this link the user will be shown the
                        associated content. The redirection link is only visible
                        on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                store.setDemoMode("deal-tos");
                                store.pushInStoreDealDemo();
                            }}
                        >
                            detailed Deal page.
                        </span>
                    </p>
                    <p>
                        The content of the dropdown can be controller on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/tos?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            TOS Gallery page.
                        </span>
                    </p>
                    <IH2 store={store} demoMode="store-specials">
                        Is Visible On Home Page
                    </IH2>
                    <p>
                        When a deal is marked as <i>visible on home page</i>, it
                        will appears on the <i>Featured Deals</i> section. ( If
                        of course the deal is also available for the user's
                        store )
                    </p>
                    <IH2 store={store} id="enabled">
                        Enabled
                    </IH2>
                    <p>
                        Control if the deal should be visible or not at all in
                        the GMAP application. A Deal not enabled will not be
                        displayed anywhere in the app even if all the other
                        requirements are meet.
                    </p>
                    <IH2 store={store} demoMode="deal-type">
                        Type
                    </IH2>
                    <p>There is three different types of deal</p>
                    <p>
                        <b>In Store:</b> They are basic deal that the mobile
                        user only has to show to the cashier to claim. They
                        should be glowing in{" "}
                        <span className={style.material_blue_text}>blue</span>{" "}
                        if you activated the <i>Type</i> preview.
                    </p>
                    <p>
                        <b>Coupon:</b> They are a limited quantity Deal that the
                        user can only claim a fixed number of time. They have
                        slightly different Deal Card display and two extra
                        buttons on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                store.setDemoMode("deal-type");
                                store.pushCouponDealDemo();
                            }}
                        >
                            detailed Deal page
                        </span>
                        . They should be glowing in{" "}
                        <span className={style.material_red_text}>red</span> if
                        you activated the <i>Type</i> preview.
                        <br />
                        <br />
                        <span id="deal-coupon-reissue">
                            Coupons can be reissued if there is a problem on the
                            GMAP user side. To be able to reissue a coupon, the
                            CMS user can navigate to the specific coupon and
                            click on the button <b>Reissue Coupon</b> at the
                            bottom of the page. That button is only visible when
                            the deal type is a coupon and already created. The
                            Okta Id is used to identify the specific user who
                            needs to be reissued a coupon.
                        </span>
                        <br />
                        <br />
                        <Specs warning>
                            Coupons are using a static UPC Activation Code.{" "}
                            <b>
                                (Meaning the code wouldn't change from user to
                                user or uses to uses)
                            </b>{" "}
                            People would be able to screenshot the code, save
                            it, and use it another time.
                        </Specs>
                    </p>
                    <p>
                        <b>Contest:</b> They are Deal that redirect the mobile
                        user to an external partner. Visually, they look similar
                        to <i>In Store</i> Deal. The only difference is that
                        they have an extra button on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                store.setDemoMode("deal-type");
                                store.pushContestDealDemo();
                            }}
                        >
                            detailed Deal page
                        </span>
                        . The text of this button is controlled by the{" "}
                        <i>cta</i> field and the associated action by the{" "}
                        <i>externalUrl</i> field. They should be glowing in{" "}
                        <span className={style.material_green_text}>green</span>{" "}
                        if you activated the <i>Type</i> preview.
                    </p>
                    <IH2 store={store} demoMode="deal-age-restriction">
                        Age restriction
                    </IH2>
                    <p>
                        If the user has not confirmed his age, a custom display
                        will be shown and the user will need to confirm is age
                        if he want to see the deal.
                    </p>
                    <IH2 store={store} id="identity-verification">
                        Identity Verification
                    </IH2>
                    <p>
                        The user has to verify his identity in order to access
                        to those specific deals.
                    </p>
                    <IH2 store={store} demoMode="deal-buckets">
                        Deal Bucket
                    </IH2>
                    <p>Associate the Deal with a given Deal Bucket</p>
                    <IH2 store={store} id="deal-group-limitation">
                        Group Limitation
                    </IH2>
                    <p>
                        Input used to specify which group of users this deal
                        will be limited to.
                    </p>
                    <i>e.g. Employees and Sip & Save Users</i>
                    <IH2 store={store} id="deal-coupon-quantity">
                        Quantity
                    </IH2>
                    <p>
                        Input used to determine the amount of coupons available
                        <b> overall</b>.
                    </p>
                    <IH2 store={store} id="deal-coupon-activation-code">
                        Activation Code
                    </IH2>
                    <p>
                        Input used to determine the code shown under the barcode
                        (UPC) seen on the deal page.
                    </p>
                    <IH2 store={store} id="deal-coupon-max-activations-count">
                        Max Activations Count
                    </IH2>
                    <p>
                        Input used to determine the amount of coupons available
                        <b> per user</b>.
                    </p>
                    {/*
                        ███████ ███████  █████  ████████ ██    ██ ██████  ███████ ██████  
                        ██      ██      ██   ██    ██    ██    ██ ██   ██ ██      ██   ██ 
                        █████   █████   ███████    ██    ██    ██ ██████  █████   ██   ██ 
                        ██      ██      ██   ██    ██    ██    ██ ██   ██ ██      ██   ██ 
                        ██      ███████ ██   ██    ██     ██████  ██   ██ ███████ ██████  
                    */}
                    <h1 id="featured">Featured</h1>
                    <IH2 store={store} id="featured-enabled">
                        Enabled
                    </IH2>
                    <p>
                        Control if the Featured should be visible or not at all
                        in the GMAP application. A Featured not enabled will not
                        be displayed anywhere in the app even if all the other
                        requirements are meet.
                    </p>
                    <IH2 store={store} id="featured-inner-circle">
                        Inner Circle
                    </IH2>
                    <p>
                        Control if the Featured is related to the loyalties
                        inside Inner Circle or not.
                    </p>
                    <IH2 store={store} id="featured-position">
                        Position
                    </IH2>
                    <p>
                        The order in which the featured will appear on the GMAP
                        App.
                    </p>
                    <IH2 store={store} id="featured-expiration">
                        End Date / Start Date
                    </IH2>
                    <p>The dates are set in the UTC timezone.</p>
                    <p>
                        Those field give more control over the visibility of a
                        Featured. It will only be visible if the we are
                        currently in the period between the Start Date and End
                        Date.
                    </p>
                    <IH2 store={store} demoMode="featured-type">
                        Redirect to
                    </IH2>
                    <p>
                        Defined the action that should occurs when clicking on
                        the image
                    </p>
                    <ul>
                        <li>
                            <b>External Url</b>: When clicking on the the
                            Featured, an in-app browser will be used to show the
                            content. They should be glowing in{" "}
                            <span className={style.material_blue_text}>
                                blue
                            </span>{" "}
                            if you activated the <i>Type</i> preview.
                        </li>
                        <li>
                            <b>Deal</b>: When clicking on the the Featured, the
                            user will be redirect to the deal associated with
                            the featured. They should be glowing in{" "}
                            <span className={style.material_red_text}>red</span>{" "}
                            if you activated the <i>Type</i> preview.
                        </li>
                    </ul>
                    <IH2 store={store} id="featured-external-url">
                        External Url
                    </IH2>
                    <p>
                        The external url that will be used to populate the
                        in-app browser when the user click on the Featured.{" "}
                        <b>
                            This is only necessary if the redirection type is{" "}
                            <i>external url</i>.
                        </b>
                    </p>
                    <MaxLengthSpec max={255} />
                    <IH2 store={store} id="featured-is-full-screen">
                        Is Full Screen
                    </IH2>
                    <p>
                        This toggle will determine if we see the featured full
                        screen or not.
                    </p>
                    <IH2 store={store} id="featured-age-restriction">
                        Age restriction
                    </IH2>
                    <p>
                        If the user has not confirmed his age, a custom display
                        will be shown and the user will need to confirm is age
                        if he want to see the deal.
                    </p>
                    <IH2 store={store} id="featured-age-restriction">
                        Age restriction Type
                    </IH2>
                    <p>
                        As a Full-Circle user, I want to be able to type each
                        featured in a certain category.
                        <ul>
                            <li>
                                <b>Tobacco</b>
                            </li>
                            <li>
                                <b>Alcohol</b>
                            </li>
                            <li>
                                <b>Lottery</b>
                            </li>
                            <li>
                                <b>Other</b>
                            </li>
                        </ul>
                    </p>
                    <IH2 store={store} id="featured-identity-verification">
                        Identity Verification
                    </IH2>
                    <p>
                        The user has to verify his identity in order to access
                        to those specific deals.
                    </p>
                    <IH2 store={store} id="featured-authentication-type">
                        Authentication Type
                    </IH2>
                    <p>
                        The authentication type will be used to tell how GMAP
                        will authenticate the user using the featured.
                    </p>
                    <IH2 store={store} id="featured-authentication-name">
                        Authentication Name
                    </IH2>
                    <p>
                        The field name is used to describe the details of the
                        authentication type. when there is no authentication,
                        the field name will be empty.
                    </p>
                    <IH2 store={store} id="featured-deal">
                        Deal
                    </IH2>
                    <p>
                        The Deal that will be shown when the user click on the
                        Featured.{" "}
                        <b>
                            This is only necessary if the redirection type is{" "}
                            <i>deal</i>.
                        </b>
                    </p>
                    <IH2 store={store} id="featured-background-image">
                        Background Image
                    </IH2>
                    <p>The image associated with the Featured.</p>
                    <p>
                        {" "}
                        The content of the Image Picker can be controlled on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/featured?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            Featured Gallery page.
                        </span>
                    </p>
                    <Specs>
                        • The image must respect a 1.686 ratio ( 312x185,
                        624x370, etc )
                        <br />• The image size must be lower than 200kb
                    </Specs>
                    <IH2
                        store={store}
                        id="featured-cta"
                        demoMode="featured-cta"
                    >
                        Call To Action
                    </IH2>
                    <p>
                        The CTA (Call To Action) is the text rendered on the
                        button.
                    </p>
                    <MaxLengthSpec max={255} />
                    {/*
                        ██       ██████  ██    ██  █████  ██      ████████ ███████ ███████ 
                        ██      ██    ██  ██  ██  ██   ██ ██         ██    ██      ██      
                        ██      ██    ██   ████   ███████ ██         ██    █████   ███████ 
                        ██      ██    ██    ██    ██   ██ ██         ██    ██           ██ 
                        ███████  ██████     ██    ██   ██ ███████    ██    ███████ ███████ 
                    */}
                    <h1 id="loyalty">Loyalties</h1>
                    <p>
                        The Full-Circle CMS doesn't apply any logic for the
                        display of the Loyalties. This aspect is controlled by
                        NGRP / Brierley.
                    </p>
                    <img
                        alt="ngrp gmap full circle relation"
                        className={style.store_group_image}
                        src="https://lucid.app/publicSegments/view/eeca2d45-2d32-4c2e-a14b-4c8bd8c2a784/image.png"
                    />
                    <IH2 store={store} demoMode="loyalty-category">
                        Category
                    </IH2>
                    <p>
                        The category is a logical an indicator field to help the
                        user know which type of deal he is looking at.
                    </p>
                    <IH2 store={store} demoMode="loyalty-type">
                        Type
                    </IH2>
                    <p>
                        There is three different types of Loyalty. Each of them
                        is associated with a Brierley concept.
                    </p>
                    <ul>
                        <li>
                            <b>Club</b>: They represent a club in which the user
                            can engage to gain some reward by buying some
                            products.
                        </li>
                        <li>
                            <b>Offer</b>: They represent a general offer
                            available to each user of a given tiers ( member,
                            premium ).
                        </li>
                        <li>
                            <b>Reward</b>: They represent a reward a used has
                            won in a club.
                        </li>
                    </ul>
                    <IH2 store={store} id="loyalty-brierley-club-id">
                        Brierley Club Id
                    </IH2>
                    <p>The id of the associated Brierley club.</p>
                    <MaxLengthSpec max={255} />
                    <IH2
                        store={store}
                        id="loyalty-brierley-associated-reward-id"
                    >
                        Brierley Associated Reward Id
                    </IH2>
                    <p>
                        The id of the Brierley reward associated with the given
                        club.
                    </p>
                    <MaxLengthSpec max={255} />
                    <IH2 store={store} id="loyalty-brierley-reward-id">
                        Brierley Reward Id
                    </IH2>
                    <p>The id of the associated Brierley reward.</p>
                    <IH2 store={store} id="loyalty-brierley-offer-id">
                        Brierley Offer Id
                    </IH2>
                    <p>The id of the associated Brierley offer.</p>
                    <IH2 store={store} id="loyalty-age-verified-offer">
                        Age Verified Offer
                    </IH2>
                    <p>
                        Offers that are visible for all users that have verified
                        their age (identity).
                    </p>
                    <IH2 store={store} id="loyalty-non-targeted-offer">
                        Non Targeted Offer
                    </IH2>
                    <p>Offers that are visible for all users.</p>
                    <MaxLengthSpec max={255} />
                    <IH2 store={store} demoMode="loyalty-filled-icon">
                        Filled Icon
                    </IH2>
                    <p>The icon to display the progress in a given club</p>
                    <IH2 store={store} demoMode="loyalty-empty-icon">
                        Empty Icon
                    </IH2>
                    <p>
                        The icon to display the remaining progress in a given
                        club
                    </p>

                    <IH2 store={store} demoMode="loyalty-image">
                        Image
                    </IH2>
                    <p>
                        The main image associated with the Loyalty. This image
                        will always be visible on the detailed Loyalty page.
                    </p>
                    <p>
                        This image will also be used on the Loyalty card{" "}
                        <b>if no Thumbnail is defined.</b>
                    </p>
                    <p>
                        The content of the Image Picker can be controlled on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/loyalty?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            Loyalty Gallery page.
                        </span>
                    </p>
                    <Specs>
                        • The image must be a square
                        <br />• The image size must be lower than 200kb
                    </Specs>
                    <IH2 store={store} demoMode="loyalty-thumbnail">
                        Thumbnail
                    </IH2>
                    <p>
                        The image displayed on the Loyalty cards.{" "}
                        <b>This is field is not required.</b> If not present,
                        the Image field will be used instead
                    </p>
                    <p>
                        The content of the Image Picker can be controlled on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/loyalty?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            Loyalty Gallery page.
                        </span>
                    </p>
                    <Specs>
                        • The image must be a square
                        <br />• The image size must be lower than 200kb
                    </Specs>
                    <IH2 store={store} demoMode="loyalty-title">
                        Title
                    </IH2>
                    <p>The main title of the Loyalty</p>
                    <MaxLengthSpec max={255} />
                    <IH2 store={store} demoMode="loyalty-title-highlight">
                        Title Highlight
                    </IH2>
                    <p>
                        A flashy prefix that can be added in front of the title
                        for Reward.{" "}
                        <b>The length is limited to 10 characters.</b>
                    </p>
                    <MaxLengthSpec max={20} />
                    <IH2 store={store} demoMode="loyalty-sub-title">
                        Sub Title
                    </IH2>
                    <p>A more detailed explanation about the Loyalty</p>
                    <MaxLengthSpec max={255} />
                    {/* 
                        ██████  ███████  █████  ██            ██████  ██    ██  ██████ ██   ██ ███████ ████████ ███████ 
                        ██   ██ ██      ██   ██ ██            ██   ██ ██    ██ ██      ██  ██  ██         ██    ██      
                        ██   ██ █████   ███████ ██      █████ ██████  ██    ██ ██      █████   █████      ██    ███████ 
                        ██   ██ ██      ██   ██ ██            ██   ██ ██    ██ ██      ██  ██  ██         ██         ██ 
                        ██████  ███████ ██   ██ ███████       ██████   ██████   ██████ ██   ██ ███████    ██    ███████ 
                    */}
                    <h1 id="deal-bucket">Deal Buckets</h1>
                    <IH2 store={store} demoMode="deal-bucket-name">
                        Name
                    </IH2>
                    <p>The name of the Bucket.</p>
                    <MaxLengthSpec max={255} />
                    <IH2 store={store} demoMode="deal-bucket-icon">
                        Icons
                    </IH2>
                    <b>Focused Icon</b>
                    <p>
                        The focused icon is the one used when the user is
                        looking at the specific bucket page.
                    </p>
                    <b>Not Focused Icon</b>
                    <p>
                        The not focused icon is the one used when the user is
                        looking at another bucket page.
                    </p>
                    <Specs>
                        • The icon must be a square
                        <br />• The icon must be of type <b>svg</b>.
                    </Specs>

                    <IH2 store={store} demoMode="deal-buckets">
                        Explanation
                    </IH2>
                    <p>
                        Deal bucket are a grouping configuration used for the
                        Deal's display.{" "}
                        <b>A Deal can only belong to a single Deal Bucket</b>.
                    </p>
                    <p>
                        For example, in the demo-app there is currently 3 Deal
                        Buckets.
                    </p>
                    <ul>
                        <li>Summer Time</li>
                        <li>Beer and Chips</li>
                        <li>Energy Drink</li>
                    </ul>
                    <p>
                        Each of these Bucket contains a list of Deals. You can
                        interact with the demo app to see how Deal Bucket impact
                        the display.
                    </p>
                    {/*
                        ███████ ████████  ██████  ██████  ███████        ██████  ██████   ██████  ██    ██ ██████  ███████ 
                        ██         ██    ██    ██ ██   ██ ██            ██       ██   ██ ██    ██ ██    ██ ██   ██ ██      
                        ███████    ██    ██    ██ ██████  █████   █████ ██   ███ ██████  ██    ██ ██    ██ ██████  ███████ 
                             ██    ██    ██    ██ ██   ██ ██            ██    ██ ██   ██ ██    ██ ██    ██ ██           ██ 
                        ███████    ██     ██████  ██   ██ ███████        ██████  ██   ██  ██████   ██████  ██      ███████ 
                    */}
                    <h1 id="store-groups">Store Groups</h1>
                    <p>
                        Store Groups are a logical grouping of Stores <b>and</b>{" "}
                        content ( Deals and Featured ). They are used to control
                        the content displayed to the user based on his selected
                        store.
                    </p>
                    <ul>
                        <li>
                            A Store can be in <b>multiple</b> Store Groups
                        </li>
                        <li>
                            A content ( Deal and Featured ) can be in <b>one</b>{" "}
                            Store Group
                        </li>
                    </ul>
                    <p>Lets look at a concrete example!</p>
                    <img
                        alt="store groups structure"
                        className={style.store_group_image}
                        src="https://lucid.app/publicSegments/view/900d666e-cf61-4e1c-a8ae-6e2e6d4933d6/image.png"
                    />
                    <p>
                        In the scenario above, users would have three different
                        possibilities for content.
                    </p>
                    <ul>
                        <li>
                            User with <b>Store 1</b> would see the{" "}
                            <b>2/1 on Pepsi</b> deal and the{" "}
                            <b>Mountain Dew challenge</b> featured.
                        </li>
                        <li>
                            User with <b>Store 2</b> would see the{" "}
                            <b>2/1 on Pepsi</b> and <b>2$ off Car-Wash</b> deals
                            and the <b>Mountain Dew challenge</b> featured.
                        </li>
                        <li>
                            User with <b>Store 3</b> would see the{" "}
                            <b>2$ off Car-Wash</b> deal.
                        </li>
                    </ul>
                    <h3>
                        The <i>all</i> store group
                    </h3>
                    <p>
                        The CMS comes with a special store-group call All. Using
                        this store-group, you can easily assign a given concept
                        to every stores
                    </p>
                    <img
                        alt="all store group structure"
                        className={style.store_group_image}
                        src="https://lucid.app/publicSegments/view/b9e0b89b-b95c-48d4-a280-75b842998e52/image.png"
                    />
                    {/* 
                         ██████   █████  ██      ██      ███████ ██████  ██    ██ 
                        ██       ██   ██ ██      ██      ██      ██   ██  ██  ██  
                        ██   ███ ███████ ██      ██      █████   ██████    ████   
                        ██    ██ ██   ██ ██      ██      ██      ██   ██    ██    
                         ██████  ██   ██ ███████ ███████ ███████ ██   ██    ██    
                    */}
                    <h1 id="gallery">Gallery</h1>
                    <p>
                        The Gallery section of the CMS allow more control for
                        the content displayed inside Image picker in the CMS. So
                        for example, you might have see this image picker in the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open("/promotion/create", "blank");
                            }}
                        >
                            Deal page
                        </span>
                        :
                    </p>

                    <GalleryPicker
                        url={demoGalleryUrl}
                        onChange={(url) => setDemoGalleryUrl(url)}
                        onDelete={() => setDemoGalleryUrl(null)}
                    />
                    <p>
                        In this case, the content shown by the image picker is
                        editable in the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/deals?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            associated Gallery.
                        </span>
                    </p>
                    {/*  
                         ████████ ██ ███████ ██████      ██████  ███████ ███    ██ ███████ ███████ ██ ████████ 
                            ██    ██ ██      ██   ██     ██   ██ ██      ████   ██ ██      ██      ██    ██    
                            ██    ██ █████   ██████      ██████  █████   ██ ██  ██ █████   █████   ██    ██    
                            ██    ██ ██      ██   ██     ██   ██ ██      ██  ██ ██ ██      ██      ██    ██    
                            ██    ██ ███████ ██   ██     ██████  ███████ ██   ████ ███████ ██      ██    ██     
                    */}
                    <h1 id="tier-benefit">Tier Benefits</h1>
                    <p>
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open("/tier-benefit", "blank");
                            }}
                        >
                            The Tier Benefit page
                        </span>{" "}
                        is used to visualize all the different benefits
                        available depending on the users membership type.
                    </p>
                    {/* 
                        ███████ ███████ ██████  ██    ██ ██  ██████ ███████ 
                        ██      ██      ██   ██ ██    ██ ██ ██      ██      
                        ███████ █████   ██████  ██    ██ ██ ██      █████   
                             ██ ██      ██   ██  ██  ██  ██ ██      ██      
                        ███████ ███████ ██   ██   ████   ██  ██████ ███████ 
                     */}
                    <h1 id="services">Services</h1>
                    <p>
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open("/services", "blank");
                            }}
                        >
                            The Services page
                        </span>{" "}
                        is used to visualize the different types of services;
                        External Services or In App services
                    </p>
                    <IH2 store={store} demoMode="service-type">
                        In App
                    </IH2>
                    <br />
                    <p>
                        The In App type of service is a service developed and
                        provided by Circle K. You cannot create In App service,
                        they will already be created. The user will be able to
                        see them on the service page.
                    </p>
                    <IH2 store={store} id="service-background-image">
                        Background Image
                    </IH2>
                    <p>The image associated with the Service.</p>
                    <p>
                        The content of the Image Picker can be controlled on the{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open(
                                    "/gallery/service?page=0&perPage=10",
                                    "blank"
                                );
                            }}
                        >
                            Service Gallery page.
                        </span>
                    </p>
                    <Specs>
                        • The image must respect a 1.686 ratio ( 312x185,
                        624x370, etc )
                        <br />• The image size must be lower than 200kb
                    </Specs>
                    {/* 
                        ███████ ██████  ███████  ██████ ██ ███████ ██  ██████  █████  ████████ ██  ██████  ███    ██ ███████ 
                        ██      ██   ██ ██      ██      ██ ██      ██ ██      ██   ██    ██    ██ ██    ██ ████   ██ ██      
                        ███████ ██████  █████   ██      ██ █████   ██ ██      ███████    ██    ██ ██    ██ ██ ██  ██ ███████ 
                             ██ ██      ██      ██      ██ ██      ██ ██      ██   ██    ██    ██ ██    ██ ██  ██ ██      ██ 
                        ███████ ██      ███████  ██████ ██ ██      ██  ██████ ██   ██    ██    ██  ██████  ██   ████ ███████ 
                    */}
                    <h1 id="specification">Specifications</h1>
                    <h2 id="spec-form">Form</h2>
                    <p>
                        <span style={{ color: "#da291c" }}>∗</span> The previous
                        symbol indicates that a form field is required in order
                        to complete the form.
                    </p>
                    <p>
                        The forms you will have to fill to create or edit will
                        generally have controls at the top right of the page. If
                        the form is in a modal, the controls will be at the
                        bottom of the modal.
                    </p>
                    <h2 id="spec-image">Image</h2>
                    <p>
                        In this section we will present you the different
                        specifications regarding all the images you'll find on
                        the CMS.
                    </p>
                    <Specs>
                        • Image size: 200kb maximum
                        <br />• Icons can only be an SVG
                        <br />• Images can be JPEGs or PNGs
                        <br />• 1.88 is the ratio for{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open("/featured", "blank");
                            }}
                        >
                            Featured
                        </span>{" "}
                        and{" "}
                        <span
                            className={style.interactive_link}
                            onClick={() => {
                                window.open("/services", "blank");
                            }}
                        >
                            Service
                        </span>{" "}
                        images
                    </Specs>
                    <h2 id="spec-input">Input</h2>
                    <p>
                        The maximmum length for basic inputs is 255 characters.
                    </p>
                </div>
            </div>
            <div className={style.agvt}>
                <AGVT store={store} />
            </div>
        </div>
    );
};

const IH2 = observer(
    (props: {
        id?: string;
        demoMode?: DemoMode;
        children: string;
        store: AgvtDemoStore;
    }) => {
        const { store } = props;
        const classes = [style.interactive_h2];

        if (props.demoMode === store.demoMode) {
            classes.push(style.is_glowing);
        }
        return (
            <h2
                id={props.demoMode ?? props.id}
                className={classes.join(" ")}
                onClick={() =>
                    props.demoMode &&
                    store.setDemoMode(
                        props.demoMode === store.demoMode
                            ? null
                            : props.demoMode
                    )
                }
            >
                {props.children}{" "}
                {props.demoMode && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                    >
                        <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 7.449-11.985 7.449c-7.18 0-12.015-7.449-12.015-7.449s4.446-6.551 12.015-6.551c7.694 0 11.985 6.551 11.985 6.551zm-7 .449c0-2.761-2.238-5-5-5-2.761 0-5 2.239-5 5 0 2.762 2.239 5 5 5 2.762 0 5-2.238 5-5z" />
                    </svg>
                )}
            </h2>
        );
    }
);
