import { observable, makeObservable, flow } from "mobx";

import { RootStore, MakeNetworkCall } from "./root";
import { DealBucketForm } from "models";
import { NotificationManager } from "../components";
import {
    DealBucket,
    DealBucketParams,
    DealBucketPreview,
    DealBucketList,
} from "@full-circle-types";

export type SearchParams = {
    perPage: number;
    page: number;
};

export class DealBucketStore {
    @observable paginatedList: DealBucketList | null = null;
    @observable isFetchingBuckets = false;
    @observable isFetchingPreviews = false;
    @observable previews: DealBucketPreview[] = [];

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    fetchDealBuckets = flow(function* (
        this: DealBucketStore,
        params: DealBucketParams
    ) {
        if (this.isFetchingBuckets) {
            return;
        }

        this.isFetchingBuckets = true;
        const response = yield this.rootStore.makeNetworkCall<DealBucketList>({
            method: "get",
            url: "/deal-buckets",
            params,
        });
        this.isFetchingBuckets = false;

        if (!response.err) {
            this.paginatedList = response.data;
        }
    });

    fetchPreviews = flow(function* (this: DealBucketStore) {
        if (this.isFetchingPreviews) {
            return;
        }

        this.isFetchingPreviews = true;
        const response = yield this.rootStore.makeNetworkCall<
            DealBucketPreview[]
        >({
            method: "get",
            url: "/deal-buckets/previews",
        });
        this.isFetchingPreviews = false;

        if (!response.err) {
            this.previews = response.data;
        }
    });

    fetchSingleBucket = flow(function* (
        this: DealBucketStore,
        id: number
    ): MakeNetworkCall<DealBucket, DealBucket> {
        const response = yield this.rootStore.makeNetworkCall({
            method: "get",
            url: `/deal-buckets/${id}`,
        });

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    });

    updateDealBucket = flow(function* (
        this: DealBucketStore,
        id: number,
        bucket: DealBucketForm
    ) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "put",
            url: `/deal-buckets/${id}`,
            data: bucket,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Deal bucket updated!");
        }
    });

    createDealBucket = flow(function* (
        this: DealBucketStore,
        bucket: DealBucketForm
    ) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "post",
            url: `/deal-buckets`,
            data: bucket,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Deal bucket created!");
        } else {
            throw response.err;
        }
    });

    deleteDealBucket = flow(function* (this: DealBucketStore, id: number) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/deal-buckets/${id}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Deal Bucket deleted!");
        }
    });
}
