import React from "react";
import { observer } from "mobx-react";
import { useAgvtStore } from "..";
import { GenericStorePicker } from "../generic-store-picker";
import { expirationText } from "../../standard-card";
import style from "./style.module.scss";
import moment from "moment";
import { ActivationZone } from "../activation-zone";
import { Button } from "../../../easyui-components/button";
import { GlowingSpan, GlowingColor, GlowingDiv } from "../../glowing-hoc";
import { MobileDeal } from "@full-circle-types";
import { AgvtState } from "../agvt-state";

export interface GlowingConfiguration {
    body?: GlowingColor;
    title?: GlowingColor;
    description?: GlowingColor;
    category?: GlowingColor;
    subTitle?: GlowingColor;
    expiration?: GlowingColor;
    image?: GlowingColor;
    tos?: GlowingColor;
    contestButton?: GlowingColor;
    couponButtons?: GlowingColor;
}

export type DealPageStore = Pick<
    AgvtState,
    "glowConfiguration" | "fetchDeal" | "couponManager" | "navigation"
>;

export const DealPage = observer(() => {
    const store = useAgvtStore() as DealPageStore;
    const deal: MobileDeal | undefined = store.navigation.currentPage.data.deal;

    const bucketName = store.navigation.currentPage.data.bucketName;
    const amountOfDays = moment(deal?.endDate).diff(moment(), "days");
    const glowConfiguration = store.glowConfiguration.dealPage;

    React.useEffect(() => {
        const dealId = store.navigation.currentPage.data.dealId;
        if (dealId) {
            store.fetchDeal({ dealId });
        }
    }, [store]);

    return (
        <div>
            <div
                className={`${style.sticky_header} ${style.bordered_header}`}
                onClick={() => store.navigation.pop()}
            >
                ← {bucketName}
            </div>
            {deal && (
                <>
                    <div className={style.image_holder}>
                        <img
                            className={style.deal_img}
                            src={deal.image ?? undefined}
                            alt="deal visual"
                        />
                        <GlowingDiv
                            glowColor={glowConfiguration?.image}
                            className={style.list_glower}
                        ></GlowingDiv>
                    </div>
                    <div className={style.page_content}>
                        <GlowingSpan
                            className={style.category}
                            glowColor={glowConfiguration?.category}
                        >
                            {deal.category}
                        </GlowingSpan>
                        <GlowingSpan
                            className={style.title}
                            glowColor={glowConfiguration?.title}
                        >
                            {deal.title}
                        </GlowingSpan>
                        {deal.subTitle && (
                            <GlowingSpan
                                className={style.subtitle}
                                glowColor={glowConfiguration?.subTitle}
                            >
                                {deal.subTitle}
                            </GlowingSpan>
                        )}
                        <GlowingSpan
                            className={style.subtitle}
                            glowColor={glowConfiguration?.expiration}
                        >
                            {expirationText(amountOfDays)}
                        </GlowingSpan>
                        <GlowingSpan className={style.nearest_store_label}>
                            View nearest participating stores ↓
                        </GlowingSpan>
                        <GlowingSpan
                            className={style.tos}
                            glowColor={glowConfiguration?.tos}
                        >
                            View terms & conditions
                        </GlowingSpan>
                        {deal.type === "coupon" &&
                            !store.couponManager.activatedCoupons.has(
                                deal.id
                            ) && (
                                <GlowingDiv
                                    glowColor={glowConfiguration?.couponButtons}
                                    className={style.coupon_buttons_holder}
                                >
                                    <Button
                                        type="secondary"
                                        onClick={() =>
                                            store.couponManager.showBottomDrawer(
                                                "coupon-activation",
                                                deal
                                            )
                                        }
                                    >
                                        USE COUPON
                                    </Button>
                                    {!!store.couponManager.couponList.find(
                                        (c) => c.id === deal.id
                                    ) ? (
                                        <Button
                                            type="secondary"
                                            onClick={() =>
                                                store.couponManager.removeCouponFromList(
                                                    deal
                                                )
                                            }
                                        >
                                            REMOVE COUPON
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                store.couponManager.addCouponToList(
                                                    deal
                                                )
                                            }
                                        >
                                            ADD COUPON
                                        </Button>
                                    )}
                                </GlowingDiv>
                            )}
                        {deal.type === "coupon" &&
                            store.couponManager.activatedCoupons.has(
                                deal.id
                            ) && (
                                <ActivationZone
                                    activationCode={"123445678990"}
                                    activationTime={
                                        store.couponManager.activatedCoupons.get(
                                            deal.id
                                        )!
                                    }
                                />
                            )}
                        {deal.type === "contest" && (
                            <GlowingDiv
                                glowColor={glowConfiguration?.contestButton}
                                className={style.contest_button_holder}
                            >
                                <Button type="primary" onClick={() => void 0}>
                                    {deal.cta}
                                </Button>
                            </GlowingDiv>
                        )}
                        <GlowingSpan
                            className={style.description}
                            dangerouslySetInnerHTML={{
                                __html: deal.description ?? "",
                            }}
                            glowColor={glowConfiguration?.description}
                        ></GlowingSpan>
                        <span className={style.nearest_store_label_large}>
                            NEAREST PARTICIPATING STORES
                        </span>
                    </div>
                    <div className={style.nearest_stores}>
                        <GenericStorePicker />
                        <GenericStorePicker />
                        <GenericStorePicker />
                    </div>
                </>
            )}
        </div>
    );
});
