import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

type SearchParamsType = {
    [key: string]: number | string | string[] | boolean | undefined;
};

export function useQueryParams<T extends SearchParamsType>() {
    const location = useLocation();
    const history = useHistory();
    const isFirstTime = useRef<boolean>(true);
    const [searchParams, setSearchParams] = React.useState(
        qs.parse(location.search, {
            parseBooleans: true,
            parseNumbers: true,
        })
    );

    React.useEffect(() => {
        // On the first invocation, the value is going to be exactly the same has the initial searchParams state.
        // This would cause a useless double fetching. So to fix this, we are going to ignore the first invocation
        // of the hook.
        if (!isFirstTime.current || (isFirstTime.current = false)) {
            setSearchParams(
                qs.parse(location.search, {
                    parseBooleans: true,
                    parseNumbers: true,
                })
            );
        }
    }, [location.search]);

    const updateParams = <Key extends keyof T>(values: {
        [key: string]: T[Key];
    }) => {
        const search = qs.stringify({
            ...searchParams,
            ...values,
        });
        history.push({
            pathname: location.pathname,
            search,
        });
    };

    return [searchParams as T, updateParams] as const;
}
