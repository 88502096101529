import React from "react";
import { Tag } from "antd";
import { PromotionTypes, LoyaltyType } from "./const";

export const getPromotionsTypeDisplay = (
    type: PromotionTypes
): JSX.Element | undefined => {
    if (type === PromotionTypes[0]) {
        return <Tag color="#004b87">{getPromotionsTypeName(type)}</Tag>;
    }
    if (type === PromotionTypes[1]) {
        return <Tag color="#6610f2">{getPromotionsTypeName(type)}</Tag>;
    }
    if (type === PromotionTypes[2]) {
        return <Tag color="#ed8b00">{getPromotionsTypeName(type)}</Tag>;
    }
};

export const getPromotionsTypeName = (
    type: PromotionTypes
): string | undefined => {
    if (type === "in_store") {
        return "In store";
    }
    if (type === "coupon") {
        return "Coupon";
    }
    if (type === "contest") {
        return "Contest";
    }
};

export const getLoyaltyTypeName = (type: LoyaltyType): string | undefined => {
    if (type === "club") {
        return "Club";
    }
    if (type === "reward") {
        return "Reward";
    }
    if (type === "offer") {
        return "Offer";
    }
};

export const getLoyaltyTypeDisplay = (
    type: LoyaltyType
): JSX.Element | undefined => {
    if (type === LoyaltyType[0]) {
        return <Tag color="#004b87">{getLoyaltyTypeName(type)}</Tag>;
    }
    if (type === LoyaltyType[1]) {
        return <Tag color="#6610f2">{getLoyaltyTypeName(type)}</Tag>;
    }
    if (type === LoyaltyType[2]) {
        return <Tag color="#ed8b00">{getLoyaltyTypeName(type)}</Tag>;
    }
};
