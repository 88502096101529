import React from "react";
import style from "./style.module.scss";
import { GlowingConfiguration } from "../advance-gmap-visualization-tool/loyalty-page";
import { GlowingDiv, GlowingSpan } from "../glowing-hoc";

interface Props {
    title?: string;
    progress: 0 | 1 | 2 | 3 | 4 | 5;
    filledIcon?: string;
    emptyIcon?: string;
    onClick?: () => void;
    glow?: GlowingConfiguration;
}

const lol = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export const ClubPreview = (props: Props): JSX.Element => {
    const classes = [style.club_preview];

    const completed = props.progress === 5;

    if (completed) {
        classes.push(style.completed);
    }

    return (
        <GlowingDiv
            className={classes.join(" ")}
            onClick={props.onClick}
            glowColor={props.glow?.club}
        >
            {completed && <span className={style.redeem_text}>REDEEM NOW</span>}
            <span className={style.title}>{props.title}</span>
            <div className={style.info_row}>
                <span>Expires in 10 days</span>
                {!completed && <span>{props.progress}/5</span>}
            </div>
            <ClubPreviewProgress {...props} />
        </GlowingDiv>
    );
};

export const ClubPreviewProgress = (props: {
    progress: 0 | 1 | 2 | 3 | 4 | 5;
    filledIcon?: string;
    emptyIcon?: string;
    glow?: GlowingConfiguration;
}): JSX.Element => {
    return (
        <div className={style.icons_row}>
            {lol.map((i) => {
                if (i % 2 === 0) {
                    return (
                        <GlowingSpan
                            glowColor={
                                props.progress > i / 2
                                    ? props.glow?.filledIcon
                                    : props.glow?.emptyIcon
                            }
                            key={i}
                        >
                            <img
                                src={
                                    props.progress > i / 2
                                        ? props.filledIcon
                                        : props.emptyIcon
                                }
                                alt="club icons"
                            />
                        </GlowingSpan>
                    );
                } else {
                    return <div key={i} className={style.line}></div>;
                }
            })}
        </div>
    );
};
