import React from "react";
import { observer } from "mobx-react";
import { ColumnProps } from "antd/es/table";
import { Tag, Table } from "antd";
import { Pagination, CkLogo, TopHeaderPortal } from "../../components";
import { useServiceStore } from "../../contexts/mobx";
import { useQueryParams } from "../../utils/use-query-params";
import { useNavigation } from "../../utils/use-navigation";
import { ServiceCmsDto, GetAllServicesQuery } from "@full-circle-types";
import style from "./style.module.scss";

const renderIsActive = (_: unknown, record: ServiceCmsDto) => {
    return (
        <div>
            {record.isEnabled ? (
                <Tag color="#87d068">Enabled</Tag>
            ) : (
                <Tag color="#ee1313">Disabled</Tag>
            )}
        </div>
    );
};

export const ServicesPage = observer(() => {
    const store = useServiceStore();
    const navigation = useNavigation();
    const [queryParams, updateQueryParams] = useQueryParams<
        GetAllServicesQuery
    >();

    const columns: ColumnProps<ServiceCmsDto>[] = [
        {
            title: "Status",
            key: "is_active",
            render: renderIsActive,
            width: "10%",
        },
        {
            title: "In-App Service",
            key: "in_app_service",
            render: (_, record) => <span>{record.inAppService}</span>,
            width: "90%",
        },
    ];

    React.useEffect(() => {
        store.fetchStores({});
    }, [store]);

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="services">
                        SERVICES
                    </CkLogo>
                </div>
            </TopHeaderPortal>
            <div className={style.services_page}>
                <Pagination
                    params={queryParams}
                    meta={store.paginatedList}
                    onChange={(pageNumber, pageSize) =>
                        updateQueryParams({
                            page: pageNumber,
                            perPage: pageSize,
                        })
                    }
                    onPageSizeChange={(size) =>
                        updateQueryParams({ perPage: size })
                    }
                />
                <Table<ServiceCmsDto>
                    scroll={{ x: 900 }}
                    rowKey="id"
                    className={`${style.table} clickable_table`}
                    dataSource={store.paginatedList?.results}
                    columns={columns}
                    loading={store.isFetchingServicesCount > 0}
                    pagination={false}
                    onRow={(r) => ({
                        onClick: () => navigation.goToServiceEdit(r.id),
                    })}
                />
            </div>
        </>
    );
});
