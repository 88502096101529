import React from "react";

export const IconWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return (
        <div
            style={{
                width: "auto",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "4px",
            }}
        >
            {props.children}
        </div>
    );
};
