import React from "react";
import useCollapse from "react-collapsed";
import style from "./style.module.scss";

export const SideNav = (): JSX.Element => {
    const {
        getCollapseProps: dealCollapseProps,
        getToggleProps: dealToggleProps,
    } = useCollapse({});

    const {
        getCollapseProps: featuredCollapseProps,
        getToggleProps: featuredToggleProps,
    } = useCollapse();

    const {
        getCollapseProps: loyaltyCollapseProps,
        getToggleProps: loyaltyToggleProps,
    } = useCollapse();

    const {
        getCollapseProps: bucketCollapseProps,
        getToggleProps: bucketToggleProps,
    } = useCollapse();

    const {
        getCollapseProps: specificationCollapseProps,
        getToggleProps: specificationToggleProps,
    } = useCollapse();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        const id = (event.target as HTMLElement).id;

        if (id.startsWith("link__")) {
            window.location.href = `#${id.substring(6)}`;
        }
    };

    return (
        <div className={style.side_nav} onClick={handleClick}>
            <h1 {...dealToggleProps()}>Deals</h1>
            <div {...dealCollapseProps()}>
                <h6 id="link__deal-title">Title</h6>
                <h6 id="link__deal-sub-title">Sub Title</h6>
                <h6 id="link__deal-image">Image</h6>
                <h6 id="link__deal-thumbnail">Thumbnail</h6>
                <h6 id="link__deal-category">Category</h6>
                <h6 id="link__deal-expiration">End Date / Start Date</h6>
                <h6 id="link__deal-description">Description</h6>
                <h6 id="link__deal-tos">Terms of Service</h6>
                <h6 id="link__deal-group-limitation">Group Limitations</h6>
            </div>
            <h1 {...featuredToggleProps()}>Featured</h1>
            <div {...featuredCollapseProps()}>
                <h6 id="link__featured-type">Redirect To</h6>
                <h6 id="link__featured-position">Position</h6>
                <h6 id="link__featured-external-url">External Url</h6>
                <h6 id="link__featured-deal">Deal</h6>
                <h6 id="link__featured-background-image">Background Image</h6>
                <h6 id="link__featured-cta">Call To Action</h6>
            </div>
            <h1 {...loyaltyToggleProps()}>Loyalties</h1>
            <div {...loyaltyCollapseProps()}>
                <h6 id="link__loyalty-category">Category</h6>
                <h6 id="link__loyalty-type">Type</h6>
                <h6 id="link__loyalty-brierley-club-id">Brierley Club Id</h6>
                <h6 id="link__loyalty-brierley-associated-reward-id">
                    Brierley Associate Reward Id
                </h6>
                <h6 id="link__loyalty-brierley-reward-id">
                    Brierley Reward Id
                </h6>
                <h6 id="link__loyalty-brierley-offer-id">Brierley Offer Id</h6>
                <h6 id="link__loyalty-age-verified-offer">
                    Age Verified Offer
                </h6>
                <h6 id="link__loyalty-non-targeted-offer">
                    Non Targeted Offer
                </h6>
                <h6 id="link__loyalty-filled-icon">Filled Icon</h6>
                <h6 id="link__loyalty-empty-icon">Empty Icon</h6>
                <h6 id="link__loyalty-image">Image</h6>
                <h6 id="link__loyalty-thumbnail">Thumbnail</h6>
                <h6 id="link__loyalty-title">Title</h6>
                <h6 id="link__loyalty-title-highlight">Title Highlight</h6>
                <h6 id="link__loyalty-sub-title">Sub Title</h6>
            </div>
            <h1 {...bucketToggleProps()}>Deal Buckets</h1>
            <div {...bucketCollapseProps()}>
                <h6 id="link__deal-bucket-name">Name</h6>
                <h6 id="link__deal-bucket-icon">Icons</h6>
            </div>
            <h1 id="link__store-groups">Store Groups</h1>
            <h1 id="link__gallery">Gallery</h1>
            <h1 id="link__tier-benefit">Tier Benefits</h1>
            <h1 id="link__services">Services</h1>
            <h1 {...specificationToggleProps()}>Specifications</h1>
            <div {...specificationCollapseProps()}>
                <h6 id="link__spec-form">Form</h6>
                <h6 id="link__spec-image">Image</h6>
                <h6 id="link__spec-input">Input</h6>
            </div>
        </div>
    );
};
