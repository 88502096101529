import React from "react";
import style from "./style.module.scss";

export const HelpfulSpan = (props: {
    section?: string;
    children: React.ReactNode | string;
    className?: string;
}): JSX.Element => {
    return (
        <span className={`${style.helpful_span} ${props.className}`}>
            {props.children}
            {props.section && (
                <span
                    onClick={() =>
                        window.open(`/documentation#${props.section}`)
                    }
                >
                    ?
                </span>
            )}
        </span>
    );
};

export const HelpfulH1 = (props: {
    section?: string;
    children: string;
    className?: string;
}): JSX.Element => {
    const classes = [style.helpful_h1];
    if (props.className) {
        classes.push(props.className);
    }
    return (
        <h1 className={classes.join(" ")}>
            {props.children}
            {props.section && (
                <span
                    onClick={() =>
                        window.open(`/documentation#${props.section}`)
                    }
                >
                    ?
                </span>
            )}
        </h1>
    );
};
