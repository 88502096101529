import React from "react";
import * as yup from "yup";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

import style from "./style.module.scss";
import { useNavigation } from "../../utils/use-navigation";
import { CategoryForm } from "@full-circle-types";
import { useTranslationFormik } from "../../utils/use-translation-formik";
import {
    AssociatedDeals,
    LangSelector,
    CkLogo,
    TopHeaderPortal,
} from "../../components";
import { SupportedLanguage } from "../../types";
import { isAppMultiLang } from "../../utils";
import { Input, Button } from "../../easyui-components";
import {
    useCategoryStore,
    usePromotionStore,
    useLoyaltyStore,
} from "../../contexts/mobx";
import { AssociatedLoyalties } from "components/associated-elements";

// Validation for the expected params
const YupParams = yup.object({
    action: yup.string().oneOf(["create", "edit"]),
    id: yup
        .string()
        .test("shouldBeDefined", "Id should be defined", function (v) {
            const action = this.resolve(yup.ref("action"));
            return !(v === undefined && action !== "create");
        })
        .notRequired(),
});

type Params = yup.InferType<typeof YupParams>;

const translationSchema = yup.object({
    title: yup.string().required(),
});

const INITIAL_VALUES = {
    translations: {
        en: {
            title: "",
        },
    },
};

export const CategoryPage = observer(() => {
    const navigation = useNavigation();
    const params = useParams<Params>();
    const categoryStore = useCategoryStore();
    const promotionStore = usePromotionStore();
    const loyaltyStore = useLoyaltyStore();
    const [isFetching, setIsFetching] = React.useState<boolean>(
        params.action !== "create"
    );
    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);

    const {
        selectedLangs,
        setSelectedLangs,
        formik,
        getFinalValue,
    } = useTranslationFormik<CategoryForm>(
        INITIAL_VALUES,
        () => translationSchema
    );

    if (!YupParams.isValidSync(params)) {
        navigation.replaceToCategoryCreate();
    }

    React.useEffect(() => {
        if (params.action === "edit") {
            setIsFetching(true);
            const categoryId = Number(params.id);
            categoryStore
                .fetchCategory(categoryId)
                .then((cat) => {
                    const langs = Object.keys(cat.translations);
                    setSelectedLangs(langs as SupportedLanguage[]);
                    formik.setValues(cat);
                })
                .catch(() => navigation.replaceToCategoryCreate())
                .finally(() => setIsFetching(false));
            promotionStore.fetchForCategory(categoryId);
            loyaltyStore.fetchForCategory(categoryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createCategory = async () => {
        setIsUpserting(true);
        try {
            await categoryStore.createCategory(getFinalValue());
            navigation.replaceToCategories();
        } catch {
        } finally {
            setIsUpserting(false);
        }
    };

    const updateCategory = async () => {
        setIsUpserting(true);
        try {
            await categoryStore.updateCategory(
                getFinalValue(),
                Number(params.id)
            );
        } catch {
        } finally {
            setIsUpserting(false);
        }
    };

    const deleteCategory = async () => {
        setIsUpserting(true);
        try {
            await categoryStore.deleteCategory(Number(params.id));
        } catch {
        } finally {
            setIsUpserting(false);
            navigation.replaceToCategories();
        }
    };

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton>CATEGORY {params.id}</CkLogo>
                    <div className="nav_button_holder">
                        {params.action !== "create" ? (
                            <>
                                {isUpserting && (
                                    <>
                                        <Spin />{" "}
                                    </>
                                )}
                                <Button
                                    disabled={isFetching}
                                    type="secondary"
                                    onClick={deleteCategory}
                                >
                                    Delete
                                </Button>
                                <Button
                                    disabled={isFetching}
                                    type="primary"
                                    onClick={updateCategory}
                                >
                                    SAVE
                                </Button>
                            </>
                        ) : (
                            <Button
                                disabled={isFetching || !formik.isValid}
                                type="primary"
                                onClick={createCategory}
                            >
                                CREATE
                            </Button>
                        )}
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.category_page}>
                {isFetching ? (
                    <Spin />
                ) : (
                    <div>
                        {isAppMultiLang && (
                            <>
                                <span style={{ display: "block" }}>
                                    Language
                                </span>
                                <LangSelector
                                    langs={selectedLangs}
                                    onChange={(langs) =>
                                        setSelectedLangs(langs)
                                    }
                                />
                                <div className={style.spacer}></div>
                            </>
                        )}

                        <form className="small_form">
                            {selectedLangs.map((lang) => (
                                <div key={lang}>
                                    <div
                                        className={`${style.required} ${style.title}`}
                                    >
                                        Title {isAppMultiLang ? lang : ""}
                                    </div>
                                    <Input
                                        name={`translations.${lang}.title`}
                                        value={
                                            formik.values.translations[lang]
                                                ?.title ?? undefined
                                        }
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            ))}
                        </form>

                        {params.action !== "create" && (
                            <>
                                <AssociatedDeals
                                    loading={
                                        promotionStore.isFetchingForCategory
                                    }
                                    data={promotionStore.promotionsForCategory}
                                />

                                <AssociatedLoyalties
                                    loading={loyaltyStore.isFetchingForCategory}
                                    data={loyaltyStore.loyaltiesForCategory}
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
});
