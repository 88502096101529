import React from "react";
import { observer } from "mobx-react";
import { ColumnProps } from "antd/es/table";
import { Table } from "antd";

import style from "./style.module.scss";
import { Pagination, CkLogo, TopHeaderPortal } from "../../components";
import { useDealBucketStore } from "../../contexts/mobx";
import { SearchParams } from "../../stores/deal-bucket";
import { DealBucket } from "@full-circle-types";
import { useQueryParams } from "../../utils/use-query-params";
import { useNavigation } from "../../utils/use-navigation";
import { Button } from "../../easyui-components";

export const DealBucketsPage = observer(() => {
    const dealBucketStore = useDealBucketStore();
    const navigation = useNavigation();
    const [params, setParams] = useQueryParams<SearchParams>();

    React.useEffect(() => {
        dealBucketStore.fetchDealBuckets(params);
    }, [params, dealBucketStore]);

    const columns = React.useMemo<ColumnProps<DealBucket>[]>(
        () => [
            {
                title: "Name",
                dataIndex: "name",
                render: (_, record) => record.translations.en.name,
                width: "60%",
            },
            {
                title: "Focused Icon",
                dataIndex: "focusedIcon",
                render: (_, record) => (
                    <img
                        key="en"
                        src={record.focusedIcon}
                        alt=""
                        className={"thumbnail"}
                    />
                ),
                width: "20%",
            },
            {
                title: "Not Focused Icon",
                dataIndex: "notFocusedIcon",
                render: (_, record) => (
                    <img
                        key="en"
                        src={record.notFocusedIcon}
                        alt=""
                        className={"thumbnail"}
                    />
                ),
                width: "20%",
            },
        ],
        []
    );

    return (
        <>
            <TopHeaderPortal>
                <div className={"nav_header"}>
                    <CkLogo isBackButton section="deal-bucket">
                        DEAL BUCKETS
                    </CkLogo>
                    <div className={"nav_button_holder"}>
                        <Button
                            type="primary"
                            onClick={() => navigation.goToDealBucketCreate()}
                        >
                            CREATE NEW
                        </Button>
                    </div>
                </div>
            </TopHeaderPortal>

            <div className={style.deals_bucket_page}>
                <Pagination
                    className={style.pagination}
                    params={params}
                    meta={dealBucketStore.paginatedList}
                    onChange={(pageNumber, pageSize) =>
                        setParams({ page: pageNumber, perPage: pageSize })
                    }
                    onPageSizeChange={(size) => setParams({ perPage: size })}
                />
                <Table<DealBucket>
                    rowKey="id"
                    className={`${style.table} clickable_table`}
                    columns={columns}
                    scroll={{ x: 900 }}
                    dataSource={dealBucketStore.paginatedList?.results}
                    loading={dealBucketStore.isFetchingBuckets}
                    pagination={false}
                    onRow={(r) => ({
                        onClick: () => navigation.goToDealBucketEdit(r.id),
                    })}
                />
            </div>
        </>
    );
});
