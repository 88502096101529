import { useParams } from "react-router-dom";

interface IParams {
    action: "edit" | "create";
    id?: string;
}

interface IParamsWithDuplicate {
    action: "edit" | "create" | "duplicate";
    id?: string;
}

const validateParams = (
    params: any,
    options?: { allowDuplicate?: boolean }
) => {
    if (
        params.action === "edit" ||
        params.action === "create" ||
        (params.action === "duplicate" && options?.allowDuplicate)
    ) {
        if (params.action === "edit" || params.action === "duplicate") {
            return Boolean(params.id);
        }
        if (params.action === "create") {
            return !params.id;
        }
    }
    return false;
};

export const useGenericParams = <T extends { allowDuplicate?: boolean }>(
    onInvalidParams: () => void,
    options?: T
) => {
    const params = useParams<
        T extends { allowDuplicate: true } ? IParamsWithDuplicate : IParams
    >();
    if (!validateParams(params, options)) {
        onInvalidParams();
    }
    return params;
};
