import React from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { observer } from "mobx-react";
import { Select as EasySelect } from "../../easyui-components";

export interface DebounceSelectProps<T> {
    debounceTimeout?: number;
    placeholder?: string;
    onChange?: (id: number, content: T | undefined) => void;
    disabled?: boolean;
    tooltip?: string;
    value?: number;
    width?: number;
    getDisplay: (item: T) => JSX.Element;
    getValue: (item: T) => number;
    getPreviews: (q: string) => Promise<T[] | undefined>;
    findElement: (item: T[], id: number) => T | undefined;
}

export const DebounceSelectSingle = observer(function DebounceSelect<T>({
    debounceTimeout = 500,
    placeholder,
    onChange,
    disabled,
    tooltip,
    value,
    width = 300,
    getDisplay,
    getValue,
    getPreviews,
    findElement,
}: DebounceSelectProps<T>) {
    const [initialValue] = React.useState(String(value));
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<T[]>([]);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (q: string) => {
            setOptions([]);
            setFetching(true);

            getPreviews(q).then((newOptions) => {
                if (newOptions) {
                    setOptions(newOptions);
                }
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout]); // eslint-disable-line

    React.useEffect(() => {
        debounceFetcher(initialValue);
    }, [initialValue, debounceFetcher]);

    return (
        <EasySelect
            allowClear
            width={width}
            showSearch
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching && <Spin size="small" />}
            onChange={(id) => {
                const store = findElement(options, id);
                onChange?.(id, store);
            }}
            placeholder={placeholder}
            disabled={disabled}
            tooltip={tooltip}
            value={value}
        >
            {options?.map((content) => {
                return (
                    <Select.Option value={getValue(content)}>
                        {getDisplay(content)}
                    </Select.Option>
                );
            })}
        </EasySelect>
    );
});
