import { observable, makeObservable, flow } from "mobx";
import { RootStore } from "./root";
import { NotificationManager } from "../components";
import { WithNetworkConcurrency } from "./with-network-concurrency";
import { Config, ConfigPayload } from "@full-circle-types";

export class ConfigStore extends WithNetworkConcurrency {
    @observable isFetching = false;
    @observable isCreating = false;
    @observable config = "";

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    fetch = flow(function* (this: ConfigStore) {
        this.isFetching = true;

        const response = yield this.rootStore.makeNetworkCall<Config>({
            method: "get",
            url: "/configs",
        });

        this.isFetching = false;

        if (!response.err) {
            this.config = (response.data.config);
        }
    });

    create = flow(function* (this: ConfigStore, data: ConfigPayload) {
        const response = yield this.rootStore.makeNetworkCall({
            method: "post",
            url: `/configs`,
            data,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Configurations Updated!");
        }
    });
}
