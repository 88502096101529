import { observable, makeObservable, flow } from "mobx";
import { MakeNetworkCall, RootStore } from "./root";
import { WithNetworkConcurrency } from "./with-network-concurrency";
import {
    StoreList,
    StoreParams,
    StorePreview,
    StorePreviewParams,
} from "@full-circle-types";

export class StoresStore extends WithNetworkConcurrency {
    private storePreviewsTag = new WithNetworkConcurrency();
    @observable stores: StoreList | null = null;
    @observable storesPreview: StorePreview[] | null = null;
    @observable isFetchingStoresCount = 0;
    @observable isUpdatingStores = false;
    @observable isFetchingStoresPreview = 0;
    @observable isValidatingIds = false;
    @observable invalidIds: number[] = [];
    @observable isFetchingSimServices = false;
    @observable simServices: string[] = [];

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    fetchStores = flow(function* (this: StoresStore, params?: StoreParams) {
        const tag = this.getTag();

        this.isFetchingStoresCount++;

        const response = yield this.rootStore.makeNetworkCall<StoreList>({
            method: "get",
            url: "/stores",
            params,
        });

        this.isFetchingStoresCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.stores = response.data;
        }
    });

    fetchStoresPreview = flow(function* (
        this: StoresStore,
        params?: StorePreviewParams
    ): MakeNetworkCall<StorePreview[], StorePreview[] | undefined> {
        const tag = this.storePreviewsTag.getTag();

        this.isFetchingStoresPreview++;

        const response = yield this.rootStore.makeNetworkCall<StorePreview[]>({
            method: "get",
            url: "/stores/preview",
            params,
        });

        this.isFetchingStoresPreview--;

        if (!response.err && this.storePreviewsTag.isLatestTag(tag)) {
            this.storesPreview = response.data;
            return response.data;
        }
    });

    validateIds = flow(function* (this: StoresStore, ids: number[]) {
        if (this.isValidatingIds) {
            return;
        }

        this.isValidatingIds = true;
        const response = yield this.rootStore.makeNetworkCall<number[]>({
            method: "post",
            url: "/stores/validate",
            data: ids,
        });
        this.isValidatingIds = false;

        if (!response.err) {
            return (this.invalidIds = response.data);
        }
    });

    fetchSimServices = flow(function* (this: StoresStore) {
        if (this.isFetchingSimServices) {
            return;
        }

        this.isFetchingSimServices = true;
        const response = yield this.rootStore.makeNetworkCall<string[]>({
            method: "get",
            url: "/stores/sim-services",
        });
        this.isFetchingSimServices = false;

        if (!response.err) {
            this.simServices = response.data;
        }
    });
}
