import React from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { observer } from "mobx-react";

import { StoreGroupPreview } from "@full-circle-types";
import { Select as EasySelect } from "../../easyui-components";
import { useStoreGroupsStore } from "../../contexts/mobx";

export interface DebounceSelectProps {
    debounceTimeout?: number;
    placeholder?: string;
    onChange?: (storeGroups: number[]) => void;
    disabled?: boolean;
    tooltip?: string;
    value?: number[];
    width?: number;
}

export const DebounceSelectMultiple = observer(function DebounceSelect({
    debounceTimeout = 500,
    placeholder,
    onChange,
    disabled,
    tooltip,
    value,
    width = 300,
}: DebounceSelectProps) {
    const [initialValue] = React.useState(String(value));
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<StoreGroupPreview[]>([]);
    const storeGroupStore = useStoreGroupsStore();

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (q: string, ids?: number[]) => {
            setOptions([]);
            setFetching(true);

            storeGroupStore
                .fetchStoreGroupsPreview({
                    q: q === initialValue ? "" : q,
                })
                .then((newOptions) => {
                    if (newOptions) {
                        setOptions(newOptions);
                    }
                    setFetching(false);
                });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout, storeGroupStore, initialValue]);

    React.useEffect(() => {
        debounceFetcher(initialValue);
    }, [initialValue, debounceFetcher]);

    return (
        <EasySelect
            mode="multiple"
            allowClear
            width={width}
            showSearch
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching && <Spin size="small" />}
            onChange={(ids) => {
                onChange?.(ids);
            }}
            placeholder={placeholder}
            disabled={disabled}
            tooltip={tooltip}
            value={value}
        >
            {options?.map((storeGroup) => (
                <Select.Option value={storeGroup.id} key={storeGroup.id}>
                    {storeGroup.name}
                </Select.Option>
            ))}
        </EasySelect>
    );
});
