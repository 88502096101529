import React from "react";
import { Table, Select, Tooltip } from "antd";
import { Pagination } from "../../components";
import { observer } from "mobx-react";
import { ColumnProps } from "antd/es/table";
import { useStoresStore, useStoreGroupsStore } from "../../contexts/mobx";
import style from "./styles.module.scss";
import { useQueryParams } from "../../utils/use-query-params";
import { useDebounceEffect } from "../../utils/use-debounce-effect";
import {
    CkLogo,
    TopHeaderPortal,
    DebounceSelectSingle,
} from "../../components";
import {
    Input,
    Select as EasySelect,
    Highlighter,
} from "../../easyui-components";
import { Store, StoreParams, StoreGroupPreview } from "@full-circle-types";

export const StoresPage = observer(() => {
    const store = useStoresStore();
    const storeGroupsStore = useStoreGroupsStore();

    const [params, setParams] = useQueryParams<StoreParams>();

    const columns = React.useMemo<ColumnProps<Store>[]>(
        () => [
            {
                title: "Id",
                dataIndex: "simId",
                key: "simId",
                render: (_: unknown, record: Store) => (
                    <Highlighter search={params.q ?? ""}>
                        {record.simId.toString()}
                    </Highlighter>
                ),
                width: "10%",
            },
            {
                title: "Street",
                dataIndex: "street",
                render: (_: unknown, record: Store) => (
                    <Highlighter search={params.q ?? ""}>
                        {record.street}
                    </Highlighter>
                ),
                width: "30%",
            },
            {
                title: "City",
                dataIndex: "city",
                render: (_: unknown, record: Store) => (
                    <Highlighter search={params.q ?? ""}>
                        {record.city}
                    </Highlighter>
                ),
                width: "15%",
            },
            {
                title: "Postal Code",
                dataIndex: "postal-code",
                render: (_: unknown, record: Store) => (
                    <Highlighter search={params.q ?? ""}>
                        {record.postalCode}
                    </Highlighter>
                ),
                width: "15%",
            },
            {
                title: "State",
                dataIndex: "state",
                render: (_: unknown, record: Store) => (
                    <Highlighter search={params.q ?? ""}>
                        {record.state}
                    </Highlighter>
                ),
                width: "10%",
            },
            {
                title: "Country",
                dataIndex: "country",
                render: (_: unknown, record: Store) => (
                    <Highlighter search={params.q ?? ""}>
                        {record.country}
                    </Highlighter>
                ),
                width: "10%",
            },
            {
                title: "Services",
                dataIndex: "services",
                render: (_: unknown, record: Store) => (
                    <Tooltip
                        title={
                            <ul className={style.sim_service_list}>
                                {record.services?.map((s) => (
                                    <li key={s}>{s}</li>
                                ))}
                            </ul>
                        }
                    >
                        <span>
                            {record.services?.length ?? 0} service
                            {record.services?.length ? "s" : ""}
                        </span>
                    </Tooltip>
                ),
                width: "10%",
            },
        ],
        [params.q]
    );

    useDebounceEffect(
        () => void store.fetchStores(params),
        [params, store],
        200
    );

    React.useEffect(() => {
        storeGroupsStore.fetchStoreGroups();
    }, [storeGroupsStore]);

    React.useEffect(() => {
        store.fetchSimServices();
    }, [store]);

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton>STORES</CkLogo>
                    <div className="nav_button_holder"></div>
                </div>
            </TopHeaderPortal>
            <div className={style.stores_page}>
                <div className={style.query_box}>
                    <Input
                        value={params.q}
                        onChange={(e) =>
                            setParams({ q: e.target.value, page: 0 })
                        }
                        type="text"
                        allowClear={true}
                        placeholder="Search"
                    />
                    <DebounceSelectSingle<StoreGroupPreview>
                        placeholder="Store Group"
                        onChange={(id: number) =>
                            setParams({
                                storeGroupId: id,
                                page: 0,
                            })
                        }
                        value={params.storeGroupId}
                        getValue={(storeGroup: StoreGroupPreview) =>
                            storeGroup.id
                        }
                        getPreviews={(q: string) =>
                            storeGroupsStore.fetchStoreGroupsPreview({
                                q,
                                limit: 20,
                            })
                        }
                        getDisplay={(storeGroup: StoreGroupPreview) => (
                            <>{storeGroup.name}</>
                        )}
                        findElement={(
                            storeGroups: StoreGroupPreview[],
                            id: number
                        ) => storeGroups.find((sg) => sg.id === id)}
                    />
                    <EasySelect
                        width={200}
                        placeholder="SIM services"
                        defaultValue={undefined}
                        mode="multiple"
                        onChange={(services: string | string[]) =>
                            setParams({ simServices: services, page: 0 })
                        }
                        value={params.simServices}
                        allowClear
                        loading={store.isFetchingSimServices}
                    >
                        {store.simServices?.map((service) => (
                            <Select.Option value={service} key={service}>
                                {service}
                            </Select.Option>
                        ))}
                    </EasySelect>
                </div>
                <Pagination
                    className={style.pagination}
                    params={params}
                    meta={store.stores}
                    onChange={(pageNumber, pageSize) =>
                        setParams({ page: pageNumber, perPage: pageSize })
                    }
                    onPageSizeChange={(size) => setParams({ perPage: size })}
                />
                <Table<Store>
                    rowKey="id"
                    columns={columns}
                    scroll={{ x: 900 }}
                    dataSource={store.stores?.results}
                    loading={store.isFetchingStoresCount > 0}
                    pagination={false}
                />
            </div>
        </>
    );
});
