import React from "react";
import { observer } from "mobx-react";
import { Table, Tag, Select } from "antd";
import { ColumnProps } from "antd/es/table";

import style from "./style.module.scss";
import {
    useFeaturedStore,
    useStoreGroupsStore,
    useStoresStore,
} from "../../contexts/mobx";
import { useQueryParams } from "../../utils/use-query-params";
import { SearchParams } from "../../stores/featured";
import { useNavigation } from "../../utils/use-navigation";
import { FeatureTypeColors, ActiveQueryParam } from "../../utils/const";
import { Button, Select as EasySelect } from "../../easyui-components";
import {
    Featured,
    ExternalUrlFeatured,
    StorePreview,
    StoreGroupPreview,
} from "@full-circle-types";
import {
    ImageHolder,
    Pagination,
    DebounceSelectSingle,
    CkLogo,
    TopHeaderPortal,
} from "../../components";

const renderIsActive = (_: unknown, record: Featured) => {
    return (
        <div>
            {record.isActive ? (
                <Tag color="#87d068">Enabled</Tag>
            ) : (
                <Tag color="#ee1313">Disabled</Tag>
            )}
        </div>
    );
};

const renderImage = (_: unknown, record: Featured) => {
    return (
        <ImageHolder
            src={record.translations.en.backgroundImage ?? undefined}
            className="thumbnail"
            rectangle={true}
        />
    );
};

const renderType = (_: unknown, record: Featured) => {
    return (
        <div>
            <span color={FeatureTypeColors[record.type]} className="simple-tag">
                {record.type.replaceAll("_", " ")}
            </span>
            {record.type === "external_url" && (
                <div className="light_label lil_label lil_top_spacer">
                    {
                        (record as ExternalUrlFeatured).translations.en
                            .externalUrl
                    }
                </div>
            )}
        </div>
    );
};

export const FeaturedPage = observer(() => {
    const featuredStore = useFeaturedStore();
    const storeStore = useStoresStore();
    const storeGroupsStore = useStoreGroupsStore();
    const navigation = useNavigation();

    const columns: ColumnProps<Featured>[] = [
        {
            title: "Status",
            key: "is_active",
            render: renderIsActive,
            width: "10%",
        },
        {
            title: "Image",
            key: "image",
            render: renderImage,
            width: "20%",
        },
        {
            title: "Type",
            render: renderType,
        },
    ];

    const [params, updateParams] = useQueryParams<SearchParams>();

    React.useEffect(() => {
        featuredStore.getPaginatedList(params);
    }, [featuredStore, params]);

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="featured">
                        FEATURED
                    </CkLogo>
                    <div className="nav_button_holder">
                        <Button
                            type="primary"
                            onClick={() => navigation.goToFeaturedCreation()}
                        >
                            CREATE NEW
                        </Button>
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.featured_page}>
                <div className={`${style.selects_row} ${style.commands}`}>
                    <EasySelect<ActiveQueryParam>
                        placeholder="Status"
                        width={200}
                        value={params.filter}
                        allowClear={true}
                        onChange={(a: ActiveQueryParam) =>
                            updateParams({ filter: a, page: 0 })
                        }
                    >
                        {ActiveQueryParam.map((a) => (
                            <Select.Option value={a} key={a}>
                                {a === "active" ? "Enabled" : "Disabled"}
                            </Select.Option>
                        ))}
                    </EasySelect>
                    <DebounceSelectSingle<StoreGroupPreview>
                        disabled={!!params.storeId}
                        placeholder="Store Group"
                        onChange={(id: number) =>
                            updateParams({
                                storeGroupId: id,
                                page: 0,
                            })
                        }
                        value={params.storeGroupId}
                        tooltip={"Clear the Store input to select a Store"}
                        getValue={(storeGroup: StoreGroupPreview) =>
                            storeGroup.id
                        }
                        getPreviews={(q: string) =>
                            storeGroupsStore.fetchStoreGroupsPreview({
                                q: q === "undefined" ? "" : q,
                                limit: 20,
                            })
                        }
                        getDisplay={(storeGroup: StoreGroupPreview) => (
                            <>{storeGroup.name}</>
                        )}
                        findElement={(
                            storeGroups: StoreGroupPreview[],
                            id: number
                        ) => storeGroups.find((sg) => sg.id === id)}
                    />
                    <DebounceSelectSingle<StorePreview>
                        disabled={!!params.storeGroupId}
                        placeholder="Store"
                        onChange={(id: number, store?: StorePreview) =>
                            updateParams({ storeId: id, page: 0 })
                        }
                        value={params.storeId}
                        tooltip={
                            "Clear the Store Group input to select a Store"
                        }
                        getValue={(store: StorePreview) => store.simId}
                        getPreviews={(q: string) =>
                            storeStore.fetchStoresPreview({ q, limit: 20 })
                        }
                        getDisplay={(store: StorePreview) => (
                            <>
                                <span className={style.sim_highlight}>
                                    {store.simId}
                                </span>
                                {` - ${store.street} ${store.city} ${store.postalCode}`}
                            </>
                        )}
                        findElement={(stores: StorePreview[], id: number) =>
                            stores.find((store) => store.simId === id)
                        }
                    />
                </div>

                <Pagination
                    params={params}
                    meta={featuredStore.paginatedList}
                    onChange={(pageNumber, pageSize) =>
                        updateParams({
                            page: pageNumber,
                            perPage: pageSize,
                        })
                    }
                    onPageSizeChange={(size) => updateParams({ perPage: size })}
                />
                <Table<Featured>
                    rowKey="id"
                    className={`${style.table} clickable_table`}
                    dataSource={featuredStore.paginatedList?.results}
                    columns={columns}
                    loading={featuredStore.isFetchingList}
                    pagination={false}
                    onRow={(r) => ({
                        onClick: () => navigation.goToFeaturedEdit(r.id),
                    })}
                />
            </div>
        </>
    );
});
