import React from "react";
import { observer } from "mobx-react";
import { DealBucketsStorePicker } from "../deal-buckets-store-picker";
import { StandardCard } from "../../standard-card";
import { useAgvtStore } from "..";
import { GlowingDiv, GlowingSpan } from "../../glowing-hoc";

import style from "./style.module.scss";

export const DealBucketsPage = observer(() => {
    const store = useAgvtStore();

    React.useEffect(() => {
        store.fetchDealBuckets();
    }, [store]);

    const { glowConfiguration } = store;

    return (
        <div className={style.page}>
            <DealBucketsStorePicker />
            <div className={style.main}>
                {store.dealBuckets &&
                    store.dealBuckets.map((db) => (
                        <div key={db.id} className={style.section}>
                            <GlowingDiv
                                glowColor={glowConfiguration.dealBuckets}
                                className={style.list_header}
                            >
                                <GlowingSpan
                                    glowColor={
                                        glowConfiguration.dealBucket?.name
                                    }
                                >
                                    {db.name}
                                </GlowingSpan>
                                <span
                                    onClick={() =>
                                        store.navigation.push({
                                            section: "deal-bucket",
                                            parentSection: "deals",
                                            data: db,
                                        })
                                    }
                                >
                                    View All →
                                </span>
                            </GlowingDiv>
                            <div className={style.deal_list}>
                                {db.deals.map((d) => (
                                    <StandardCard
                                        glowingConfiguration={
                                            glowConfiguration.dealCard
                                        }
                                        onClick={() =>
                                            store.navigation.push({
                                                section: "deal",
                                                parentSection: "deals",
                                                data: {
                                                    deal: d,
                                                    bucketName:
                                                        "store specials",
                                                },
                                            })
                                        }
                                        key={d.id}
                                        content={StandardCard.transform(d)}
                                        cursorType="pointer"
                                        isCouponSelected={
                                            !!store.couponManager.couponList.find(
                                                (c) => c.id === d.id
                                            )
                                        }
                                        onAddCoupon={() =>
                                            store.couponManager.addCouponToList(
                                                d
                                            )
                                        }
                                        onRemoveCoupon={() =>
                                            store.couponManager.removeCouponFromList(
                                                d
                                            )
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
});
