import React from "react";
import { observer } from "mobx-react";
import { Modal, Spin, Select } from "antd";
import uniqBy from "lodash/uniqBy";
import * as yup from "yup";
import style from "./style.module.scss";
import moment from "moment";
import { useNavigation } from "../../utils/use-navigation";
import { useGenericParams } from "../../utils/use-generic-params";
import { NotificationManager } from "../../components/notification-manager";
import { FeaturedForm } from "../../models";
import { useTranslationFormik } from "../../utils/use-translation-formik";
import { SupportedLanguage } from "../../types";
import {
    Featured,
    DealPreview,
    FeaturedType,
    FeaturedAuthenticationType,
    AgeRestriction,
    AgeRestrictionType,
} from "@full-circle-types";
import { Permission } from "../../../../shared-utils/";
import { SideForm } from "./side-form";
import { AGVT, CkLogo, TopHeaderPortal } from "../../components";
import { AgvtFeaturedStore } from "./agvt-store";
import { Button, Select as EasySelect } from "../../easyui-components";
import {
    usePromotionStore,
    useStore,
    useAuthStore,
    useFeaturedStore,
} from "../../contexts/mobx";

const INITIAL_VALUES: FeaturedForm = {
    type: "deal",
    position: 0,
    isActive: false,
    isInnerCircle: false,
    isFullScreen: false,
    storeGroupIds: [],
    promotionId: null,
    requiresIdentityVerification: false,
    ageRestriction: "none",
    ageRestrictionType: null,
    authenticationType: "no_authentication",
    authenticationName: null,
    startDate: moment().startOf("day").toISOString(),
    endDate: moment().endOf("day").toISOString(),
    translations: {
        en: {
            textTitle: null,
            backgroundImage: null,
            cta: "",
        },
    },
};

const convertToForm = (featured: Featured): FeaturedForm => {
    const form = {
        ...featured,
        storeGroupIds: featured.storeGroups.map((sg) => sg.id),
    } as FeaturedForm;

    if (featured.type === "deal" && form.type === "deal") {
        form.promotionId = featured.dealPreview.id;
    }

    return form;
};

const translationSchema = yup.object({
    textTitle: yup.string().nullable(),
    backgroundImage: yup.string().required(),
    cta: yup.string().required().min(1),
});

const externalUrlTranslationSchema = translationSchema.shape({
    externalUrl: yup.string().required(),
});

const modalTranslationSchema = translationSchema.shape({
    value: yup.string().required(),
});

const getTranslationSchema = (form: FeaturedForm) => {
    if (form.type === "external_url") {
        return externalUrlTranslationSchema;
    } else if (form.type === "modal") {
        return modalTranslationSchema;
    } else {
        return translationSchema;
    }
};

const coreSchema = yup.object({
    isActive: yup.boolean().required(),
    isInnerCircle: yup.boolean().required(),
    isFullScreen: yup.boolean().required(),
    storeGroupIds: yup.array().of(yup.number()),
    requiresIdentityVerification: yup.boolean().required(),
    ageRestriction: yup.mixed<AgeRestriction>(),
    ageRestrictionType: yup.mixed<AgeRestrictionType>(),
    authenticationType: yup.mixed<FeaturedAuthenticationType>(),
    authenticationName: yup.string().when("authenticationType", {
        is: "no_authentication",
        then: yup.string().nullable(),
    }),
    type: yup.mixed<FeaturedType>(),
    position: yup.number().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
});

const dealSchema = coreSchema.shape({
    promotionId: yup.number().required(),
});

const getCoreSchema = (form: FeaturedForm) => {
    if (form.type === "deal") {
        return dealSchema;
    } else {
        return coreSchema;
    }
};

export const FeaturedSinglePage = observer(() => {
    const rootStore = useStore();
    const { current: mobilePreviewStore } = React.useRef(
        new AgvtFeaturedStore(rootStore)
    );
    const promotionStore = usePromotionStore();
    const featuredStore = useFeaturedStore();
    const authStore = useAuthStore();
    const navigation = useNavigation();
    const params = useGenericParams(() =>
        navigation.replaceToFeaturedCreation()
    );
    const [featured, setFeatured] = React.useState<Featured>();
    const [isUpserting, setIsUpserting] = React.useState<boolean>(false);
    const [isFetching, setIsFetching] = React.useState<boolean>(
        params.action !== "create"
    );

    const role = authStore.role;

    const {
        selectedLangs,
        setSelectedLangs,
        formik,
        getFinalValue,
    } = useTranslationFormik<FeaturedForm>(
        INITIAL_VALUES,
        getTranslationSchema,
        getCoreSchema
    );

    const canEdit = Permission.canEdit({
        role,
        enabled: formik.values.isActive,
    });

    React.useEffect(() => {
        if (params.action === "edit") {
            setIsFetching(true);
            rootStore
                .makeNetworkCall<Featured>({
                    method: "get",
                    url: `/featured/${params.id}`,
                })
                .then((response) => {
                    if (response.data) {
                        const form = convertToForm(response.data);
                        const langs = Object.keys(form.translations);
                        setSelectedLangs(langs as SupportedLanguage[]);
                        setFeatured(response.data);
                        formik.setValues(form);
                    } else {
                        navigation.replaceToFeaturedCreation();
                    }
                })
                .finally(() => setIsFetching(false));
        }
    }, [navigation, params.action, params.id, rootStore, setSelectedLangs]);

    React.useEffect(() => {
        mobilePreviewStore.syncWithForm(formik.values);
    }, [formik.values, mobilePreviewStore]);

    const [modalUrl, setModalUrl] = React.useState<string | undefined>();
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);

    const createFeatured = async () => {
        setIsUpserting(true);
        const response = await rootStore.makeNetworkCall({
            method: "POST",
            url: "/featured",
            data: getFinalValue(),
        });
        setIsUpserting(false);
        if (!response.err) {
            NotificationManager.showSuccess("Featured created");
            navigation.replaceToFeaturedList();
        }
    };

    const updateFeatured = async () => {
        setIsUpserting(true);
        const response = await rootStore.makeNetworkCall({
            method: "PUT",
            url: `/featured/${params.id}`,
            data: getFinalValue(),
        });
        setIsUpserting(false);
        if (!response.err) {
            NotificationManager.showSuccess("Featured updated");
        }
    };

    const deleteFeatured = async () => {
        Modal.confirm({
            title: "Are you sure you want to delete this featured?",
            onOk: async () => {
                await featuredStore.delete({
                    id: Number(params.id),
                });
                navigation.replaceToFeaturedList();
            },
        });
    };

    let dealList: DealPreview[] = promotionStore.promotionsForSelect.map(
        (promotion) => ({
            id: promotion.id,
            title: promotion.translations.en.title,
            image: promotion.translations.en.thumbnail ?? "",
        })
    );

    if (featured?.type === "deal") {
        dealList.push(featured.dealPreview);
        dealList = uniqBy(dealList, (d) => d.id);
    }

    const handleModalUrl = (url: string) => {
        setModalUrl(url);
    };

    const handleModalVisible = (state: boolean) => {
        setModalVisible(state);
    };

    const handleSelectedLangs = (langs: SupportedLanguage[]) => {
        setSelectedLangs(langs);
    };

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="featured">
                        FEATURED {params.id}
                    </CkLogo>
                    <div className="nav_button_holder">
                        {isUpserting && <Spin />}
                        {params.action !== "create" ? (
                            <>
                                <Button
                                    disabled={
                                        isFetching || !canEdit || isUpserting
                                    }
                                    type="secondary"
                                    onClick={deleteFeatured}
                                >
                                    Delete
                                </Button>
                                <Button
                                    disabled={
                                        isFetching ||
                                        !canEdit ||
                                        !formik.isValid ||
                                        isUpserting
                                    }
                                    type="primary"
                                    onClick={updateFeatured}
                                >
                                    SAVE
                                </Button>
                            </>
                        ) : (
                            <Button
                                disabled={isFetching || !formik.isValid}
                                type="primary"
                                onClick={createFeatured}
                            >
                                CREATE
                            </Button>
                        )}
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.container}>
                <Modal
                    visible={isModalVisible}
                    title="Image"
                    footer={null}
                    onCancel={() => setModalVisible(false)}
                >
                    <img
                        alt="thumbnail"
                        style={{ width: "100%" }}
                        src={modalUrl ?? ""}
                    />
                </Modal>
                <div className={style.preview_holder}>
                    <div className={style.display_mode_holder}>
                        <EasySelect
                            withWhiteBackground
                            width={150}
                            value={mobilePreviewStore.displayMode}
                            onChange={(value) =>
                                mobilePreviewStore.setDisplayMode(value)
                            }
                        >
                            <Select.Option value="featured-only">
                                Featured Only
                            </Select.Option>
                            <Select.Option value="fake-content">
                                Fake Content
                            </Select.Option>
                        </EasySelect>
                    </div>
                    <AGVT store={mobilePreviewStore} />
                </div>

                <div className={style.side_form}>
                    {isFetching ? (
                        <div className={style.spinner_center}>
                            <Spin />
                        </div>
                    ) : (
                        <SideForm
                            formik={formik}
                            deals={dealList}
                            langs={selectedLangs}
                            isFetching={isFetching}
                            setModalUrl={handleModalUrl}
                            setModalVisible={handleModalVisible}
                            setSelectedLangs={handleSelectedLangs}
                            params={params.action}
                        />
                    )}
                </div>
            </div>
        </>
    );
});
