import { MobileHomeContent, MobileDealBucket } from "@full-circle-types";

export const HomePage: MobileHomeContent = {
    dealBuckets: [
        {
            id: 14,
            name: "Summer Time",
            focusedIcon:
                "https://static-content.full-circle.thirdbridge.ca/dev/images/42dacc2143cb68.svg+xml",
            notFocusedIcon:
                "https://static-content.full-circle.thirdbridge.ca/dev/images/24d9f33261c48e.svg+xml",
        },
        {
            id: 15,
            name: "Hot Dog",
            focusedIcon:
                "https://static-content.full-circle.thirdbridge.ca/dev/images/870c05ea139743.svg+xml",
            notFocusedIcon:
                "https://static-content.full-circle.thirdbridge.ca/dev/images/5cfa33c6ce597b.svg+xml",
        },
        {
            id: 16,
            name: "Donut",
            focusedIcon:
                "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
            notFocusedIcon:
                "https://static-content.full-circle.thirdbridge.ca/dev/images/9afb20f0245d4d.svg+xml",
        },
    ],
    deals: [
        {
            id: 691,
            uid: 691,
            title: "Drop & Win",
            subTitle: "1 of every 3 plays win!",
            image:
                "https://static-content.full-circle.thirdbridge.ca/dev/images/cd0e97dc7ab25a.png",
            thumbnail: null,
            startDate: "2021-11-23T05:00:00.000Z",
            endDate: "2023-01-01T04:59:59.000Z",
            category: "Contest",
            ageRestriction: "none",
            ageRestrictionType: null,
            requiresIdentityVerification: false,
            description:
                "<p><strong>Celebrate the New Year with Circle K! Starting January 5th, visit win.circlek.com and play for your chance to win daily prizes! One in every three game plays win a prize!</strong></p>\n",
            type: "contest",
            externalUrl: "https://win.circlek.com/",
            cta: "Participate here",
            termsAndConditions: null,
            associatedOffer: null,
            groupLimitation: null,
        },
        {
            id: 345,
            uid: 345,
            title: "VIZZY",
            subTitle: "Get 5$ OFF the 6 or 12 cans VIZZY Hard Seltzer",
            image:
                "https://couchetard.s3.us-east-1.amazonaws.com/promotions/e0894794fc144c.png",
            thumbnail: null,
            startDate: "2021-04-06T04:00:00.000Z",
            endDate: "2023-01-01T04:45:50.000Z",
            category: "Alcomalt",
            ageRestriction: "21+",
            ageRestrictionType: "alcohol",
            requiresIdentityVerification: false,
            description:
                "<p>With this coupon, you get <strong>$5 OFF on pack of 6 or 12 cans VIZZY Hard Seltzer (355 ML – Various Flavours).</strong></p>\n<p></p>\n<p>Benefit now in participating Couche-Tard stores!</p>\n<p></p>\n<p>Valid while supplies last, coupons or by April 26, 2021. The coupon is only valid once during the entire promotion, for one use per person. No reduction granted if the value does not comply with the minimum price established by law. Prices are set exclusively by the retailer, who must respect the minimum price established by law.</p>\n<p>Offer valid at participating Couche-Tard in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse.</p>\n<p>* Taxes and deposit not included.</p>\n<p>* Must be 21 years or older.</p>\n",
            type: "coupon",
            maxActivationsCount: 1,
            termsAndConditions: null,
            activationsCount: null,
            associatedOffer: null,
            quantityRemaining: 600,
            activation: null,
            groupLimitation: null,
        },
        {
            id: 680,
            uid: 680,
            title: "BIOSTEEL",
            subTitle: "Buy 2 Biosteel drinks for the price of 1",
            image:
                "https://couchetard.s3.us-east-1.amazonaws.com/promotions/71fc77f0d95f29.png",
            thumbnail: null,
            startDate: "2021-11-24T21:39:04.000Z",
            endDate: "2023-01-01T04:45:00.000Z",
            category: "Sports Drink",
            ageRestriction: "none",
            ageRestrictionType: null,
            requiresIdentityVerification: false,
            description:
                "<p>Buy 2 BioSteel and receive 1 <strong>FREE</strong>! (various flavours - 500ml)&nbsp;</p>\n<p></p>\n<p>Valid while coupons last or by December 31, 2021. The coupon is only valid once during the entire promotion, for one use per person. No reduction granted if the value does not comply with the minimum price established by law. Prices are set exclusively by the retailer, who must respect the minimum price established by law. Offer valid at participating Couche-Tard in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse. Taxes are not included.</p>\n",
            type: "coupon",
            maxActivationsCount: 1,
            termsAndConditions: null,
            activationsCount: null,
            associatedOffer: null,
            quantityRemaining: 6500,
            activation: null,
            groupLimitation: null,
        },
        {
            id: 685,
            uid: 685,
            title: "OLD DUTCH CHIPS",
            subTitle: " 3$ for 3 bags",
            image:
                "https://couchetard.s3.us-east-1.amazonaws.com/promotions/a73cc81ee3a548.png",
            thumbnail: null,
            startDate: "2021-08-17T04:00:00.000Z",
            endDate: "2023-01-01T04:59:59.000Z",
            category: "Chips",
            ageRestriction: "none",
            ageRestrictionType: null,
            requiresIdentityVerification: false,
            description:
                "<p>With this coupon, you get 3 bags of Old Dutch chips for only $3 (55G-84G - various flavours).</p>\n<p></p>\n<p>Benefit now in participating Couche-Tard stores!</p>\n<p></p>\n<p>Valid while supplies last, coupons or by September 13, 2021. The coupon is only valid once during the entire promotion, for one use per person. Offer valid at participating Couche-Tard convenience stores in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse.</p>\n<p>* Taxes not included.</p>\n",
            type: "coupon",
            maxActivationsCount: 1,
            termsAndConditions: null,
            activationsCount: null,
            associatedOffer: null,
            quantityRemaining: 1000,
            activation: null,
            groupLimitation: null,
        },
    ],
    featured: [
        {
            id: 9,
            title: "Rock Paper Gifts!",
            image:
                "https://couchetard-dev.s3.ca-central-1.amazonaws.com/promotions/6d147c7f0cbb83.jpeg",
            externalUrl: "https://concourscouchetard.com/en",
            dealId: null,
            position: 0,
            type: "external_url",
            cta: "External cta",
            isInnerCircle: true,
            isFullScreen: false,
            requiresIdentityVerification: false,
            ageRestriction: "none",
            ageRestrictionType: null,
            authenticationType: "no_authentication",
            authenticationName: null,
            startDate: "2021-08-17T04:00:00.000Z",
            endDate: "2023-01-01T04:59:59.000Z",
            value: null,
        },
        {
            id: 10,
            title: "Big weekend",
            image:
                "https://static-content.full-circle.thirdbridge.ca/uat/images/a9e1282b0549e5.png",
            externalUrl: null,
            dealId: 699,
            position: 1,
            type: "deal",
            cta: "deal cta",
            isInnerCircle: true,
            isFullScreen: false,
            requiresIdentityVerification: false,
            ageRestriction: "none",
            ageRestrictionType: null,
            authenticationType: "no_authentication",
            authenticationName: null,
            startDate: "2021-08-17T04:00:00.000Z",
            endDate: "2023-01-01T04:59:59.000Z",
            value: null,
        },
    ],
    services: [
        {
            id: 1,
            inAppService: "pay_by_plate",
        },
    ],
};

export const DealBuckets: MobileDealBucket[] = [
    {
        id: 14,
        name: "Summer Time",
        focusedIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/42dacc2143cb68.svg+xml",
        notFocusedIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/24d9f33261c48e.svg+xml",
        deals: [
            {
                id: 679,
                uid: 679,
                title: "SELTZER DESTINATION",
                subTitle: "its raining Coors!",
                image:
                    "https://couchetard.s3.us-east-1.amazonaws.com/promotions/9d1484e217f186.jpeg",
                thumbnail: null,
                startDate: "2021-06-01T04:00:00.000Z",
                endDate: "2023-10-13T03:59:59.000Z",
                category: "Alcomalt",
                ageRestriction: "none",
                ageRestrictionType: null,
                requiresIdentityVerification: false,
                description:
                    "<p>Thirsty? We got you covered! Come check out our variety of Seltzer at one of our store. Promised, you will be well served.</p>\n<p></p>\n<p>18+. In participating Couche-Tards, depending on product availability or while stocks last.</p>\n",
                type: "in_store",
                termsAndConditions:
                    "https://static-content.full-circle.thirdbridge.ca/dev/html/190fce7e564170.html",
                associatedOffer: null,
                groupLimitation: null,
            },
            {
                id: 1679,
                uid: 1679,
                title: "SELTZER DESTINATION",
                subTitle: "its raining Coors!",
                image:
                    "https://couchetard.s3.us-east-1.amazonaws.com/promotions/9d1484e217f186.jpeg",
                thumbnail: null,
                startDate: "2021-06-01T04:00:00.000Z",
                endDate: "2023-10-13T03:59:59.000Z",
                category: "Alcomalt",
                ageRestriction: "21+",
                ageRestrictionType: "alcohol",
                requiresIdentityVerification: false,
                description:
                    "<p>Thirsty? We got you covered! Come check out our variety of Seltzer at one of our store. Promised, you will be well served.</p>\n<p></p>\n<p>18+. In participating Couche-Tards, depending on product availability or while stocks last.</p>\n",
                type: "in_store",
                termsAndConditions:
                    "https://static-content.full-circle.thirdbridge.ca/dev/html/190fce7e564170.html",
                associatedOffer: null,
                groupLimitation: null,
            },
        ],
    },
    {
        id: 15,
        name: "Beer and Chips",
        focusedIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/870c05ea139743.svg+xml",
        notFocusedIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/5cfa33c6ce597b.svg+xml",
        deals: [
            {
                id: 680,
                uid: 680,
                title: "BIOSTEEL",
                subTitle: "Buy 2 Biosteel drinks for the price of 1",
                image:
                    "https://couchetard.s3.us-east-1.amazonaws.com/promotions/71fc77f0d95f29.png",
                thumbnail: null,
                startDate: "2021-11-24T21:39:04.000Z",
                endDate: "2023-01-01T04:45:00.000Z",
                category: "Sports Drink",
                ageRestriction: "none",
                ageRestrictionType: null,
                requiresIdentityVerification: false,
                description:
                    "<p>Buy 2 BioSteel and receive 1 <strong>FREE</strong>! (various flavours - 500ml)&nbsp;</p>\n<p></p>\n<p>Valid while coupons last or by December 31, 2021. The coupon is only valid once during the entire promotion, for one use per person. No reduction granted if the value does not comply with the minimum price established by law. Prices are set exclusively by the retailer, who must respect the minimum price established by law. Offer valid at participating Couche-Tard in Quebec. The coupon must be presented to the cashier within 10 minutes of its issue. This offer cannot be combined with any other offer or promotion. Non-cash offer, non-transferable. The Couche-Tard representative reserves the right to refuse the coupon in its sole discretion. Couche-Tard may use any recourse available to it in case of fraud or abuse. Taxes are not included.</p>\n",
                type: "coupon",
                maxActivationsCount: 1,
                termsAndConditions: null,
                activationsCount: null,
                associatedOffer: null,
                quantityRemaining: 6482,
                activation: null,
                groupLimitation: null,
            },
            {
                id: 699,
                uid: 699,
                title: "Old and Dutch",
                subTitle: "Good chips",
                image:
                    "https://static-content.full-circle.thirdbridge.ca/dev/images/68d0dbc594766c.png",
                thumbnail: null,
                startDate: "2022-02-16T05:00:00.000Z",
                endDate: "2022-03-01T04:59:59.000Z",
                category: "Food",
                ageRestriction: "none",
                ageRestrictionType: null,
                requiresIdentityVerification: false,
                description: "<p>Those chips <em>are</em> great!</p>\n",
                type: "coupon",
                maxActivationsCount: 1,
                termsAndConditions: null,
                activationsCount: null,
                associatedOffer: null,
                quantityRemaining: 2,
                activation: null,
                groupLimitation: null,
            },
        ],
    },
    {
        id: 16,
        name: "Energy Drink",
        focusedIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/0f18b8f79e8fcc.svg+xml",
        notFocusedIcon:
            "https://static-content.full-circle.thirdbridge.ca/dev/images/9afb20f0245d4d.svg+xml",
        deals: [
            {
                id: 677,
                uid: 677,
                title: "SAVE ON FUEL",
                subTitle: "Save up to 12 cents per liter",
                image:
                    "https://couchetard.s3.us-east-1.amazonaws.com/promotions/9a4a7f5758ed99.jpeg",
                thumbnail: null,
                startDate: "2021-08-17T04:00:00.000Z",
                endDate: "2022-05-28T03:59:59.000Z",
                category: "Gas",
                ageRestriction: "none",
                ageRestrictionType: null,
                requiresIdentityVerification: false,
                description:
                    "<p>You save big when you fuel at Couche-Tard! You can save up to 12 cents per liter when you buy participating products. Take advantage of this offer!</p>\n<p></p>\n<p>- 2 BioSteel (various flavours, 500ml)</p>\n<p>- 2 Old Dutch (various flavours, 170g-200g)</p>\n<p>- 2 Gadoua bread (various flavours, 600g-675g)</p>\n<p>- 2 Vachon Cake Boxes (various flavours, 252g-336g)</p>\n<p></p>\n<p>Valid from August 17 to November 8, 2021. Offer valid at participating Couche-Tards, subject to product availability or while stocks last. Get a discount of 3 cents per liter (maximum of 65 liters) applicable on your next tank of gasoline with the purchase of a participating offer, up to a maximum of 12 cents a liter per transaction. Not valid when paying at the pump and has no cash value. Any reproduction is prohibited. This offer can be combined with your CAA or Irving Rewards card. Cannot be combined with any other offer. Certain conditions apply. The offer can be changed in any way time, without notice and vary by establishment. Taxes and deposit extra if applicable.</p>\n",
                type: "contest",
                externalUrl: "http://google.com",
                cta: "YOUR CTA",
                termsAndConditions:
                    "https://static-content.full-circle.thirdbridge.ca/dev/html/190fce7e564170.html",
                associatedOffer: null,
                groupLimitation: null,
            },
        ],
    },
];
