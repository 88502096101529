import { action, computed, observable, flow, makeAutoObservable } from "mobx";
import {
    StorePreview,
    MobileDeal,
    CategoryPreviewDto,
    DealBucketPreview,
    LoyaltyMobile,
    LoyaltyPayload,
} from "@full-circle-types";
import {
    AgvtState,
    CoreCouponManager,
    NavigationSection,
    InnerCircleNavigation,
} from "../../components/advance-gmap-visualization-tool";
import { HomePage } from "../promotion-page/dummy-content";

type AugmentedDeal = MobileDeal & {
    isVisibleOnHomePage: boolean;
    categoryId?: number;
    bucketId?: number;
    position: number;
};

type AugmentedLoyalty = LoyaltyMobile & {
    position: number;
    categoryId?: number;
};

const formToMobileLoyalty = (form: LoyaltyPayload) => {
    const isClub = form.type === "club";
    const isReward = form.type === "reward";
    const isOffer = form.type === "offer";

    return {
        id: -1,
        category: "category",
        categoryId: form.categoryId,
        title: form.translations.en.title,
        titleHighlight: form.translations.en.titleHighlight,
        subTitle: form.translations.en.subTitle,
        description: form.translations.en.description,
        tosLink: form.translations.en.tosLink,
        image: form.translations.en.image,
        thumbnail: form.translations.en.thumbnail,
        type: form.type,
        position: 0,

        emptyPunchIcon: isClub && form.emptyPunchIcon,
        filledPunchIcon: (isClub || isReward) && form.filledPunchIcon,
        brierleyClubId: isClub && form.brierleyClubId,
        brierleyRewardId: isReward && form.brierleyRewardId,
        brierleyOfferId: isOffer && form.brierleyOfferId,
    } as AugmentedLoyalty;
};

export type DisplayMode = "loyalty-only" | "fake-content";

export class AgvtLoyaltyStore implements AgvtState {
    @observable
    type: "inner-circle" | null = "inner-circle";

    @observable
    store: StorePreview = {
        id: 2709355,
        simId: 7900382,
        city: "ST-JEAN-SUR-RICHELIEU",
        street: "165 Boul Omer Marcil",
        postalCode: "J2W 0A3",
        state: "QC",
        country: "CA",
    };

    glowConfiguration = {};

    @observable
    trackedDeal: AugmentedDeal | null = null;

    @observable
    trackedLoyalty: AugmentedLoyalty | null = null;

    @observable.shallow
    availableCategories: CategoryPreviewDto[] = [];

    @observable.shallow
    availableDealBuckets: DealBucketPreview[] = [];

    @observable
    displayMode: DisplayMode = "loyalty-only";

    constructor(
        public couponManager = new CoreCouponManager(),
        public navigation = new InnerCircleNavigation({
            section: "inner-circle" as NavigationSection,
        })
    ) {
        makeAutoObservable(this);
    }

    @action
    setStore(store: StorePreview): void {
        // do nothing
    }

    @action
    syncWithForm(form: LoyaltyPayload): void {
        this.trackedLoyalty = formToMobileLoyalty(form);
        this.trackedLoyalty.category =
            this.availableCategories.find(
                (c) => c.id === this.trackedLoyalty?.categoryId
            )?.title || "category";
    }

    @action
    syncWithCategories(categories: CategoryPreviewDto[]): void {
        this.availableCategories = categories;
    }

    fetchHomeContent = flow(function* () {
        // do nothing
    });
    fetchDealBuckets = flow(function* () {
        // do nothing
    });
    fetchDeals = flow(function* () {
        // do nothing
    });
    fetchDeal = flow(function* () {
        // do nothing
    });

    @computed
    get homeContent() {
        const useFakeContent = this.displayMode === "fake-content";
        const fakeDeals = useFakeContent ? HomePage.deals ?? [] : [];
        const fakeBuckets = useFakeContent ? HomePage.dealBuckets : [];

        return {
            services: useFakeContent ? HomePage.services : [],
            featured: useFakeContent ? HomePage.featured : [],
            deals: fakeDeals,
            dealBuckets: fakeBuckets,
        };
    }

    @computed
    get loyalties(): AugmentedLoyalty[] | undefined {
        return this.trackedLoyalty ? [this.trackedLoyalty] : undefined;
    }

    deals = null;
    dealBuckets = null;
    isCouponListVisible = false;
}
