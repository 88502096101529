import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { enableMapSet } from "immer";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const {
    REACT_APP_SENTRY_DSN,
    REACT_APP_SENTRY_T_SAMPLE_RATE,
    REACT_APP_ENV,
} = process.env;

Sentry.init({
    environment: REACT_APP_ENV,
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: Number(REACT_APP_SENTRY_T_SAMPLE_RATE),
});


enableMapSet();

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
