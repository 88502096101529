import React from "react";
import { observer } from "mobx-react";
import style from "./style.module.scss";
import { useAgvtStore } from "..";

export const DealBucketsStorePicker = observer(() => {
    const store = useAgvtStore();
    return (
        <div className={style.store_picker}>
            <p className={style.title}>EXPLORE DEALS</p>
            <p className={style.sub_title}>You're browsing deals available</p>
            <p className={style.current_store}>{store.store?.street ?? ""}</p>
        </div>
    );
});
