import React from "react";
import { observer } from "mobx-react";
import { ReactSortable } from "react-sortablejs";
import { Modal, Spin } from "antd";

import style from "./style.module.scss";
import { CursorType } from "../../models";
import { useQueryParams } from "../../utils/use-query-params";
import { Button } from "../../easyui-components";
import { useTierBenefitStore } from "../../contexts/mobx";
import { CkLogo, FuelDiscount, TopHeaderPortal } from "../../components";
import { useAuthStore } from "../../contexts/mobx";
import { Permission } from "../../utils/permission";
import { EditAndCreateModal, TierBenefitRow } from "./parts";
import {
    GetTierBenefitsQuery,
    TierBenefitCmsDto,
    TierBenefitType,
} from "@full-circle-types";

export const TierBenefitsPage = observer(() => {
    const { role } = useAuthStore();
    const canEnable = Permission.canEnable({ role });

    const tierBenefitStore = useTierBenefitStore();
    const [cursorType, setCursorType] = React.useState<CursorType>("grab");
    const isSortingRef = React.useRef(false);
    const [isModalVisible, setIsModalVisible] = React.useState<
        false | "edit" | "create"
    >(false);
    const [currentTB, setCurrentTB] = React.useState<TierBenefitCmsDto | null>(
        null
    );
    const [queryParams, updateQueryParams] = useQueryParams<
        GetTierBenefitsQuery
    >();

    const showModal = (
        type: "edit" | "create",
        benefit?: TierBenefitCmsDto
    ) => {
        setCurrentTB(null);
        setIsModalVisible(type);
        if (benefit) setCurrentTB(benefit);
    };

    const updateOrderArray = (
        tierBenefits: TierBenefitCmsDto[],
        type: TierBenefitType
    ) => {
        if (!isSortingRef.current) return;
        isSortingRef.current = false;
        tierBenefitStore.setTierOrder(tierBenefits, type);
    };

    const onDelete = (tierBenefit: TierBenefitCmsDto) => {
        Modal.confirm({
            title: "Are you sure you want to delete this benefit?",
            onOk: async () => {
                await tierBenefitStore.delete(Number(tierBenefit.id));
                tierBenefitStore.getAll(queryParams);
            },
            okButtonProps: {
                disabled: !Permission.canEdit({
                    role,
                    enabled: tierBenefit.isEnabled,
                }),
            },
        });
    };

    const saveMemberOrder = () => {
        tierBenefitStore.order(
            tierBenefitStore.memberBenefits.map((ben) => Number(ben.id)),
            "member"
        );
    };
    const savePremiumOrder = () => {
        tierBenefitStore.order(
            tierBenefitStore.premiumBenefits.map((ben) => Number(ben.id)),
            "premium"
        );
    };

    React.useEffect(() => {
        tierBenefitStore.getAll(queryParams);
        tierBenefitStore.getFuel();
    }, [queryParams, tierBenefitStore]);

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="tier-benefit">
                        TIER BENEFITS
                    </CkLogo>
                    <div className="nav_button_holder">
                        <Button
                            type="primary"
                            onClick={() => {
                                setCurrentTB(null);
                                showModal("create");
                            }}
                        >
                            CREATE NEW
                        </Button>
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.container}>
                <div className={`${style.selects_row} ${style.commands}`}>
                    {tierBenefitStore.isFetchingList && <Spin />}
                    <div className={style.benefit_columns}>
                        {!tierBenefitStore.isFetchingList && (
                            <div className={style.single_column}>
                                <div className={style.type_header}>
                                    <h2 className="h4-header">Member</h2>

                                    {!tierBenefitStore.isMemberOrderSameSame(
                                        tierBenefitStore.memberBenefits
                                    ) && (
                                        <>
                                            <Button
                                                type="primary"
                                                onClick={saveMemberOrder}
                                            >
                                                Save Order
                                            </Button>
                                            {tierBenefitStore.isUpsertingTierBenefit && (
                                                <Spin />
                                            )}
                                        </>
                                    )}
                                </div>

                                <FuelDiscount tier="member" />

                                {tierBenefitStore.memberBenefits.length ===
                                0 ? (
                                    <p style={{ marginLeft: 15 }}>
                                        No Benefits
                                    </p>
                                ) : (
                                    <ReactSortable
                                        handle={".handle"}
                                        animation={100}
                                        className={style.list}
                                        list={tierBenefitStore.memberBenefits}
                                        setList={(newState) =>
                                            updateOrderArray(newState, "member")
                                        }
                                        onUpdate={() => {
                                            isSortingRef.current = true;
                                            tierBenefitStore.didMemberOrderChange = true;
                                        }}
                                        onChoose={() =>
                                            setCursorType("grabbing")
                                        }
                                        onUnchoose={() => setCursorType("grab")}
                                        forceFallback={true}
                                    >
                                        {tierBenefitStore.memberBenefits.map(
                                            (ben) => (
                                                <TierBenefitRow
                                                    key={ben.id}
                                                    tierBenefit={ben}
                                                    onDelete={() =>
                                                        onDelete(ben)
                                                    }
                                                    onEdit={() =>
                                                        showModal("edit", ben)
                                                    }
                                                    cursorType={cursorType}
                                                />
                                            )
                                        )}
                                    </ReactSortable>
                                )}
                            </div>
                        )}

                        {!tierBenefitStore.isFetchingList && (
                            <div className={style.single_column}>
                                <div className={style.type_header}>
                                    <h2 className="h4-header">Premium</h2>

                                    {!tierBenefitStore.isPremiumOrderSameSame(
                                        tierBenefitStore.premiumBenefits
                                    ) && (
                                        <>
                                            <Button
                                                type="primary"
                                                onClick={savePremiumOrder}
                                            >
                                                Save Order
                                            </Button>
                                            {tierBenefitStore.isUpsertingTierBenefit && (
                                                <Spin />
                                            )}
                                        </>
                                    )}
                                </div>

                                <FuelDiscount tier="premium" />

                                {tierBenefitStore.premiumBenefits.length ===
                                0 ? (
                                    <p style={{ marginLeft: 15 }}>
                                        No Benefits
                                    </p>
                                ) : (
                                    <ReactSortable
                                        handle={".handle"}
                                        animation={100}
                                        className={style.list}
                                        list={tierBenefitStore.premiumBenefits}
                                        setList={(newState) =>
                                            updateOrderArray(
                                                newState,
                                                "premium"
                                            )
                                        }
                                        onUpdate={() => {
                                            isSortingRef.current = true;
                                            tierBenefitStore.didPremiumOrderChange = true;
                                        }}
                                        onChoose={() =>
                                            setCursorType("grabbing")
                                        }
                                        onUnchoose={() => setCursorType("grab")}
                                        forceFallback={true}
                                    >
                                        {tierBenefitStore.premiumBenefits.map(
                                            (ben) => (
                                                <TierBenefitRow
                                                    key={ben.id}
                                                    tierBenefit={ben}
                                                    onDelete={() =>
                                                        onDelete(ben)
                                                    }
                                                    onEdit={() =>
                                                        showModal("edit", ben)
                                                    }
                                                    cursorType={cursorType}
                                                />
                                            )
                                        )}
                                    </ReactSortable>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <EditAndCreateModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    canEnable={canEnable}
                    currentTB={currentTB!}
                />
            </div>
        </>
    );
});
