import React from "react";
import { capitalize } from "lodash";
import { observer } from "mobx-react";
import { Select as AntdSelect } from "antd";

import { TierBenefitType, FuelUnits } from "@full-circle-types";
import { Button, Input, Select } from "../../easyui-components";
import { useTierBenefitStore } from "../../contexts/mobx";

import style from "./style.module.scss";

interface Props {
    tier: TierBenefitType;
}

export type FuelDiscountDetails = {
    description: string;
    unit: FuelUnits;
};

export const FuelDiscount = observer(
    (props: Props): JSX.Element => {
        const tierBenefitStore = useTierBenefitStore();

        const [fuelDiscount, setFuelDiscount] = React.useState<{
            member: FuelDiscountDetails;
            premium: FuelDiscountDetails;
        }>({
            member: {
                description: "",
                unit: "gal",
            },
            premium: {
                description: "",
                unit: "gal",
            },
        });

        const createFuelDiscount = (tier: TierBenefitType) => {
            tierBenefitStore.create({
                type: tier,
                lang: "en",
                isEnabled: true,
                position: 0,
                icon: "",
                description: fuelDiscount[tier].description,
                isFuelReward: true,
                unit: fuelDiscount[tier].unit || null,
            });
        };

        React.useEffect(() => {
            setFuelDiscount(tierBenefitStore.latestFuelBenefit);
        }, [tierBenefitStore.latestFuelBenefit]);

        return (
            <div className={style.fuel_container}>
                <h3>Fuel Discount</h3>
                <div className={style.form_container}>
                    <div className={style.inputs_container}>
                        <div className={style.description_input_container}>
                            <label
                                className={style.label}
                                htmlFor="description"
                            >
                                <span className={style.label_text}>
                                    Description
                                </span>
                                <Input
                                    name="description"
                                    width={100}
                                    type="string"
                                    placeholder="15 ¢"
                                    value={fuelDiscount[props.tier].description}
                                    onChange={(e) =>
                                        setFuelDiscount({
                                            ...fuelDiscount,
                                            [props.tier]: {
                                                ...fuelDiscount[props.tier],
                                                description: e.target.value,
                                            },
                                        })
                                    }
                                ></Input>
                            </label>
                        </div>
                        <label className={style.label} htmlFor="unit">
                            <span className={style.label_text}>Unit</span>
                            <Select
                                optionLabelProp="unit"
                                className={style.select}
                                width={100}
                                onChange={(val) => {
                                    setFuelDiscount({
                                        ...fuelDiscount,
                                        [props.tier]: {
                                            ...fuelDiscount[props.tier],
                                            unit: val,
                                        },
                                    });
                                }}
                                defaultValue="gal"
                                value={fuelDiscount[props.tier].unit}
                            >
                                <AntdSelect.Option value="gal">
                                    Galon
                                </AntdSelect.Option>
                                <AntdSelect.Option value="l">
                                    Liter
                                </AntdSelect.Option>
                            </Select>
                        </label>
                    </div>

                    <Button
                        className={style.input_spacer}
                        type="primary"
                        onClick={() => createFuelDiscount(props.tier)}
                    >
                        Save {capitalize(props.tier)} Discount
                    </Button>
                </div>
            </div>
        );
    }
);
