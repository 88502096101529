import { observable, action, toJS, makeObservable, flow } from "mobx";
import { MakeNetworkCall, RootStore } from "./root";
import { NotificationManager } from "../components";
import { WithNetworkConcurrency } from "./with-network-concurrency";
import {
    LoyaltyCmsDto,
    PaginatedLoyalty,
    LoyaltyParams,
    LoyaltyPayload,
} from "@full-circle-types";

export class LoyaltyStore extends WithNetworkConcurrency {
    @observable paginatedList: PaginatedLoyalty | null = null;
    @observable private cachedLoyalty: LoyaltyCmsDto | null = null;
    @observable loyaltiesForCategory: LoyaltyCmsDto[] = [];
    @observable isFetchingForCategory = false;
    @observable isUpsertingLoyalty = false;
    @observable isFetchingCount = 0;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    setCachedPromotion(this: LoyaltyStore, loyalty: LoyaltyCmsDto): void {
        this.cachedLoyalty = loyalty;
    }

    getPaginatedLoyalty = flow(function* (
        this: LoyaltyStore,
        params: LoyaltyParams
    ) {
        const tag = this.getTag();
        this.isFetchingCount++;
        const response = yield this.rootStore.makeNetworkCall<PaginatedLoyalty>(
            {
                method: "get",
                url: "/loyalties",
                params,
            }
        );
        this.isFetchingCount--;

        if (!response.err && this.isLatestTag(tag)) {
            this.paginatedList = response.data;
        }
    });

    getSingleLoyalty = flow(function* (
        this: LoyaltyStore,
        loyaltyId: number
    ): MakeNetworkCall<LoyaltyCmsDto, LoyaltyCmsDto> {
        if (loyaltyId === this.cachedLoyalty?.id) {
            return toJS(this.cachedLoyalty);
        }

        const response = yield this.rootStore.makeNetworkCall({
            method: "get",
            url: `/loyalties/${loyaltyId}`,
        });

        if (!response.err) {
            return response.data;
        } else {
            throw response.err;
        }
    });

    @action
    async fetchForCategory(
        this: LoyaltyStore,
        categoryId: number
    ): Promise<void> {
        this.isFetchingForCategory = true;
        const response = await this.rootStore.makeNetworkCall<LoyaltyCmsDto[]>({
            method: "get",
            url: `/loyalties/preview/${categoryId}`,
        });
        this.isFetchingForCategory = false;

        if (!response.err) {
            this.loyaltiesForCategory = response.data;
        }
    }

    @action
    async createLoyalty(
        this: LoyaltyStore,
        loyalty: LoyaltyPayload
    ): Promise<boolean> {
        this.isUpsertingLoyalty = true;

        const response = await this.rootStore.makeNetworkCall({
            method: "post",
            data: loyalty,
            url: `/loyalties`,
        });
        this.isUpsertingLoyalty = false;

        if (!response.err) {
            NotificationManager.showSuccess("Loyalty created");
        }

        return !response.err;
    }

    @action
    async updateLoyalty(
        this: LoyaltyStore,
        loyaltyId: number,
        loyalty: LoyaltyPayload
    ): Promise<boolean> {
        this.isUpsertingLoyalty = true;

        const response = await this.rootStore.makeNetworkCall({
            method: "put",
            data: loyalty,
            url: `/loyalties/${loyaltyId}`,
        });

        this.isUpsertingLoyalty = false;

        if (!response.err) {
            NotificationManager.showSuccess("Loyalty modified");
        }

        return !response.err;
    }

    @action
    async delete(this: LoyaltyStore, loyaltyId: number) {
        const response = await this.rootStore.makeNetworkCall({
            method: "delete",
            url: `/loyalties/${loyaltyId}`,
        });

        if (!response.err) {
            NotificationManager.showSuccess("Loyalty deleted");
        }

        return response;
    }
}
