import { action, computed, observable, flow, makeAutoObservable } from "mobx";
import { RootStore } from "../../stores/root";
import {
    StorePreview,
    MobileDeal,
    MobileDealBucket,
    MobileHomeContent,
} from "@full-circle-types";
import {
    AgvtState,
    NoInnerCircleNavigation,
    CoreCouponManager,
} from "../../components/advance-gmap-visualization-tool";

export class AgvtHomePreviewStore implements AgvtState {
    @observable.shallow
    dealBuckets: MobileDealBucket[] | null = null;

    @observable.shallow
    deals: MobileDeal[] | null = null;

    @observable
    store: StorePreview | null = null;

    @observable.shallow
    homeContent: MobileHomeContent | null = null;

    loyalties = undefined;

    constructor(
        private rootStore: RootStore,
        public navigation = new NoInnerCircleNavigation(),
        public couponManager = new CoreCouponManager()
    ) {
        makeAutoObservable(this);
    }

    @action
    setStore(store: StorePreview): void {
        this.store = store;

        if (this.navigation.currentPage.section === "home") {
            this.fetchHomeContent();
        } else if (this.navigation.currentPage.section === "deals") {
            this.fetchDealBuckets();
        }
    }

    fetchHomeContent = flow(function* (this: AgvtHomePreviewStore) {
        if (!this.store) {
            return;
        }
        const response = yield this.rootStore.makeMobileNetworkCall<
            MobileHomeContent
        >({
            method: "get",
            url: `/home?storeId=${this.store.simId}&includeStoreSpecials=true&includeInnerCircleFeatured=false`,
        });

        if (!response.err) {
            this.homeContent = response.data;
        }
    });

    fetchDealBuckets = flow(function* (this: AgvtHomePreviewStore) {
        if (!this.store) {
            return;
        }
        const response = yield this.rootStore.makeMobileNetworkCall<
            MobileDealBucket[]
        >({
            method: "get",
            url: `/deal-buckets?storeId=${this.store.simId}`,
        });

        if (!response.err) {
            this.dealBuckets = response.data;
        }
    });

    fetchDeals = flow(function* (
        this: AgvtHomePreviewStore,
        options: {
            dealBucketId: number;
        }
    ) {
        if (!this.store) {
            return;
        }
        this.deals = [];
        const response = yield this.rootStore.makeMobileNetworkCall<
            MobileDeal[]
        >({
            method: "get",
            url: `/deal-buckets/${options.dealBucketId}/deals?storeId=${this.store.simId}`,
        });

        if (!response.err) {
            this.deals = response.data;
        }
    });

    fetchDeal = flow(function* (
        this: AgvtHomePreviewStore,
        options: {
            dealId: number;
        }
    ) {
        if (!this.store) {
            return;
        }
        const response = yield this.rootStore.makeMobileNetworkCall<{
            deal: MobileDeal;
        }>({
            method: "get",
            url: `/deals/${options.dealId}?storeId=${this.store.simId}`,
        });

        if (!response.err) {
            return response.data.deal;
        }
    });

    @computed
    get glowConfiguration(): Record<string, never> {
        return {};
    }

    @computed
    get isCouponListVisible(): boolean {
        return (
            this.couponManager.couponList.length > 0 &&
            (this.navigation.currentPage.section === "deal-bucket" ||
                this.navigation.currentPage.section === "deals")
        );
    }
}
