import React from "react";
import style from "./style.module.scss";

export const HomePageHeader = (): JSX.Element => {
    return (
        <div className={style.header}>
            <svg
                width="32px"
                height="32px"
                viewBox="0 0 14 14"
                version="1.1"
                fill="white"
            >
                <path d="M 12.5616 11.3355 C 13.4605 10.1622 14 8.6705 14 7.0001 C 14 3.0246 10.9759 0 7 0 C 3.0239 0 0 3.0246 0 7.0001 C 0 10.9757 3.0239 14 7 14 C 8.4062 14 9.6964 13.6193 10.7748 12.9531 L 6.397 7.307 L 6.397 10.8592 L 3.9335 10.8592 L 3.9335 3.1404 L 6.397 3.1404 L 6.397 6.6932 L 8.9348 3.1404 L 11.7479 3.1404 L 9.009 6.8518 L 12.5616 11.3355 Z"></path>
            </svg>
            <p className={style.welcome_message}>
                Good morning<span>⛅</span>
            </p>
            <p className={style.easy_message}>Take it easy, Karl</p>

            <svg
                className={style.bell_svg}
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path d="M15 21c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6zm.137-17.055c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.668 2.709-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.193-10.598-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm-6.451 16c1.189-1.667 1.605-3.891 1.964-5.815.447-2.39.869-4.648 2.354-5.509 1.38-.801 2.956-.76 4.267 0 1.485.861 1.907 3.119 2.354 5.509.359 1.924.775 4.148 1.964 5.815h-12.903z" />
            </svg>
        </div>
    );
};
