import React from "react";

export const useDebounceEffect = (
    effect: React.EffectCallback,
    deps: React.DependencyList,
    delay: number
) => {
    const timeoutRef = React.useRef<number>();

    React.useEffect(() => {
        let cleanUpFn: void | (() => void);
        timeoutRef.current = window.setTimeout(() => {
            cleanUpFn = effect();
        }, delay);

        return () => {
            clearTimeout(timeoutRef.current);
            cleanUpFn?.();
        };
    }, deps);
};
