import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import {
    HelpfulSpan,
    CkLogo,
    TopHeaderPortal,
    AGVT,
    GalleryPicker,
} from "../../components";
import style from "./style.module.scss";
import { Params, INITIAL_VALUES, convertBUCodes } from "./utils";
import { ServicePayload, AssociationType } from "@full-circle-types";
import { AgvtServiceStore } from "./agvt-store";
import { Permission } from "shared-utils";
import {
    useServiceStore,
    useStoreGroupsStore,
    useAuthStore,
} from "../../contexts/mobx";
import {
    Input,
    ToggleSwitch,
    Select as EasySelect,
    Button,
} from "../../easyui-components";

const REACT_APP_SUPPORTABLE_BUS =
    process.env.REACT_APP_SUPPORTABLE_BUS?.split(",") || [];

export const ServicePage = observer(() => {
    const params = useParams<Params>();
    const serviceStore = useServiceStore();
    const storeGroupStore = useStoreGroupsStore();
    const authStore = useAuthStore();
    const { current: mobilePreviewStore } = React.useRef(
        new AgvtServiceStore()
    );

    const [assType, setAssType] = React.useState<"sim_name" | "store_group">(
        "sim_name"
    );
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(
        params.action !== "create"
    );

    const role = authStore.role;

    const {
        getValues,
        control,
        setValue,
        formState: { isValid },
    } = useForm<ServicePayload>({
        defaultValues: INITIAL_VALUES,
        mode: "onChange",
    });

    React.useEffect(() => {
        if (params.id) {
            const id = Number(params.id);
            setIsFetching(true);
            serviceStore
                .fetchSingleService(id)
                .then((service) => {
                    setAssType(service.associationType);
                    setValue("associationType", service.associationType);
                    setValue("isEnabled", service.isEnabled);
                    setValue("simName", service.simName);
                    setValue("inAppService", service.inAppService);
                    setValue(
                        "storeGroupIds",
                        service.storeGroups.map((sg) => sg.id)
                    );
                    setValue("supportedBUs", service.supportedBUs || []);
                })
                .finally(() => {
                    setIsFetching(false);
                });
        }
    }, [params.id, serviceStore, setValue]);

    React.useEffect(() => {
        storeGroupStore.fetchStoreGroupsPreview();
    }, [storeGroupStore]);

    const updateService = async () => {
        try {
            setIsUpdating(true);
            await serviceStore.updateService(Number(params.id), getValues());
        } finally {
            setIsUpdating(false);
        }
    };

    const canEdit = Permission.canEdit({
        role,
        enabled: getValues().isEnabled,
    });

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="services">
                        SERVICE {params.id}
                    </CkLogo>
                    <div className="nav_button_holder">
                        {isUpdating && <Spin />}
                        <Button
                            disabled={
                                isFetching || !canEdit || isUpdating || !isValid
                            }
                            type="primary"
                            onClick={updateService}
                        >
                            SAVE
                        </Button>
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.deal_page}>
                <div className={style.preview_holder}>
                    <AGVT store={mobilePreviewStore} />
                </div>
                <div className={style.side_form}>
                    {isFetching ? (
                        <div className={style.spinner_center}>
                            <Spin />
                        </div>
                    ) : (
                        <>
                            <Controller
                                name="isEnabled"
                                control={control}
                                render={({ field }) => (
                                    <div className={style.basic_input}>
                                        <HelpfulSpan section="enabled">
                                            Enabled
                                        </HelpfulSpan>

                                        <ToggleSwitch
                                            checked={field.value}
                                            name="isEnabled"
                                            onChange={field.onChange}
                                            disabled={
                                                !Permission.canEnable({ role })
                                            }
                                            tooltipMessage={
                                                Permission.canEnable({ role })
                                                    ? undefined
                                                    : "Only a Publisher can change the enabled value of a Service"
                                            }
                                        />
                                    </div>
                                )}
                            />

                            <div className={style.basic_input}>
                                <HelpfulSpan section="enabled">
                                    In App Service
                                </HelpfulSpan>

                                <span className={style.in_app}>
                                    {getValues().inAppService}
                                </span>
                            </div>

                            <Controller
                                name="supportedBUs"
                                control={control}
                                render={({ field }) => (
                                    <div className={style.basic_input}>
                                        <HelpfulSpan>Supported BUs</HelpfulSpan>

                                        <EasySelect
                                            mode="multiple"
                                            placeholder="Business Units"
                                            width={200}
                                            value={field.value}
                                            allowClear
                                            onChange={field.onChange}
                                        >
                                            {REACT_APP_SUPPORTABLE_BUS.map(
                                                (bu) => (
                                                    <Select.Option
                                                        value={bu}
                                                        key={bu}
                                                    >
                                                        {convertBUCodes(bu)}
                                                    </Select.Option>
                                                )
                                            )}
                                        </EasySelect>
                                    </div>
                                )}
                            />

                            <div className={style.separator}></div>

                            <Controller
                                name="associationType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div className={style.basic_input}>
                                        <HelpfulSpan>
                                            Association Type
                                        </HelpfulSpan>

                                        <EasySelect<AssociationType>
                                            placeholder="Type"
                                            width={200}
                                            value={field.value}
                                            onChange={(v) => {
                                                field.onChange(v);
                                                setAssType(v);
                                            }}
                                        >
                                            <Select.Option
                                                value={"sim_name"}
                                                key={"sim_name"}
                                            >
                                                Sim Name
                                            </Select.Option>
                                            <Select.Option
                                                value={"store_group"}
                                                key={"store_group"}
                                            >
                                                Store Group
                                            </Select.Option>
                                        </EasySelect>
                                    </div>
                                )}
                            />

                            {assType === "sim_name" && (
                                <Controller
                                    name="simName"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className={style.basic_input}>
                                            <HelpfulSpan>SIM Name</HelpfulSpan>

                                            <Input
                                                width={200}
                                                placeholder="SIM Name"
                                                value={field.value!}
                                                onChange={field.onChange}
                                            />
                                        </div>
                                    )}
                                />
                            )}

                            {assType === "store_group" && (
                                <Controller
                                    name="storeGroupIds"
                                    control={control}
                                    render={({ field }) => (
                                        <div className={style.basic_input}>
                                            <HelpfulSpan>
                                                Store Group
                                            </HelpfulSpan>

                                            <EasySelect
                                                mode="multiple"
                                                placeholder="Store Group"
                                                width={200}
                                                value={field.value}
                                                allowClear
                                                onChange={field.onChange}
                                            >
                                                {storeGroupStore.storeGroupPreviews.map(
                                                    (storeGroup) => (
                                                        <Select.Option
                                                            value={
                                                                storeGroup.id
                                                            }
                                                            key={storeGroup.id}
                                                        >
                                                            {storeGroup.name}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </EasySelect>
                                        </div>
                                    )}
                                />
                            )}

                            <div className={style.separator}></div>

                            <Controller
                                name="backgroundImage"
                                control={control}
                                render={({ field }) => (
                                    <div className={style.rectangle_image}>
                                        <HelpfulSpan section="service-background-image">
                                            Background Image
                                        </HelpfulSpan>

                                        <GalleryPicker
                                            allowDeletion
                                            size={437 - 32}
                                            dataType="service"
                                            url={field.value}
                                            onChange={field.onChange}
                                        />
                                    </div>
                                )}
                            ></Controller>
                        </>
                    )}
                </div>
            </div>
        </>
    );
});
