import React from "react";
import { observer } from "mobx-react";
import { Table, Select } from "antd";
import { ColumnProps } from "antd/es/table";

import style from "./style.module.scss";
import { useQueryParams } from "../../utils/use-query-params";
import { LoyaltyCmsDto, LoyaltyParams } from "@full-circle-types";
import { Pagination, CkLogo, TopHeaderPortal } from "../../components";
import { useNavigation } from "../../utils/use-navigation";
import { LoyaltyType } from "../../utils/const";
import {
    Input,
    Button,
    Select as EasySelect,
    Highlighter,
} from "../../easyui-components";
import { useLoyaltyStore } from "../../contexts/mobx";
import { getLoyaltyTypeDisplay } from "../../utils/content-type";

export const LoyaltiesPage = observer(() => {
    const loyaltyStore = useLoyaltyStore();
    const navigation = useNavigation();

    const [queryParams, updateQueryParams] = useQueryParams<LoyaltyParams>();

    const renderId = (_: unknown, record: LoyaltyCmsDto) => {
        return (
            <Highlighter search={queryParams.query ?? ""}>
                {record.type === "club"
                    ? record.brierleyClubId
                    : record.type === "offer"
                    ? record.brierleyOfferId
                    : record.brierleyRewardId}
            </Highlighter>
        );
    };

    const renderImage = (_: unknown, record: LoyaltyCmsDto) => {
        return (
            <img
                key="en"
                src={record.translations.en.image ?? ""}
                alt=""
                className={style.image}
                loading="lazy"
            />
        );
    };

    const renderTitle = (_: unknown, record: LoyaltyCmsDto) => {
        return (
            <Highlighter search={queryParams.query ?? ""}>
                {record.translations.en.title}
            </Highlighter>
        );
    };

    const renderType = (_: unknown, record: LoyaltyCmsDto) => {
        return getLoyaltyTypeDisplay(record.type);
    };

    const renderCategory = (_: unknown, record: LoyaltyCmsDto) => {
        return <span className="simple-tag">{record.category.title}</span>;
    };

    const columns: ColumnProps<LoyaltyCmsDto>[] = [
        {
            title: "Brierley Id",
            key: "id",
            render: renderId,
            width: "15%",
        },
        {
            title: "Image",
            key: "image",
            render: renderImage,
            width: "15%",
        },
        {
            title: "Title",
            key: "title",
            render: renderTitle,
            width: "50%",
        },
        {
            title: "Type",
            key: "type",
            render: renderType,
            width: "10%",
        },
        {
            title: "Category",
            key: "category",
            render: renderCategory,
            width: "10%",
        },
    ];

    React.useEffect(() => {
        loyaltyStore.getPaginatedLoyalty(queryParams);
    }, [queryParams, loyaltyStore]);

    return (
        <>
            <TopHeaderPortal>
                <div className="nav_header">
                    <CkLogo isBackButton section="loyalty">
                        LOYALTIES
                    </CkLogo>
                    <div className="nav_button_holder">
                        <Button
                            type="primary"
                            onClick={() => navigation.goToLoyaltyCreation()}
                        >
                            CREATE NEW
                        </Button>
                    </div>
                </div>
            </TopHeaderPortal>
            <div className={style.loyalty_page}>
                <div className={`${style.selects_row} ${style.commands}`}>
                    <EasySelect<LoyaltyType>
                        placeholder="Type"
                        width={200}
                        value={queryParams.type}
                        allowClear={true}
                        onChange={(v) =>
                            updateQueryParams({ type: v, page: 0 })
                        }
                    >
                        {LoyaltyType.map((a) => (
                            <Select.Option value={a} key={a}>
                                {a.charAt(0).toUpperCase() + a.slice(1)}
                            </Select.Option>
                        ))}
                    </EasySelect>
                    {queryParams.type === "offer" && (
                        <EasySelect
                            placeholder="Non Targeted Offers"
                            width={200}
                            allowClear
                            value={queryParams.onlyNonTargetedOffers}
                            onChange={(onlyNonTargetedOffers) =>
                                updateQueryParams({
                                    page: 0,
                                    onlyNonTargetedOffers,
                                })
                            }
                        >
                            <Select.Option value={true}>Yes</Select.Option>
                            <Select.Option value={false}>No</Select.Option>
                        </EasySelect>
                    )}
                    <Input
                        value={queryParams.query}
                        onChange={(e) =>
                            updateQueryParams({
                                query: e.target.value,
                                page: 0,
                            })
                        }
                        allowClear={true}
                        placeholder="Search"
                    />
                </div>

                <Pagination
                    params={queryParams}
                    meta={loyaltyStore.paginatedList}
                    onChange={(pageNumber, pageSize) =>
                        updateQueryParams({
                            page: pageNumber,
                            perPage: pageSize,
                        })
                    }
                    onPageSizeChange={(size) =>
                        updateQueryParams({ perPage: size })
                    }
                />
                <Table<LoyaltyCmsDto>
                    scroll={{ x: 900 }}
                    rowKey="id"
                    className={`${style.table} clickable_table`}
                    dataSource={loyaltyStore.paginatedList?.results}
                    columns={columns}
                    loading={loyaltyStore.isFetchingCount > 0}
                    pagination={false}
                    onRow={(r) => ({
                        onClick: () => navigation.goToLoyaltyEdit(r.id),
                    })}
                />
            </div>
        </>
    );
});
